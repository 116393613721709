import axios from "../../plugins/axios";

const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
    getAnalytics,
    healthCheck,
    getSensorOptions,
    getAccountOptions,
    getPollenOptions,
    getGlobalAnalytics
}
const prefixUrl = '/analytics';

function getAnalytics(data) {
    data.version = 'v2';
    return axios.get(prefixUrl + '?' + querystring.stringify(data), authHeader.classicalHeader())
}

function healthCheck() {
    return axios.get('/healthcheck');
}
function getSensorOptions(data = null) {
    return axios.get(prefixUrl + '/sensors', {...authHeader.classicalHeader(),...data})
}
function getAccountOptions() {
    return axios.get(prefixUrl + '/accounts', authHeader.classicalHeader())
}
function getPollenOptions() {
    return axios.get('/report-types', authHeader.classicalHeader())
}
function getGlobalAnalytics(data = null) {
    return axios.get(prefixUrl + '/data/' + data[0], {...authHeader.classicalHeader(),...data[1]})
}

