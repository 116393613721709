<template>
    <div ref="pollenTable" class="mx-auto mx-md-2">
        <b-table-simple
            hover
            sticky-header
            class="sensor-array table-height"
            v-if="relatedAccounts.length > 0"
        >
            <colgroup>
                <col>
                <col>
            </colgroup>
            <b-thead class="text-center">
                <b-tr>
                    <b-th>id</b-th>
                    <b-th>nom</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    v-for="(item,i) in relatedAccounts"
                    :key="i"
                    class="text-center clr-dark-blue add-cursor justify-content-start"
                >
                    <b-td class="align-middle">{{ item.id }}</b-td>
                    <b-td class="align-middle" @click="showAccountDetails(item)">{{ item.name }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <p class="clr-dark-grey text-center" v-else>Aucune ressource associée</p>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "AccountTable",
    data() {
        return {}
    },
    methods: {
        showAccountDetails(item) {
            this.selectedItem = item
            this.$router.push('/accounts/' + item.id + '/information')
        },
    },
    computed: {
        ...Vuex.mapGetters('campaign', {
            relatedAccounts: 'relatedAccounts'
        })
    },
}
</script>

<style scoped lang="scss">
</style>
