import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getReportType,
    createReportType,
    deleteReportType
}

function getReportType() {
    return axios.get('/report-types',authHeader.classicalHeader())
}
function createReportType(data) {
    return axios.post('/report-types/new',data,authHeader.classicalHeader())
}
function deleteReportType(data) {
    return axios.delete('/report-types/'+data,authHeader.classicalHeader())
}

