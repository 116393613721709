<template>
    <b-row>
        <b-col cols="12" class="row justify-content-center align-self-center mb-3 mb-md-0 my-md-5 mx-auto p-0">
            <b-col cols="12" md="6" class="float-left my-3 my-md-auto">
                <b-button @click="$router.push('/campaigns/new')" variant="primary">
                    <b-icon icon="plus-circle"></b-icon>
                    {{ $t('campaign.addNew') | capitalize }}
                </b-button>
            </b-col>
            <b-col cols="12" md="6">
                <b-input-group class="" v-on:submit.prevent="queryCampaigns"
                               v-on:keyup.enter="queryCampaigns">
                    <b-form-input v-model="search" :placeholder="$t('campaign.search') | capitalize"></b-form-input>
                    <b-input-group-append>
                        <b-button type="submit" variant="primary" @click="queryCampaigns">GO</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-col>
        <b-spinner label="Loading.." v-if="loadingStatus" class="mx-auto"/>
        <b-table-simple
            responsive
            hover
            sticky-header
            class="sensor-array table-height"
            v-else-if="campaigns.length > 0"
        >
            <colgroup>
                <col>
                <col>
            </colgroup>
            <colgroup>
                <col>
                <col>
                <col>
            </colgroup>
            <colgroup>
                <col>
                <col>
            </colgroup>
            <b-thead class="text-center">
                <b-tr>
                    <b-th>Id</b-th>
                    <b-th>Nom</b-th>
                    <b-th>Code</b-th>
                    <b-th>Début</b-th>
                    <b-th>Fin</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    v-for="(item,i) in campaigns"
                    :key="i"
                    class="text-center clr-dark-blue add-cursor"
                    :class="hoveredCampaign === item.id ? 'bg-lighter-green' : 'bg-white'"
                    @click="$router.push('/campaigns/'+item.id+'/information')"
                    @mouseover="setHoveredCampaign(item.id)"
                    @mouseout="setHoveredCampaign(null)"
                >
                    <b-td>{{ item.id }}</b-td>
                    <b-td>{{ item.name }}</b-td>
                    <b-td>{{ item.code }}</b-td>
                    <b-td>{{ item.startAt | convertDate }}</b-td>
                    <b-td>{{ item.endAt | convertDate }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <p class="text-center col-12" v-else>{{ $t('campaign.none') | capitalize}}</p>
        <div class="mt-3 col-xl-12" v-if="campaigns.length > 0">
            <h6 class="text-center">Page(s)</h6>
            <b-pagination
                v-model="paginator.current"
                pills
                :total-rows="paginator.totalCount"
                :per-page="paginator.numItemsPerPage"
                align="center"
            >
            </b-pagination>
        </div>
    </b-row>
</template>

<script>
import Vuex from 'vuex';

export default {
    name: "CampaignList",
    data() {
        return {
            isBusy: false,
            text: '',
            rows: 100,
            currentPage: 1,
            hoverCampaign: null,
            search: ''
        }
    },
    computed: {
        ...Vuex.mapGetters('campaign', {
            campaigns: 'campaigns',
            paginator: 'paginator',
            loadingStatus: 'loadingStatus'
        }),
        hoveredCampaign() {
            return this.hoverCampaign
        }
    },
    methods: {
        ...Vuex.mapActions('campaign', {
            setCampaigns: 'setCampaigns'
        }),
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        setHoveredCampaign(id) {
            this.hoverCampaign = id;
        },
        async queryCampaigns() {
            let query = {}
            if (this.paginator) {
                query.page = this.paginator.current !== 0 ? query.page = this.paginator.current : query.page = 1
            } else {
                query.page = 1
            }
            this.search.length >= 3 ? query.search = this.search : '';
            await this.setCampaigns(query)
        }
    },
    async mounted() {
        this.toggleBusy()
        await this.queryCampaigns()
        this.toggleBusy()
    },
    watch: {
        'paginator.current'() {
            this.queryCampaigns()
        },
        'search'() {
            this.queryCampaigns()
        },
    }
}
</script>

<style scoped>

</style>
