import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

const state = {
    content: [],
    locations:[],
    isContentLoading:false
};

const mutations = {
    SET_CONTENT: (state, content) => {
        state.content.unshift(
            {
                params:content[1],
                content:content[0][0],
                prompt:content[0][1],
                data:content[0][0]
            }
        );
    },
    SET_LOCATIONS: (state, locations) => {
        state.locations = locations;
    },
    SET_IS_LOADING: (state) => {
        state.isContentLoading = !state.isContentLoading;
    },
};

const actions = {
    setContent: async (store, params) => {
        await api.contentGeneration.getContent(params)
            .then(response => {
                store.commit('SET_CONTENT', [response.data.data,params])
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    setContentWithData: async (store, data) => {
        await api.contentGeneration.getContentWithData(data)
            .then(response => {
                store.commit('SET_CONTENT', [response.data.data,data[1]])
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    setLocations: async (store) => {
        await api.contentGeneration.getLocations()
            .then(response => {
                store.commit('SET_LOCATIONS', response.data.data)
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
};

const getters = {
    getContent: state => {
        return state.content
    },
    getLocations: state => {
        return state.locations
    },
    isContentLoading: state => {
        return state.isContentLoading
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
