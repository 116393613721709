import api from '@/services/api/index.js'

const state = {
    sensors: [],
    sensorsByState: {
        pollen: [],
        pollution: [],
        prod: [],
        online: [],
        quarantine: [],
        deploy: [],
        inTransit: [],
        waitingForInstallation: [],
        test: [],
        testing: [],
        stock: [],
        out: [],
        customerOut: [],
        offCycle: [],
        disconnected: []
    },
    pollenSensors: [],
    paramsByStep: {
        // Sensor Typ parameters
        pollen: {type: 'pollen', status: null, statusStep: null},
        pollution: {type: 'pollution', status: null, statusStep: null},
        // Sensor Production parameters
        prod: {type: 'pollen', status: 'prod', statusStep: null},
        online: {type: 'pollen', status: 'prod', statusStep: 1},
        warning: {type: 'pollen', status: 'prod', statusStep: 2},
        quarantine: {type: 'pollen', status: 'prod', statusStep: 3},
        // Sensor deployment parameters
        deploy: {type: 'pollen', status: 'deploy', statusStep: null},
        inTransit: {type: 'pollen', status: 'deploy', statusStep: 1},
        waitingForInstallation: {type: 'pollen', status: 'deploy', statusStep: 2},
        // Sensor test parameters
        test: {type: 'pollen', status: 'test', statusStep: null},
        testing: {type: 'pollen', status: 'test', statusStep: 1},
        tested: {type: 'pollen', status: 'test', statusStep: 2},
        // Sensor stock parameters
        stock: {type: 'pollen', status: 'stock', statusStep: null},
        // Sensor out parameters
        out: {type: 'pollen', status: 'out', statusStep: null},
        customerOut: {type: 'pollen', status: 'stock', statusStep: 1},
        offCycle: {type: 'pollen', status: 'stock', statusStep: 2}
    },
    stepByStatusName: {
        'stock': ['stocked', 'returning', 'repairing'],
        'test': ['testing', 'tested'],
        'deploy': ['in transit', 'waiting for installation'],
        'prod': ['online', 'warning', 'quarantine'],
        'out': ['customer out', 'off cycle']
    },
    paginator: {
        current: 1,
        limit: 50
    },
    loadingStatus: false
};


const mutations = {
    SET_SENSORS: (state, sensors) => {
        state.sensors = sensors;
    },
    SET_SENSORS_BY_STATE: (state, data) => {
        state.sensorsByState[data.state] = data.sensors;
    },
    SET_PAGINATOR: (state, paginator) => {
        state.paginator = paginator;
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request = requestStatus;
    },
    SET_CHOSEN_TYPE: (state, data) => {
        state.chosenType = data
    },
    SET_CHOSEN_STATUS: (state, data) => {
        state.chosenStatus = data
    },
    SET_CHOSEN_STATUS_STEP: (state, data) => {
        state.chosenStatusStep = data
    },
    SET_LOADING_STATUS: (state, value) => {
        state.loadingStatus = value;
    },
};
const actions = {
    setSensorsByState: async (store, data) => {
        store.commit('SET_LOADING_STATUS', true);
        await api.sensor.getSensors(data)
            .then(response => {
                store.commit('SET_SENSORS', response.data.data.sensors);
                store.commit('SET_SENSORS_BY_STATE', {sensors: response.data.data.sensors, state: data.state});
                store.commit('SET_PAGINATOR', response.data.data.paginator);
                store.commit('SET_LOADING_STATUS', false);
            }).catch(() => {
                store.commit('SET_LOADING_STATUS', false);
            })
    },
    setDisconnectedSensors: async (store, data) => {
        await api.sensor.getSensors(data)
            .then(response => {
                store.commit('SET_SENSORS_BY_STATE', {sensors: response.data.data.sensors, state: 'disconnected'});
            })
    },
    setDashboardLoading: (store, value) => {
        store.commit('SET_LOADING_STATUS', value);
    }
};

const getters = {
    sensors: state => {
        return state.sensors
    },
    sensorsByState: state => (sensorState) => {
        return state.sensorsByState[sensorState];
    },
    paginator: state => {
        return state.paginator
    },
    getRequest: state => {
        return state.request
    },
    getStepNameByValue: state => (status, step) => {
        return state.stepByStatusName[status][step - 1];
    },
    getLoadingStatus: state => {
        return state.loadingStatus
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
