<template>
    <div>
        <b-col cols="12">
            <b-table-simple
                responsive
                hover
                sticky-header
                borderless
                v-if="accountContact.length > 0 && !isBusy"
            >
                <b-thead>
                    <b-tr>
                        <b-th> Nom du contact</b-th>
                        <b-th> Sensor(s) Suivi</b-th>
                        <b-th> Email</b-th>
                        <b-th> Téléphone</b-th>
                        <b-th> Action</b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="(item,i) in accountContact"
                        :key="i"
                        class="add-cursor clr-dark-grey justify-content-center align-items-center"
                    >
                        <b-td class="">
                            {{ item.firstName | capitalize }} {{ item.lastName | capitalize }}
                        </b-td>
                        <b-td class="">
                            <b-badge v-for="(dataReference,i) in item.dataReferences" :key="i"
                                     class="mr-1 bg-orange clr-white">
                                {{ dataReference.sensor ? dataReference.sensor.serialNumber : '- - -' | capitalize }}
                            </b-badge>
                        </b-td>
                        <b-td class="align-middle">
                            {{ item.email }}
                        </b-td>
                        <b-td class="align-middle">
                            {{ item.phoneNumber }}
                        </b-td>
                        <b-td class="align-middle">
                            <b-row class="clr-orange p-0 m-0">
                                <!--                                <p @click="$emit('edit-contact',item)"-->
                                <b-col cols="2" @click="editContact(item)"
                                       class="justify-content-center align-items-center">
                                    <b-icon icon="pencil-fill" aria-hidden="true" class="mr-2"></b-icon>
                                </b-col>
                                <b-col cols="2" @click="deleteContact(item)" class="">
                                    <b-icon icon="dash-square" aria-hidden="true"></b-icon>
                                </b-col>
                            </b-row>
                        </b-td>
                        <b-td>
                            <b-button
                                variant="outline-primary"
                                @click.prevent="openContactModalMonitoring(item)"
                            >
                                Contacter
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
        <!--        SENSOR CONTACT MODAL-->
        <b-modal ref="contact-modal"
                 id="contact-modal"
                 ok-only
                 scrollable
                 hide-header
                 title="Contact"
                 size="lg"
                 class="p-2 clr-darker-grey bg-white"
        >
            <b-container v-if="selectedContact" fluid>
                <b-row class="p-3 my-2 justify-content-between">
                    <b-col
                        cols="12"
                        class="text-center mb-4">
                        <span class="container-title">
                            {{ selectedContact.firstName | capitalize }} {{ selectedContact.lastName | capitalize }}
                        </span>
                        <br>{{ selectedContact.position }}
                    </b-col>
                    <b-col cols="6">
                        <span class="bold clr-darker-grey">
                            <b-icon icon="at"></b-icon>
                            Email
                        </span>
                        :
                        <a
                            target="_blank"
                            :href="`mailto:${selectedContact.email}`"
                            class="clr-dark-grey">
                            {{ selectedContact.email }}
                        </a>
                    </b-col>
                    <b-col cols="6">
                        <span class="bold clr-darker-grey">
                            <b-icon icon="phone"></b-icon>
                            Téléphone
                        </span>
                        :
                        <a
                            target="_blank"
                            :href="'tel:'+selectedContact.phoneNumber"
                            class="clr-dark-grey">
                            {{ selectedContact.phoneNumber }}
                        </a>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "AccountContactTable",
    props: {
        accountContact: Array,
        dataLocalisation: Object,
        loadingStatus: Boolean,
        sort: String,
        direction: String,
        isBusy: Boolean,
    },
    data() {
        return {
            selectedItem: {},
            editAccountContact: {},
            selectedContact: null
        }
    },
    methods: {
        async handleSubmit() {
            let query = this.generateQueryString()
            await this.editAccountContact(query);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-edit-contact')
                await this.setAccounts()
            })
        },
        generateQueryString() {
            let data = []
            data.push(this.updatedItem.id)
            let fields = new FormData();

            if (this.firstName.name !== '') {
                fields.append('firstName', this.updatedItem.firstName)
            }

            if (this.lastName.name !== '') {
                fields.append('lastName', this.updatedItem.lastName)
            }

            if (this.email.logo !== '') {
                fields.append('email', this.updatedItem.email)
            }

            if (this.phoneNumber.logo !== '') {
                fields.append('phoneNumber', this.updatedItem.phoneNumber)
            }

            data.push(fields)
            return data
        },
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        editContact(value) {
            this.$emit('editContact', value)
        },
        deleteContact(value) {
            this.$emit('deleteContact', value)

        },
        openContactModalMonitoring(item) {
            this.selectedContact = item;
            this.$bvModal.show('contact-modal')
        }
    }
}
</script>

<style scoped lang="scss">

#right-click-menu {
    background: #FAFAFA;
    border: 1px solid #BDBDBD;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 250px;
    z-index: 999999;
}

#right-click-menu li {
    border-bottom: 1px solid #E0E0E0;
    margin: 0;
    padding: 5px 35px;
}

#right-click-menu li:last-child {
    border-bottom: none;
}

#right-click-menu li:hover {
    background: $primary;
    color: #FAFAFA;
}

.dot {
    margin: 5px 0;
}
</style>
