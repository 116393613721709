<template>
    <div class="w-100">
        <b-table-simple
            responsive
            hover
            sticky-header
            borderless
            v-if="events.length > 0"
        >
            <b-thead>
                <b-tr>
                    <b-th>
                        Type
                    </b-th>
                    <b-th>
                        Description
                    </b-th>
                    <b-th>

                    </b-th>
                    <b-th>
                        Date
                    </b-th>
<!--                    <b-th>-->
<!--                        Terminé le-->
<!--                    </b-th>-->
                    <b-th>
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody v-if="isBusy">
                <b-tr v-for="i in 8" :key="'skeleton_' + i">
                    <b-td colspan="8">
                        <b-skeleton
                            animation="fade"
                            :width="(Math.floor(Math.random() * (95 - 85 + 1)) + 85) + '%'"/>
                    </b-td>
                </b-tr>
            </b-tbody>
            <b-tbody v-else>
                <b-tr
                    v-for="(event,i) in events"
                    :key="i"
                    :class="hoveredEvent === event.id ? 'bg-lighter-orange' : 'bg-white'"
                    @mouseover="setHoveredEvent(event.id)"
                    @mouseout="setHoveredEvent(null)"
                >
                    <b-td style="width: 10%">
                        <b-badge :class="contentByType[event.type].class" class="font-weight-normal p-2">
                            <b-icon :icon="contentByType[event.type].icon"/>
                            {{ contentByType[event.type].text }}
                        </b-badge>
                    </b-td>
                    <b-td class="text-left" style="width: 50%">
                        <p class="m-0">
                            <span class="bold">{{ event.title }}</span>
                            <br>{{ event.context }}
                        </p>
                    </b-td>
                    <b-td style="width: 30%">
                        {{ event.createdAt | readableDate }} - {{ event.endedAt | readableDate }}
                    </b-td>
                    <b-td style="width: 10%" class="text-right">
                        <b-button @click="showEvent(event)" variant="outline-dark">
                            <b-icon icon="eye"/>
                        </b-button>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-modal
            hide-footer
            hide-header
            hide-header-close
            id="event-details"
            body-class="p-0"
            size="xl">
            <SensorEventDetails
                v-if="selectedEvent"
                :event="selectedEvent"
                from="SensorEventHistory"
                :key="'selected_event_' + selectedEvent.id"/>
        </b-modal>
    </div>
</template>

<script>

import SensorEventDetails from "@/components/sensor/Detail/SAV/SensorEventDetails.vue";

export default {
    name: "SensorEventHistory",
    components: {SensorEventDetails},
    props: {
        events: {
            type: Array,
            default: () => {
                return []
            }
        },
        isBusy: Boolean,
    },
    data() {
        return {
            hoveredEvent: null,
            contentByType: {
                incident: {
                    text: 'Incident',
                    icon: 'exclamation-circle-fill',
                    backgroundColor: 'bg-pink-seashell',
                    color: 'clr-white',
                    class: 'clr-white bg-danger'
                }
            },
            advancedMode: false,
            expanded: false,
            icons: [
                {value: 'box-arrow-right'},
                {value: 'box-arrow-left'},
                {value: 'cloud-arrow-down'},
                {value: 'info-circle'},
                {value: 'pen'},
                {value: 'x-circle'},
                {value: 'wrench'},
                {value: 'tools'},
                {value: 'box-seam'},
            ],
            type: [
                {value: 'light', text: 'défaut'},
                {value: 'primary', text: 'information'},
                {value: 'warning', text: 'attention'},
                {value: 'danger', text: 'important'},
            ],
            customToolbar: [
                ["bold", "italic", "underline"],
                [{list: "ordered"}, {list: "bullet"}],
                ['link']
            ],
            selectedEvent: null
        }
    },
    methods: {
        expand(idx) {
            if (!this.expanded) {
                this.expanded = idx;
            } else if (this.expanded && idx && this.expanded !== idx) {
                this.expanded = idx;
            } else if (this.expanded === idx) {
                this.expanded = false;
            }
        },
        showHue(item) {
            if (item && item.context) {
                return !!(item.context.length > 300 || item.context.includes('<br'));
            }
        },
        setHoveredEvent(id) {
            this.hoveredEvent = id;
        },
        showEvent(event) {
            this.selectedEvent = event;
            this.$bvModal.show('event-details')
        }
    },
    computed: {
        histories() {
            const sensorHistories = this.event.sensorHistories;
            return sensorHistories.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
    }
}
</script>

<style scoped lang="scss">

li {
    list-style-type: none;
}

.shadow-bottom {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.wrapper {
    max-height: 500px;
    overflow: scroll;
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
    padding: 1em;

    &::-webkit-scrollbar {
        display: none;
    }
}

.timeline {
    padding-left: 75px;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: fit-content;

    .card-title {
        color: black;
        font-size: 20px;
    }

    .card-footer {
        color: black;
        font-size: 14px;
    }

    &:before {
        content: "";
        position: absolute;
        top: -20px;
        bottom: 0;
        width: 4px;
        left: 23px;
        color: #8993A6;
        background: linear-gradient(
                0deg,
                rgba(137, 147, 166, 0) 0%,
                rgba(137, 147, 166, 0.75) 10%,
                rgba(137, 147, 166, 1) 20%,
                rgb(137, 147, 166) 80%,
                rgba(137, 147, 166, 0.75) 90%,
                rgba(137, 147, 166, 0) 100%
        );
    }

    .timelineItem {
        position: relative;
        margin-bottom: 2rem;

        &.expanded {
            .hue {
                display: none;
            }

            @media screen and (min-width: 800px) {
                > .b-avatar {
                    transform: translate(120%, -17px);
                }
                .card {
                    transform: translateX(-3%) scale(1.06);
                }
                &.due .card {
                    animation: none;
                }
            }

            .card-text {
                max-height: 100%;
                overflow: visible;
                white-space: normal;
            }
        }

        &.past {
            .timelineIcon {
                background-color: grey;
                color: #FFF;
            }
        }

        &.due .card {
            animation: vibrate-1 0.3s linear infinite both;
        }


        .timelineIcon {
            position: absolute;
            left: -67px;
            border-radius: 50%;
            top: 50%;
            transform: translate(0%, -17px);
            box-shadow: -1px 2px 2px 0px rgb(0 0 0 / 38%);
            font-size: 1.2rem;
        }

        .timelineInfo {
            width: 100%;
            min-height: 50px;
            box-shadow: 2px 2px 8px 2px rgb(0 0 0 / 10%);
        }

        .card-body {
            position: relative;
            overflow: hidden;
            padding: 15px;
        }

        .card-text {
            width: 100%;
            margin-bottom: 0;
            position: relative;
            max-height: 50px;
            overflow: hidden;
            transition: all, .5s;

            p, span {
                margin-bottom: 0 !important;
                transition: all, .5s;
                text-overflow: elipsis;
            }
        }

        span.dueDate {
            float: right;
        }
    }
}

.hue {
    width: 100%;
    height: calc(0.2 * 1vmin);
    border-radius: 100% 100% 0% 0%/100% 100% 0% 0%;
    position: absolute;
    left: 0;
    bottom: -2px;
    box-shadow: 0px -2px 7px 0px #000;
}
</style>