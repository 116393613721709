<template>
    <b-col sm="12" class="rounded shadow bg-white">
        <PDFTemplate
            :sensor="setup ? setup.sensor.serialNumber : null"
            :setup="setup"
            :onlyPreview="onlyPreview"
            @pdf-progress="onProgress"
            template="SensorTestingReportTemplate"
        ></PDFTemplate>
        <b-row class="bg-white justify-content-center align-content-center">
            <b-col cols="4"
                   class=" rounded add-cursor text-center pt-3"
                   @click="subPage = 'last'"
                   :class="subPage === 'last' ? 'clr-orange bg-white' : 'clr-dark-grey bg-lighter-grey'"
            >
                <p class="">
                    LE PLUS RÉCENT
                </p>
            </b-col>
            <b-col cols="4"
                   class="rounded add-cursor text-center pt-3"
                   @click="subPage = 'history'"
                   :class="subPage === 'history' ? 'clr-orange bg-white' : 'clr-dark-grey bg-lighter-grey'">
                <p class="">
                    HISTORIQUE
                </p>
            </b-col>
            <b-col cols="4"
                   class="rounded text-center py-2 bg-lighter-grey">
                <p class="">
                </p>
            </b-col>
        </b-row>
        <b-row class="p-0 my-3" v-if="subPage === 'last'">
            <b-col cols="12" class="bg-white ">
                <p class="text-center h4 py-2 mb-0 clr-dark-grey responsive-font">
                    Dernier test
                    <span class="clr-orange"
                          v-if="lastSetup && !lastSetup.completedAt">
                        <br/>
                        (en cours)
                    </span>
                </p>
                <b-col cols="12" class="text-center my-5" v-if="isLoading">
                    <b-spinner style="width: 3rem; height: 3rem;" variant="primary"/>
                </b-col>
                <b-row class="px-0 px-md-5" v-else-if="lastSetup">
                    <b-col cols="12" class="mt-3 text-center">
                        <b-button class="btn-action"
                                  v-if="lastSetup && !lastSetup.completedAt"
                                  @click="goToSensorTest(lastSetup.sensor.serialNumber)">
                            Reprendre
                        </b-button>
                    </b-col>
                    <b-col cols="12" class="mt-5">
                        <p>
                            Testé du<span class="bold">{{ lastSetup.createdAt | dayDate }}</span>
                            au
                            <span class="bold" v-if="lastSetup.completedAt">
                            {{ lastSetup.completedAt | dayDate }}
                            </span>
                            <span class="bold" v-else> - - -</span>
                            par<span class="font-weight-bold clr-orange"> {{ lastSetup.user.email }}</span>
                        </p>
                    </b-col>
                    <b-col cols="12" class="my-2">
                        <p class="font-weight-bold">Résumé du test effectué :</p>
                    </b-col>
                    <b-row class="col-12" v-for="(step, index) in splicedSteps" :key="index">
                        <b-row class="col-12">
                            <b-col cols="9">
                                <p>{{ step.name }}</p>
                            </b-col>
                            <b-col cols="3" class="clr-orange">
                                <p class="h4">
                                    <b-icon-check-circle v-if="step.fields.every(step => lastSetup[step] === true)"/>
                                </p>
                            </b-col>
                        </b-row>
                    </b-row>
                    <div class="divider mt-2"/>
                    <b-col cols="12" class="mt-4 mb-2">
                        <p class="font-weight-bold">Informations du capteur :</p>
                    </b-col>
                    <b-row class="col-12" v-for="(item, index) in sensorFields"
                           :key="`${item.name}_field_index_${index}`">
                        <b-row class="col-12">
                            <b-col cols="9">
                                <p>{{ item.name }}</p>
                            </b-col>
                            <b-col cols="3" class="clr-orange">
                                <p v-if="item.fields.length > 1">{{ actualSensor[item.fields[0]][item.fields[1]] }}</p>
                                <p v-else>{{ actualSensor[item.fields[0]] }}</p>
                            </b-col>
                        </b-row>
                    </b-row>
                    <div class="divider mt-2"/>
                    <b-row class="col-12 mt-4">
                        <b-col cols="10">
                            <p class="font-weight-bold">Commentaire:</p>
                        </b-col>
                    </b-row>
                    <b-row class="col-12">
                        <b-col cols="12" v-if="lastSetup.context">
                            <p>{{ lastSetup.context }}</p>
                        </b-col>
                        <b-col cols="12" v-else>
                            <p class="clr-dark-grey text-center">Aucun commentaire</p>
                        </b-col>
                    </b-row>
                    <b-row class="col-12 mt-3">
                        <b-col cols="12" md="6">
                            <div class="w-100 text-center" v-if="pdfInProgress && onlyPreview">
                                <b-spinner variant="primary"/>
                            </div>
                            <b-button
                                class="btn-action w-100"
                                @click.prevent="previewReport(lastSetup)"
                                v-else>
                                <b-icon icon="fullscreen"></b-icon>
                                Prévisualiser le rapport
                            </b-button>
                        </b-col>
                        <b-col cols="12" md="6">
                            <div class="w-100 text-center" v-if="pdfInProgress && !onlyPreview">
                                <b-spinner variant="primary"/>
                            </div>
                            <b-button
                                class="btn-action w-100"
                                @click.prevent="generateReport(lastSetup)"
                                v-else>
                                <b-icon icon="download"></b-icon>
                                Télécharger le rapport
                            </b-button>
                        </b-col>
                    </b-row>
                </b-row>
                <b-col cols="12" class="text-center my-5" v-else>
                    <p>Aucun test n'a été effectué sur ce capteur.</p>
                </b-col>
            </b-col>
        </b-row>
        <b-row class="p-0 my-3" v-else-if="subPage === 'history'">
            <b-col cols="12" class="bg-white ">
                <b-col cols="12" v-if="isLoading">
                    <b-card>
                        <b-skeleton animation="wave" width="85%"/>
                        <b-skeleton animation="wave" width="55%"/>
                        <b-skeleton animation="wave" width="70%"/>
                    </b-card>
                </b-col>
                <b-col cols="12"
                       class="sensor-monitoring text-center row"
                       v-else-if="sensorSetupsHistory.length > 0">
                    <b-table
                        responsive
                        borderless
                        striped
                        :fields="fields"
                        :items="sensorSetupsHistory"
                        class="responsive-font">
                        <template #cell(status)="data">
                            {{ data.item.completedAt ? 'Terminé' : 'En cours' }}
                        </template>
                        <template #cell(createdAt)="data">
                            {{ moment(data.value).format('YYYY-MM-DD HH:mm') }}
                        </template>
                        <template #cell(completedAt)="data">
                            {{ data.value ? moment(data.value).format('YYYY-MM-DD HH:mm') : '- - -' }}
                        </template>
                        <template #cell(action)="data">
                            <div v-if="pdfInProgress && setup.id === data.item.id">
                                <b-spinner variant="primary"/>
                            </div>
                            <div v-else>
                                <b-button class="btn-action mr-3" @click.prevent="previewReport(data.item)">
                                    <b-icon icon="fullscreen"/>
                                </b-button>
                                <b-button class="btn-action mr-3" @click.prevent="generateReport(data.item)">
                                    <b-icon icon="download"/>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" class="text-center my-5" v-else>
                    <p>Aucun test n'a été effectué sur ce capteur.</p>
                </b-col>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
import Vuex from "vuex";
import EventBus from "@/utils/event-bus";
import PDFTemplate from "@/components/pdf/PDFTemplate";
import moment from "moment";

export default {
    name: "SensorSetupList",
    components: {PDFTemplate},
    props: {
        actualSensor: Object,
    },
    data() {
        return {
            subPage: 'last',
            onlyPreview: false,
            setup: null,
            pdfDownloadProgress: 0,
            reportDownloaded: false,
            sensorFields: [
                {fields: ['serialNumber'], name: 'Numéro de série'},
                {fields: ['sensorParameters', 'simId'], name: 'Numéro de carte SIM'},
                {fields: ['sensorParameters', 'laserPower'], name: 'Puissance du laser'},
                {fields: ['sensorParameters', 'firmwareVersion'], name: 'Version du firmware'},
            ],
            fields: [
                {key: 'user.email', label: 'Testeur'},
                {key: 'status', label: 'Statut'},
                {key: 'createdAt', label: 'Créé le'},
                {key: 'completedAt', label: 'Terminé le'},
                {key: 'action', label: ' '},
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensorSetup', {
            setSensorSetupsHistory: 'setSensorSetupsHistory'
        }),
        generateReport(setup) {
            this.onlyPreview = false
            this.setup = setup;
            EventBus.$emit('generate-pdf', false);
        },
        previewReport(setup) {
            this.onlyPreview = true
            this.setup = setup;
            EventBus.$emit('generate-pdf', true);
        },
        onProgress(value) {
            this.pdfDownloadProgress = value
        },
        goToSensorTest(id) {
            this.$router.push({path: "/sensors/testing/" + id});
        },
    },
    computed: {
        moment() {
            return moment
        },
        ...Vuex.mapGetters('sensorSetup', {
            isLoading: 'isLoading',
            sensorSetupsHistory: 'sensorSetupsHistory',
            steps: 'steps'
        }),
        lastSetup() {
            return this.sensorSetupsHistory[0]
        },
        splicedSteps() {
            let steps = this.steps;
            return steps.slice(0, -1)
        },
        pdfInProgress() {
            return this.pdfDownloadProgress && this.pdfDownloadProgress < 100
        }
    },
    created() {
        this.setSensorSetupsHistory(this.actualSensor.serialNumber);
    }
}
</script>

<style scoped lang="scss">

</style>