<template>
    <b-row class="justify-content-center">
        <b-spinner v-if="loadingStatus"></b-spinner>
        <b-col lg="10" md="12" sm="12" cols="12" class="p-0" v-else>

            <!-- HEADER -->
            <b-row class="justify-content-start align-content-center align-items-center">
                <b-col cols="12">
                    <p class="h5 clr-dark-grey add-cursor responsive-font">
                        <span @click="goToUsersList">
                            <b-icon icon="chevron-left"></b-icon>
                            Retour à la liste
                        </span>
                    </p>
                </b-col>
            </b-row>

            <!-- USER INFORMATIONS -->
            <b-col cols="12" class="basic-card p-3 mt-4 mb-3">
                <b-row class="justify-content-center align-content-center">
                    <b-col cols="12" md="6">
                        <b-row>
                            <b-col cols="12">
                                <p class="h4 mb-3 clr-dark-grey">Informations Générales</p>
                            </b-col>
                            <b-col cols="12">
                                <p class="h4 mb-2">
                                    <b-icon icon="person-fill" class="mr-3"></b-icon>
                                    ID : <span class="clr-orange">{{ getUser.id }}</span>
                                </p>
                            </b-col>
                            <b-col cols="12">
                                <p class="h4 mb-2">
                                    <b-icon icon="envelope-fill" class="mr-3"></b-icon>
                                    Email : <span class="clr-orange">{{ getUser.email }}</span>
                                </p>
                            </b-col>
                            <b-col cols="12">
                                <p class="h4 mb-2">
                                    <b-icon icon="shield-lock-fill" class="mr-3"></b-icon>
                                    Rôles :
                                    <span class="h6 clr-orange"
                                          v-for="(role,i) in getUser.roles"
                                          :key="i">
                                            {{ role }} ,
                                        </span>
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-row class="h-100">
                            <b-col cols="12">
                                <p class="float-right section-title">
                                    <b-badge v-if="isSuperAdmin" class="p-2 bg-orange clr-white">
                                        SUPER ADMIN
                                    </b-badge>
                                    <b-badge v-else-if="isAdmin" class="p-2 bg-orange clr-white">
                                        ADMIN
                                    </b-badge>
                                    <b-badge v-else class="p-2 bg-orange clr-white">
                                        {{ $route.params.type | toUpperCase }}
                                    </b-badge>
                                </p>
                            </b-col>
                            <b-col cols="12" class="text-center">
                                <div v-if="getUser.memberships && Object.keys(getUser.memberships).length > 0"
                                     class="justify-content-center align-items-center">
                                    <div>
                                        <b-icon icon="person-square" :scale="6"></b-icon>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>

            <!-- ACCOUNT GROUPS ASSOCIATED WITH THIS USER -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <b-row class="justify-content-between">
                    <b-col cols="auto">
                        <p class="h4 mb-3 clr-dark-grey">Groupes associés</p>
                    </b-col>
                    <b-col cols="auto">
                        <div @click="addUserIntoGroup" class="add-cursor clr-orange mr-3">
                            <b-icon icon="plus-circle" scale="2"></b-icon>
                        </div>
                    </b-col>
                </b-row>
                <UserGroupTable
                    v-if="accountGroups.length > 0"
                    :account-group="accountGroups"
                    :actual-user="getUser"
                ></UserGroupTable>
            </b-col>

            <!-- API KEYS ASSOCIATED WITH THIS USER -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <b-row class="justify-content-between mb-3">
                    <b-col cols="auto">
                        <p class="h4 clr-dark-grey">{{ $t('apiKey.this') | capitalize }}</p>
                    </b-col>
                    <b-col cols="auto">
                        <b-button @click="addApiKey" variant="primary" class="float-right">
                            <b-icon icon="plus-circle"></b-icon>
                            {{ $t('common.button.apiKey') | capitalize }}
                        </b-button>
                    </b-col>
                </b-row>
                <b-col cols="12" v-if="getUser.apiKeyTokens && getUser.apiKeyTokens.length > 0">
                    <b-table-simple
                        responsive
                        hover
                        sticky-header
                        borderless
                    >
                        <b-thead>
                            <b-tr>
                                <b-th>Type</b-th>
                                <b-th>Key</b-th>
                                <b-th>Secret</b-th>
                                <b-th>{{ $t('data.table.field.createdAt') | capitalize }}</b-th>
                                <b-th>{{ $t('data.table.field.status') | capitalize }}</b-th>
                                <b-th></b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr
                                v-for="(token,index) in getUser.apiKeyTokens"
                                :key="index"
                                class="clr-dark-grey add-cursor"
                            >
                                <b-td>API</b-td>
                                <b-td class="clr-orange">
                                    {{ token.value.substring(0, 8) + '************' }}
                                    <b-icon icon="file-earmark" class="add-cursor"
                                            @click="copyContent(token.value)"></b-icon>
                                </b-td>
                                <b-td class="clr-orange">
                                    {{ token.secret.substring(0, 8) + '************' }}
                                    <b-icon icon="file-earmark" class="add-cursor"
                                            @click="copyContent(token.secret)"></b-icon>
                                </b-td>
                                <b-td>{{ token.createdAt }}</b-td>
                                <b-td>
                                    <b-form-checkbox v-model="token.isValid" switch :key="index"
                                                     :name="index + '-check-validate'"
                                                     v-if="editableApiKey === token" @change="updateApiKey">
                                        <p class="m-0">
                                            {{
                                                $tc('data.table.field.active', token.isValid ? 2 : 1) | capitalize
                                            }}</p>
                                    </b-form-checkbox>
                                    <b-badge :class="token.isValid ? 'bg-green' : 'bg-light-grey'"
                                             class="active-badge" v-else>{{
                                            $tc('data.table.field.active', token.isValid ? 2 : 1) | capitalize
                                        }}
                                    </b-badge>
                                </b-td>
                                <b-td>
                                    <b-icon icon="pencil-fill" class="clr-dark-blue mr-2"
                                            @click="choseApiKey(token)"></b-icon>
                                    <b-icon icon="x-circle-fill" class="clr-red"
                                            @click="removeApiKey(token.id)"></b-icon>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <div v-else>
                    <p class="text-center clr-dark-grey">{{ $t('apiKey.none') | capitalize }}</p>
                </div>
            </b-col>

            <!-- USER PARAMETERS -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <b-row>
                    <b-col cols="12" class="user-action add-cursor" @click="gotToUserEdit">
                        <p class="h4 my-2">
                            <b-icon icon="pencil" class="mr-3"></b-icon>
                            {{ $t('common.button.edit') | capitalize }}
                            <b-icon icon="chevron-right" class="float-right"></b-icon>
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="user-action add-cursor"
                           @click="gotToUserAssociationToAccount">
                        <p class="h4 my-2">
                            <b-icon icon="pencil" class="mr-3"></b-icon>
                            {{ $t('common.button.associateAccount') | capitalize }}
                            <b-icon icon="chevron-right" class="float-right"></b-icon>
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="user-action add-cursor"
                           @click="gotToUserRoles">
                        <p class="h4 my-2">
                            <b-icon icon="shield-check" class="mr-3"></b-icon>
                            {{ $t('common.button.manageRoles') | capitalize }}
                            <b-icon icon="chevron-right" class="float-right"></b-icon>
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="user-action add-cursor" @click="askDeleteUser">
                        <p class="h4 my-2">
                            <b-icon icon="trash" class="mr-3"></b-icon>
                            {{ $t('common.button.delete') | capitalize }}
                            <b-icon icon="chevron-right" class="float-right"></b-icon>
                        </p>
                    </b-col>
                </b-row>
            </b-col>

            <b-modal
                id="modal-add-group-to-user"
                ref="modal"
                centered
                :title="'Ajout d\'un groupe a l\'utilisateur'"
                @ok="handleOk"
            >
                <p>Vous êtes sur le point d'ajouter un groupe</p>
                <b-form @submit.stop.prevent="handleSubmit" v-if="accountGroupsOptions.length > 0">
                    <b-form-group
                        id="input-group-1"
                        label="Nom du groupe :"
                        label-for="input-1"
                        description="Il représente le sous-groupe ou le sous-client du compte"
                    >
                        <b-form-select
                            id="input-1"
                            v-model="newAccountGroup.name"
                            :options="accountGroupsOptions"
                            placeholder="Entrez le nom du groupe"
                            required
                        ></b-form-select>
                    </b-form-group>
                </b-form>
                <p class="clr-orange" v-else>L'utilisateur est déjà associé à tous les groupes possible.</p>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex';
import Toast from "@/services/swal2/mixins"
import UserGroupTable from "@/components/user/UserGroupTable";

export default {
    name: "UserInformation",
    components: {UserGroupTable},
    data() {
        return {
            editableApiKey: null,
            newAccountGroup: {}
        }
    },
    methods: {
        ...Vuex.mapActions('user', {
            setUsers: 'setUsers',
            deleteUser: 'deleteUser',
            generateApiKey: 'generateApiKey',
            editApiKey: 'editApiKey',
            deleteApiKey: 'deleteApiKey',
            deleteLinkedUserGroup: 'deleteLinkedUserGroup',
            linkedUserToGroup: 'linkedUserToGroup',
            setUser: 'setUser'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        askDeleteUser() {
            this.$swal.fire({
                title: 'Etes vous sur de vouloir supprimer cet utilisateur ?',
                text: "Cette action est irréversible",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, je suis sur!'
            }).then((result) => {

                if (result.value) {
                    this.deleteUser(this.$route.params.id)
                }
            })
        },
        goToUsersList() {
            this.$router.push(`/users/${this.$route.params.type}`);
        },
        gotToUserEdit() {
            this.$router.push(`/users/${this.$route.params.type}/${this.$route.params.id}/edit`);
        },
        gotToUserAssociationToAccount() {
            this.$router.push(`/users/${this.$route.params.type}/${this.$route.params.id}/account`);
        },
        gotToUserRoles() {
            this.$router.push(`/users/${this.$route.params.type}/${this.$route.params.id}/roles`);
        },
        addApiKey() {
            this.$swal.fire({
                title: this.$options.filters.capitalize(
                    this.$t('common.button.areYouSure', {action: this.$t('common.button.apiKey')})
                ),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.$options.filters.capitalize(this.$t('common.button.cancel')),
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: this.$options.filters.capitalize(this.$t('common.button.ImSure')),
            }).then((result) => {
                if (result.value) {
                    this.generateApiKey(this.$route.params.id).then(() => {
                        this.setUser(this.$route.params.id);
                    });
                }
            })
        },
        copyContent(text) {
            navigator.clipboard.writeText(text);
            Toast.fire({
                icon: 'success',
                title: this.$options.filters.capitalize(this.$t('common.toast.copy', {item: this.$t('apiKey.this')})),
            })
        },
        choseApiKey(apiKey) {
            this.editableApiKey = this.editableApiKey === apiKey ? null : apiKey
        },
        updateApiKey() {
            let data = {
                id: this.editableApiKey.id,
                params: {
                    isValid: this.editableApiKey.isValid
                }
            }
            this.editApiKey(data).then(() => {
                this.setUser(this.$route.params.id)
            });
        },
        removeApiKey(id) {
            this.$swal.fire({
                title: this.$options.filters.capitalize(
                    this.$t('common.button.askDeletion', {action: this.$t('apiKey.this')})
                ),
                text: "Cette action est irréversible",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.$options.filters.capitalize(this.$t('common.button.cancel')),
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: this.$options.filters.capitalize(this.$t('common.button.ImSure')),
            }).then((result) => {
                if (result.value) {
                    this.deleteApiKey(id).then(() => this.setUsers());
                }
            })
        },
        addUserIntoGroup() {
            this.$bvModal.show('modal-add-group-to-user')
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            let data = []
            data.push(this.getUser.id)
            let fields = new FormData();

            if (this.newAccountGroup.name !== '') {
                fields.append('accountGroup', this.newAccountGroup.name)
            }
            data.push(fields)

            await this.linkedUserToGroup(data);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-add-group')
                await this.setUsers()
            })
        },
    },
    computed: {
        ...Vuex.mapGetters('user', {
            getUsersById: 'getUsersById',
            users: 'users',
            getRequest: 'getRequest',
            getUser: 'getUser',
            loadingStatus: 'loadingStatus'
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
        accountGroups() {
            let accountsGroups = []
            if (this.getUser.memberships) {
                for (let i = 0; i < this.getUser.memberships.length; i++) {
                    accountsGroups.push(this.getUser.memberships[i].accountGroup)
                }
            }
            return accountsGroups
        },
        accountGroupsOptions() {
            let availableGroups = []

            if (this.accountGroups.length > 0) {
                const groupNames = this.accountGroups.map(item => item.name);
                let actualAccount = this.getUser.memberships[0].accountGroup.account.name
                actualAccount = this.accounts.filter(account => account.name === actualAccount)[0]
                if (actualAccount && actualAccount.accountGroups.length > 0) {
                    actualAccount.accountGroups.forEach(group => {
                        if (!groupNames.includes(group.name)) {
                            availableGroups.push(group.name)
                        }
                    })
                }

            }

            return availableGroups
        },
        isSuperAdmin() {
            return this.getUser.roles.includes('ROLE_SUPER_ADMIN')
        },
        isAdmin() {
            return this.getUser.roles.includes('ROLE_ADMIN')
        }
    },
    async created() {
        await this.setUser(this.$route.params.id);
        if (this.accounts.length < 1) {
            await this.setAccounts()
        }
    },
    watch: {
        getRequest: function () {
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
                timer: 1000
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.user-action {
    &:hover {
        background-color: rgba($orange, 0.1);
    }
}

.active-badge {
    font-size: 1rem;
    padding: 5px 10px;
}
</style>
