<template>
    <div class="col-xl-4"></div>
</template>

<script>
export default {
name: "AWSServices"
}
</script>

<style scoped>

</style>