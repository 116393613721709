<template>
    <div class="row">
        <div class="col-xl-12">
            <p class="clr-white bg-dark-grey rounded p-1 my-2">HTML</p>
            <br>
            <b-form-textarea
                    id="textarea"
                    v-model="html"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
            ></b-form-textarea>
            <br>
            <b-button class="btn text-center float-right" variant="primary" @click="copyURL()">Copy HTML to clipboard</b-button>
        </div>
    </div>
</template>

<script>
    import Toast from "@/services/swal2/mixins";
    export default {
        name: "IframeCode",
        props:{
            html:String
        },
        methods:{
            copyURL() {
                navigator.clipboard.writeText(this.html);
                Toast.fire('HTML Copied!','',"success")
            }
        }
    }
</script>

<style scoped></style>