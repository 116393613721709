<template>
    <div class="row">
        <div class="my-5 offset-4 col-xl-4 text-center">
                <b-form-group
                        class="mb-0"
                        label="Id du capteur"
                        label-for="sensor"
                >
                    <b-form-select
                            id="input-formatter"
                            v-model="sensorId"
                            :options="sensorsOptions"
                            placeholder="Entrez le numéro de série du capteur - Format d'Exemple : 2007-2-1-A"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                        class="mb-0"
                        label="Nombre de ligne"
                        label-for="number of line"
                >
                <b-form-select v-model="limit" :options="limitOptions"></b-form-select>
                </b-form-group>
                <p><b>Id choisi:</b> {{ sensorId }} / <b>Nombre de ligne:</b> {{ limit }}</p>
            <b-button @click="getDataFromDynamoDb()" variant="primary">Demander les dernières données de ce capteur</b-button>
        </div>
        <div v-if="dynamoValues">
            <b-card-group v-for="(item,i) in dynamoValues" :key="i" class="mb-xl-2">
                <b-card :header="'Données DynamoDB du '+item.date.S" border-variant="primary" header-border-variant="primary" header-class="bg-blue font-weight-bolder clr-white">
                    <div class="card-text mt-2">
                        <p class="font-weight-bolder">Données Environnementales Instantanées : </p>
                        <b-table-simple hover small caption-top responsive>
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th colspan="2">Temperature (°C)</b-th>
                                    <b-th colspan="2">Humidity (%)</b-th>
                                    <b-th colspan="2">Altitude (m)</b-th>
                                    <b-th colspan="2">GPS Localisation</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td colspan="2" > {{item.temperature.N}}</b-td>
                                    <b-td colspan="2" > {{item.humidity.N}}</b-td>
                                    <b-td colspan="2" > {{item.altitude.N}}</b-td>
                                    <b-td colspan="2" > [{{item.longitude.N}} , {{item.latitude.N}}]</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <br>
                        <p class="font-weight-bolder">Données des Voies de Flux: </p>
                        <b-table-simple hover small caption-top responsive>
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th colspan="2"></b-th>
                                    <b-th colspan="2">Voie 1</b-th>
                                    <b-th colspan="2">Voie 2</b-th>
                                    <b-th colspan="2">Voie 3</b-th>
                                    <b-th colspan="2">Voie 4</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-th colspan="2" > Offset</b-th>
                                    <b-td colspan="2" > {{ JSON.parse(item.data.S)[1] }}</b-td>
                                    <b-td colspan="2" > {{ JSON.parse(item.data.S)[21] }}</b-td>
                                    <b-td colspan="2" > {{ JSON.parse(item.data.S)[41] }}</b-td>
                                    <b-td colspan="2" > {{ JSON.parse(item.data.S)[61] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="2">Values</b-th>
                                    <b-td colspan="2">
                                        {{ JSON.parse(item.data.S).slice(2, -60) }}
                                    </b-td>
                                    <b-td colspan="2">
                                        {{ JSON.parse(item.data.S).slice(22, -40) }}
                                    </b-td>
                                    <b-td colspan="2">
                                        {{ JSON.parse(item.data.S).slice(42, -20) }}
                                    </b-td>
                                    <b-td colspan="2">
                                        {{ JSON.parse(item.data.S).slice(62) }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </b-card>
            </b-card-group>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import AWS from "aws-sdk";

export default {
    name: "AWS",
    data() {
        return {
            dynamoValues: [],
            sensorId: '',
            limit: 1,
            limitOptions: [1,10,100,250],
            sensorsOptions:[]
        }
    },
    methods:{
        ...Vuex.mapActions('sensor',{
            setSensors:'setSensors'
        }),
        async getDataFromDynamoDb(){
            AWS.config.update({
                accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
                region: process.env.VUE_APP_AWS_DEFAULT_REGION
            });

            let dynamoDB = new AWS.DynamoDB();

            let params = {
                TableName : "reports",
                KeyConditionExpression: "id = :id",
                ExpressionAttributeValues: {
                    ":id": {'S': this.sensorId}
                },
                Limit: 2000000,
                ScanIndexForward: false,
            };
            await dynamoDB.query(params, (err, data) => {
                if (err) {
                    console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                } else {

                    this.dynamoValues = data.Items;
                    // let formattedData =  AWS.DynamoDB.Converter.output(data);
                }
            });
        }
    },
    computed:{
        ...Vuex.mapGetters('sensor',{
            sensors:'sensors'
        }),
        sensorsIds(){
            return this.sensorsOptions
        }
    },
    async mounted() {
        if (this.sensors.length === 0){
            await this.setSensors({paginated: false})
        }

        for (let i = 0 ; i < this.sensors.length;i++) {
            this.sensorsOptions.push({ value: this.sensors[i].serialNumber, text: this.sensors[i].serialNumber})
        }
    }
}
</script>

<style scoped>

</style>