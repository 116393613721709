<template>
    <b-col cols="12" v-if="isSensorMonitoringLoading">
        <b-card>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
        </b-card>
    </b-col>
    <b-col
        cols="12"
        class="sensor-monitoring row"
        v-else-if="undefined !== getCurrentSensorMonitoringBySensor && Object.keys(getCurrentSensorMonitoringBySensor).length > 0">
        <b-table
            responsive
            borderless
            striped
            v-for="(error, index) in errors"
            :key="'table_' + index"
            :items="error"
            :fields="fields"
            class="monitoring-table col-12 col-md-6 responsive-font">
            <template #cell(icon)="data">
                <b-icon :icon="data.value"/>
            </template>
            <template #cell(status)="data">
                <div class="monitoring-icon text-right"
                     :class="getErrorData(data.item.context) ? 'fail' : ''">
                </div>
            </template>
            <template #cell(updatedAt)="data">
                <p v-if="getErrorData(data.item.context) && data.item.context === 'ERROR_DISCONNECTION'"
                   v-b-tooltip.hover
                   :title="sensor.lastUpdate | basicDate">
                    {{ sensor.lastUpdate | dateDayMonth }}
                </p>
                <p v-else-if="data.item.context && getErrorData(data.item.context)"
                   v-b-tooltip.hover
                   :title="getErrorData(data.item.context).createdAt | basicDate">
                    {{ getErrorData(data.item.context).createdAt | dateDayMonth }}
                </p>
                <p v-else-if="data.item.context">-</p>
            </template>
        </b-table>
    </b-col>
    <b-col cols="12" class="clr-darker-grey bg-lighter-grey text-center" v-else>
        Aucune données de monitoring pour le moment. Le capteur à probablement été initialisé il y a peu.
    </b-col>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "SensorMonitoring",
    props: {
        sensor: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            errors: [
                [
                    {icon: 'battery-charging', text: 'DISCONNECTION', context: 'ERROR_DISCONNECTION'},
                    {icon: 'phone-vibrate', text: 'GSM', context: 'ERROR_GSM'},
                    {icon: 'geo-alt', text: 'GPS', context: 'ERROR_GPS'},
                    {icon: 'x-square', text: 'NOISE', context: 'ERROR_NOISE'},
                    {icon: 'node-plus', text: 'COUNTING', context: 'ERROR_NO_COUNTING'}
                ],
                [
                    {icon: 'asterisk', text: 'PHOTODIODE 1', context: 'ERROR_PHOTODIODE_BOARD_1'},
                    {icon: 'asterisk', text: 'PHOTODIODE 2', context: 'ERROR_PHOTODIODE_BOARD_2'},
                    {icon: 'asterisk', text: 'PHOTODIODE 3', context: 'ERROR_PHOTODIODE_BOARD_3'},
                    {icon: 'asterisk', text: 'PHOTODIODE 4', context: 'ERROR_PHOTODIODE_BOARD_4'},
                    {icon: 'lamp', text: 'ALL PHOTODIODE BOARD', context: 'ERROR_ALL_PHOTODIODE_BOARD'}
                ],
                [
                    // {icon: 'key', text: 'ID', context: 'ERROR_ID'},
                    {icon: 'soundwave', text: 'OFFSET LOW 1', context: 'ERROR_OFFSET_LOWV1'},
                    {icon: 'soundwave', text: 'OFFSET LOW 2', context: 'ERROR_OFFSET_LOWV2'},
                    {icon: 'soundwave', text: 'OFFSET LOW 3', context: 'ERROR_OFFSET_LOWV3'},
                    {icon: 'soundwave', text: 'OFFSET LOW 4', context: 'ERROR_OFFSET_LOWV4'},
                    {icon: 'sun', text: 'LASER', context: 'ERROR_LASER'}
                ],
                [
                    // {icon: 'key', text: 'ID', context: 'ERROR_ID'},
                    {icon: 'soundwave', text: 'OFFSET HIGH 1', context: 'ERROR_OFFSET_HIGHV1'},
                    {icon: 'soundwave', text: 'OFFSET HIGH 2', context: 'ERROR_OFFSET_HIGHV2'},
                    {icon: 'soundwave', text: 'OFFSET HIGH 3', context: 'ERROR_OFFSET_HIGHV3'},
                    {icon: 'soundwave', text: 'OFFSET HIGH 4', context: 'ERROR_OFFSET_HIGHV4'}
                ],
                // [
                    // {icon: 'droplet-half', text: 'PTU', context: 'ERROR_PTU'},
                // ]
            ],
            fields: [
                'icon',
                'text',
                'status',
                'updatedAt'
            ],
            allPhotoDiodes: false,
            photodiodesErrors: [],
        }
    },
    methods: {
        ...Vuex.mapActions('monitoring', {
            setCurrentSensorMonitoringBySensor: 'setCurrentSensorMonitoringBySensor'
        }),
        getErrorData(error) {
            let foundItem = this.getCurrentSensorMonitoringBySensor['errors'][error]
            if (error !== 'ERROR_ALL_PHOTODIODE_BOARD') {
                if (error.includes('PHOTODIODE') && !this.photodiodesErrors.includes(error) && foundItem) {
                    this.photodiodesErrors.push(error)
                }
                return foundItem ? foundItem : null;
            } else {
                if (this.photodiodesErrors.length === 4) {
                    let item = this.getCurrentSensorMonitoringBySensor['errors']['ERROR_PHOTODIODE_BOARD_4']
                    return {
                        createdAt: item.createdAt,
                        context: 'ERROR_ALL_PHOTODIODE_BOARD'
                    };
                }
                return null;
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('monitoring', {
            getCurrentSensorMonitoringBySensor: 'getCurrentSensorMonitoringBySensor',
            isSensorMonitoringLoading: 'isSensorMonitoringLoading'
        })
    },
    mounted() {
        this.setCurrentSensorMonitoringBySensor(this.sensor.serialNumber);
    }
}
</script>

<style lang="scss">
.sensor-monitoring {
    .monitoring-table {
        thead {
            display: none;
        }
        .monitoring-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $cadetblue;
            color: $white;

            &.fail {
                background-color: $indianred;
            }
        }
    }
}
.sensor-monitoring td {
    width: 30%;
}

.sensor-monitoring td:first-child {
    width: 10%;
}


.responsive-font {
    font-size: calc(15px + 0.390625vw)
}
</style>