<template>
    <div class=" align-self-center justify-content-center w-100">
        <b-form @submit.prevent="submit()">
            <b-form-group
                id="input-group-4"
                label="Compte Associé"
                label-for="account"
            >
                <b-form-select v-model="chosenAccount" :options="loadedAccounts" class="mb-3"
                               v-if="optionsAccounts"></b-form-select>
                <b-spinner variant="primary" label="Spinning" v-else></b-spinner>
            </b-form-group>
            <b-form-group
                id="input-group-4"
                label="Compte Associé"
                label-for="accountGroup"
                v-show="chosenAccount && !version"
            >
                <b-form-select v-model="chosenAccountGroup" :options="loadedAccountGroups" class="mb-3">
                </b-form-select>
            </b-form-group>
            <b-form-group
                    label="Veuillez choisir le ou les groupes :"
                    v-slot="{ ariaDescribedby }"
                    label-for="accountGroups" class="dataAcceFormSubTitle clr-dark-grey" v-show="chosenAccount && version">
                <b-form-select v-model="chosenAccountGroups" multiple :select-size="4" id="accounts" required :state="chosenAccountGroups.length > 0 ? true : null">
                    <b-form-select-option :aria-describedby="ariaDescribedby"
                                          :value="group.id" v-for="(group, index) in loadedAccountGroups" :key="index">
                        {{ group.name }}
                    </b-form-select-option>
                </b-form-select>
                <b-form-text id="input-live-help" class="clr-light-grey">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
            </b-form-group>
            <b-form-group
                v-show="chosenAccountGroup || chosenAccountGroups.length > 0"
                id="input-group-1"
                label="Environnement"
                label-for="environment"
            >
                <b-form-select v-model="status" :options="loadedStatus" class="mb-3"></b-form-select>
            </b-form-group>
            <div class="text-center">
                <b-button type="submit" variant="primary" class="mr-2">Valider</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorEditStocked",
    props: {
        sensors: Array,
        version:{
            type:String,
            default:null
        }
    },
    data() {
        return {
            chosenAccount: null,
            chosenAccountGroup: null,
            chosenAccountGroups: [],
            sensor: null,
            optionsAccounts: [],
            optionsAccountGroups:[],
            status: null,
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensor: 'editSensor',
            stockSensor:'stockSensor',
            setStockedSensors: 'setStockedSensors'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts',
        }),
        submit() {
            for (let i = 0; i < this.sensors.length; i++) {
                let query = this.generateQueryString(this.sensors[i])

                if (this.version){
                    this.stockSensor(query)
                } else {
                    this.editSensor(query);
                }

            }

            setTimeout(() => {
                this.$bvModal.hide('modal-unstock-sensors');
                this.$emit('refresh-sensors');
            }, 1000);
        },
        generateQueryString(sensor) {
            let data = []
            data.push(sensor.serialNumber)

            let fields = new FormData();

            if  (this.version){
                if (this.chosenAccountGroups !== null) {
                    if (this.chosenAccountGroups.length > 0) {
                        for (let z = 0; z < this.chosenAccountGroups.length; z++) {
                            fields.append('accountGroups[]', this.chosenAccountGroups[z]);
                        }
                    }
                }

                if (this.status !== null) {
                    fields.set('status', this.status)
                }

                data.push(fields)

                return data
            } else {

                if (this.chosenAccount !== null) {
                    fields.set('account', this.chosenAccount)
                }

                if (this.chosenAccountGroup !== null) {
                    fields.set('accountGroup', this.chosenAccountGroup)
                }

                if (this.status !== null) {
                    fields.set('status', this.status)
                }

                data.push(fields)
                return data
            }
        },
        async setOptionsAccounts(){
            for (let i = 0 ; i < this.accounts.length; i++){
                this.optionsAccounts.push(this.accounts[i].name)
            }
        },
        setOptionsAccountGroups(){
            if (this.chosenAccount){
                let accountList = this.accounts
                this.optionsAccountGroups = []
                let selectedAccount = accountList.filter(item => item.name === this.chosenAccount)[0]
                for (let i = 0 ; i < selectedAccount.accountGroups.length; i++){
                    this.optionsAccountGroups.push(selectedAccount.accountGroups[i])
                }
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
        }),
        loadedAccounts(){
            this.setOptionsAccounts()
            return this.optionsAccounts
        },
        loadedAccountGroups(){
            this.setOptionsAccountGroups()
            return this.optionsAccountGroups
        },
        loadedStatus() {
            const isDisable = this.chosenAccount !== 'LIFYAIR';
            return [
                {value: 'deploy', text: 'En Déploiement (sur le terrain)'},
                {value: 'test', text: 'En Test (chez LifyAir)', disabled: isDisable},
                {value: 'stock', text: 'En Stock (chez LifyAir)', disabled: isDisable},
                {value: 'prod', text: 'En Production (sur le terrain)'},
            ];
        }
    },
    async created() {
        if (this.accounts.length === 0) {
            await this.setAccounts()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
