import axios from "../../plugins/axios";
import authHeader from "./authHeader";

const prefixAdmin = '/admin/account-groups';

export default {
    getAccounts,
    getAccountsGroups,
    getAccountUserList,
    createAccount,
    updateAccount,
    deleteAccount,
    createAccountGroup,
    createAccountContact,
    editAccountContact,
    deleteAccountGroup,
    updateAccountGroup,
    deleteAccountContact
}

function getAccounts(data) {
    const params = new URLSearchParams(data)
    return axios.get('/accounts?' + params, authHeader.classicalHeader())
}

function getAccountsGroups(data) {
    return axios.get('/account-groups/' + data, authHeader.classicalHeader())
}

function getAccountUserList(data){
    return axios.get('/accounts/' + data.account + '/users' , authHeader.classicalHeader())
}

function createAccount(data) {
    return axios.post('/accounts/new', data, authHeader.formDataHeader())
}

function createAccountGroup(data) {
    return axios.post(prefixAdmin + '/new', data, authHeader.formDataHeader())
}

function createAccountContact(data) {
    return axios.post('/accounts/' + data[0] + '/contacts/new', data[1], authHeader.formDataHeader())
}

function editAccountContact(data) {
    return axios.post('/accounts/' + data[0] + '/contacts/' + data[1], data[2], authHeader.formDataHeader())
}

function deleteAccountContact(data) {
    return axios.delete('/accounts/' + data[0] + '/contacts/' + data[1], authHeader.formDataHeader())
}

function updateAccount(data) {
    return axios.post('/accounts/' + data[0], data[1], authHeader.formDataHeader())
}

function updateAccountGroup(data) {
    return axios.post(prefixAdmin + '/' + data[0], data[1], authHeader.formDataHeader())
}

function deleteAccount(data) {
    return axios.delete('/accounts/' + data, authHeader.classicalHeader())
}

function deleteAccountGroup(data) {
    return axios.delete(prefixAdmin + '/' + data, authHeader.classicalHeader())
}
