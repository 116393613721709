<template>
    <div class="container">
        <div class="row shadow rounded p-3">
            <div class="col-xl-12">
                <h2 class="justify-content-center align-self-center">Générateur de Widget</h2>
                <IframeParameters v-on:html-code="updateHtmlCode"></IframeParameters>
                <IframeCode :html="strFrame"></IframeCode>
                <IframePreview :html="htmlIFrame"></IframePreview>
            </div>
        </div>
    </div>
</template>

<script>
import IframeParameters from "@/components/iframe/IframeParameters";
import IframePreview from "@/components/iframe/IframePreview";
import IframeCode from "@/components/iframe/IframeCode";

export default {
    name: "IframeGenerator",
    components: {IframeCode, IframePreview, IframeParameters},
    data() {
        return {
            strFrame: '<iframe></iframe>',
            htmlIFrame: '<iframe></iframe>'
        }
    },
    methods: {
        updateHtmlCode(iframe) {
            this.strFrame = iframe.outerHTML
            this.htmlIFrame = iframe
        }
    }
}
</script>

<style scoped>

</style>