import FullMQTT from "@/components/mqtt/FullMQTT.vue";


export default [
    {
        path: '/mqtt',
        component: FullMQTT,
        name: 'MQTT',
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Console MQTT'
                }
            ]
        },
    }
];