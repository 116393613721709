import AWS from "aws-sdk";
import * as fzstd from "fzstd";

AWS.config.update({
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.VUE_APP_AWS_DEFAULT_REGION
});

let s3 = new AWS.S3();

export default {
    getFile,
    findLastFileInBucket,
    decompressZstandardFile
}

async function getFile(bucket, key, needDecompression = false) {
    const params = {
        Bucket: bucket,
        Key: key
    }
    s3.getObject(params, function (err, data) {
        if (err) {
            console.log(err, err.stack);
        } else {
            if (needDecompression) {
                return decompressZstandardFile(data.Body)
            }
            return data;
        }
    });
}

function findLastFileInBucket(bucket, prefix) {
    const params = {
        Bucket: bucket,
        Prefix: prefix,
        MaxKeys: 1
    }
    s3.listObjectsV2(params, function (err, data) {
        if (err) {
            console.log(err, err.stack);
        } else {
            return data;
        }
    });
}

function decompressZstandardFile(compressed) {
    const uint8array = fzstd.decompress(compressed);
    return new TextDecoder().decode(uint8array)
}