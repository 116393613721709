<template>
    <b-col cols="12" class="" v-if="!isLoading">
        <b-row class="justify-content-center mt-3">
            <b-col cols="12">
                <h3 class="clr-lifyair text-center">
                    Synthèse du Territoire
                </h3>
            </b-col>
            <b-col cols="12">
                <h4 class="clr-dark-grey">
                    Vue Globale
                </h4>
            </b-col>
        </b-row>
        <b-row class="my-3">
            <b-col cols="6" class="my-2">
                <div
                    class="shadow bg-white rounded p-3 global-chart-display"
                    v-if="!isLoading & series[0].data.length > 0"
                >
                    <BarChart
                        :series="series"
                        :chartOptions="daysOfPollenPresenceChartOptions"
                        :width="'95%'"
                        v-if="series.length > 0"
                    >
                    </BarChart>
                </div>
                <div class="d-flex shadow bg-white rounded scrollable global-chart-display p-2 justify-content-center align-content-center align-items-center" v-else-if="series[0].data.length === 0 && !isLoading">
                    <div>
                        <h1 class="clr-orange">
                            NO DATA
                        </h1>
                    </div>
                </div>
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-else>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </div>
            </b-col>
            <b-col cols="6" class="my-2">
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-if="Object.entries(highestRiskSensorDonutSeries).length > 0 && !isLoading">
                    <b-row class="p-3 w-100">
                        <b-col cols="4" md="12" sm="12" v-for="(sensor,i) in globalAnalytics.highestRiskSensor" :key="i">
                            <DonutChart
                                :series="highestRiskSensorDonutSeries[i]"
                                :chartOptions="highestRiskSensorDonutOptions[i]"
                                :width="'99%'"
                                :height="'100%'"
                            ></DonutChart>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex shadow bg-white rounded scrollable global-chart-display p-2 justify-content-center align-content-center align-items-center" v-else-if="Object.entries(highestRiskSensorDonutSeries).length === 0 && !isLoading">
                    <div>
                        <h1 class="clr-orange">
                            NO DATA
                        </h1>
                    </div>
                </div>
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-else>
                    <b-card class="w-100 h-100">
                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                        <b-skeleton animation="wave" width="55%"></b-skeleton>
                        <b-skeleton animation="wave" width="65%"></b-skeleton>
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                    </b-card>

                </div>
            </b-col>
            <b-col cols="12" class="my-2">
                <div class="shadow bg-white rounded p-3 scrollable global-chart-display" v-if="Object.entries(highestRiskSensorSeries).length > 0">
                    <div v-for="(sensor,i) in globalAnalytics.highestRiskSensor" :key="i">
                        <BarChart
                            :series="highestRiskSensorSeries[i]"
                            :chartOptions="highestRiskSensorOptions[i]"
                            :width="'99%'"
                            :height="'99%'"
                        >
                        </BarChart>
                    </div>
                </div>
                <div
                    class="d-flex shadow bg-white rounded scrollable global-chart-display p-2 justify-content-center align-content-center align-items-center"
                    v-else-if="Object.entries(highestRiskSensorSeries).length === 0 && !isLoading"
                >
                    <div>
                        <h1 class="clr-orange">
                            NO DATA
                        </h1>
                    </div>
                </div>
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-else>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </div>
            </b-col>
            <b-col cols="12" class="my-3">
                <div class="shadow bg-white rounded scrollable global-chart-display p-2 w-100 h-100" v-if="highestRiskSensorLineSeries[0].data.length > 0 && !isLoading">
                    <LineSimpleChart
                        :series="highestRiskSensorLineSeries"
                        :chartOptions="highestRiskSensorLineOptions"
                        :width="'95%'"
                        :height="'100%'"
                        class="p-3 w-100 h-100"
                        v-if="Object.keys(highestRiskSensorLineSeries).length > 0"
                    ></LineSimpleChart>
                </div>
                <div class="d-flex shadow bg-white rounded scrollable global-chart-display p-2 justify-content-center align-content-center align-items-center" v-else-if="series[0].data.length === 0 && !isLoading">
                    <div>
                        <h1 class="clr-orange">
                            NO DATA
                        </h1>
                    </div>
                </div>
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-else>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </div>
            </b-col>

        </b-row>
    </b-col>
    <b-col cols="12" v-else>
        <b-row class="justify-content-center align-content-center align-items-center mt-3">
            <b-col cols="12" class="text-center">
                <span class="loader"></span>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>

import Vuex from "vuex";
import moment from "moment";
import LineSimpleChart from "@/components/chart/LineSimpleChart";
import DonutChart from "@/components/chart/DonutChart";
import BarChart from "@/components/chart/BarChart";

export default {
    name: "GlobalAnalytics",
    components: {LineSimpleChart, DonutChart, BarChart},
    props: {
        startDate:String,
        endDate:String,
    },
    data(){
        return {
            series: [],
            highestRiskSensorSeries: [],
            highestRiskSensorOptions: [],
            highestRiskSensorDonutSeries: [],
            highestRiskSensorDonutOptions: [],
            highestRiskSensorLineSeries: [],
            highestRiskSensorLineOptions: {},
            daysOfPollenPresenceChartOptions: {
                xaxis: {
                    type: 'category',
                    labels: {
                        show: true,
                        formatter: (value) => {
                            return Math.ceil(value)
                        },
                    },
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                    '#f48024', '#69d2e7'
                ],
                noData: {
                    text: 'Loading...'
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#ffffff']
                    },
                },
                title: {
                    text: 'Nombre de jour de présence pour chaque pollen',
                    align: 'left'
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            individualOptions:null,
            highestRiskSensorTemplateOptions: {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd/MM',
                        rotate: -0,
                        rotateAlways: true,
                        minHeight: 50,
                        maxHeight: 180,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        style: {
                            fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                        },
                    }
                },
                yaxis: {
                    tickAmount: 3,
                    min: 0,
                    max: 3,
                    forceNiceScale: false,
                    floating: false,
                    labels: {
                        show: true,
                        formatter: (value) => {
                            const risk = ['Aucun Risque','Risque Faible','Risque Moyen','Risque Fort']
                            return value ? risk[Math.floor(value)] : 'Aucun Risque'
                        },
                    },
                },
                noData: {
                    text: 'Loading...'
                },
                dataLabels: {
                    enabled: false,
                    style: {
                        colors: ['#ffffff']
                    }
                },
                title: {
                    text: undefined,
                    align: 'left'
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                toolbar: {
                    show:false,
                    autoSelected: 'zoom'
                },
            },
        }
    },
    computed:{
        ...Vuex.mapGetters('analytics',{
            globalAnalytics:'globalAnalytics',
            getSensorList:'getSensorList',
            isLoading:'loadingAnalytics'
        })
    },
    methods: {
        setDaysOfPollenPresence() {
            let data = [];
            this.series = [];

            if (Object.entries(this.globalAnalytics.daysOfPollenPresence).length > 0) {
                Object.entries(this.globalAnalytics.daysOfPollenPresence).forEach((pollen) => {
                    const [key, value] = pollen;
                    data.push({
                        x: this.$t('pollen.type.'+key),
                        y: value
                    })
                })
            }

            this.series.push({data:data});
        },
        setHighestRiskSensorSeries() {
            if (Object.entries(this.globalAnalytics.highestRiskSensor).length > 0){
                Object.entries(this.globalAnalytics.highestRiskSensor).forEach((sensor) => {
                    this.individualOptions = JSON.parse(JSON.stringify(this.highestRiskSensorTemplateOptions))
                    let data = [];
                    const [key, value] = sensor;
                    let mySensor = this.getSensorList.filter(item => item.serialNumber === key)[0]

                    let numberOfDaysInPeriod = moment(this.endDate).diff(moment(this.startDate), 'days')+1
                    for (let i = 0; i < numberOfDaysInPeriod; i++) {
                        let loopDate = moment(this.startDate).add(i,'days').format('YYYY-MM-DD')
                        if (value.find(item => item.date === loopDate) !== undefined){
                            data.push({
                                x: value.find(item => item.date === loopDate).date,
                                y: value.find(item => item.date === loopDate).riskLevel ? value.find(item => item.date === loopDate).riskLevel : 0.4,
                            })
                        } else {
                            data.push({
                                x: loopDate,
                                y: 0,
                            })
                        }
                    }

                    this.highestRiskSensorSeries[key] = [{
                        data:data
                    }];

                    let titlePrefix = 'Risque maximum journalier - '
                    this.individualOptions.title.text = mySensor.sensorName !== undefined ? titlePrefix + mySensor.sensorName : titlePrefix + mySensor.serialNumber
                    this.individualOptions.yaxis.labels.formatter =  (value) => {
                        const risk = ['Aucun Risque','Risque Faible','Risque Moyen','Risque Fort']
                        return risk[Math.floor(value)]
                    }

                    this.individualOptions.xaxis = {
                        type: 'datetime',
                        tickAmount: moment(this.endDate).diff(moment(this.startDate), 'days')+1,
                        min: moment(this.startDate).format('YYYY-MM-DD'),
                        max: moment(this.endDate).add(1,'days').format('YYYY-MM-DD'),
                        labels: {
                            show:true,
                            // format: 'dd/MM',
                            // rotate: -0,
                            // rotateAlways: true,
                            // hideOverlappingLabels: true,
                            // showDuplicates: false,
                            // trim: false,
                            // style: {
                            //     fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                            // },
                            // formatter: function(val) {
                            //     return moment(val).format("YYYY-MM-DD");
                            // }
                        }
                    }

                    this.individualOptions.colors = [
                        function ({value}) {
                            let riskColor = [
                                '#008FFB',
                                '#78da6f',
                                'rgba(255,90,0,0.71)',
                                'rgba(227,34,0,0.72)'
                            ]
                            return riskColor[Math.floor(value)];
                        }
                    ]
                    this.highestRiskSensorOptions[key] = this.individualOptions
                })
            }
            // else {
            //     this.highestRiskSensorSeries = [];
            //     this.highestRiskSensorOptions = []
            // }
        },
        setHighestRiskSensorDonutSeries() {
            this.highestRiskSensorDonutOptions = []
            this.highestRiskSensorDonutSeries = [];

            if (Object.entries(this.globalAnalytics.highestRiskSensor).length > 0) {
                Object.entries(this.globalAnalytics.highestRiskSensor).forEach((sensor) => {
                    this.individualOptions = JSON.parse(JSON.stringify(this.highestRiskSensorTemplateOptions))
                    let data = [0,0,0,0];
                    const [key, value] = sensor;
                    let mySensor = this.getSensorList.filter(item => item.serialNumber === key)[0]

                    for (let i = 0; i < value.length; i++) {
                        data[value[i].riskLevel] +=1
                    }
                    this.highestRiskSensorDonutSeries[key] = data;

                    let titlePrefix = 'Risque maximum journalier - '
                    this.individualOptions.title.text = mySensor.sensorName !== null ? titlePrefix + mySensor.sensorName : titlePrefix + mySensor.serialNumber
                    this.individualOptions.yaxis.labels.formatter =  (value) => {
                        const risk = ['Aucun Risque','Risque Faible','Risque Moyen','Risque Fort']
                        return risk[Math.floor(value)]
                    }

                    this.individualOptions.plotOptions= {
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize:18,
                                            color: '#000000'
                                        },
                                        value: {}
                                    }
                                }
                            }
                        },
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontSize:18,
                                        color: '#000000'
                                    },
                                    value: {
                                        show: true,
                                    },
                                    total:{
                                        show: true,
                                        label:'Total',
                                        fontSize: '18px',
                                        formatter:(value)=>{
                                            let test =  value.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)

                                            let total = (test * 100) / moment(this.endDate).diff(moment(this.startDate), 'days')
                                            return Math.ceil(total) + '%'
                                        }
                                    }
                                }
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return val
                            },
                        },
                    }
                    this.individualOptions.labels = ['Aucun Risque','Risque Faible','Risque Moyen','Risque Fort']

                    // this.individualOptions.colors = [
                    //     function ({value}) {
                    //         let riskColor = [
                    //             '#78da6f',
                    //             '#faf177',
                    //             'rgba(255,90,0,0.71)',
                    //             'rgba(227,34,0,0.72)'
                    //         ]
                    //         return riskColor[Math.floor(value)];
                    //     }
                    // ]

                    this.highestRiskSensorDonutOptions[key] = this.individualOptions
                })
            }
        },
        setHighestRiskSensorLineSeries() {
            let data = [];
            this.highestRiskSensorLineSeries = [{data: data}];

            if (this.globalAnalytics.yearlyConcentration.length > 0) {
                this.globalAnalytics.yearlyConcentration.forEach((dailyConcentration) => {
                    data.push({
                        x: dailyConcentration.date,
                        y: dailyConcentration.value,
                    })
                })

                this.highestRiskSensorLineOptions.title = {text: 'Concentration annuel journalière du territoire'}
                this.highestRiskSensorLineOptions.xaxis = {
                    type: 'datetime',
                    labels: {
                        format: 'dd/MM',
                        rotate: -0,
                        rotateAlways: true,
                        minHeight: 50,
                        maxHeight: 180,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: false,
                        style: {
                            fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                        },
                    }
                }
                this.highestRiskSensorLineOptions.stroke = {
                    curve: 'smooth',
                }
                this.highestRiskSensorLineOptions.markers = {
                    size: 1,
                    colors: '#000',
                    strokeColors: '#fff',
                    strokeWidth: 2,
                    strokeOpacity: 0.9,
                    strokeDashArray: 1,
                    fillOpacity: 1,
                    discrete: [],
                    shape: "circle",
                    radius: 2,
                    offsetX: 0,
                    offsetY: 0,
                    onClick: undefined,
                    onDblClick: undefined,
                    showNullDataPoints: true,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                    }
                }
            }
            this.highestRiskSensorLineSeries = [{data: data}];
        },

    },
    created() {
        this.setDaysOfPollenPresence()
        this.setHighestRiskSensorSeries()
        this.setHighestRiskSensorDonutSeries()
        this.setHighestRiskSensorLineSeries()
    },
    watch: {
        globalAnalytics: {
            handler() {
                this.setDaysOfPollenPresence()
                this.setHighestRiskSensorSeries()
                this.setHighestRiskSensorDonutSeries()
                this.setHighestRiskSensorLineSeries()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
}

.global-chart-display{
    min-height: 50vh;
    max-height:50vh;
}

.loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-bottom: 4px solid $dark-blue;
    border-left: 4px solid transparent;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>