<template>
    <div class="w-100 responsive-font">
        <b-row class="w-100 mb-3 justify-content-around mx-0">
            <b-button-group name="dateFilter">
                <b-button variant="light" @click="dateFilter('Desc')">Date <b-icon icon="sort-down"/></b-button>
                <b-button variant="light" @click="dateFilter('Asc')">Date <b-icon icon="sort-up"/></b-button>
            </b-button-group>
            <b-select :options="type" @change="catFilter" v-model="iFilter" name="importance" class="w-25">
                <template #first>
                    <b-form-select-option  :value="null">
                        Importance
                    </b-form-select-option>
                </template>
            </b-select>
            <b-form-checkbox v-model="advancedMode" name="check-button" switch>
                Mode avancé
            </b-form-checkbox>
        </b-row>
        <div class="wrapper">
            <ul class="timeline">
                <li class='timelineItem'>
                    <b-button class="timelineInfo" variant="primary" @click="newNote()">
                        <span>Nouvelle note</span>
                    </b-button>
                </li>
                <li class='timelineItem'
                    v-for="(item,idx) in notes" :key="idx"
                    :class="[due(item) ? 'due' : '', {expanded: expanded === idx.toString()}]"
                >
                    <b-avatar :variant="setVariant(item)" class="timelineIcon" size="50px">
                        <b-icon :icon="item.icon && item.icon !== 'null' ? item.icon : 'pen'"/>
                    </b-avatar>
                    <b-card
                        :title="item.action"
                        :sub-title="item.user ? 'Par ' + item.user.email : 'Par lifyair_system'"
                        :border-variant="setVariant(item)"
                        :footer-bg-variant="setVariant(item)"
                        :footer-text-variant="setVariant(item) !== 'light' ? 'white' : ''"
                        class="shadow"
                    >
                        <b-avatar
                            v-if="item.payload && Object.keys(item.payload).length > 0"
                            :text-variant="setVariant(item) !== 'light' ? 'white' : ''"
                            :variant="setVariant(item)"
                            style="position:absolute; right: 120px; top: 10px;cursor:pointer"
                            button
                            @click="triggerPayload(item)">
                            <b-icon icon="code-slash"/>
                        </b-avatar>
                        <b-avatar
                            :text-variant="setVariant(item) !== 'light' ? 'white' : ''"
                            :variant="setVariant(item)"
                            style="position:absolute; right: 60px; top: 10px;cursor:pointer"
                            button
                            @click="editComment(item)">
                            <b-icon icon="pencil"></b-icon>
                        </b-avatar>
                        <b-avatar button
                                  :text-variant="setVariant(item) !== 'light' ? 'white' : ''"
                                  :variant="setVariant(item)"
                                  style="position:absolute; right: 10px; top: 10px; cursor:pointer"
                                  @click="expand(idx.toString())">
                            <b-icon v-if="expanded === idx.toString()" icon="arrows-angle-contract"></b-icon>
                            <b-icon v-else icon="arrows-angle-expand"/>
                        </b-avatar>
                        <b-card-text v-html="item.context"/>
                        <div class="hue" v-if="showHue(item)" :class="setVariant(item)" :ref="idx"></div>
                        <template #footer>
                            <b-row>
                                <b-col cols="6">
                                    <span class="mr-3">Le {{ item.createdAt | basicDate }}</span>
                                    <span v-if="item.dueDate">
                                        Pour le {{item.dueDate | dayDate}}
                                    </span>
                                </b-col>
                                <b-col cols="6">
                                    <div
                                        @click="showEvent(item.sensorEvent)"
                                        class="text-right title add-cursor"
                                        v-if="item.sensorEvent">
                                        <b-badge
                                            :class="contentByEventType[item.sensorEvent.type].class"
                                            class="font-weight-normal p-2">
                                            <b-icon
                                                :icon="contentByEventType[item.sensorEvent.type].icon"/>
                                            {{ contentByEventType[item.sensorEvent.type].text }} :
                                            {{ item.sensorEvent.title }}
                                        </b-badge>
                                    </div>
                                </b-col>
                            </b-row>
                        </template>
                    </b-card>
                </li>
            </ul>

            <!-- ADD NOTE MODAL -->
            <b-modal id="noteModal"
                     :ok-disabled="!inputState"
                     @ok="saveNote()"
                     @cancel="cancelModal()"
                     @hidden="cancelModal()"
                     size="xl" title="Ajout d'une note"
            >
                <b-form id="noteForm">
                    <b-form-group label="Titre" class="my-4 mx-2">
                        <b-form-input v-model="form.action" required :state="inputState" ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                            <span v-if="form.action.length < 1">Entrer au moins 3 caractères</span>
                            <span v-if="form.action.length > 50">Maximum 50 caractères</span>
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-row class="my-4 mx-2">
                        <b-select v-model="form.variant" :options="type"></b-select>
                    </b-row>
                    <b-row class="my-4 mx-2">
                        <b-form-radio-group  class="d-flex align-items-center justify-content-around align-content-between flex-wrap" v-model="form.icon">
                            <b-radio v-for="(icon, idx) in icons" :key="idx" :value="icon.value">
                                <b-avatar :variant="form.variant" class="timelineIcon" size="50px"><b-icon :icon="icon.value"/></b-avatar>
                            </b-radio>
                        </b-form-radio-group>
                    </b-row>
                    <b-row class="my-4 mx-2 justify-content-between flex-wrap">
                        <b-col class="p-0 col-5" style="min-width: 320px">
                            <b-form-group label="Le">
                                <b-datepicker id="dateNow"
                                              :placeholder="new Date().toLocaleDateString()"
                                              :max="form.dueDate"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              v-model="form.createdAt"
                                ></b-datepicker>
                            </b-form-group>
                        </b-col>
                        <b-col class="p-0 col-5" style="min-width: 320px">
                            <b-form-group label="Pour le">
                                <b-datepicker id="dueDate"
                                              placeholder=" "
                                              :min="form.createdAt"
                                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                              v-model="form.dueDate"
                                              :state="validDueDate"
                                ></b-datepicker>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group label="Description" label-for="sensorNoteDescription" class="my-4 mx-2">
                        <vue-editor v-model="form.context" :editorToolbar="customToolbar" aria-required="true"/>
                    </b-form-group>
                </b-form>
                <b-button v-b-toggle.collapse-1 variant="primary">Preview</b-button>
                <b-collapse id="collapse-1">
                    <ul class="timeline my-5" key="preview">
                        <li class='timelineItem' :class="[form && due(form) ? 'due' : '', {expanded: expanded === 'preview'}]">
                            <b-avatar :variant="form.variant" class="timelineIcon" size="50px"><b-icon :icon="form.icon"/></b-avatar>
                            <b-card
                                :title="form.action"
                                sub-title="Par test@lifyair.com"
                                :border-variant="form.variant"
                                :footer-bg-variant="form.variant"
                                :footer-text-variant="form.variant !== 'light' ? 'white' : ''"
                                class="shadow"
                            >
                                <b-avatar
                                    :text-variant="form.variant !== 'light' ? 'white' : ''"
                                    :variant="form.variant"
                                    style="position:absolute; right: 60px; top: 10px;cursor:pointer"
                                    button>
                                    <b-icon icon="pencil"></b-icon>
                                </b-avatar>
                                <b-avatar button
                                          :text-variant="form.variant !== 'light' ? 'white' : ''"
                                          :variant="form.variant"
                                          style="position:absolute; right: 10px; top: 10px; cursor:pointer"
                                          @click="expand('preview')">
                                    <b-icon v-if="expanded === 'preview'" icon="arrows-angle-contract"></b-icon>
                                    <b-icon v-else icon="arrows-angle-expand"/>
                                </b-avatar>
                                <b-card-text text-tag="p" v-html="form.context"></b-card-text>
                                <template #footer>
                                    <b-row>
                                        <b-col v-if="form.createdAt === ''">
                                            <p>Le {{ new Date().toLocaleDateString() }}</p>
                                        </b-col>
                                        <b-col v-else>
                                            <p>Le {{ form.createdAt }}</p>
                                        </b-col>
                                        <b-col>
                                            <p v-if="form.dueDate" class="dueDate">Pour le {{form.dueDate}}</p>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-card>
                        </li>
                    </ul>
                </b-collapse>
            </b-modal>

            <!-- PAYLOAD MODAL -->
            <b-modal id="modal-payload" title="Payload" ok-only>
                <div class="bg-darker-grey clr-white p-3 rounded">
                    <b-icon-clipboard @click="copyPayload" class="float-right add-cursor"/>
                    <p class="my-4">{{ payload }}</p>
                </div>
            </b-modal>

            <!-- SENSOR EVENT MODAL -->
            <b-modal
                hide-footer
                hide-header
                hide-header-close
                id="event-details"
                body-class="p-0"
                size="xl">
                <SensorEventDetails
                    v-if="sensorEvent"
                    :event="sensorEvent"
                    from="SensorEventHistory"
                    :key="'selected_event_' + sensorEvent.id"/>
            </b-modal>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex';
import {VueEditor} from "vue2-editor";
import moment from "moment/moment";
import store from "@/store";
import Toast from "@/services/swal2/mixins";
import SensorEventDetails from "@/components/sensor/Detail/SAV/SensorEventDetails.vue";
export default {
    name: "Timeline",
    components:{SensorEventDetails, VueEditor},
    data() {
        return {
            advancedMode: false,
            expanded: false,
            payload: null,
            formTemplate: {
                action: '',
                context: '',
                createdAt: null,
                dueDate: null,
                icon: 'pen',
                variant: 'light',
            },
            form:{
                action: '',
                context: '',
                createdAt: null,
                dueDate: null,
                icon: 'pen',
                variant: 'light',
            },
            icons: [
                {value:'box-arrow-right'},
                {value:'box-arrow-left'},
                {value:'cloud-arrow-down'},
                {value:'info-circle'},
                {value:'pen'},
                {value:'x-circle'},
                {value:'wrench'},
                {value:'tools'},
                {value:'box-seam'},
            ],
            type: [
                {value: 'light', text: 'défaut'},
                {value: 'primary', text: 'information'},
                {value: 'warning', text: 'attention'},
                {value: 'danger', text: 'important'},
            ],
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ['link']
            ],
            edit: false,
            iFilter: null,
            items: [],
            contentByEventType: {
                incident: {
                    text: 'Incident',
                    icon: 'exclamation-circle-fill',
                    class: 'clr-white bg-danger'
                }
            },
        }
    },
    props: {
        past: Boolean
    },
    computed: {
        ...Vuex.mapGetters('user', {
            user: 'getUser'
        }),
        ...Vuex.mapGetters('sensor', {
            histories: 'histories',
            individualSensor: 'individualSensor'
        }),
        ...Vuex.mapGetters('sensorEvent', {
            sensorEvent: 'sensorEvent'
        }),
        inputState(){
            return this.form.action.length > 1 && this.form.action.length <= 50
        },
        validDueDate(){
            if(this.form.dueDate === '') return null;
            let today = new Date().setHours(0, 0,0,0)
            let due = new Date(this.form.dueDate).getTime();
            let created = new Date(this.form.createdAt).getTime();
            if(isNaN(created) && due < today){
                return false;
            } else if(isNaN(created) && due >= today){
                return true;
            } else if(created > due){
                return false;
            } else if(created<= due){
                return true;
            }
            return false;
        },
        notes() {
            if (this.items.length === 0 || this.advancedMode) {
                return this.items;
            } else {
                return this.items.filter(item => {
                    return item.user && item.user.email !== null;
                })
            }
        }
    },
    methods:{
        ...Vuex.mapActions('sensor', {
            addSensorNote: 'addSensorNote',
            editStatus: 'editStatus',
            setHistories: 'setHistories',
            emptyHistories: 'emptyHistories'
        }),
        ...Vuex.mapActions('sensorEvent', {
            setSensorEvent: 'setSensorEvent'
        }),
        newNote(){
            this.resetForm();
            this.$bvModal.show('noteModal')
        },
        cancelModal(){
            this.resetForm();
            this.$bvModal.hide('noteModal');
        },
        due(item){
            if(!item.dueDate || this.isOld(item)) return false;
            let date = new Date(item.createdAt);
            date.setDate(date.getDate() + 7)
            let dueDate = new Date(item.dueDate).setHours(0,0,0);
            return dueDate <= date.getTime();
        },
        isOld(item){
            let d = new Date().getTime();
            let i = new Date(item.dueDate && item.dueDate !== '' ? item.dueDate : item.createdAt).getTime();
            const sevenDays = 604800000;
            return d - i > sevenDays;
        },
        setVariant(item){
            if(!item.variant) return 'light';
            if(!this.isOld(item)) return item.variant;

            switch (item.variant){
                case 'primary':
                case 'warning':
                    return 'secondary'
                case 'danger':
                    return 'dark'
                case 'light':
                default:
                    return 'light'
            }
        },
        editComment(item){
            this.edit = true;
            Object.assign(this.form, item);
            this.$bvModal.show('noteModal')
        },
        async saveNote() {

            if(!this.inputState) return false;

            if(!this.form.user) {

                let query = this.generateQueryString()
                await this.addSensorNote(query).then(() => {
                        this.$bvModal.hide('noteModal');
                        this.resetForm()
                    });
                await this.setHistories(this.$route.params.id);
            } else {
                let query = [this.form.id];
                query.push(this.generateQueryString());
                await this.editStatus(query)
                    .then(() => {
                        this.resetForm();
                        this.$bvModal.hide('noteModal');
                    });
                await this.setHistories(this.$route.params.id);

            }
            this.catFilter();
        },
        generateQueryString() {
            let fields = new FormData();
            !this.edit ? fields.set('sensor', this.individualSensor.id) : '';
            for(let [key, value] of Object.entries(this.form)){
                if (value) {
                    if(key === 'createdAt' || key === 'dueDate') {
                        fields.set(key,this.formatDate(value));
                    } else if (key !== 'user' && key !== 'id' && key !== 'payload'){
                        fields.set(key, value)
                    }
                }
            }
            return fields
        },
        formatDate(date){
            return date ? moment(date).format('DD-MM-YYYY HH:mm:ss') : '';
        },
        resetForm(){
            this.edit = false;
            Object.assign(this.form, this.formTemplate);
            delete this.form['payload'];
            delete this.form['user'];
            delete this.form['id'];
        },
        expand(idx){
            if(!this.expanded){
                this.expanded = idx;
            } else if(this.expanded && idx && this.expanded !== idx){
                this.expanded = idx;
            } else if(this.expanded === idx){
                this.expanded = false;
            }
        },
        showHue(item){
            if(item && item.context){
                return !!(item.context.length > 300 || item.context.includes('<br'));
            }
        },
        dateFilter(value){
            if(value === 'Asc'){
                if(value && value === 'Asc'){
                    this.items.sort(function (i1, i2){
                        return new Date(i1.createdAt) - new Date(i2.createdAt);
                    })
                }
            } else if(value === 'Desc'){
                if(value === 'Desc') {
                    this.items.sort(function (i1, i2) {
                        return new Date(i2.createdAt) - new Date(i1.createdAt)
                    })
                }
            }
        },
        catFilter(){
            this.items = this.histories;
            let filteredArray = [];
            switch (this.iFilter){
                case 'light':
                    this.items.filter((h) => {
                        h.variant === 'light' || h.variant === null || h.variant === '' ? filteredArray.push(h): false;
                    })
                    this.items = filteredArray;
                    break;
                case 'primary':
                    this.items.filter((h) => {
                         h.variant === 'primary' ? filteredArray.push(h): false;
                    })
                    this.items = filteredArray;
                    break;
                case 'warning':
                    this.items.filter((h) => {
                         h.variant === 'warning' ? filteredArray.push(h): false;
                    })
                    this.items = filteredArray;
                    break;
                case 'danger':
                    this.items.filter((h) => {
                         h.variant === 'danger' ? filteredArray.push(h): false;
                    })
                    this.items = filteredArray;
                    break;
                default:
                    this.items = this.histories;
                    break;
            }
        },
        triggerPayload(item) {
            this.payload = item.payload;
            this.$bvModal.show('modal-payload');
        },
        copyPayload() {
            navigator.clipboard.writeText(JSON.stringify(this.payload));
            Toast.fire({
                icon: 'success',
                title: "Le payload a été copié dans le presse-papier",
            })
        },
        showEvent(sensorEvent) {
            this.setSensorEvent(sensorEvent.id).then(() => {
                this.$bvModal.show('event-details')
            })
        }
    },
    async created() {
        moment.locale(store.getters.getAppLanguage)
        await this.setHistories(this.$route.params.id)
        this.items = this.histories;
    },
    destroyed() {
        this.emptyHistories()
    }
}

</script>

<style scoped lang="scss">
li {
    list-style-type: none;
}
.card-body{
    padding-bottom: .8rem;
}
.wrapper{
    max-height: 760px;
    overflow: scroll;
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
    padding:2em;
    &::-webkit-scrollbar{
        display:none;
    }
}
.timeline {
    padding-left: 75px;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: fit-content;
    &:before {
        content: "";
        position: absolute;
        top: -20px;
        bottom: 0;
        width: 4px;
        left: 30px;
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(226, 226, 226, 0.75) 10%,
                rgba(226, 226, 226, 1) 20%,
                rgba(226, 226, 226, 1) 80%,
                rgba(226, 226, 226, 0.75) 90%,
                rgba(255, 255, 255, 0) 100%
        );
    }
    .timelineItem {
        position: relative;
        margin-bottom: 2rem;
        &.expanded{
            .hue{
                display: none;
            }
            @media screen and (min-width: 800px){
                >.b-avatar{
                    transform: translate(120%, -17px);
                }
                .card{
                    transform: translateX(-3%) scale(1.06);
                }
                &.due .card {
                    animation: none;
                }
            }
            .card-text{
                max-height: 100%;
                overflow:visible;
                white-space:normal;
            }
        }
        &.past{
            .timelineIcon{
                background-color: grey;
                color: #FFF;
            }
        }
        &.due .card{
            animation: vibrate-1 0.3s linear infinite both;
        }


        .timelineIcon {
            position: absolute;
            left: -67px;
            border-radius: 50%;
            top: 50%;
            transform: translate(0%, -17px);
            box-shadow: -1px 2px 2px 0px rgb(0 0 0 / 38%);
            font-size: 1.7rem;
        }
        .timelineInfo {
            width: 100%;
            min-height: 50px;
            box-shadow: 2px 2px 8px 2px rgb(0 0 0 / 10%);
        }
        .card-body{
            position:relative;
            overflow:hidden;
            padding-bottom: 0;
        }
        .card-text{
            width: 100%;
            margin-bottom: 0;
            position:relative;
            max-height: 50px;
            overflow:hidden;
            transition:all, .5s;
            p, span{
                margin-bottom: 0!important;
                transition:all, .5s;
                text-overflow: elipsis;
            }
        }
    }
}
.hue{
    width: 100%;
    height: calc(0.2 * 1vmin);
    border-radius: 100% 100% 0% 0%/100% 100% 0% 0%;
    position: absolute;
    left: 0;
    bottom: -2px;
    box-shadow: 0px -2px 7px 0px #000;
}
@media screen and (max-width: 800px){
    .timeline{
        padding-left: 0;
    }
    .timelineItem{
        .card{border-radius: 0!important;}
    }
    .timelineIcon{
        width: 25px !important;
        height: 24px !important;
        left: 2px!important;
        top: 2px!important;
        -webkit-transform: none;
        transform: none!important;
        z-index: 1;
        -webkit-box-shadow: none !important;
        box-shadow: none! important;
        background: none;
        font-size: 1em!important;
    }
}

@keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }
    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }
    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }
    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

</style>