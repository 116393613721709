<template>
    <b-row class="justify-content-center p-3">
        <b-col cols="12" md="10">
            <b-form-group label="Veuillez choisir le comptes cible pour le transfert :"
                          label-for="accounts" class="dataAccessFormSubTitle clr-dark-grey">
                <b-form-select v-model="chosenAccount" id="accounts" required>
                    <b-form-select-option :value="account.id"
                                          v-for="(account, index) in accounts"
                                          :key="index">
                        {{ account.name }}
                    </b-form-select-option>
                </b-form-select>
                <b-form-text id="input-live-help" class="">
                    Tous les capteurs liés à ce compte par un "account_equipment" vont être transformés en <span
                    class="font-weight-bold">accès</span> de type <span class="font-weight-bold">propriétaire</span>
                    pour chaque sous-groupes.
                </b-form-text>
            </b-form-group>
        </b-col>
        <b-col cols="12" md="10" v-if="chosenAccount" class="mt-3">
            <p class="clr-dark-grey">Capteurs qui vont être ajouté dans l'accès :</p>
            <ul>
                <li v-for="(sensor, index) in sensors" :key="index">{{ sensor.serialNumber }}</li>
            </ul>
        </b-col>
        <b-col cols="12" class="mt-5 text-center">
            <b-button class="text-uppercase" :disabled="!chosenAccount" @click="transfer"
                      :class="chosenAccount ? 'bg-blue clr-white' : ''">
                Transférer
            </b-button>
        </b-col>
        <b-col cols="12" md="10" v-if="getLoadingStatus && showResume" class="mt-3">
            <b-spinner label="Loading..."></b-spinner>
        </b-col>
        <b-col cols="12" md="10" v-else-if="showResume" class="mt-3">
            <p>Résultat :</p>
            <div class="bg-white p-3">
                <ul class="m-0">
                    <li v-for="(action, index) in getRequest.actions" :key="index">
                        {{ action }}
                    </li>
                </ul>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "NewArchitectureMigration",
    data() {
        return {
            chosenAccount: null,
            showResume: false
        }
    },
    methods: {
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors'
        }),
        ...Vuex.mapActions('migration', {
            transfertAccountEquipmentToDataAccessByAccount: 'transfertAccountEquipmentToDataAccessByAccount',
        }),
        transfer() {
            this.transfertAccountEquipmentToDataAccessByAccount(this.chosenAccount).then(() => {
                this.showResume = true;
            })
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors'
        }),
        ...Vuex.mapGetters('migration', {
            getRequest: 'getRequest',
            getLoadingStatus: 'getLoadingStatus',
        }),
    },
    created() {
        this.setAccounts();
    },
    watch: {
        chosenAccount: function (value) {
            if (value) this.setSensors({account: value})
        }
    }
}
</script>

<style scoped>

</style>