<template>
    <div ref="pollenTable">
        <b-col cols="12">
            <!--            <div class="col-xl-6 float-right my-auto">-->
            <!--                <b-input-group :prepend="$t('common.search') | capitalize"-->
            <!--                               v-on:submit.prevent="filterUsers"-->
            <!--                               v-on:keyup.enter="filterUsers">-->
            <!--                    <b-form-input v-model="search" :placeholder="$t('user.search') | capitalize"></b-form-input>-->
            <!--                    <b-input-group-append>-->
            <!--                        <b-button type="submit" variant="info" @click="filterUsers">GO</b-button>-->
            <!--                    </b-input-group-append>-->
            <!--                </b-input-group>-->
            <!--            </div>-->
            <b-table-simple
                responsive
                hover
                sticky-header
                borderless
                v-if="users.length > 0"
            >
                <b-thead>
                    <b-tr>
                        <b-th>Email de l'utilisateur</b-th>
                        <b-th>Roles</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="(item,i) in users"
                        :key="i"
                        class="clr-dark-grey add-cursor justify-content-center align-items-center"
                        @click="toUserInfo(item.planLevel, item[0].id)"
                    >
                        <b-td class="align-middle">{{ item[0].email }}</b-td>
                        <b-td class="align-middle">
                            <p>
                            <span v-for="(role,i) in item[0].roles" :key="i">
                                {{ role }} <span v-if="(i+1) !== item[0].roles.length">,</span>
                            </span>
                            </p>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <div class="my-auto" v-else>
                <p class="h4 mb-3 mt-2 clr-dark-grey text-center">Pas d'utilisateur(s) associé au compte</p>
            </div>
        </b-col>
    </div>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "AccountUserList",
    data() {
        return {
            search: '',
            direction: 'desc',
            optionsLimits: [
                {value: 10, text: '10'},
                {value: 25, text: '25'},
                {value: 50, text: '50'},
            ],
            users: []
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            loadingStatus: 'loadingStatus',
            getUsersList: 'getUsersList'
        })
    },
    methods: {
        ...Vuex.mapActions('account', {
            setUsersList: 'setUsersList'
        }),
        async queryUsers() {
            let query = {}
            query.account = this.$route.params.id;

            this.search.length >= 3 ? query.search = this.search : '';
            this.direction !== '' ? query.direction = this.direction : '';
            await this.setUsersList(query);
            this.users = this.getUsersList;
        },
        filterUsers() {
            if (this.search.length > 3) {
                this.users = this.getUsersList.filter(user => {
                    if (user.email.includes(this.search.toString())) {
                        return user;
                    }
                });
            } else {
                this.users = this.getUsersList;
            }
        },
        toUserInfo(planLevel, userId) {
            planLevel === 'individual' ?
                this.$router.push('/users/mobile/' + userId + '/information') :
                this.$router.push('/users/supervisor/' + userId + '/information')
        }
    },
    async mounted() {
        await this.queryUsers();
    },
    watch: {
        'search'() {
            if (this.search) {
                this.filterUsers()
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>
