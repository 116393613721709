import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

// initial state
const state = {
    trackings: [],
    createdTracking: null,
    loadingStatus: false,
    request: {},
    actualStep: 0,
    isReturning: false,
    stepShipping: [
        {
            name: 'Création du tracker',
            fields: ['trackingNumber'],
            completed: false
        },
        {
            name: 'Sélection du Compte',
            fields: ['accountSelection'],
            completed: false
        },
        {
            name: 'Fin',
            fields: ['completedAt'],
            completed: false
        }
    ],
    stepReturn: [
        {
            name: 'Gestion des remplacements',
            fields: ['trackingNumber'],
            completed: false
        },
        {
            name: 'Ajout suivi de colis (Capteur remplaçant)',
            fields: ['trackingNumber'],
            completed: false
        },
        // {
        //     name: 'Gestion du retour & Tracker',
        //     fields: ['trackingNumber'],
        //     completed: false
        // },
        {
            name: 'Association à LifyAir',
            fields: ['accountSelection'],
            completed: false
        },
        {
            name: 'Fin',
            fields: ['completedAt'],
            completed: false
        }
    ]
};

const mutations = {
    SET_TRACKINGS: (state, trackings) => {
        state.loadingStatus = true;
        state.trackings = trackings;
        state.loadingStatus = false;
    },
    CREATE_TRACKING: (state, tracking) => {
        state.loadingStatus = true;
        state.createdTracking = tracking;
        state.loadingStatus = false;
    },
    SET_ACTUAL_STEP: (state, step) => {
        state.actualStep = step;
    },
    UPDATE_STEPS: (state) => {
        if (!state.isReturning) {
            state.stepShipping[state.actualStep].completed = true
        } else {
            state.stepReturn[state.actualStep].completed = true
        }
    },
    SET_IS_RETURNING: (state) => {
        state.isReturning = true
    },
    RESET_ACTUAL_STEP: (state) => {
        state.actualStep = 0
    },
    RESET_DATA: (state) => {
        state.trackings = [];
        state.createdTracking = null;
        state.request = {};
        state.actualStep = 0;
        state.isReturning = false;
        state.stepShipping.forEach((step) => {
            step.completed = false;
        })
        state.stepReturn.forEach((step) => {
            step.completed = false;
        })
    }
};
const actions = {
    setTrackings: async (store) => {
        await api.deliveryTracking.getTrackings()
            .then(response => {
                store.commit('SET_TRACKINGS', response.data.data);
            });
    },
    createTracking: async (store, data) => {
        return await api.deliveryTracking.createTracking(data)
            .then(response => {
                store.commit('CREATE_TRACKING', response.data.data);
                store.commit('UPDATE_STEPS')
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
                return true
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
                return false
            })
    },
    updateTrackingStatus: async (store, params) => {
        return await api.deliveryTracking.updateTrackingStatus(params.id, params.data)
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
                return true
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
                return false
            })
    },
    setActualStep: async (store, step) => {
        store.commit('SET_ACTUAL_STEP', step);
    },
    resetActualStep: async (store) => {
        store.commit('RESET_ACTUAL_STEP');
    },
    setIsReturning: async (store) => {
        store.commit('SET_IS_RETURNING');
    },
    updateFormStep: (store) => {
        store.commit('UPDATE_STEPS');
    },
    resetData: (store) => {
        store.commit('RESET_DATA');
    }
};

const getters = {
    sensorTrackings: state => {
        return state.trackings
    },
    createdTracking: state => {
        return state.createdTracking
    },
    isLoading: state => {
        return state.isLoading;
    },
    actualStep: state => {
        return state.actualStep;
    },
    steps: state => {
        if (!state.isReturning) {
            return state.stepShipping
        } else {
            return state.stepReturn
        }
    },
    stepReturn: state => {
        return state.stepReturn
    },
    formCompletedShipping: state => {
        return state.stepShipping.slice(0, -1).every(step => step.completed === true);
    },
    formCompleted: state => {
        if (!state.isReturning) {
            return state.stepShipping.slice(0, -1).every(step => step.completed === true);
        } else {
            return state.stepReturn.slice(0, -1).every(step => step.completed === true);
        }
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
