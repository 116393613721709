<template>
    <div ref="pollenTable">
        <b-table-simple
            responsive
            hover
            sticky-header
            borderless
            v-if="accountGroup.length > 0 && !isBusy"
        >
            <b-thead>
                <b-tr>
                    <b-th>
                        Nom du Compte
                    </b-th>
                    <b-th>
                        Nom du groupe
                    </b-th>
                    <b-th v-if="full">Action</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    v-for="(item,i) in accountGroup"
                    :key="i"
                    class="clr-dark-grey add-cursor align-items-center"
                >

                    <b-td class="align-middle"
                          @click="$router.push('/accounts/'+item.account.id+'/information')">{{ item.account.name }}
                    </b-td>
                    <b-td class="align-middle">{{ item.name }}</b-td>
                    <b-td class="align-middle">
                        <p @click="deleteUserLinkToGroup(item)" class="m-0 p-0">
                            <b-icon icon="dash-square" aria-hidden="true"></b-icon>
                        </p>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-modal
            v-if="selectedItem"
            id="modal-delete-group"
            ref="modal2"
            centered
            :title="'Suppression du groupe : '"
            @ok="handleOk"
        >
            <p>
                Etes vous sur de vouloir détacher l'utilisateur de ce groupe ?
                Les utilsateurs associés ne seront plus lié !
                Si ce compte est le dernier de l'utilisateur, alors celui ci ne pourra être supprimé
            </p>
        </b-modal>
    </div>
</template>

<script>
import Vuex from "vuex";

const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export default {
    name: "UserGroupTable",
    props: {
        accountGroup: Array,
        actualUser: Object,
        dataLocalisation: Object,
        loadingStatus: Boolean,
        sort: String,
        direction: String,
        isBusy: Boolean,
        full: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            viewMenu: false,
            top: '0px',
            left: '0px',
            assetsBucket: process.env.VUE_APP_AWS_ASSETS_BUCKET,
            selectedModal: '',
            selectedItem: null,
            updatedItem: null,
            logo: null,
            logoSrc: null,
        }
    },
    methods: {
        ...Vuex.mapActions('accountGroup', {
            deleteAccountGroup: 'deleteAccountGroup',
            editAccountGroup: 'editAccountGroup'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('user', {
            setUsers: 'setUsers',
            deleteLinkedUserGroup: 'deleteLinkedUserGroup'
        }),
        async handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            if (this.selectedModal === 'edit') {
                await this.handleSubmit()
            } else if (this.selectedModal === 'delete') {
                await this.handleDelete()
            }
        },
        async handleSubmit() {
            let query = this.generateQueryString()
            await this.editAccountGroup(query);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-edit-group')
                await this.setAccounts()
            })
        },
        async handleDelete() {
            let data = []
            data.push(this.actualUser.id)

            if (this.selectedItem.name !== '') {
                data.push(this.selectedItem.id);
            }

            await this.deleteLinkedUserGroup(data);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-delete-group')
                await this.setUsers()
            })
        },
        generateQueryString() {
            let data = []
            data.push(this.updatedItem.id)
            let fields = new FormData();

            if (this.updatedItem.name !== '') {
                fields.append('name', this.updatedItem.name)
            }

            if (this.updatedItem.logo !== '') {
                fields.append('logo', this.updatedItem.logo)
            }

            data.push(fields)
            return data
        },
        setMenu: function (top, left) {
            this.top = (top - 267) + 'px';
            this.left = (left - 61) + 'px';
        },
        closeMenu: function () {
            this.viewMenu = false;
        },
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        // showGroupDetails(item) {
        //     this.$router.go('account-groups/'+item.id)
        // },
        deleteUserLinkToGroup(item) {
            this.selectedItem = item
            this.selectedModal = 'delete'
            this.$bvModal.show('modal-delete-group')
        }
    },
    watch: {
        logo(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.logoSrc = value;
                        })
                        .catch(() => {
                            this.logoSrc = null;
                        });
                } else {
                    this.logoSrc = null;
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

#right-click-menu {
    background: #FAFAFA;
    border: 1px solid #BDBDBD;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 250px;
    z-index: 999999;
}

#right-click-menu li {
    border-bottom: 1px solid #E0E0E0;
    margin: 0;
    padding: 5px 35px;
}

#right-click-menu li:last-child {
    border-bottom: none;
}

#right-click-menu li:hover {
    background: $primary;
    color: #FAFAFA;
}

.dot {
    margin: 5px 0;
}
</style>
