<template>
    <div class="row">
        <div class="col-xl-auto">
            <p class="font-weight-bolder"> SUIVI DES REPOSITORY GITLAB <b-badge variant="primary" class="h4">{{gitlabData.length}}</b-badge>  :</p>
            <div v-if="gitlabData.length > 0" class="row">
                <b-card
                    :header="item.name_with_namespace"
                    header-tag="header"
                    header-class="bg-blue clr-white font-weight-bolder"
                    :title="item.name"
                    v-for="(item,i) in gitlabData"
                    :key="i"
                    class="col-xl-4"
                >
                    <div>
                        <p><span class="font-weight-bolder">Id :</span> {{ item.id }}</p>
                        <p><span class="font-weight-bolder" v-if="item.description">Description :</span> {{ item.description }}</p>
                        <p><span class="font-weight-bolder">Date de création :</span> {{ item.created_at }}</p>
                        <p><span class="font-weight-bolder">Branche par default :</span> {{ item.default_branch }}</p>
                        <p
                            @click="sendToGitlab(item.web_url)"
                            class="add-cursor"
                        >
                            <span class="font-weight-bolder">URL Web :</span> {{ item.web_url }}
                        </p>
                        <p
                                @click="sendToGitlab(item.readme_url)"
                                class="add-cursor"
                        >
                            <span class="font-weight-bolder">URL Readme :</span> {{ item.readme_url }}
                        </p>
                        <p>
                            <span class="font-weight-bolder">
                            Derniere activité :</span> {{ item.last_activity_at }}
                        </p>
                        <div v-if="gitLabPipelines.find(x => x.projectId === item.id) && Object.keys(gitLabPipelines.find(x => x.projectId === item.id)).length > 1">
                            <p>
                                <span class="font-weight-bolder">
                                Statut du Build :</span> {{ gitLabPipelines.find(x => x.projectId === item.id).status}}
                            </p>
                            <p v-if="gitLabPipelines[i] !== undefined">
                                <span class="font-weight-bolder">
                                Date du Build :</span> {{ gitLabPipelines.find(x => x.projectId === item.id).updated_at }}
                            </p>
                        </div>
                    </div>
                </b-card>
            </div>
            <div v-else>
                <b-spinner></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import gitlab from "@/services/api/gitlab";

export default {
    name: "GitlabGlobal",
    data() {
        return {
            gitlabData:[],
            gitLabPipelines:[],
            error:[]
        }
    },
    methods:{
        async getDataFromGitLab(){
            await gitlab.getListOfProject().then( data => {
                this.gitlabData = data.data

                for (let i = 0 ; i < data.data.length ; i++){
                    this.gitPipelinesDataFromGitlab(data.data[i].id)
                }

            }).catch(error =>{
                this.error.push(error)
            });
        },
        async gitPipelinesDataFromGitlab(id){
            await gitlab.getListOfPipelines(id).then( data => {
                if (data.data !== undefined && data.data.length > 0) {
                    data.data[0].projectId = id
                    this.gitLabPipelines.push(data.data[0])
                } else {
                    this.gitLabPipelines.push({projectId:id})
                }
            }).catch(error =>{
                this.error.push(error)
            });
        },
        sendToGitlab(url) {
            var win = window.open(url, '_blank');
            win.focus();
        },
    },
    computed: {},
    async created() {
        await this.getDataFromGitLab()
    }
}
</script>

<style scoped>

</style>