import Vue from "vue"
import moment from 'moment';
import store from "@/store";


function capitalize(value){
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

function toUpperCase(value){
	if (!value) return ''
	return value.toUpperCase()
}

Vue.filter('toUpperCase', function (value) {
	return toUpperCase(value)
});

Vue.filter('capitalize', function (value) {
	return capitalize(value)
});

Vue.filter('isEmptyObject', function (object) {
	return Object.keys(object).length !== 0
});

// Vue.filter('round', function (value) {
// 	if (!value) return ''
// 	return Math.round(0.9)
// });
//
// Vue.filter('minuteToHourMinute', function (value) {
// 	let num = value;
// 	let hours = (num / 60);
// 	let rhours = Math.floor(hours);
// 	let minutes = (hours - rhours) * 60;
// 	let rminutes = Math.round(minutes);
// 	if (rhours === 0) {
// 		return rminutes + " mn";
// 	} else {
// 		return rhours + " hr " + rminutes + " mn";
//
// 	}
// });
//
// Vue.filter('formatPhone', function (value) {
// 	if (!value) return '';
// 	let phoneValue = '0' + value.toString();
// 	let arrayValue = phoneValue.split('');
// 	let phoneNumber = '';
// 	for (let i = 0; i < arrayValue.length; i++) {
// 		if (i % 2 === 0 && i !== 0) {
// 			phoneNumber += ' ' + arrayValue[i];
// 		} else {
// 			phoneNumber += arrayValue[i];
// 		}
// 	}
// 	return phoneNumber;
// });
//
// Vue.filter('ageByBirthday', function (birthday) {
// 	let formatedBirthday = new Date(birthday);
// 	let ageDifMs = Date.now() - formatedBirthday.getTime();
// 	let ageDate = new Date(ageDifMs);
// 	return Math.abs(ageDate.getUTCFullYear() - 1970);
// });
//
// Vue.filter('displayDateDiffHour', function (date) {
// 	const formatDate = new Date(date);
// 	let hours = moment(new Date()).diff(formatDate, 'hours');
//
// 	if (hours < 1) {
// 		let minutes = moment(new Date()).diff(formatDate, 'minutes');
// 		if (minutes < 0) {
// 			return ' ' + i18n.tc('time.lessThanMinute')
// 		} else {
// 			return minutes + ' ' + i18n.tc('time.day',minutes);
// 		}
// 	} else if (hours > 24) {
// 		let days = moment(new Date()).diff(formatDate, 'days');
// 		return days + ' ' + i18n.tc('time.day',days)
// 	} else {
// 		return hours + ' ' + i18n.tc('time.hour',hours) + (hours > 1 ? 's' : '')
// 	}
// });

// Vue.filter('uppercase', function (value) {
// 	if (!value) return '';
// 	return value.toUpperCase()
// });

// Vue.filter('formatTimeStringToSimpleHour', function (value) {
// 	if (!value) return '';
// 	let dataTime = value
// 	let splitDatatime = dataTime.split(':')
// 	let splitDataTimeValue = splitDatatime[0]
// 	if (splitDataTimeValue.substring(0, 1) === '0') {
// 		dataTime = splitDataTimeValue.substring(1)
// 	}else {
// 		dataTime = splitDataTimeValue
// 	}
// 	return dataTime
// });
//
Vue.filter('dateDayMonth', function(value) {
	// let locale = store.getters.getAppLanguage;
	// moment.locale(locale)
	if (value) return moment.utc(value).local().fromNow()
	else return '- -'
});
Vue.filter('dateToTime', function(value) {
	return  moment(value).format('HH:mm:ss')
});

Vue.filter('basicDate', function(value) {
	let locale = store.getters.getAppLanguage;
	moment.locale(locale)
	return moment(value).format('DD-MM-YYYY HH:mm:ss')
});


Vue.filter('dayDate', function(value) {
	let locale = store.getters.getAppLanguage;
	moment.locale(locale)
	return moment(value).format('DD-MM-YYYY')
});

Vue.filter('readableDate', function(value) {
	let locale = store.getters.getAppLanguage;
	moment.locale(locale)
	return moment(value).format('LL')
});

Vue.filter('convertDate', function (date) {
	let utcDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
	const stillUtc = moment.utc(utcDate).toDate();
	return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss')
});

Vue.filter('toPourcentage', function (value) {
	if (isNaN(value)) {
		return '0%'
	} else {
		return ((Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2)*100).toFixed(1) + '%'
	}
});
//
// Vue.filter('minutesToHours', function(minutes) {
// 	return Math.round(minutes/60);
// });

Vue.filter('compactHighNumber', function (value){
	let o = Intl.NumberFormat('en', { notation: 'compact' });
	return o.format(value)
})
