<template>
    <div ref="LoacTable">
        <b-col cols="12" class="basic-card p-2">
            <b-table-simple
                    responsive
                    hover
                    borderless
                    sticky-header
                    class="sensor-array table-height"
                    v-if="sensors.length > 0 && !isBusy"
            >
                <b-thead>
                    <b-tr>
                        <b-th @click="setSort('serialNumber')" class="add-cursor">
                            Numéro de série
                            <b-icon-arrow-down-short v-if="sort === 'serialNumber' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'serialNumber'"/>
                        </b-th>
                        <b-th>Localisation</b-th>
                        <b-th class="add-cursor" @click="setSort('sensorAddress.locality')">
                            Ville d'implantation
                            <b-icon-arrow-down-short v-if="sort === 'sensorAddress.locality' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'sensorAddress.locality'"/>
                        </b-th>
                        <b-th>Compte</b-th>
                        <b-th>Dernière connexion</b-th>
                        <b-th @click="setSort('lastUpdate')" class="text-center add-cursor">
                            Statut connexion
                            <b-icon-arrow-down-short v-if="sort === 'lastUpdate' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'lastUpdate'"/>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                            v-for="(item,i) in sensors"
                            :key="i"
                            class="clr-dark-grey add-cursor"
                            :class="hoveredSensor === item.id ? 'bg-lighter-green' : 'bg-white'"
                            @mouseover="setHoveredSensor(item.serialNumber)"
                            @mouseout="setHoveredSensor(null)"
                            @click="$router.push('/sensors/'+item.serialNumber+'/detail')"
                    >
                        <b-td>{{ item.serialNumber }}</b-td>
                        <b-td v-if="item.sensorAddress.lat">
                            {{ item.sensorAddress.lat }}
                            <br/>
                            {{ item.sensorAddress.lon }}
                        </b-td>
                        <b-td v-else>NO GPS</b-td>
                        <b-td v-if="item.sensorAddress && item.sensorAddress.locality">
                            {{ item.sensorAddress.locality }}, {{ item.sensorAddress.countryLongName }}
                        </b-td>
                        <b-td v-else>
                            No Address
                        </b-td>
                        <b-td v-if="item.owningAccount">
                            <router-link :to="'/accounts/'+item.owningAccount.id">
                                {{ item.owningAccount.name }}
                            </router-link>
                        </b-td>
                        <b-td v-else>
                            No Account
                        </b-td>
                        <b-td v-if="item.lastUpdate">
                            {{ item.lastUpdate | convertDate }}
                        </b-td>
                        <b-td v-else>
                            Never Initialised
                        </b-td>
                        <b-td class="text-center" v-if="item.lastUpdate">
                            <div v-if="isOnline(item.lastUpdate,2)" class="dot medium-size bg-green"></div>
                            <div v-else class="dot medium-size bg-red"></div>
                        </b-td>
                        <b-td class="text-center" v-else>
                            No Data
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "LoacTable",
    props: {
        sensors: Array,
        loadingStatus: Boolean,
        hoveredSensor: String,
        sort: String,
        direction: String,
        isBusy: Boolean,
    },
    methods: {
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        setHoveredSensor(sensorId) {
            this.$emit('hovered-sensor', sensorId)
        },
        isOnline(date, offset = 2) {
            let utcData = moment.utc(date);
            let hoursAgo = moment.utc().subtract(offset, 'hours').toDate().toUTCString();
            return utcData.isAfter(hoursAgo);
        },
        lastTimeOnline(date) {
            let utcData = moment.utc(date);
            return moment(utcData, "YYYY-MM-DD HH:mm:SS", true).fromNow()
        }
    },
    created() {
    }
}
</script>

<style scoped lang="scss">
.dot {
  margin: 5px 0;
}
</style>
