<template>
    <div class="offset-xl-2 col-xl-8 h-100">
        <div class="row mb-5">
            <div class="col-xl-3 d-flex justify-content-center align-items-center p-0 m-0 float-left"
                 @click="goToCampaignsList">
                <p class="h4 clr-dark-grey add-cursor">
                    <b-icon icon="chevron-left" class=""></b-icon>
                    Retour à la liste
                </p>
            </div>
            <div class="col-xl-6 text-center">
                <p class="h1 clr-dark-grey">
                    Informations campagne
                </p>
            </div>
        </div>
        <div class="row justify-content-center align-content-center" v-if="campaigns.length > 0">
            <div class="col-xl-12 shadow rounded mb-3 row">
                <div class="col-md-6 my-2">
                    <div class="row p-3 clr-dark-blue">
                        <div class="col-xl-12">
                            <p class="h4 mb-3 clr-dark-grey">Informations Générales</p>
                        </div>
                        <div class="col-xl-12">
                            <p class="h4 mb-2">
                                <b-icon icon="people-fill" class="mr-3"></b-icon>
                                Nom : {{ actualCampaign.name | capitalize }}
                            </p>
                        </div>
                        <div class="col-xl-12">
                            <p class="h4 mb-2">
                                <b-icon icon="card-checklist" class="mr-3"></b-icon>
                                Code : {{ actualCampaign.code }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 my-2">
                    <div class="row p-3 clr-dark-blue">
                        <div class="col-xl-12">
                            <p class="h4 mb-3 clr-dark-grey">Période</p>
                        </div>
                        <div class="col-xl-12">
                            <p class="h4 mb-2">
                                <b-icon icon="people-fill" class="mr-3"></b-icon>
                                Début : {{ actualCampaign.startAt | convertDate }}
                            </p>
                        </div>
                        <div class="col-xl-12">
                            <p class="h4 mb-2">
                                <b-icon icon="card-checklist" class="mr-3"></b-icon>
                                Fin : {{ actualCampaign.endAt | convertDate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 p-3 shadow rounded mb-3 row">
                <div class="col-12 justify-content-center align-items-center">
                    <p class="h4 mb-3 mt-2 clr-dark-grey">Ressources associés à la campagne</p>
                </div>
                <div class="col-12 my-2">
                    <AccountTable class="account-sensors-table"></AccountTable>
                </div>
            </div>
            <div class="col-12 p-3 shadow rounded mb-3 row">
                <b-row class="col-12">
                    <b-col cols="6">
                        <p class="h4 mb-3 mt-2 clr-dark-grey float-left">Adhérents({{relatedUsers.length}})</p>
                    </b-col>
                    <b-col cols="6">
                        <b-input-group :prepend="$t('common.search') | capitalize">
                            <b-form-input v-model="search" placeholder="john.doe@example.com"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <div class="col-12 my-2">
                    <UserTable class="account-sensors-table"/>
                </div>
            </div>
            <div class="col-12 rounded mb-3">
                <div class="row p-3 rounded bg-white shadow h-100">
                    <div class="col-12 border-dark account-action rounded add-cursor" @click="goToCampaignEdit">
                        <p class="h4 my-2">
                            <b-icon icon="pencil" class="mr-3"></b-icon>
                            Éditer la campagne
                            <b-icon icon="chevron-right" class="float-right"></b-icon>
                        </p>
                    </div>
                    <div class="col-12 account-action rounded my-auto add-cursor" @click="askDeleteCampaign()">
                        <p class="h4 my-2">
                            <b-icon icon="trash" class="mr-3"></b-icon>
                            Supprimer
                            <b-icon icon="chevron-right" class="float-right"></b-icon>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <b-spinner v-else></b-spinner>
    </div>
</template>

<script>
import Vuex from 'vuex';
import Toast from "@/services/swal2/mixins"
import UserTable from "@/components/campaign/UserTable";
import AccountTable from "@/components/campaign/AccountTable";

export default {
    name: "CampaignInformation",
    components: {AccountTable, UserTable},
    data() {
        return {
            search: ''
        }
    },
    methods: {
        ...Vuex.mapActions('campaign', {
            deleteCampaign: 'deleteCampaign',
            setRelatedAccounts: 'setRelatedAccounts',
            setCampaigns: 'setCampaigns',
            setRelatedUsers: 'setRelatedUsers',
        }),
        askDeleteCampaign() {
            this.$swal.fire({
                title: 'Etes vous sur de vouloir supprimer cette campagne ?',
                text: "Cette action est irréversible",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, je suis sur!'
            }).then((result) => {

                if (result.value) {
                    this.deleteCampaign(this.$route.params.id)
                }
            })
        },
        goToCampaignsList() {
            this.$router.push('/campaigns');
        },
        goToCampaignEdit() {
            this.$router.push('/campaigns/' + this.$route.params.id + '/edit');
        },
        queryUsers() {
            this.setRelatedUsers({
                campaign: this.actualCampaign.id,
                params: {search: this.search}
            });
        }
    },
    computed: {
        ...Vuex.mapGetters('campaign', {
            getCampaignById: 'getCampaignById',
            getRequest: 'getRequest',
            campaigns: 'campaigns',
            relatedAccounts: 'relatedAccounts',
            relatedUsers: 'relatedUsers'
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
            loadingStatus: 'loadingStatus',
        }),
        actualCampaign() {
            return this.getCampaignById(parseInt(this.$route.params.id))
        },
    },
    watch: {
        getRequest: function () {
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
            }).then(
                setTimeout(() => {
                    this.goToCampaignsList()
                }, 3050)
            )
        },
        search: function (value) {
            if (value.length > 2 || value.length === 0) {
                this.queryUsers();
            }
        }
    },
    async mounted() {
        if (this.campaigns.length === 0) {
            await this.setCampaigns()
        }
        this.setRelatedAccounts(this.actualCampaign.id);
        this.setRelatedUsers({campaign: this.actualCampaign.id});
    }
}
</script>

<style lang="scss" scoped>

@keyframes animate-circle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.account-action {
    &:hover {
        background-color: rgba(62, 159, 218, 0.22);
    }
}

.logo-account {
    width: 128px;
}

.add-button {
    background-color: white;

    &:hover {
        color: #058bcc;
    }
}

.animate-circle {
    &:hover {
        animation: animate-circle 1s 1 running;
    }
}
</style>