import DataAccessCreate from "@/components/account/AccountCustomAccess";
import AccountAccessEdit from "@/components/account/AccountAccessEdit";

export default [
    {
        path: '/access-data/new',
        component: DataAccessCreate,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Accounts',
                    link: '/accounts'
                },
                {
                    name: 'New'
                }
            ]
        },
    },
    {
        path: '/access-data/:id?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/DataAccess.vue'),
        props:true,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'edit/:type?',
                name: 'AccountAccessEdit',
                component: AccountAccessEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Accounts',
                            link: '/accounts'
                        },
                        {
                            name: 'DataAccess Edit'
                        }
                    ]
                },
            }
        ]
    },
];