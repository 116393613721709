import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

const state = {
    campaigns: [],
    paginator: {
        current:1,
        limit:50
    },
    relatedAccounts: [],
    relatedUsers: [],
    loadingStatus: false,
    loadingAccounts: false,
    loadingUsers: false,
    request: {}
};


const mutations = {
    SET_CAMPAIGNS: (state, campaigns) => {
        state.campaigns = campaigns;
    },
    SET_ACCOUNTS: (state, accounts) => {
        state.relatedAccounts = accounts;
    },
    SET_USERS: (state, users) => {
        state.relatedUsers = users;
    },
    SET_PAGINATOR: (state, paginator) => {
        state.paginator = paginator;
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
    CAMPAIGNS_IS_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus
    },
    LOADING_ACCOUNTS: (state) => {
        state.loadingAccounts = !state.loadingAccounts
    },
    LOADING_USERS: (state) => {
        state.loadingUsers = !state.loadingUsers
    },
    CREATE_CAMPAIGN: (state, campaign) => {
        state.campaigns.push(campaign);
    },
    UPDATE_INDIVIDUAL_CAMPAIGN: (state, data) => {
        let selectedCampaign = state.campaigns.find(campaign => campaign.id === data.id)
        Object.assign(selectedCampaign, data);
    },
};
const actions = {
    setCampaigns: async (store, data) => {
        store.commit('CAMPAIGNS_IS_LOADING_STATUS');
        await api.campaign.getCampaigns(data)
            .then(response => {
                store.commit('SET_CAMPAIGNS', response.data.campaigns);
                store.commit('SET_PAGINATOR', response.data.paginator);
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            });
    },
    editCampaign: async (store, data) => {
        store.commit('CAMPAIGNS_IS_LOADING_STATUS');
        await api.campaign.updateCampaign(data)
            .then(response => {
                store.commit('UPDATE_INDIVIDUAL_CAMPAIGN', response.data.data);
                store.commit('SET_REQUEST', response.data);
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            });
    },
    emptyCampaigns: async (store) => {
        store.commit('SET_CAMPAIGNS', []);
    },
    deleteCampaign: async (store, campaign) => {
        store.commit('CAMPAIGNS_IS_LOADING_STATUS');
        await api.campaign.deleteCampaign(campaign)
            .then(response => {
                store.commit('SET_REQUEST', response.data);
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            });
    },
    createCampaign: async (store, data) => {
        store.commit('CAMPAIGNS_IS_LOADING_STATUS');
        await api.campaign.createCampaign(data)
            .then(response => {
                store.commit('CREATE_CAMPAIGN', response.data.data);
                store.commit('SET_REQUEST', response.data);
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
                store.commit('CAMPAIGNS_IS_LOADING_STATUS');
            });
    },
    setRelatedAccounts: async (store, campaign) => {
        store.commit('LOADING_ACCOUNTS');
        await api.campaign.getCampaignRelatedAccounts(campaign)
            .then((response) => {
                store.commit('SET_ACCOUNTS', response.data.data);
                store.commit('LOADING_ACCOUNTS');
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error', title: error.response.message,
                })
                store.commit('LOADING_ACCOUNTS');
            })
    },
    setRelatedUsers: async (store, data) => {
        store.commit('LOADING_USERS');
        await api.campaign.getCampaignRelatedUsers(data.campaign, data.params)
            .then((response) => {
                store.commit('SET_USERS', response.data.data);
                store.commit('LOADING_USERS');
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error', title: error.response.message,
                })
                store.commit('LOADING_USERS');
            })
    },
};

const getters = {
    campaigns: state => {
        return state.campaigns
    },
    relatedAccounts: state => {
        return state.relatedAccounts
    },
    relatedUsers: state => {
        return state.relatedUsers
    },
    paginator: state => {
        return state.paginator
    },
    getCampaignById: (state) => (id) => {
        return state.campaigns.find(campaign => campaign.id === id)
    },
    loadingStatus: state => {
        return state.loadingStatus
    },
    getRequest: state => {
        return state.request
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
