import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getSensorEvents,
    getSensorEvent,
    getBySensor,
    getByAccount,
    editSensorEvent,
    createSensorEvent
}
const adminPrefixUrl = '/admin/sensor-events';

function getSensorEvents() {
    return axios.get(adminPrefixUrl + '?type=ongoing', authHeader.classicalHeader())
}

function getSensorEvent(id) {
    return axios.get(adminPrefixUrl + '/' + id, authHeader.classicalHeader())
}
function getBySensor(serialNumber) {
    return axios.get(adminPrefixUrl + '/sensor/' + serialNumber, authHeader.classicalHeader())
}
function getByAccount(accountId) {
    return axios.get(adminPrefixUrl + '/account/' + accountId, authHeader.classicalHeader())
}
function editSensorEvent(data) {
    return axios.post(adminPrefixUrl + '/' + data.id, data.params, authHeader.classicalHeader())
}
function createSensorEvent(data) {
    return axios.post(adminPrefixUrl + '/new', data, authHeader.classicalHeader())
}