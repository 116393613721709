<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="!onlyPreview"
            :preview-modal="onlyPreview"
            :paginate-elements-by-height="1400"
            :filename="sensor+'_testing_report_'+(this.$options.filters.dayDate(Date.now()))"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
            pdf-content-width="100%"
            @progress="onProgress($event)"
        >
            <section slot="pdf-content">
                <section slot="pdf-item">
                    <component v-bind:is="dynamicPDFTemplate" :sensor="sensor" :setup="setup"></component>
                </section>
            </section>
        </vue-html2pdf>
    </div>
</template>


<script>
import VueHtml2pdf from 'vue-html2pdf'
import EventBus from "@/utils/event-bus";
import SensorTestingReportTemplate from "@/components/pdf/SensorTestingReportTemplate.vue";

export default {
    name: "PDFTemplate",
    components: {VueHtml2pdf, SensorTestingReportTemplate},
    props: {
        template: {
            type: String,
            default: ''
        },
        sensor: {
            type: String,
            default: ''
        },
        setup: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            onlyPreview: false
        }
    },
    computed: {
        dynamicPDFTemplate() {
            if (this.template === 'SensorTestingReportTemplate') return SensorTestingReportTemplate;
            return null
        }
    },
    methods: {
        onProgress(value) {
            this.$emit('pdf-progress', value);
        },
        generateReport() {
            if (this.$refs.html2Pdf) {
                this.$refs.html2Pdf.generatePdf()
            }
        }
    },
    mounted() {
        EventBus.$on('generate-pdf', (value) => {
            this.onlyPreview = value
            this.$nextTick(() => {
                this.generateReport()
            })
        });
    }
}
</script>

<style scoped>

</style>