import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

const state = {
    loadingStatus: false,
    request:{}
};


const mutations = {
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
    SET_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus;
    }
};
const actions = {
    transfertAccountEquipmentToDataAccessByAccount: async (store, params) => {
        store.commit('SET_LOADING_STATUS');
        await api.developerTools.transfertAccountEquipmentToDataAccessByAccount(params)
            .then( response =>  {
                store.commit('SET_REQUEST', response.data.data);
                store.commit('SET_LOADING_STATUS');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
};

const getters = {
    getRequest: state => {
        return state.request;
    },
    getLoadingStatus: state => {
        return state.loadingStatus;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
