<template>
    <b-container fluid>
            <homeAnalytics></homeAnalytics>
    </b-container>
</template>

<script>
import HomeAnalytics from "@/components/analytics/HomeAnalytics";
export default {
    name: "Dataviz",
    components:{HomeAnalytics}
}
</script>

<style scoped>

</style>