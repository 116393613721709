import authentication from './authentication.js'
import sensor from "./sensor";
import account from "./account";
import user from "./user";
import parameter from "./parameter";
import analytics from "@/services/api/analytics";
import matooma from "@/services/api/matooma";
import campaign from "@/services/api/campaign";
import dataAccess from "@/services/api/dataAccess";
import developerTools from "@/services/api/developerTools";
import monitoring from "@/services/api/monitoring";
import mobile from "@/services/api/mobile";
import deliveryTracking from "@/services/api/deliveryTracking";
import sensorSetup from "@/services/api/sensorSetup";
import contentGeneration from "@/services/api/contentGeneration";
import sensorEvent from "@/services/api/sensorEvent";

export default {
	authentication,
	sensor,
	account,
	user,
	parameter,
	analytics,
	matooma,
	campaign,
	dataAccess,
	developerTools,
	monitoring,
	mobile,
	deliveryTracking,
	sensorSetup,
	contentGeneration,
	sensorEvent
}
