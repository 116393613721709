<template>
    <b-row
        class="justify-content-center align-content-center"
        v-if="loadingStatus || isLoading">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Modifier le capteur
                <span v-if="Object.keys(individualSensor).length > 0" class="clr-orange">
                    <br>N° {{ individualSensor.serialNumber | toUpperCase }}
                </span>
                <span v-else class="clr-orange">
                    <br>N° {{ $route.params.id | toUpperCase }}
                </span>
            </h2>
            <b-skeleton
                v-for="(skeleton, index) in skeletons"
                :key="index"
                :width="randomWidth()"
                :height="skeleton.height"
                animation="wave"
                class="mb-4"/>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
    <b-row class="justify-content-center align-content-center" v-else>
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Modifier le capteur
                <span v-if="sensor" class="clr-orange">
                    <br>N° {{ sensor.serialNumber | toUpperCase }}
                </span>
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <!-- ### Name ### -->
                <p class="h4 mb-4">Nommage du capteur</p>
                <b-form-group
                    label="Nom publique du capteur :"
                    label-for="public-name"
                >
                    <b-form-input
                        id="public-name"
                        placeholder="Nom du capteur"
                        v-model="chosenName"/>
                </b-form-group>
                <div class="divider my-4 ml-0"/>

                <!-- ### Parameters ### -->
                <p class="h4">Paramétrage</p>
                <b-form-group
                    label="Version du capteur"
                    label-for="sensor-version"
                >
                    <b-form-select
                        id="sensor-version"
                        v-model="sensorVersion"
                        :options="versionOptions">
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    label="Visibilité du capteur"
                    label-for="visibility"
                >
                    <b-form-select
                        id="visibility"
                        v-model="visibility"
                        :options="visibilityOptions">
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    label="Puissance du Capteur"
                    label-for="power"
                >
                    <b-form-input
                        id="power"
                        required
                        v-model="power"
                        placeholder="Puissance du laser en mW">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Capteur Mobile"
                    label-for="is-mobile"
                >
                    <b-form-select
                        id="is-mobile"
                        v-model="mobile"
                        :options="optionsMobile">
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    label="Fixer le GPS (en cas de dérive)"
                    label-for="gps-fixed"
                >
                    <b-form-select
                        id="gps-fixed"
                        v-model="fixedGPS"
                        :options="optionsMobile">
                    </b-form-select>
                </b-form-group>
                <div class="divider my-4 ml-0"/>

                <!-- ### Communication ### -->
                <p class="h4">Communication</p>
                <b-form-group
                    label="Identifiant Sim Matooma"
                    label-for="sim"
                >
                    <b-form-input
                        id="sim"
                        v-model="simId"
                        placeholder="ex: 1698465135461"
                        required>
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Le sensor fonctionnera t'il par wifi ?"
                    label-for="wifi"
                >
                    <b-form-select
                        id="wifi"
                        v-model="wifi"
                        :options="optionsWifi">
                    </b-form-select>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorEdit",
    props: {
        type: {
            type: String,
            default: 'pollen'
        }
    },
    data() {
        return {
            serialNumber: null,
            mobile: '0',
            fixedGPS: '0',
            power: null,
            simId: null,
            sensorVersion: null,
            visibility: null,
            sensor: null,
            chosenName: '',
            optionsMobile: [
                {value: 1, text: 'Oui'},
                {value: 0, text: 'Non'}
            ],
            optionsWifi: [
                {value: 1, text: 'Oui'},
                {value: 0, text: 'Non'}
            ],
            visibilityOptions: [
                {value: 'public', text: 'Publique'},
                {value: 'private', text: 'Privé'},
                {value: 'hidden', text: 'Caché'},
            ],
            versionOptions: [
                {value: 'v1', text: 'Version 1'},
                {value: 'v2', text: 'Version 2'},
                {value: 'v3', text: 'Version 3'},
            ],
            sensorType: null,
            isMobile: null,
            wifi: null,
            isLoading: false,
            skeletons: [
                {height: "30px"},
                {height: "50px"},
                {height: "20px"},
                {height: "50px"},
                {height: "50px"},
                {height: "50px"},
                {height: "20px"},
                {height: "50px"},
                {height: "50px"},
                {height: "50px"},
                {height: "50px"}
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensor: 'editSensor',
            setIndividualSensors: 'setIndividualSensors'
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.editSensor(query).then(() => {
                this.goToSensorProfile();
            });
        },
        generateQueryString() {
            const data = [this.$route.params.id];
            const fields = new FormData();

            if (this.chosenName !== null) {
                fields.set('sensorName', this.chosenName)
            }

            if (this.mobile !== this.individualSensor.isMobile) {
                fields.set('isMobile', this.mobile)
            }

            if (this.fixedGPS !== this.individualSensor.fixedGps) {
                fields.set('fixedGps', this.fixedGPS)
            }

            if (this.wifi !== this.individualSensor.wifiEnable) {
                fields.set('wifiEnable', this.wifi)
            }

            if (this.visibility !== this.individualSensor.visibility) {
                fields.set('visibility', this.visibility)
            }

            if (this.sensorVersion !== this.individualSensor.sensorVersion) {
                fields.set('sensorVersion', this.sensorVersion)
            }

            fields.set('laserPower', this.power)
            fields.set('simId', this.simId)

            data.push(fields)
            return data
        },
        goToSensorProfile() {
            this.$router.push('/sensors/' + this.$route.params.id + '/detail');
        },
        randomWidth() {
            return `${Math.random() * (100 - 60) + 60}%`;
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            loadingStatus: 'loadingStatus'
        }),
    },
    async created() {
        this.isLoading = true;
        if (Object.keys(this.individualSensor).length === 0 ||
            this.individualSensor.serialNumber !== this.$route.params.id) {
            await this.setIndividualSensors(this.$route.params.id);
        }

        const {
            sensorParameters: {
                laserPower,
                simId,
                isMobile,
                fixedGps,
                wifiEnable
            },
            visibility,
            type,
            sensorName,
            sensorVersion
        } = this.individualSensor;

        this.power = laserPower;
        this.simId = simId;
        this.mobile = isMobile ? 1 : 0;
        this.fixedGPS = fixedGps ? 1 : 0;
        this.visibility = visibility;
        this.sensorType = type;
        this.chosenName = sensorName;
        this.wifi = wifiEnable ? 1 : 0;
        this.isLoading = false;
        this.sensorVersion = sensorVersion;
    }
}
</script>

<style lang="scss" scoped>
.divider {
    @include divider(rgba($orange, .2), 90%, 2px);
}

.basic-card {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
