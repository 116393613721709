<template>
    <div class="offset-xl-4 col-xl-4 col-sm-auto align-self-center justify-content-center w-100">
        <h2 class="mb-5">Création d'un Type de Données</h2>
        <b-form @submit.prevent="submit()">
            <b-form-group
                    id="input-type"
                    label="Type :"
                    label-for="input-type"
                    description="Nom du type"
            >
                <b-form-input
                        id="input-type"
                        v-model="type"
                        required
                        placeholder="Ex : Chiendent"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                    id="input-unit"
                    label="Unité"
                    label-for="input-unit"
                    description="g.o.p/m³ , C° , HPa , W ..."
            >
                <b-form-input
                        id="input-unit"
                        v-model="unit"
                        required
                        placeholder="Ex : Temperature en C°"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-category"
                label="Catégorie"
                label-for="input-category"
            >
                <b-form-select v-model="category" :options="optionsCategory" class="mb-3">
                </b-form-select>
            </b-form-group>
            <b-button type="submit" variant="primary" v-if="true">Créer</b-button>
            <b-button type="submit" variant="primary" v-else><b-spinner small></b-spinner></b-button>
        </b-form>
    </div>
</template>

<script>
    import Vuex from 'vuex'

    export default {
        name: "ReportTypeCreate",
        data() {
            return {
                type: '',
                unit: '',
                category: 'weed',
                chosenGroup: null,
                chosenAccount: null,
                optionsCategory: [
                    { value: 'tree', text: 'Arbre' },
                    { value: 'environment', text: 'Données Environnementales' },
                    { value: 'grass', text: 'Graminée' },
                    { value: 'weed', text: 'Herbacée' },
                ]
            }
        },
        methods: {
            ...Vuex.mapActions('parameter', {
                createReportType:'createReportType',
            }),
            async submit() {
                let query = this.generateQueryString()
                await this.createReportType(query).then(
                );
            },
            generateQueryString(){
                    var data = new FormData();
                    data.append('name',this.type)
                    data.append('unit',this.unit)
                    data.append('category', this.category)
                    return data
            },
            goToParametersList(){
                this.$router.push('/parameters/report-types');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
