<template>
    <b-col cols="12" class="mt-1 mb-3">
        <h4 class="clr-dark-grey text-center mb-3">CRÉATION DE NOUVEAUX CAPTEURS</h4>
        <b-row class="justify-content-center align-content-center">

            <!-- SENSORS CREATION FORM -->
            <SensorCreateForm
                    v-if="formStep === 0"
                    @resumeCreatedSensors="stepUp"
            ></SensorCreateForm>

            <!-- SENSORS CREATION RESUME -->
            <b-col cols="12" md="8" class="basic-card justify-content-center" v-if="formStep === 1">
                <b-row class="justify-content-center align-content-center">
                    <b-col cols="12" class="justify-content-center">
                        <div class="clr-dark-grey text-uppercase text-center">
                            <p>Capteurs créé(s) avec succès</p>
                        </div>
                    </b-col>
                    <b-col cols="12" class="text-center mt-5">
                        <p class="h1 clr-orange sensors-success">
                            <b-icon-check-circle/>
                        </p>
                    </b-col>
                    <b-col cols="12" class="text-center mt-5">
                        <p class="h4">
                            <b-badge v-for="(sensor, index) in createdSensors"
                                     :key="index"
                                     :class="createdSensors.length > 1 && index < createdSensors.length ? 'mr-2' : ''"
                                     class="clr-orange bg-white border-orange">
                                {{ sensor.serialNumber }}
                            </b-badge>
                        </p>
                    </b-col>
                    <p class="card-subtitle clr-light-grey mt-5">
                        Chaque capteur sera mis en stock et associé au compte Lifyair.
                    </p>
                </b-row>
            </b-col>

            <!-- NAVIGATION -->
            <b-col cols="12" md="8" class="mt-2 p-0" v-if="formStep === 1">
                <b-button class="btn-action text-center w-100" @click="finishCreation">
                    Terminer
                </b-button>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
import Vuex from 'vuex'
import SensorCreateForm from "@/components/sensor/Management/Create/SensorCreateForm.vue";

export default {
    name: "SensorCreate",
    components: {SensorCreateForm},
    data() {
        return {
            formStep: 0
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setAdminSensors: 'setAdminSensors',
            setChosenStatus: 'setChosenStatus'
        }),
        stepUp() {
            this.formStep += 1
        },
        stepDown() {
            if (this.formStep > 0) {
                this.formStep -= 1
            }
        },
        async finishCreation() {
            await this.setChosenStatus('stock')
            await this.$router.push('/sensors')
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            createdSensors: 'createdSensors'
        })
    }
}
</script>

<style lang="scss" scoped>
.sensors-success {
  font-size: 50px;
}
</style>
