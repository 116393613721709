import { render, staticRenderFns } from "./AWSDynamoDB.vue?vue&type=template&id=7fc85bd7&scoped=true&"
import script from "./AWSDynamoDB.vue?vue&type=script&lang=js&"
export * from "./AWSDynamoDB.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc85bd7",
  null
  
)

export default component.exports