<template>
    <div class="offset-4 col-xl-4 align-self-center justify-content-center w-100">
        <h2 class="mb-5">{{ $t('campaign.edit') | capitalize }}</h2>
        <b-form @submit.prevent="submit()">
            <b-form-group
                id="c-name"
                label="Nom de la campagne :"
                label-for="c-name"
            >
                <b-form-input
                    id="c-name"
                    v-model="campaign.name"
                    type="text"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="c-code"
                label="Code :"
                label-for="c-code"
            >
                <b-form-input
                    id="c-code"
                    v-model="campaign.code"
                    type="text"
                    maxlenght="8"
                    required
                    :state="campaign.code === '' ? null : campaign.code.length <= 8"
                ></b-form-input>
                <b-form-invalid-feedback id="c-code">
                    MAX 8 caractères
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Comptes rattachés à la campagne :" v-slot="{ ariaDescribedby }"
                          v-if="accounts.length > 0" label-for="accounts">
                <b-form-select v-model="chosenAccounts" multiple :select-size="5" id="accounts">
                    <b-form-select-option :aria-describedby="ariaDescribedby"
                                          :value="account.id" v-for="(account, index) in accounts" :key="index">
                        {{ account.name }}
                    </b-form-select-option>
                </b-form-select>
                <b-form-text id="input-live-help">Sélection multiple.</b-form-text>
            </b-form-group>
            <b-form-group
                id="start-datepicker"
                label="Début :"
                label-for="start-datepicker"
            >
                <b-form-datepicker id="start-datepicker" v-model="campaign.startAt" class="mb-2"></b-form-datepicker>
            </b-form-group>
            <b-form-group
                id="end-datepicker"
                label="Fin :"
                label-for="start-datepicker"
            >
                <b-form-datepicker id="end-datepicker" v-model="campaign.endAt" class="mb-2"></b-form-datepicker>
            </b-form-group>

            <b-button @click="$router.go(-1)" variant="outline-danger" class="float-left">Annuler</b-button>
            <b-button type="submit" variant="primary" class="float-right">Éditer</b-button>
        </b-form>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins";

export default {
    name: "CampaignEdit",
    data() {
        return {
            campaign: {
                name: '',
                code: '',
                startAt: null,
                endAt: null,
                sensors: [],
                busy: false
            },
            chosenAccounts: [],
        }
    },
    methods: {
        ...Vuex.mapActions('campaign', {
            editCampaign: 'editCampaign',
            setRelatedAccounts: 'setRelatedAccounts'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts',
        }),
        async submit() {
            this.busy = true;
            let query = this.generateQueryString()
            await this.editCampaign({campaign: this.actualCampaign.id, data: query});
            this.busy = false;
        },
        generateQueryString() {
            let data = new FormData();
            data.append('name', this.campaign.name)
            data.append('code', this.campaign.code)
            data.append('startAt', this.campaign.startAt)
            data.append('endAt', this.campaign.endAt)
            if (this.chosenAccounts.length > 0) {
                let accounts = this.chosenAccounts;
                accounts = accounts.join(':')
                data.append('accounts', accounts)
            }
            return data
        },
        goToCampaign() {
            this.$router.push('/campaigns/' + this.$route.params.id + '/information');
        }
    },
    computed: {
        ...Vuex.mapGetters('campaign', {
            getCampaignById: 'getCampaignById',
            getRequest: 'getRequest',
            campaigns: 'campaigns',
            relatedAccounts: 'relatedAccounts'
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
        actualCampaign() {
            return this.getCampaignById(parseInt(this.$route.params.id))
        }
    },
    watch: {
        getRequest() {
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
            }).then(
                setTimeout(() => this.goToCampaign(), 1500)
            )
        },
    },
    mounted() {
        this.setAccounts();
        this.setRelatedAccounts(this.actualCampaign.id).then(() => {
            this.chosenAccounts = this.relatedAccounts.map(account => account.id)
        });
    },
    beforeMount() {
        this.campaign = this.actualCampaign
    },
}
</script>

<style lang="scss" scoped>

</style>
