<template>
    <b-row class="justify-content-center">
        <!-- FILTERS -->
        <b-col cols="12">
            <b-row class="justify-content-center my-4">
                <b-col cols="12" md="7" class="text-left">
                    <b-dropdown variant="primary" text="Plus d'options">
                        <b-dropdown-item @click="$router.push('/accounts/new')" variant="primary">
                            <b-icon icon="plus-circle"></b-icon>
                            {{ $t('account.addNew') | capitalize }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col cols="12" md="5" class="align-items-end align-self-end mt-2 mt-md-0">
                    <b-input-group
                        class=""
                        v-on:submit.prevent="queryAccount"
                        v-on:keyup.enter="queryAccount">
                        <b-form-input v-model="search"
                                      :placeholder="$t('account.search') | capitalize">
                        </b-form-input>
                        <b-input-group-append>
                            <b-button
                                type="submit"
                                variant="primary"
                                @click="queryAccount">
                                {{ $t('common.search')| capitalize }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="12" class="mt-3">
                    <b-tabs pills align="center">
                        <b-tab title="Standard" @click="planLevel = 'standard'" :active="planLevel === 'standard'"/>
                        <b-tab title="Entreprise" @click="planLevel = 'pro'" :active="planLevel === 'pro'"/>
                        <b-tab title="Mobile" @click="planLevel = 'individual'"
                               :active="planLevel === 'individual'"/>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-col>

        <!-- ACCOUNTS TABLE -->
        <b-col cols="12">
            <b-col cols="12" class="basic-card">
                <b-skeleton-table
                    v-if="loadingStatus || isBusy"
                    :rows="10"
                    :columns="4"
                    :table-props="{ bordered: true, striped: true }"
                    class="sensor-array table-height table-design"
                ></b-skeleton-table>
                <b-table-simple
                    responsive
                    hover
                    sticky-header
                    borderless
                    class="table-height"
                    v-else-if="accounts && accounts.length > 0"
                >
                    <b-thead>
                        <b-tr>
                            <b-th @click="setSort('a.id')" class="add-cursor">
                                Id
                                <b-icon-arrow-down-short v-if="sort === 'a.id' && direction === 'asc'"/>
                                <b-icon-arrow-up-short v-else-if="sort === 'a.id'"/>
                            </b-th>
                            <b-th @click="setSort('a.name')" class="add-cursor">
                                Nom
                                <b-icon-arrow-down-short v-if="sort === 'a.name' && direction === 'asc'"/>
                                <b-icon-arrow-up-short v-else-if="sort === 'a.name'"/>
                            </b-th>
                            <b-th>Groupe(s) Associé(s)</b-th>
                            <b-th>Plan</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(item,i) in accounts"
                              :key="i"
                              class="clr-dark-grey add-cursor"
                              :class="hoveredAccount === item.id ? 'bg-lighter-orange' : 'bg-white'"
                              @click="$router.push('/accounts/'+item.id+'/information')"
                              @mouseover="setHoveredAccount(item.id)"
                              @mouseout="setHoveredAccount(null)"
                        >
                            <b-td>{{ item.id }}</b-td>
                            <b-td class="clr-orange">{{ item.name }}</b-td>
                            <b-td>
                                <p>
                            <span v-for="(group,i) in item.accountGroups" :key="i">
                                {{ group.name }} <span v-if="i < (item.accountGroups.length - 1)">,</span>
                            </span>
                                </p>
                            </b-td>
                            <b-td>{{ item.planLevel }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <p class="col-12 p-0 text-center my-5" v-else>
                    Aucun compte dans cette catégorie
                </p>
            </b-col>
        </b-col>

        <!-- PAGINATION -->
        <b-col cols="12" class="mt-3" v-if="accounts && !loadingStatus && accounts.length > 0">
            <b-row class="justify-content-center align-items-center w-100">
                <b-col cols="12" md="4" class="font-weight-bold">
                    Total Comptes : {{ paginator.totalCount }} <br>
                    Comptes par Page : {{ paginator.numItemsPerPage }}
                </b-col>
                <b-col cols="12" md="4" class="mt-3">
                    <h6 class="text-center">Page(s)</h6>
                    <b-pagination
                        v-model="paginator.current"
                        pills
                        :total-rows="paginator.totalCount"
                        :per-page="paginator.numItemsPerPage"
                        align="center">
                    </b-pagination>
                </b-col>
                <b-col cols="12" md="4" class="my-3">
                    <b-form-select v-model="paginator.numItemsPerPage" :options="optionsLimits">
                    </b-form-select>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex';

export default {
    name: "AccountList",
    data() {
        return {
            searchTimeout: null,
            isBusy: false,
            text: '',
            currentPage: 1,
            hoverAccount: null,
            search: '',
            planLevel: 'standard',
            optionsLimits: [
                {value: 10, text: '10'},
                {value: 25, text: '25'},
                {value: 50, text: '50'}
            ],
            sort: 'a.id',
            direction: 'asc',
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
            paginator: 'paginator',
            loadingStatus: 'loadingStatus'
        }),
        hoveredAccount() {
            return this.hoverAccount
        },
    },
    methods: {
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        setHoveredAccount(id) {
            this.hoverAccount = id;
        },
        async queryAccount() {
            clearTimeout(this.searchTimeout);
            this.isBusy = true;
            let query = {search: ''}
            if (!this.paginator) {
                query.page = 1;
                query.limit = 50;
            } else {
                query.page = this.paginator.current !== 0 ? query.page = this.paginator.current : query.page = 1
                query.limit = 50
            }
            if (this.search.length >= 3) {
                query.search = this.search;
            }

            if (this.sort !== '') {
                query.sort = this.sort;
            }
            query.direction = this.direction;
            query.planLevel = this.planLevel;
            this.searchTimeout = setTimeout(async () => {
                await this.setAccounts(query)
            }, 200);
            this.isBusy = false;
        },
        setSort(value) {
            this.sort = value;
            this.direction = this.direction === 'desc' ? 'asc' : 'desc';
            this.queryAccount()
        },
    },
    async mounted() {
        await this.queryAccount()
    },
    watch: {
        'paginator.current'() {
            this.queryAccount()
        },
        'search'() {
            this.queryAccount()
        },
        'planLevel'() {
            this.queryAccount()
        }
    }
}
</script>

<style scoped>

</style>
