<template>
    <div ref="sensorProductionTable">
        <b-col cols="12" :class="shadow ? 'basic-card p-2' : ''">
            <ul id="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" :style="{top: top, left: left}"
                @blur="closeMenu()">
                <li @click="goToProfile(rightClickedSensor.serialNumber)">
                    Informations du capteurs
                </li>
                <li @click="goToAccount(rightClickedSensor.serialNumber)">
                    Client associé
                </li>
            </ul>
            <b-table-simple
                    responsive
                    hover
                    sticky-header
                    borderless
                    class="table-height"
                    v-if="sensors.length > 0"
            >
                <b-thead>
                    <b-tr>
                        <b-th @click="setSort('sensor.serialNumber')" class="add-cursor clr-darker-grey">
                            Numéro de série
                            <b-icon-arrow-down-short v-if="sort === 'sensor.serialNumber' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'sensor.serialNumber'"/>
                        </b-th>
                        <b-th @click="setSort('sensor.sensorName')" class="add-cursor clr-darker-grey">
                            Nom
                            <b-icon-arrow-down-short v-if="sort === 'sensor.sensorName' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'sensor.sensorName'"/>
                        </b-th>
                        <b-th v-if="!simpleVersion" class="clr-darker-grey">Client</b-th>
                        <b-th class="add-cursor clr-darker-grey" @click="setSort('sensor_address.locality')">
                            Ville d'implantation
                            <b-icon-arrow-down-short v-if="sort === 'sensor_address.locality' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'sensor_address.locality'"/>
                        </b-th>
                        <b-th v-if="!simpleVersion" class="clr-darker-grey">Dernière connexion</b-th>
                        <b-th @click="setSort('sensor.lastUpdate')" class="text-center add-cursor clr-darker-grey">
                            Statut connexion
                            <b-icon-arrow-down-short v-if="sort === 'sensor.lastUpdate' && direction === 'asc'"/>
                            <b-icon-arrow-up-short v-else-if="sort === 'sensor.lastUpdate'"/>
                        </b-th>
                        <b-th class="text-center clr-darker-grey">Traitement de données</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody v-if="isBusy">
                    <b-tr v-for="i in 8" :key="'skeleton_' + i">
                        <b-td colspan="8">
                            <b-skeleton
                                animation="fade"
                                :width="(Math.floor(Math.random() * (95 - 85 + 1)) + 85) + '%'"/>
                        </b-td>
                    </b-tr>
                </b-tbody>
                <b-tbody v-else>
                    <b-tr
                        v-for="(item,i) in sensors"
                        :key="i"
                        class="clr-dark-grey add-cursor"
                        :class="hoveredSensor === item.serialNumber ? 'bg-lighter-orange' : 'bg-white'"
                        @mouseover="setHoveredSensor(item.serialNumber)"
                        @mouseout="setHoveredSensor(null)"
                        @click="$router.push('/sensors/'+item.serialNumber+'/detail')"
                        @contextmenu="handler($event,item)"
                    >
                        <b-td class="clr-orange">{{ item.serialNumber }}</b-td>
                        <b-td v-if="item.sensorName">
                            {{ item.sensorName }}
                        </b-td>
                        <b-td v-else>
                            ----
                        </b-td>
                        <b-td v-if="item.owningAccount && !simpleVersion">
                            <router-link :to="'/accounts/'+item.owningAccount.id">
                                {{ item.owningAccount.name }}
                            </router-link>
                        </b-td>
                        <b-td v-else-if="!simpleVersion">
                            No Account
                        </b-td>
                        <b-td v-if="item.sensorAddress && item.sensorAddress.locality">
                            {{ item.sensorAddress.locality }}, {{ item.sensorAddress.countryLongName }}
                        </b-td>
                        <b-td v-else>
                            No Address
                        </b-td>
                        <b-td v-if="item.lastUpdate && !simpleVersion">
                            {{ item.lastUpdate | convertDate }}
                        </b-td>
                        <b-td v-else-if="!simpleVersion">
                            Never Initialised
                        </b-td>
                        <b-td class="text-center" v-if="item.lastUpdate">
                            <div v-if="isOnline(item.lastUpdate,2)" class="dot medium-size bg-green"></div>
                            <div v-else class="dot medium-size bg-red"></div>
                        </b-td>
                        <b-td class="text-center" v-else>
                            No Data
                        </b-td>
                        <b-td class="text-center" v-if="item.lastDataFlowUpdate">
                            <div v-if="isOnline(item.lastDataFlowUpdate,2)" class="dot medium-size bg-green"></div>
                            <div v-else class="dot medium-size bg-red"></div>
                        </b-td>
                        <b-td class="text-center" v-else>
                            The data stream was never started
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "SensorProductionTable",
    props: {
        sensors: Array,
        loadingStatus: Boolean,
        hoveredSensor: String,
        isBusy: Boolean,
        shadow: {
            type: Boolean,
            default: true
        },
        simpleVersion: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            viewMenu: false,
            top: '0px',
            left: '0px',
            rightClickedSensor: '',
            sort: 'sensor.lastUpdate',
            direction: 'desc',
        }
    },
    methods: {
        handler(event, data) {
            this.viewMenu = true;
            this.rightClickedSensor = data
            setTimeout(() => {
                this.$refs.right.focus();
                this.setMenu(event.y, event.x)
            }, 0);
            event.preventDefault();
        },
        setMenu: function (top, left) {
            this.top = (top - 267) + 'px';
            this.left = (left - 61) + 'px';
        },
        closeMenu: function () {
            this.viewMenu = false;
        },
        setSort(value) {
            this.sort = value;
            this.direction = this.direction === 'desc' ? 'asc' : 'desc';
            this.$emit('set-sorting', value)
        },
        setHoveredSensor(sensorId) {
            this.$emit('hovered-sensor', sensorId)
        },
        isOnline(date, offset = 2) {
            let utcData = moment.utc(date);
            let hoursAgo = moment.utc().subtract(offset, 'hours').toDate().toUTCString();
            return utcData.isAfter(hoursAgo);
        },
        goToProfile(id) {
            let route = this.$router.resolve({path: "/sensors/" + id + "/detail"});
            window.open(route.href);
        },
        goToAccount(id) {
            let route = this.$router.resolve({path: "/sensors/" + id + "/account"});
            window.open(route.href);
        }
    }
}
</script>

<style scoped lang="scss">

#right-click-menu {
  background: #FAFAFA;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 250px;
  z-index: 999999;
}

#right-click-menu li {
  border-bottom: 1px solid #E0E0E0;
  margin: 0;
  padding: 5px 35px;
}

#right-click-menu li:last-child {
  border-bottom: none;
}

#right-click-menu li:hover {
  background: $orange;
  color: #FAFAFA;
}

.dot {
  margin: 5px 0;
}
</style>
