<template>
    <b-row class="justify-content-center align-content-center">
        <b-spinner label="loader" class="text-center spinner" v-if="busy"/>
        <b-col v-else cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Association d'un utilisateur à un compte
            </h2>
            <p
                class="px-1 px-md-5 font-weight-bolder"
                v-if="
                 actualUser &&
                 actualUser.memberships &&
                 actualUser.memberships.length > 0
            "
            >
                Attention : Cette Utilisateur est déja associé au compte suivant :
                <span class="clr-orange">{{ actualUser.memberships[0].accountGroup.account.name }}</span>
            </p>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    label="Compte à associer"
                    label-for="account"
                >
                    <b-form-select
                        v-model="chosenAccount"
                        :options="loadedAccounts"
                        required>
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    id="input-group-5"
                    label="Groupe"
                    label-for="account"
                    v-if="chosenAccount"
                >
                    <b-form-select
                        v-model="chosenGroup"
                        :options="groupFromLoadedAccount"
                        required>
                    </b-form-select>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins"

export default {
    name: "UserAssociationToAccount",
    data() {
        return {
            chosenGroup: null,
            chosenAccount: null,
            optionsAccounts: [],
            optionsGroups: [],
            busy: false
        }
    },
    methods: {
        ...Vuex.mapActions('user', {
            editUser: 'editUser',
            setUser: 'setUser'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('accountGroup', {
            setAccountGroups: 'setAccountGroups',
        }),
        async submit() {
            let query = this.generateQueryString()
            if (query !== null) {
                await this.editUser(query).then(() => {
                    Toast.fire({
                        icon: 'success',
                        title: this.getRequest.message,
                    }).then(() => {
                            this.goToUserProfile();
                        }
                    )
                })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: "L'utilisateur est déja associé !",
                })
            }
        },
        generateQueryString() {
            let data = []
            data.push(this.$route.params.id)
            let fields = new FormData();

            if (!this.isSameAccount) {
                fields.set('account', this.chosenAccount)
                fields.set('accountGroup', this.chosenGroup)
                data.push(fields)
                return data
            } else if (this.isSameAccount && !this.isSameAccountGroups) {
                fields.set('account', this.chosenAccount)
                fields.set('accountGroup', this.chosenGroup)
                data.push(fields)
                return data
            }

            return null
        },
        async setOptionsAccounts() {
            for (let i = 0; i < this.accounts.length; i++) {
                if (!this.optionsAccounts.includes(this.accounts[i].name)) {
                    this.optionsAccounts.push(this.accounts[i].name)
                }
            }
        },
        goToUserProfile() {
            this.$router.push(`/users/${this.$route.params.type}/${this.$route.params.id}/information`);
        },
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
        ...Vuex.mapGetters('user', {
            getRequest: "getRequest",
            getUsersById: 'getUsersById',
            getUser: 'getUser'
        }),
        loadedAccounts() {
            this.setOptionsAccounts()
            return this.optionsAccounts
        },
        groupFromLoadedAccount() {
            if (this.chosenAccount) {
                let groupNameUnderAccount = this.accounts.find(account => account.name === this.chosenAccount).accountGroups
                let finalGroupNameList = []
                for (let i = 0; i < groupNameUnderAccount.length; i++) {
                    let value = {value: groupNameUnderAccount[i].name, text: groupNameUnderAccount[i].name}
                    finalGroupNameList.push(value)
                }
                return finalGroupNameList
            }
            return []
        },
        actualUser() {
            return this.getUser;
        },
        isSameAccount() {
            return this.chosenAccount === this.actualUser.memberships[0].accountGroup.account.name;
        },
        isSameAccountGroups() {
            const user = this.actualUser;
            if (user.memberships.length === 1) {
                if (user.memberships[0].accountGroup.name === this.chosenGroup) {
                    return true;
                }
            } else if (user.memberships.length > 1) {
                for (let i = 0; i < user.memberships.length; i++) {
                    if (user.memberships[i].accountGroup.name === this.chosenGroup) {
                        return true;
                    }
                }
            }
            return false;
        }
    },
    async created() {
        this.busy = true;
        await this.setAccounts()
        await this.setUser(this.$route.params.id)
        if (this.actualUser.memberships.length > 0) {
            this.chosenAccount = this.actualUser.memberships[0].accountGroup.account.name
            this.chosenGroup = this.actualUser.memberships[0].accountGroup.name
        }
        this.busy = false;
    },
}
</script>

<style lang="scss" scoped>

</style>
