import api from '@/services/api/index.js'

const state = {
    sensorSetups: [],
    sensorSetupsHistory: [],
    sensorSetup: {},
    steps: [
        {
            name: 'Configuration du capteur',
            fields: ['gsm', 'laser', 'crossComparison'],
            completed: false
        },
        {
            name: 'Données brutes',
            fields: ['rawData'],
            completed: false
        },
        {
            name: 'Mise à jour firmware',
            fields: ['firmwareUpdate'],
            completed: false
        },
        {
            name: 'Fin',
            fields: ['completedAt'],
            completed: false
        },
    ],
    actualStep: 0,
    actualStepReached: false,
    isLoading: false
};


const mutations = {
    SET_SENSOR_SETUPS: (state, sensorSetups) => {
        state.sensorSetups = sensorSetups;
    },
    SET_SENSOR_SETUPS_HISTORY: (state, sensorSetupsHistory) => {
        state.sensorSetupsHistory = sensorSetupsHistory;
    },
    SET_SENSOR_SETUP: (state, sensorSetup) => {
        state.sensorSetup = sensorSetup;
    },
    FILL_SENSOR_SETUP: (state) => {
        state.actualStepReached = false;
        const stepFields = state.steps.map(step => step.fields).flat(1);
        for (let i = 0; i < stepFields.length; i++) {
            let index = state.steps.findIndex(step => step.fields.includes(stepFields[i]));
            state.steps[index].completed = state.sensorSetup[stepFields[i]];
            if (!state.actualStepReached &&
                (state.sensorSetup[stepFields[i]] === false || state.sensorSetup[stepFields[i]] === null)) {
                state.actualStep = index;
                state.actualStepReached = true;
            }
        }
    },
    SET_LOADER: (state, isLoading) => {
        state.isLoading = isLoading;
    },
    SET_ACTUAL_STEP: (state, step) => {
        state.actualStep = step;
    },
    RESET_DATA: (state) => {
        state.actualStep = 0;
        state.sensorSetups = [];
        state.sensorSetup = {};
    },
};
const actions = {
    setSensorSetups: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorSetup.getSensorSetups(data)
            .then(response => {
                store.commit('SET_SENSOR_SETUPS', response.data.data.sensorSetups);
            });
        store.commit('SET_LOADER', false);
    },
    setSensorSetupsHistory: async (store, serialNumber) => {
        store.commit('SET_LOADER', true);
        await api.sensorSetup.getHistoryBySensor(serialNumber)
            .then(response => {
                store.commit('SET_SENSOR_SETUPS_HISTORY', response.data.data.sensorSetups);
            });
        store.commit('SET_LOADER', false);
    },
    setSensorSetup: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorSetup.getBySensor(data)
            .then(response => {
                store.commit('SET_SENSOR_SETUP', response.data.data.sensorSetup);
            });
        store.commit('SET_LOADER', false);
    },
    createSensorSetup: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorSetup.createSetupBySensor(data)
            .then(response => {
                store.commit('SET_SENSOR_SETUP', response.data.data.sensorSetup);
            });
        store.commit('SET_LOADER', false);
    },
    editSensorSetup: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorSetup.editSetupBySensor(data)
            .then(response => {
                store.commit('SET_SENSOR_SETUP', response.data.data.sensorSetup);
            });
        store.commit('SET_LOADER', false);
    },
    completeSensorSetup: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorSetup.completeSensorSetup(data)
            .then(response => {
                store.commit('SET_SENSOR_SETUP', response.data.data.sensorSetup);
            });
        store.commit('SET_LOADER', false);
    },
    setActualStep: async (store, step) => {
        store.commit('SET_ACTUAL_STEP', step);
    },
    resetData: async (store) => {
        store.commit('RESET_DATA');
    },
    fillSensorSetup: async (store) => {
        store.commit('FILL_SENSOR_SETUP');
    },
};

const getters = {
    sensorSetups: state => {
        return state.sensorSetups
    },
    sensorSetupsHistory: state => {
        return state.sensorSetupsHistory
    },
    sensorSetup: state => {
        return state.sensorSetup
    },
    isLoading: state => {
        return state.isLoading;
    },
    actualStep: state => {
        return state.actualStep;
    },
    steps: state => {
        return state.steps;
    },
    actualStepBySensorSetup: state => setup => {
        const stepFields = state.steps.map(step => step.fields).flat(1);
        let completedSteps = [];
        for (let i = 0; i < stepFields.length; i++) {
            let index = state.steps.findIndex(step => step.fields.includes(stepFields[i]));
            if (setup[stepFields[i]] === false) {
                return state.steps[index].name
            } else if (setup[stepFields[i]]) {
                completedSteps.push(setup[stepFields[i]]);
            }
        }

        if (completedSteps.every(step => step === true)) return state.steps[state.steps.length - 1].name;
        else return 0;
    },
    formCompleted: state => {
        return state.steps.slice(0,-1).every(step => step.completed === true);
    },
    findBySerialNumber: state => serialNumber => {
        let index = state.sensorSetups
            .findIndex(setup => setup.sensor && setup.sensor.serialNumber === serialNumber);
        return state.sensorSetups[index];
    },
    findCompletedBySerialNumber: state => serialNumber => {
        let index = state.sensorSetups.findIndex(setup => setup.serialNumber === serialNumber);
        return state.sensorSetups[index];
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}