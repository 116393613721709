<template>
    <b-col cols="12" v-if="!individualSensor">
        <p class="text-center">
            <b-spinner class="clr-orange"></b-spinner>
        </p>
    </b-col>
    <div v-else>
        <b-row class="mb-5">
            <b-col cols="4">
                <p class="font-weight-bold">Version du capteur : </p>
            </b-col>
            <b-col cols="4" v-if="isLoading">
                <p class="text-center">
                    <b-spinner class="clr-orange"></b-spinner>
                </p>
            </b-col>
            <b-col cols="4" v-else-if="sensorId">
                <p :class="sensorVersion ? '' : 'clr-red'">{{ sensorVersion }}</p>
            </b-col>
            <b-col cols="4" v-else>
                <p class="clr-dark-grey">inconnue</p>
            </b-col>
            <b-col cols="4" v-if="sensorSetup.firmwareUpdate">
                <p class="h2 clr-orange m-0">
                    <b-icon-check-circle/>
                </p>
            </b-col>
        </b-row>
        <b-row>
            <FullMQTT @setMqttMessages="setMqttMessages"
                      @startTest="isLoading = true"
                      :mode-individual="true"
                      :mode-sensor-testing="true"
                      :serial-number="individualSensor.serialNumber"/>
        </b-row>
    </div>
</template>

<script>
import FullMQTT from "@/components/mqtt/FullMQTT.vue";
import Vuex from "vuex";

export default {
    name: "FirmwareUpdateTest",
    components: {FullMQTT},
    data() {
        return {
            mqttResponses: [],
            correctIds: ['IN16', 'LAT', 'testRida'],
            sensorId: null,
            sensorIdPayload: null,
            isLoading: false,
            responseSuccess: false
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
        }),
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetup: 'sensorSetup'
        }),
        sensorVersion() {
            if (!this.sensorId) return null
            else if (this.sensorId.includes('IN16')) return 'INTEL'
            else if (this.sensorId.includes('LATT')) return 'LATTICE'
            else if (this.sensorId.includes('testRida')) return 'Test Rida'
            else return null;
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensorSimple: 'editSensorSimple'
        }),
        ...Vuex.mapActions('sensorSetup', {
            editSensorSetup: 'editSensorSetup'
        }),
        setMqttMessages(payloads) {
            this.mqttResponses = payloads;
            setTimeout(() => {
                this.setIdSensorInformation();
            }, 1000)
        },
        setIdSensorInformation() {
            this.mqttResponses.forEach((response) => {
                if (response.includes('ct_id_read')) {
                    let formattedResponse = JSON.parse(response);
                    this.sensorId = formattedResponse['ct_id_read']
                }
            })
            this.responseSuccess = true;
            this.isLoading = false;
            this.confirmTest();
        },
        async confirmTest() {
            if (this.sensorVersion) {
                const form = new FormData();
                const sensorForm = new FormData();
                form.append('firmwareUpdate', 'true')
                if (this.sensorVersion) sensorForm.append('firmwareVersion', this.sensorVersion)
                await this.editSensorSimple([this.individualSensor.serialNumber, sensorForm]);
                await this.editSensorSetup({id: this.sensorSetup.id, params: form});
            }
        },
    },
    created() {
        this.sensorId = this.individualSensor.sensorParameters.firmwareVersion;
    }
}
</script>

<style scoped>

</style>