<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Gestion des rôles utilisateurs
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group label="Rôles utilisateur:" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                        stacked
                        v-model="chosenRoles"
                        :options="roleOptions"
                        :aria-describedby="ariaDescribedby"
                        name="roles-list"
                    ></b-form-checkbox-group>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" v-if="isLoading">
                        <b-spinner small/>
                    </b-button>
                    <b-button class="btn-action" v-else @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import secureLS from "@/plugins/secureLS";

export default {
    name: "UserEditRoles",
    data() {
        return {
            chosenRoles: [],
            superAdminRoles: [
                {value: 'ROLE_SUPER_ADMIN', text: 'Super Administrateur Lifyair'},
                {value: 'ROLE_ADMIN', text: 'Administrateur'},
                {value: 'ROLE_LIFYAIR_COLLABORATOR', text: 'Collaborateur Lifyair'},
                {value: 'ROLE_ACCOUNT_MANAGER', text: 'Account Manager'},
                {value: 'ROLE_COLLABORATOR', text: 'Collaborateur'},
                {value: 'ROLE_API', text: 'API'},
                {value: null, text: 'Default'},
            ],
            lifyairRoles: [
                {value: 'ROLE_ADMIN', text: 'Administrateur'},
                {value: 'ROLE_LIFYAIR_COLLABORATOR', text: 'Collaborateur Lifyair'},
            ],
            externalRoles: [
                {value: 'ROLE_ACCOUNT_MANAGER', text: 'Account Manager'},
                {value: 'ROLE_COLLABORATOR', text: 'Collaborateur'},
                {value: 'ROLE_USER', text: 'Standard'}
            ],
            isLoading: false
        }
    },
    methods: {
        ...Vuex.mapActions('user', {
            manageUserRoles: 'manageUserRoles',
            setUser: 'setUser'
        }),
        async submit() {
            this.isLoading = true;
            let query = this.generateQueryString()
            await this.manageUserRoles(query).then(() => {
                this.isLoading = false;
                this.goToUserProfile();
            });
        },
        generateQueryString() {
            let data = []
            data.user = this.$route.params.id;
            let fields = new FormData();
            if (this.chosenRoles.length > 0) {
                this.chosenRoles.forEach(role => {
                    fields.append('roles[]', role);
                })
            }
            data.data = fields
            return data
        },
        goToUserProfile() {
            this.$router.push(`/users/${this.$route.params.type}/${this.$route.params.id}/information`);
        },
    },
    computed: {
        ...Vuex.mapGetters('user', {
            getUser: 'getUser'
        }),
        currentUser() {
            return secureLS.get('currentUser');
        },
        roleOptions() {
            if (this.currentUser.roles.includes('ROLE_SUPER_ADMIN')) {
                return this.superAdminRoles;
            } else if (this.getUser.roles.includes('ROLE_ADMIN') || this.getUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR')) {
                return this.lifyairRoles;
            } else {
                return this.externalRoles;
            }
        }
    },
    async created() {
        await this.setUser(this.$route.params.id);
        this.chosenRoles = this.getUser.roles
    },
}
</script>

<style lang="scss" scoped>

</style>
