import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getSensorSetups,
    getBySensor,
    createSetupBySensor,
    editSetupBySensor,
    completeSensorSetup,
    getHistoryBySensor
}
const adminPrefixUrl = '/admin/sensor-setups';

function getSensorSetups(params) {
    return axios.get(adminPrefixUrl, {params: params, ...authHeader.classicalHeader()})
}
function getBySensor(serialNumber) {
    return axios.get(adminPrefixUrl + '/' + serialNumber, authHeader.classicalHeader())
}
function getHistoryBySensor(serialNumber) {
    return axios.get(adminPrefixUrl + '/sensor/' + serialNumber + '/history', authHeader.classicalHeader())
}
function createSetupBySensor(serialNumber) {
    return axios.post(adminPrefixUrl + '/' + serialNumber + '/create', {}, authHeader.classicalHeader())
}
function editSetupBySensor(data) {
    return axios.post(adminPrefixUrl + '/' + data.id, data.params, authHeader.classicalHeader())
}
function completeSensorSetup(setup) {
    return axios.post(adminPrefixUrl + '/' + setup + '/complete', {}, authHeader.classicalHeader())
}