import axios from "../../plugins/axios";

const querystring = require('querystring');
import authHeader from "./authHeader";

const prefix = '/campaigns'

export default {
    getCampaigns,
    createCampaign,
    updateCampaign,
    deleteCampaign,
    getCampaignRelatedAccounts,
    getCampaignRelatedUsers,
}

function getCampaigns(data) {
    return axios.get(prefix + '?' + querystring.stringify(data), authHeader.classicalHeader())
}

function getCampaignRelatedAccounts(campaign) {
    return axios.get(prefix + `/${campaign}/accounts`, authHeader.classicalHeader())
}

function getCampaignRelatedUsers(campaign, params) {
    return axios.get(prefix + `/${campaign}/users?${querystring.stringify(params)}`, authHeader.classicalHeader())
}

function createCampaign(data) {
    return axios.post(prefix + '/new', data, authHeader.formDataHeader())
}

function updateCampaign(params) {
    return axios.post(prefix + '/' + params.campaign + '/edit', params.data, authHeader.formDataHeader())
}

function deleteCampaign(campaign) {
    return axios.delete( prefix + `/${campaign}`, authHeader.classicalHeader())
}
