import api from '@/services/api/index.js'

const state = {
    ongoingEvents: [],
    accountsSensorEvents: [],
    sensorEvent: null,
    ongoingSensorEvents: [],
    completedSensorEvents: [],
    isLoading: false
};

const mutations = {
    SET_ONGOING_EVENTS: (state, ongoingEvents) => {
        state.ongoingEvents = ongoingEvents;
    },
    EMPTY_SENSOR_EVENTS: (state) => {
        state.ongoingSensorEvents = [];
        state.completedSensorEvents = [];
    },
    SET_ACCOUNT_SENSOR_EVENTS: (state, events) => {
        state.accountsSensorEvents = events;
    },
    SET_SENSOR_EVENT: (state, event) => {
        state.sensorEvent = event;
    },
    SET_ONGOING_SENSOR_EVENT: (state, ongoingSensorEvents) => {
        state.ongoingSensorEvents = ongoingSensorEvents;
    },
    SET_COMPLETED_SENSOR_EVENTS: (state, completedSensorEvents) => {
        state.completedSensorEvents = completedSensorEvents;
    },
    SET_LOADER: (state, isLoading) => {
        state.isLoading = isLoading;
    },
};

const actions = {
    setOngoingEvents: async (store) => {
        store.commit('SET_LOADER', true);
        await api.sensorEvent.getSensorEvents()
            .then(response => {
                const events = response.data.data;
                store.commit('SET_ONGOING_EVENTS', events);
            });
        store.commit('SET_LOADER', false);
    },
    setSensorEvents: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorEvent.getBySensor(data)
            .then(response => {
                const events = response.data.data;
                const ongoingEvents = events.filter(event => event.endedAt === null)
                const completedEvents = events.filter(event => event.endedAt !== null)
                store.commit('SET_ONGOING_SENSOR_EVENT', ongoingEvents);
                store.commit('SET_COMPLETED_SENSOR_EVENTS', completedEvents);
            });
        store.commit('SET_LOADER', false);
    },
    setAccountSensorEvents: async (store, accountId) => {
        store.commit('SET_LOADER', true);
        await api.sensorEvent.getByAccount(accountId)
            .then(response => {
                const events = response.data.data;
                store.commit('SET_ACCOUNT_SENSOR_EVENTS', events);
            });
        store.commit('SET_LOADER', false);
    },
    emptySensorEvents: async (store) => {
        store.commit('EMPTY_SENSOR_EVENTS');
    },
    resetAccountSensorEvents: async (store) => {
        store.commit('SET_ACCOUNT_SENSOR_EVENTS', []);
    },
    setSensorEvent: async (store, id) => {
        store.commit('SET_LOADER', true);
        await api.sensorEvent.getSensorEvent(id)
            .then(response => {
                const event = response.data.data;
                store.commit('SET_SENSOR_EVENT', event);
            });
        store.commit('SET_LOADER', false);
    },
    createSensorEvent: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorEvent.createSensorEvent(data)
            .then(response => {
                store.commit('SET_ONGOING_SENSOR_EVENT', [response.data.data]);
            });
        store.commit('SET_LOADER', false);
    },
    editSensorEvent: async (store, data) => {
        store.commit('SET_LOADER', true);
        await api.sensorEvent.editSensorEvent(data);
        store.commit('SET_LOADER', false);
    },
};

const getters = {
    ongoingEvents: state => {
        return state.ongoingEvents
    },
    accountsSensorEvents: state => {
        return state.accountsSensorEvents
    },
    sensorEvent: state => {
        return state.sensorEvent
    },
    ongoingSensorEvents: state => {
        return state.ongoingSensorEvents
    },
    completedSensorEvents: state => {
        return state.completedSensorEvents
    },
    isLoading: state => {
        return state.isLoading;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}