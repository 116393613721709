import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";
import router from "@/router";

const state = {
    dataAccess: {
        locations:null,
        sensors:null,
        accountGroups:null,
    },
    dataAccessByGroup: {
        locations:null,
        sensors:null,
        accountGroups:null,
    },
    loading: false,
    request: {}
};


const mutations = {
    SET_LOADING_DATA_ACCESS: (state) => {
        state.loading = !state.loading;
    },
    SET_DATA_ACCESS: (state, data) => {
        let keys = Object.keys(data)

        for (let i = 0;i < keys.length > 0; i++){
            state.dataAccess[keys[i]] = data[keys[i]]
        }
        state.dataAccess = data;
    },
    SET_DATA_ACCESS_BY_GROUP: (state, data) => {
        let keys = Object.keys(data)

        for (let i = 0;i < keys.length > 0; i++){
            state.dataAccessByGroup[keys[i]] = data[keys[i]]
        }
        state.dataAccessByGroup = data;
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
};
const actions = {
    createDataAccess: async (store, data) => {
        store.commit('SET_LOADING_DATA_ACCESS')
        await api.dataAccess.createDataAccess(data)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: "La création d'accès s'est déroulée avec succès !",
                    timer: 2000
                }).then(() => {
                    router.go(-1)
                    store.commit('SET_REQUEST', response.data);
                    store.commit('SET_LOADING_DATA_ACCESS')
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: JSON.stringify(error.response.data.message),
                })
                store.commit('SET_REQUEST', error.response.data);
            });
    },
    setDataAccess: async (store, id) => {
        store.commit('SET_LOADING_DATA_ACCESS');
        await api.dataAccess.getDataAccess(id)
            .then(response => {
                store.commit('SET_DATA_ACCESS', response.data.data);
                store.commit('SET_LOADING_DATA_ACCESS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response,
                })
            });
    },
    setDataAccessByAccount: async (store, data) => {
        store.commit('SET_LOADING_DATA_ACCESS');
        await api.dataAccess.getDataAccessByAccount(data)
            .then(response => {
                store.commit('SET_DATA_ACCESS', response.data.data);
                store.commit('SET_LOADING_DATA_ACCESS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response,
                })
            });
    },
    setDataAccessByAccountGroup: async (store, data) => {
        store.commit('SET_LOADING_DATA_ACCESS');
        await api.dataAccess.getDataAccessByAccountGroup(data)
            .then(response => {
                store.commit('SET_DATA_ACCESS_BY_GROUP', response.data.data);
                store.commit('SET_LOADING_DATA_ACCESS');
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response,
                })
                store.commit('SET_LOADING_DATA_ACCESS');
            });
    },
    deleteDataAccess: async (store, data) => {
        store.commit('SET_LOADING_DATA_ACCESS');
        await api.dataAccess.deleteDataAccess(data)
            .then((response) => {
                    store.commit('SET_REQUEST', response.data);
                    store.commit('SET_LOADING_DATA_ACCESS')
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: JSON.stringify(error.response.data.message),
                })
                store.commit('SET_REQUEST', error.response.data);
                store.commit('SET_LOADING_DATA_ACCESS');
            });
    },
    editDataAccess: async (store, data) => {
        store.commit('SET_LOADING_DATA_ACCESS');
        await api.dataAccess.editDataAccess(data.id, data.params)
            .then((response) => {
                Toast.fire({
                    icon: 'success',
                    title: "La modification d'accès s'est déroulée avec succès !",
                    timer: 2000
                }).then(() => {
                    router.go(-1)
                    store.commit('SET_REQUEST', response.data);
                    store.commit('SET_LOADING_DATA_ACCESS')
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: JSON.stringify(error.response.data.message),
                })
                store.commit('SET_REQUEST', error.response.data);
                store.commit('SET_LOADING_DATA_ACCESS');
            });
    },
    unbindData: async (store,) => {
        store.commit('SET_DATA_ACCESS', {});
        store.commit('SET_DATA_ACCESS_BY_GROUP', {});
    }
};

const getters = {
    loadingStatus: state => {
        return state.loading
    },
    getRequest: state => {
        return state.request
    },
    getDataAccess: state => {
        return state.dataAccess
    },
    isDataAccessLoading: state => {
        return state.loading
    },
    getDataAccessByGroup: state => {
        return state.dataAccessByGroup
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
