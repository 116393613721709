<template>
    <b-col cols="12" class="mb-5">
        <h4 class="clr-dark-grey text-center mb-2">TEST DU CAPTEUR</h4>
        <h4 class="clr-orange text-center mb-3 add-cursor" @click="goToProfile(chosenSerialNumber)">
            {{ chosenSerialNumber ? chosenSerialNumber : '- - -' }}
        </h4>
        <b-row class="justify-content-center align-content-center" v-if="isSensorInStock">
            <!-- MULTI STEP FORM -->
            <MultiStepForm
                    :next-disable="nextDisable"
                    @confirmStep="confirmStep"
                    @submitForm="completeSetup"
                    v-if="showForm">
                <template v-slot:header>
                    <p class="dashboard-title mb-1 clr-orange">
                        Phase de test pour le capteur {{ individualSensor.serialNumber }}
                    </p>
                    <p class="card-subtitle clr-light-grey">
                        Suivez les différentes étapes de test pour confirmer le bon fonctionnement du capteur
                    </p>
                </template>
                <template v-slot:step-content>
                    <b-col cols="12" v-if="isLoading">
                        <p class="text-center">
                            <b-spinner class="clr-orange"></b-spinner>
                        </p>
                    </b-col>

                    <!-- SENSOR HARDWARE SETUP STEP -->
                    <b-row class="justify-content-center" v-else>
                        <b-col cols="10" v-if="actualStep === 0">
                            <SensorConfigTest/>
                        </b-col>
                        <b-col cols="10" v-if="actualStep === 1" class="text-center">
                            <RawDataTest/>
                        </b-col>
                        <b-col cols="10" v-if="actualStep === 2">
                            <FirmwareUpdateTest/>
                        </b-col>
                        <b-col cols="10" v-if="actualStep === 3">
                            <SensorTestReport/>
                        </b-col>
                    </b-row>
                </template>
            </MultiStepForm>

            <!-- INPUT METHODS FOR SERIAL NUMBER -->
            <b-col cols="12" md="8" class="basic-card mb-4" v-if="!showForm">
                <b-col cols="12" sm="auto">
                    <b-row class="justify-content-around">
                        <b-button class="btn-action mr-4" v-b-modal.modal-scan>
                            <b-icon icon="upc-scan" class="mr-2 text-center"/>
                            Scanner un code
                        </b-button>
                        <b-button class="btn-action mr-4" v-b-modal.modal-upload>
                            <b-icon icon="upload" class="mr-2 text-center"/>
                            Enregistrer un code
                        </b-button>
                        <b-button class="btn-action" v-b-modal.modal-input>
                            <b-icon icon="cursor-text" class="mr-2 text-center"/>
                            Saisir un numéro de série
                        </b-button>
                    </b-row>
                    <b-modal id="modal-scan" title="Scanner un code" :hide-footer="true">
                        <SensorScan @fillInformation="fillInformation"/>
                    </b-modal>
                    <b-modal id="modal-upload" title="Enregistrer un code" :hide-footer="true">
                        <ImageBarcodeReader @decode="onDecode" @error="onError"></ImageBarcodeReader>
                    </b-modal>
                    <b-modal id="modal-input" title="Saisir un code" :hide-footer="true">
                        <b-form @submit.prevent="$bvModal.hide('modal-input')" inline>
                            <b-form-input
                                    class="col-12 col-md-10"
                                    list="input-list"
                                    id="input-with-list"
                                    placeholder="numéro de série"
                                    v-model="chosenSerialNumber">
                            </b-form-input>
                            <b-form-datalist
                                    id="input-list"
                                    :options="serialNumbers">

                            </b-form-datalist>
                            <b-button
                                    class="btn-action border-left-0 shadow-none col-12 col-md-2"
                                    type="submit">
                                OK
                            </b-button>
                        </b-form>
                    </b-modal>
                </b-col>
            </b-col>

            <!-- SENSORS TABLE -->
            <b-col cols="12" md="8" class="basic-card" v-if="!showForm">
                <b-row class="justify-content-center mb-2">
                    <b-form-group>
                        <b-form-radio-group
                                v-model="sensorType"
                                buttons
                                class="row"
                                button-variant="outline-primary"
                        >
                            <b-form-radio value="test">Capteur en cours de test</b-form-radio>
                            <b-form-radio value="stock">Capteur en stock</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-row>

                <!-- TESTING SENSORS TABLE -->
                <div v-if="sensorType === 'test'">
                    <b-table borderless hover
                             tbody-tr-class="add-cursor"
                             @row-clicked="setChosenSerialNumber"
                             selectable
                             :items="sensorSetups"
                             :fields="fields"
                             v-if="sensorSetups.length > 0">
                        <template #head(serialNumber)>
                            <span>Numéro de série</span>
                        </template>
                        <template #head(step)>
                            <span>Étape</span>
                        </template>
                        <template #head(createdAt)>
                            <span>Commencé le</span>
                        </template>
                        <template #cell(serialNumber)="data">
                            <b class="clr-orange">{{ data.item.sensor.serialNumber }}</b>
                        </template>
                        <template #cell(step)="data">
                            <p>{{ actualStepBySensorSetup(data.item) }}</p>
                        </template>
                    </b-table>
                    <p class="clr-light-grey text-center" v-else>AUCUN</p>
                </div>

                <!-- STOCKED SENSORS TABLE -->
                <div v-else>
                    <b-table borderless hover
                             tbody-tr-class="add-cursor"
                             @row-clicked="setChosenStockedSerialNumber"
                             selectable
                             :items="adminStockedSensors"
                             :fields="stockFields"
                             v-if="adminStockedSensors.length > 0">
                        <template #head(serialNumber)>
                            <span>Numéro de série</span>
                        </template>
                        <template #head(createdAt)>
                            <span>Date de création</span>
                        </template>
                        <template #cell(serialNumber)="data">
                            <b class="clr-orange">{{ data.item.serialNumber }}</b>
                        </template>
                    </b-table>
                    <p class="clr-light-grey text-center" v-else>AUCUN</p>
                </div>
            </b-col>

            <!-- NAVIGATION -->
            <b-row class="col-12 col-md-8 justify-content-between mt-5 p-0" v-if="!showForm">
                <b-col cols="12" md="6" class="pl-0">
                    <b-button @click="$router.go(-1)" class="w-100 btn-cancel">Annuler</b-button>
                </b-col>
                <b-col cols="12" md="6" class="pr-0">
                    <b-button
                            :disabled="!chosenSerialNumber"
                            @click="setSensor"
                            class="btn-action w-100">
                        Valider
                    </b-button>
                </b-col>
            </b-row>
        </b-row>

        <!-- WARNING WHEN THE CHOSEN SENSOR IS NOT IN STOCK/TEST -->
        <b-modal no-close-on-backdrop
                 ok-only
                 hide-header
                 no-close-on-esc
                 id="modal-wrong-sensor"
                 @ok="$router.go(-1)">
            <p class="h2 text-center clr-orange mt-4">
                <b-icon-exclamation-triangle/>
            </p>
            <p class="my-4 text-center">Ce capteur ne peut pas être testé car il n'est pas en <b>STOCK</b>.</p>
        </b-modal>
    </b-col>
</template>

<script>
import MultiStepForm from "@/components/common/MultiStepForm.vue";
import Vuex from "vuex";
import SensorScan from "@/components/sensor/Management/SensorScan.vue";
import {ImageBarcodeReader} from "vue-barcode-reader";
import SensorConfigTest from "@/components/sensor/Testing/SensorConfigTest.vue";
import RawDataTest from "@/components/sensor/Testing/RawDataTest.vue";
import FirmwareUpdateTest from "@/components/sensor/Testing/FirmwareUpdateTest.vue";
import SensorTestReport from "@/components/sensor/Testing/SensorTestReport.vue";

export default {
    name: "SensorTest",
    components: {
        SensorTestReport,
        FirmwareUpdateTest, RawDataTest, SensorConfigTest, ImageBarcodeReader, SensorScan, MultiStepForm
    },
    props: {},
    data() {
        return {
            chosenSerialNumber: null,
            showForm: this.$route.params.id !== undefined,
            isLoading: true,
            sensorType: 'test',
            fields: ['serialNumber', 'step', 'createdAt'],
            stockFields: ['serialNumber', 'createdAt'],
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setIndividualSensors: 'setIndividualSensors',
            setAdminSensors: 'setAdminSensors',
        }),
        ...Vuex.mapActions('sensorSetup', {
            setSensorSetup: 'setSensorSetup',
            setSensorSetups: 'setSensorSetups',
            setActualStep: 'setActualStep',
            createSensorSetup: 'createSensorSetup',
            fillSensorSetup: 'fillSensorSetup',
            resetData: 'resetData',
            editSensorSetup: 'editSensorSetup',
            completeSensorSetup: 'completeSensorSetup',
        }),
        goToProfile(id) {
            this.$router.push({path: "/sensors/" + id + "/detail"});
        },
        async onDecode(result) {
            const decoded = result.split(';')
            let decodedData = [];
            decoded.forEach((text) => {
                const code = text.split(':');
                const name = code[0];
                decodedData[name] = code[1];
            })
            await this.fillInformation(decodedData)
        },
        onError(err) {
            console.log(err)
        },
        fillInformation(data) {
            this.chosenSerialNumber = data['IMEI1'];
            this.$bvModal.hide('modal-scan');
            this.$bvModal.hide('modal-upload');
        },
        setChosenStockedSerialNumber(item) {
            this.chosenSerialNumber = item.serialNumber;
        },
        setChosenSerialNumber(item) {
            this.chosenSerialNumber = item.sensor.serialNumber;
        },
        async setSensor() {
            await this.setIndividualSensors(this.chosenSerialNumber).then(() => {
                if (this.isSensorInStock) {
                    this.showForm = true;
                    this.setSensorSetup(this.chosenSerialNumber).then(() => {
                        if (this.sensorSetup) {
                            this.fillSensorSetup().then(() => {
                                this.isLoading = false;
                            });
                        } else {
                            this.createSensorSetup(this.chosenSerialNumber).then(() => {
                                this.fillSensorSetup().then(() => {
                                    this.isLoading = false;
                                });
                            })
                        }
                    })
                } else {
                    this.$bvModal.show('modal-wrong-sensor')
                }
            });
        },
        confirmStep() {
            this.fillSensorSetup();
        },
        async completeSetup() {
            await this.completeSensorSetup(this.sensorSetup.id).then(() => {
                this.$router.push('/sensors')
            })
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            adminStockedSensors: 'adminStockedSensors',
            adminSensors: 'adminSensors',
        }),
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetup: 'sensorSetup',
            sensorSetups: 'sensorSetups',
            actualStep: 'actualStep',
            steps: 'steps',
            actualStepBySensorSetup: 'actualStepBySensorSetup',
        }),
        nextDisable() {
            switch (this.actualStep) {
                case 0:
                    return !(this.sensorSetup &&
                        this.sensorSetup.gsm &&
                        this.sensorSetup.laser &&
                        this.sensorSetup.crossComparison);
                case 1:
                    return !(this.sensorSetup && this.sensorSetup.rawData);
                case 2:
                    return !(this.sensorSetup && this.sensorSetup.firmwareUpdate);
                default:
                    return false;
            }
        },
        serialNumbers() {
            return this.adminStockedSensors.map(item => item.serialNumber)
        },
        isSensorInStock() {
            const goodStatuses = ['test', 'stock']
            return this.individualSensor.sensorParameters &&
                goodStatuses.includes(this.individualSensor.sensorParameters.status) ||
                !this.$route.params.id;
        }
    },
    async created() {
        await this.resetData();
        if (this.$route.params.id) {
            this.chosenSerialNumber = this.$route.params.id;
            await this.setSensor();
        } else {
            await this.setAdminSensors();
            await this.setSensorSetups({type: 'inProgress'});
        }
    }
}
</script>

<style scoped>

</style>