// import DeliveryTrackingList from "@/components/DeliveryTracking/DeliveryTrackingList.vue";

export default [
    {
        path: '/deliveries',
        name: 'Deliveries',
        props:true,
        component: () => import('@/views/DeliveryTracking.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name:'Home',
                    link: '/'
                },
                {
                    name: 'Delivery',
                    link: '/deliveries'
                }
            ]
        }
    }
]