import axios from "../../plugins/axios";
import authHeader from "./authHeader";

const prefix = '/trackings'

export default {
    getTrackings,
    createTracking,
    updateTrackingStatus
}

function getTrackings() {
    return axios.get(prefix, authHeader.classicalHeader())
}
function createTracking(data) {
    return axios.post(prefix + '/new', data, authHeader.classicalHeader())
}
function updateTrackingStatus(id, data) {
    return axios.post(prefix + '/' + id, data, authHeader.classicalHeader())
}
