<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Générateur de données pour le démonstrateur
            </h2>
            <b-form @submit="onSubmit" class="mb-3 px-1 px-md-5">
                <b-form-group>
                    <p class="h4">
                        Environnement de Déploiement
                    </p>
                    <div class="divider my-4 ml-0"/>
                    <b-form-group
                        label="Environnement:"
                        label-for="nested-street"
                    >
                        <b-form-select
                            v-model="event.environment"
                            required>
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option value="test">Test ENV</b-form-select-option>
                            <b-form-select-option value="dev" disabled>DEV ENV</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                        label="Type de capteur:"
                        label-for="nested-sensor"
                    >
                        <b-form-select
                            v-model="event.sensor_type"
                            required>
                            <b-form-select-option :value="null">Merci de selectionner une option</b-form-select-option>
                            <b-form-select-option value="pollen">Capteur de Pollen (BEENOSE)</b-form-select-option>
                            <b-form-select-option value="pollution" disabled>Capteur de Pollution (LOAC)
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                        label="Compte (*):"
                        label-for="nested-sensor"
                    >
                        <b-form-select
                            v-model="event.sensor_type"
                            disabled
                        >
                            <b-form-select-option :value="null">Merci de selectionner une option</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                        label="Numéro de série du capteur (*):"
                        label-for="nested-sensor"
                    >
                        <b-form-select
                            v-model="event.sensor_type"
                            disabled
                        >
                            <b-form-select-option :value="null">Merci de selectionner une option</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <p class="h4 mt-3">
                        Options des données
                    </p>
                    <div class="divider my-4 ml-0"/>
                    <b-form-group
                        label="Niveau de Risque Moyen à générer :"
                        v-slot="{ ariaDescribedby }"
                    >
                        <b-form-radio-group
                            class="pt-2"
                            :options="[{value:'low','text':'Faible'},{value:'medium','text':'Moyen'},{value:'high','text':'Fort'}]"
                            v-model="event.parameters.risk_level"
                            :aria-describedby="ariaDescribedby"
                            required
                        ></b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                        label="Quantité de Pollen (nb de ligne en DB) :"
                        v-slot="{ ariaDescribedby }"
                    >
                        <b-form-radio-group
                            class="pt-2"
                            :options="[{value:'low','text':'Faible'},{value:'medium','text':'Moyenne'},{value:'high','text':'Important'}]"
                            v-model="event.parameters.pollen_quantity"
                            :aria-describedby="ariaDescribedby"
                            required
                        ></b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                        label="Catégorie de Pollens:">
                        <b-form-checkbox-group
                            id="checkbox-group-category"
                            v-model="category"
                            :options="[{value:'grass','text':'Graminées'},{value:'weed','text':'Herbacées'},{value:'tree','text':'Arbres'}]"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    <b-form-group
                        label="Choix Type de Pollen (*) :"
                    >
                        <b-form-select
                            v-model="event.parameters.pollens"
                            :options="optionPollenSorted"
                            multiple
                                       :select-size="4"
                            required>
                        </b-form-select>
                        <p class="align-self-end justify-content-end text-right align-text-bottom font-italic small">
                            astuce : Maintenir CTRL ou MAJ pour sélectionner plusieurs items
                        </p>
                    </b-form-group>
                    <p class="h4 mt-3">
                        Période de Génération
                    </p>
                    <div class="divider my-4 ml-0"/>
                    <b-form-group
                        label="Sélection de la période  :"
                        aria-required="true"
                    >
                        <b-form-datepicker
                            v-model="event.parameters.start_at"
                            :min="min"
                            :max="max"
                            locale="fr"
                            class="mt-3"
                            placeholder="Date de début"
                            required
                        ></b-form-datepicker>
                        <b-form-datepicker
                            v-model="event.parameters.end_at"
                            :min="min2"
                            :max="max"
                            locale="fr"
                            class="mt-4"
                            placeholder="Date de fin"
                            required
                        ></b-form-datepicker>
                    </b-form-group>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-center mt-3 mr-2">
                <b-col cols="auto">
                    <b-button size="lg" variant="primary" type="submit">
                        GÉNÉRER DONNEES POLLINIQUES
                        <b-spinner class="float-right" label="Floated Right" variant="primary"
                                   v-if="loading"></b-spinner>
                    </b-button>
                </b-col>
            </b-row>
            <p class="mt-3 align-self-end justify-content-end text-right align-text-bottom font-italic small">
                (*) Champs Facultatifs ou non disponible actuellement
            </p>
        </b-col>
    </b-row>
</template>

<script>
import AWS from "aws-sdk";
import swal from "sweetalert2";

export default {
    name: "GeneratorForm",
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        // 15th two months prior
        const minDate = new Date(today)
        minDate.setMonth(minDate.getMonth())
        minDate.setDate(minDate.getDate() - 14)
        // 15th in two months
        const maxDate = new Date(today)
        // maxDate.setMonth(maxDate.getDate())
        maxDate.setDate(maxDate.getDate() + 7)
        return {
            event: {
                "environment": "test",
                "sensor_type": "pollen",
                "parameters": {
                    "pollen_quantity": "low",
                    "risk_level": "low",
                    "pollens": [],
                    "account": null,
                    "serial_number": null,
                    "start_at": null,
                    "end_at": null
                }
            },
            test: null,
            pollenOptions: [
                {"value": "alder", "text": "Aulne", "category": "tree"},
                {"value": "ash", "text": "Frêne", "category": "tree"},
                {"value": "barley", "text": "Orge", "category": "grass"},
                {"value": "birch", "text": "Bouleau", "category": "tree"},
                {"value": "cedar", "text": "Cèdre", "category": "tree"},
                {"value": "chestnut", "text": "Châtaignier", "category": "tree"},
                {"value": "couch-quack", "text": "Chiendent", "category": "grass"},
                {"value": "cypress", "text": "Cyprés", "category": "tree"},
                {"value": "fescue", "text": "Fétuque", "category": "grass"},
                {"value": "grass", "text": "Graminées", "category": "grass"},
                {"value": "hazel", "text": "Noisetier", "category": "tree"},
                {"value": "hornbeam", "text": "Charme", "category": "tree"},
                {"value": "juniper", "text": "Genévrier", "category": "tree"},
                {"value": "linden", "text": "Tilleul", "category": "tree"},
                {"value": "maple", "text": "Erable", "category": "tree"},
                {"value": "mugwort", "text": "Armoise", "category": "weed"},
                {"value": "nettle", "text": "Ortie", "category": "weed"},
                {"value": "oak", "text": "Chêne", "category": "tree"},
                {"value": "olive", "text": "Olivier", "category": "tree"},
                {"value": "orchard", "text": "Dactyle", "category": "grass"},
                {"value": "pellitory", "text": "Pariétaire", "category": "weed"},
                {"value": "plane-tree", "text": "Platane", "category": "tree"},
                {"value": "plantain", "text": "Plantain", "category": "weed"},
                {"value": "poplar", "text": "Peuplier", "category": "tree"},
                {"value": "ragweed", "text": "Ambroisie", "category": "weed"},
                {"value": "sorrel", "text": "Oseille", "category": "weed"},
                {"value": "timothy", "text": "Fléole", "category": "grass"},
                {"value": "velvetgrass", "text": "Houlque", "category": "grass"},
                {"value": "vernal", "text": "Flouve", "category": "grass"},
                {"value": "wheat", "text": "Blé", "category": "grass"},
                {"value": "willow", "text": "Saule", "category": "tree"}
            ],
            startValue: null,
            endValue: null,
            min: minDate,
            max: maxDate,
            loading: false,
            category: ['grass', 'tree', 'weed']
        }
    },
    mounted() {
        this.test = this.pollenOptions.sort((a, b) => a.text > b.text ? 1 : -1)
    },
    methods: {
        startCallBack: function () {
        },
        endCallBack: function () {
        },
        async invokeLambdaFunction() {
            this.loading = true
            AWS.config.update({
                accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
                region: process.env.VUE_APP_AWS_DEFAULT_REGION
            });

            let lambda_fun = new AWS.Lambda();

            let request = this.event;

            let pullParams = {
                FunctionName: 'advancedDataGenerator',
                InvocationType: 'RequestResponse',
                LogType: 'None',
                Payload: JSON.stringify(request)
            }
            let pullResults;

            lambda_fun.invoke(pullParams, function (error, data) {
                    if (error) {
                        console.log(error);
                    } else {
                        pullResults = JSON.parse(data.Payload);
                        console.log('returned result: ', JSON.stringify(pullResults, null, 2))
                    }
                }
            )
            this.loading = false
        },
        gotToDashboard() {
            this.$router.push('/');
        },
        onSubmit(event) {
            event.preventDefault()
            const a = new Date();

            this.invokeLambdaFunction().then(() => {
                const b = new Date();
                let difference = Math.floor((b - a) / (1000 * 60));

                swal.fire({
                    title: '<strong>Données générées!</strong>',
                    icon: 'success',
                    html:
                        "retrouve le resultat sur " +
                        '<a href="//sweetalert2.github.io">l\'Application Démo</a> ' + '<br> ' + '(générée en ' + difference + 'sec)',
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText:
                        '<i class="fa fa-thumbs-up"></i> Great!',
                    confirmButtonAriaLabel: 'Thumbs up, great!'
                })
            })
        },
    },
    computed: {
        min2() {
            if (this.event.parameters.start_at !== null) {
                let minDateEndDate = new Date(this.event.parameters.start_at)
                minDateEndDate.setDate(minDateEndDate.getDate() + 1)
                return minDateEndDate
            } else {
                return this.min
            }

        },
        optionPollenSorted() {
            if (this.category.length > 0) {
                return this.pollenOptions.filter(item => this.category.includes(item.category))
            } else {
                return this.pollenOptions
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.divider {
    @include divider(rgba($orange, .2), 90%, 2px);
}
</style>