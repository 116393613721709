<template>
  <!-- IF TEST ALREADY COMPLETED -->
    <div class="my-5 col-xl-12 text-center" v-if="sensorSetup.rawData && !relaunch">
        <div style="font-size: 4rem;">
            <b-icon-check-circle-fill/>
        </div>
        <p class="mt-3">Vérification terminée</p>
        <b-button @click="relaunchTest()" class="btn-action col-12 col-md-8 mt-3">
            Relancer le test
        </b-button>
    </div>

    <div class="my-5 col-xl-12 text-center" v-else>
        <b-button
                @click="downloadFile"
                class="download-raw-data-btn btn-cancel"
                v-if="areRawDataRetrieved">
            <b-icon icon="download"></b-icon>
            Télécharger les données
        </b-button>
        <div style="font-size: 4rem;">
            <b-icon :icon="steps[step].icon"/>
        </div>
        <p class="mt-3 mb-0">{{ steps[step].name }}</p>
        <p v-if="subCategory !== null && steps[step].subCategories.length > 0"
           class="subtitle clr-light-grey">
            {{ steps[step].subCategories[subCategory].name }}...
        </p>
        <p v-else-if="testsFinished && !testsFailed" class="clr-green">Aucune erreur rencontrée !</p>
        <p v-else-if="testsFinished && testsFailed" class="clr-red">Erreurs rencontrées !</p>
        <b-progress v-if="!testsFinished"
                    class="mt-3"
                    :value="step + 1"
                    :max="steps.length"
                    :animated="!areRawDataRetrieved || (!testsFinished && !isEmpty && !fileTooOld)"/>

        <!-- EMPTY RAW DATA -->
        <b-row v-if="isEmpty && areRawDataRetrieved" class="mt-4 text-left">
            <b-col cols="12">
                <p class="clr-red">Aucune donnée brute n'a été trouvée.</p>
                <p>
                    Le capteur ne remonte pas de données pour le moment. Veuillez réitérer l'opération dans quelques
                    minutes.
                </p>
            </b-col>
            <div class="col-xl-12 text-center">
                <b-button @click="relaunchTest()" class="btn-action col-12 col-md-8 mt-3">
                    Relancer le test
                </b-button>
            </div>
        </b-row>

        <!-- TOO OLD RAW DATA -->
        <b-row v-else-if="fileTooOld & areRawDataRetrieved" class="mt-4 text-left">
            <b-col cols="12">
                <p class="clr-red">Les dernières données brutes trouvée date de {{ lastFileDate | basicDate }}.</p>
                <p>
                    Le capteur ne remonte pas de données pour le moment. Veuillez réitérer l'opération dans quelques
                    minutes.
                </p>
            </b-col>
            <div class="col-xl-12 text-center">
                <b-button @click="relaunchTest()" class="btn-action col-12 col-md-8 mt-3">
                    Relancer le test
                </b-button>
            </div>
        </b-row>

        <!-- ERROR LIST -->
        <b-row v-else-if="testsFinished && testsFailed" class="mt-4 text-left">
            <b-col cols="12">
                <p class="section-title">Erreur(s) rencontrée(s) :</p>
            </b-col>
            <b-col cols="12"
                   v-for="(error, index) in failedCategories"
                   :key="`error_${index}`">
                <p>
                    <b-badge class="error-type-badge">{{ error.name }}</b-badge>
                    {{ error.message }}
                </p>
            </b-col>
        </b-row>

        <b-button @click="confirmTest(false)" class="btn-action col-12 col-md-8 mt-3" v-if="testsFinished">
            {{ testsFailed ? 'Forcer la validation' : 'Valider' }}
        </b-button>

        <!-- WARNING WHEN FORCING VALIDATION -->
        <b-modal id="modal-force-validation" title="Êtes-vous sûr ?" @ok="confirmTest(true)">
            <p class="h2 text-center clr-orange">
                <b-icon-exclamation-triangle/>
            </p>
            <p class="my-4">Vous êtes sur le point de forcer la validation de ce capteur. Veuillez noter que le capteur
                pourrait ne pas être opérationnel une fois déployé.</p>
            <p>Veuillez ajouter un commentaire qui explique la situation :</p>
            <b-form-textarea
                    id="textarea"
                    v-model="context"
                    rows="3"
                    max-rows="10"
            ></b-form-textarea>
        </b-modal>
    </div>
</template>

<script>
import Vuex from "vuex";
import AWS from "aws-sdk";
import s3Service from "@/services/aws/s3";
import rawDataInspector from "@/services/sensor/rawDataInspector";
import moment from "moment";

let s3 = new AWS.S3();

export default {
    name: "RawDataTest",
    data() {
        return {
            isLoading: false,
            lastFileKey: null,
            context: '',
            bucket: 'lifyair-iot-core-logs',
            rawData: [],
            lastTimestampFile: null,
            step: 0,
            isTruncated: true,
            nextContinuationToken: null,
            subCategory: null,
            areRawDataRetrieved: false,
            relaunch: false,
            keyCount: 0,
            fields: ['type', 'message'],
            steps: [
                {
                    name: 'Récupération des données en cours...',
                    icon: 'cloud-arrow-down-fill',
                    subCategories: []
                },
                {
                    name: 'Vérification des données en cours...',
                    icon: 'clock-history',
                    subCategories: [
                        {name: 'GSM', isValid: false, message: ''},
                        {name: 'PTU', isValid: false, message: ''},
                        {name: 'ID', isValid: false, message: ''},
                        {name: 'Laser', isValid: false, message: ''},
                        {name: 'Comptage', isValid: false, message: ''},
                        {name: 'Gamme 1 - Gamme 2', isValid: false, message: ''},
                        {name: 'Gamme 10 - Gamme 19', isValid: false, message: ''}
                    ]
                },
                {
                    name: 'Vérification terminée',
                    icon: 'check-circle-fill',
                    subCategories: []
                },
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensorSetup', {
            editSensorSetup: 'editSensorSetup'
        }),
        findLastFileInBucket(prefix, token) {
            // Needed to access this under s3 SDK
            let that = this;

            // Final data that wil hold latest key in S3
            let lastInPrefix = {};

            let params = {
                Bucket: this.bucket,
                Prefix: prefix,
            }

            // Setting continuation token to explore S3 pagination
            if (token) {
                params.ContinuationToken = token
            }

            if (that.truncated) {
                s3.listObjectsV2(params, function (err, data) {
                    if (err) {
                        console.log('Error retrieving objects: ', err);
                    } else {
                        that.nextContinuationToken = data.NextContinuationToken
                        that.isTruncated = data.IsTruncated
                        that.keyCount = data.KeyCount

                        if (!data.IsTruncated && data.KeyCount > 0) {
                            // Loop through the objects in the bucket
                            data.Contents.forEach(function (obj) {
                                lastInPrefix[-1] = obj;
                            });
                            // Call Get File because we are on the last pages
                            that.lastFileKey = lastInPrefix[-1].Key;
                            let splitPath = that.lastFileKey.split('/').pop()
                            that.lastTimestampFile = splitPath.split('.')[0];
                            that.areRawDataRetrieved = true;
                        } else if (data.KeyCount > 0) {
                            // Data is still truncated, so we launch the next batch with ContinuationToken
                            // setTimeout(() => {
                            that.findLastFileInBucket(prefix, that.nextContinuationToken)
                            // }, 1000)
                        } else {
                            that.areRawDataRetrieved = true;
                        }
                    }
                });
            }
        },
        getFile(key) {
            let that = this
            const params = {
                Bucket: this.bucket,
                Key: key
            }
            s3.getObject(params, function (err, data) {
                if (err) {
                    console.log(err, err.stack);
                } else {
                    that.rawData = JSON.parse(s3Service.decompressZstandardFile(data.Body))
                }
            });
        },
        async downloadFile() {
            let s3 = new AWS.S3();
            let paramsGetObject = {
                Bucket: this.bucket,
                Key: this.lastFileKey
            }

            let myFile = await s3.getObject(paramsGetObject).promise();
            const blob = new Blob([myFile.Body], {type: 'binary/octet-stream'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = this.lastFileKey;
            link.click()
            URL.revokeObjectURL(link.href)
        },
        checkData() {
            let result = rawDataInspector.checkByStep(this.subCategory, this.rawData);
            this.steps[1].subCategories[this.subCategory].isValid = result.isValid;
            this.steps[1].subCategories[this.subCategory].message = result.message;
            setTimeout(() => {
                if (this.subCategory + 1 < this.steps[1].subCategories.length) {
                    this.subCategory += 1;
                } else {
                    this.prefillContext();
                    this.step += 1;
                }
            }, 1000)
        },
        prefillContext() {
            this.failedCategories.forEach((failure) => {
                this.context += `${failure.name} : ${failure.message} \n`;
            })
        },
        async confirmTest(force = false) {
            if (this.testsFailed && !force) {
                this.$bvModal.show('modal-force-validation')
            } else {
                const form = new FormData();
                form.append('rawData', 'true')
                if (this.context !== '') form.append('context', this.context)
                await this.editSensorSetup({id: this.sensorSetup.id, params: form});
            }
        },
        startTest() {
            const prefix = `beenose/${this.individualSensor.serialNumber}/`
            setTimeout(() => {
                this.findLastFileInBucket(prefix);
            }, 2000)
        },
        relaunchTest() {
            this.relaunch = true;
            this.resetData();
            this.startTest();
        },
        resetData() {
            this.lastFileKey = null;
            this.lastTimestampFile = null;
            this.areRawDataRetrieved = false;
            this.rawData = [];
            this.step = 0;
            this.isTruncated = true;
            this.nextContinuationToken = null;
            this.subCategory = null;
            this.areRawDataRetrieved = false;
            this.keyCount = 0;
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor'
        }),
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetup: 'sensorSetup'
        }),
        testsFailed() {
            return this.steps[1].subCategories.some(category => category.isValid === false)
        },
        testsFinished() {
            return this.step === (this.steps.length - 1)
        },
        failedCategories() {
            return this.steps[1].subCategories.filter((category) => category.isValid === false)
        },
        truncated() {
            return this.isTruncated
        },
        token() {
            return this.nextContinuationToken
        },
        fileTooOld() {
            return this.lastFileDate.diff(moment(), 'hours') > 2
        },
        lastFileDate() {
            return moment(this.lastTimestampFile, 'x');
        },
        isEmpty() {
            return this.keyCount === 0;
        }
    },
    created() {
        if (this.individualSensor && this.individualSensor.serialNumber && !this.sensorSetup.rawData) {
            this.startTest();
        }
    },
    watch: {
        lastFileKey: function (value) {
            if (value && !this.fileTooOld) {
                this.getFile(this.lastFileKey);
            }
        },
        rawData: function (value) {
            if (value.length > 1) {
                this.step += 1;
                this.subCategory = 0;
            }
        },
        subCategory: function (value) {
            if (value <= this.steps[1].subCategories.length) {
                this.checkData();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.error-type-badge {
  background-color: $indianred;
  color: $white;
  font-size: 18px;
  margin-right: 20px;
}

.download-raw-data-btn {
  margin-top: -7rem;
}
</style>