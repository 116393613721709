import api from '@/plugins/axios'
import authHeader from "@/services/api/authHeader";

export default {
	authenticate,
	resetPassword,
	sendNewPassword,
	confirmResetPasswordToken
}

function authenticate(username, password) {
	let config = {
		headers:{
			'Accept': 'application/json',
		},
		baseURL: process.env.VUE_APP_BASE_URL,
	};

	return api.post('/login_check', {"email":username,"password":password}, config)
}

function resetPassword(email) {
	return api.post('/users/reset-password', email, authHeader.unloggedFormDataHeader())
}

function confirmResetPasswordToken(token) {
	return api.get('/users/reset-password/'+ token +'/confirmation', authHeader.unloggedFormDataHeader())
}


function sendNewPassword(data) {
	return api.post('/users/reset-password/'+data[0], data[1], authHeader.unloggedFormDataHeader())
}

