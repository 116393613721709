<template>
    <div ref="SensorTestTable">
        <b-col cols="12" class="basic-card p-2">
            <b-table-simple
                    responsive
                    hover
                    sticky-header
                    borderless
                    class="table-height"
                    v-if="sensors.length > 0 && (!isBusy || !isLoading)"
            >
                <b-thead class="table-column-header-title">
                    <b-tr>
                        <b-th class="add-cursor" @click="setSort('sensor.serialNumber')">Numéro de série</b-th>
                        <b-th v-if="statusStep === 1">Étape</b-th>
                        <b-th v-if="statusStep === 1">Depuis le</b-th>
                        <b-th v-else>Terminé le</b-th>
                        <b-th>Dernière connexion</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                            v-for="(sensor,i) in sensors"
                            :key="`sensor_setups_${i}`"
                            class="clr-dark-grey add-cursor"
                            :class="hoveredSensor === sensor.serialNumber ? 'bg-lighter-orange' : 'bg-white'"
                            @mouseover="setHoveredSensor(sensor.serialNumber)"
                            @mouseout="setHoveredSensor(null)"
                            @contextmenu="handler($event,sensor)"
                    >
                        <b-td @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                            <div class="d-flex px-1 py-1 justify-content-start">
                                <h6 class="mb-0 add-cursor clr-orange">
                                    {{ sensor.serialNumber }}
                                </h6>
                            </div>
                        </b-td>
                        <b-td v-if="statusStep === 1"
                              @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                            <p>{{ actualStepBySerialNumber(sensor.serialNumber) }}</p>
                        </b-td>
                        <b-td v-if="statusStep === 1 && setupBySerialNumber(sensor.serialNumber)"
                              @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                                    <span :class="colorByDate(setupBySerialNumber(sensor.serialNumber).createdAt)">
                                        {{ setupBySerialNumber(sensor.serialNumber).createdAt | dayDate }}
                                    </span>
                        </b-td>
                        <b-td v-else-if="statusStep === 2 && completedSetupBySerialNumber(sensor.serialNumber)"
                              @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                            <p>
                                {{ completedSetupBySerialNumber(sensor.serialNumber).createdAt | dayDate }}
                            </p>
                        </b-td>
                        <b-td v-else @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                            <span></span>
                        </b-td>
                        <b-td @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                            {{ sensor.lastUpdate | convertDate }}
                        </b-td>
                        <b-td class="text-center"
                              v-if="statusStep === 1"
                              @click="goToSensorTest(sensor.serialNumber)">
                            <b-button variant="primary">
                                {{
                                actualStepBySerialNumber(sensor.serialNumber) === 'Fin' ? 'À déployer' : 'Reprendre'
                                }}
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
    </div>
</template>

<script>
import moment from "moment";
import Vuex from "vuex";

export default {
    name: "SensorTestTable",
    props: {
        sensors: Array,
        statusStep: {
            type: Number,
            default: 1
        },
        hoveredSensor: String,
        sort: String,
        direction: String,
        isBusy: Boolean
    },
    data() {
        return {
            valueByStatusStep: {
                1: 'inProgress',
                2: 'completed'
            }
        }
    },
    methods: {
        ...Vuex.mapActions('sensorSetup', {
            setSensorSetups: 'setSensorSetups',
        }),
        handler(event, data) {
            this.viewMenu = true;
            this.rightClickedSensor = data
            setTimeout(() => {
                this.$refs.right.focus();
                this.setMenu(event.y, event.x)
            }, 0);
            event.preventDefault();
        },
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        setHoveredSensor(sensorId) {
            this.$emit('hovered-sensor', sensorId)
        },
        actualStepBySerialNumber(serialNumber) {
            const setup = this.findBySerialNumber(serialNumber);
            if (setup) return this.actualStepBySensorSetup(setup);
            return '';
        },
        setupBySerialNumber(serialNumber) {
            return this.findBySerialNumber(serialNumber);
        },
        completedSetupBySerialNumber(serialNumber) {
            return this.findCompletedBySerialNumber(serialNumber);
        },
        goToSensorTest(id) {
            this.$router.push({path: "/sensors/testing/" + id});
        },
        colorByDate(date) {
            const now = moment();
            const diff = moment(date).diff(now, 'days');
            if (diff > 6) {
                return 'clr-danger'
            } else if (diff > 3) {
                return 'clr-yellow'
            } else {
                return 'clr-green'
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetups: 'sensorSetups',
            steps: 'steps',
            actualStepBySensorSetup: 'actualStepBySensorSetup',
            findBySerialNumber: 'findBySerialNumber',
            findCompletedBySerialNumber: 'findCompletedBySerialNumber',
            isLoading: 'isLoading',
        }),
    },
    async created() {
        await this.setSensorSetups({type: this.valueByStatusStep[this.statusStep]});
    }
}
</script>

<style scoped lang="scss">
</style>
