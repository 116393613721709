import SensorEdit from "@/components/sensor/Management/Edit/SensorEdit.vue";
import SensorStatusUpdate from "@/components/sensor/Management/SensorCreateNote.vue";
import SensorStock from "@/components/sensor/Management/SensorStock.vue";
import SensorAssociationToAccount from "@/components/sensor/Management/SensorAssociationToAccount.vue";
import SensorOffsetHistory from "@/components/sensor/SensorOffsetHistory";
import AlertList from "@/components/sensor/Detail/Alert/AlertList.vue";
import SensorOperationRateHistory from "@/components/sensor/SensorOperationRateHistory";
import SensorReplace from "@/components/sensor/Management/SensorReplace.vue";
import SensorAddressUpdate from "@/components/sensor/Management/Edit/SensorAddressUpdate.vue";
import SensorEditState from "@/components/sensor/Management/Edit/SensorEditState.vue";
import SensorCreate from "@/components/sensor/Management/Create/SensorCreate.vue";
import SensorUpdateAccount from "@/components/sensor/Management/Edit/SensorUpdateAccount.vue";
import SensorReplaceV2 from "@/components/sensor/Management/SensorReplaceV2.vue";
import SensorDetails from "@/components/sensor/Detail/SensorDetails.vue";
import SensorTest from "@/components/sensor/Testing/SensorTest.vue";
import Sensors from "@/components/sensor/Sensors.vue";
import SensorReturningSheets from "@/components/deliveryTracking/SensorReturningSheets.vue";

export default [
    {
        path: '/sensors/testing/:id?',
        component: SensorTest,
        name: 'Testing',
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Sensors',
                    link: '/sensors'
                },
                {
                    name: 'Testing'
                }
            ]
        },
    },
    {
        path: '/sensors/new',
        component: SensorCreate,
        name: 'New',
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Sensors',
                    link: '/sensors'
                },
                {
                    name: 'New'
                }
            ]
        },
    },
    {
        path: '/sensors/stock',
        component: SensorStock,
        name: 'Stock',
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Stock de Capteur'
                }
            ]
        },
    },
    {
        path: '/sensors/returning-sheets',
        component: SensorReturningSheets,
        name: 'Returns',
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Fiches Retour'
                }
            ]
        },
    },
    {
        path: '/sensors/:id?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Sensor.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                component: Sensors,
                name: 'sensors',
                props:true,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors'
                        }
                    ]
                },
            },
            {
                path: 'detail',
                component: SensorDetails,
                name: 'sensor-details',
                props:true,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors',
                            link:'/sensors',
                        },
                        {
                            name: 'Détails'
                        }
                    ]
                },
            },
            {
                path: 'following',
                component: SensorStatusUpdate,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors',
                            link: '/sensors'
                        },
                        {
                            name: 'Mise à jour du statut'
                        }
                    ]
                },
            },
            {
                path: 'address',
                component: SensorAddressUpdate,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors',
                            link: '/sensors'
                        },
                        {
                            name: 'Mise à jour position'
                        }
                    ]
                },
            },
            {
                path: 'edit',
                component: SensorEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Sensor Edit'
                        }
                    ]
                },
            },
            {
                path: 'edit',
                component: SensorEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Sensor Edit'
                        }
                    ]
                },
            },
            {
                path: 'accounts',
                component: SensorUpdateAccount,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors/:id',
                        },
                        {
                            name: 'Account Edit'
                        }
                    ]
                },
            },
            {
                path: 'edit/state',
                component: SensorEditState,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Sensor Edit State'
                        }
                    ]
                },
            },
            {
                path: 'account',
                component: SensorAssociationToAccount,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Account'
                        }
                    ]
                },
            },
            {
                path: 'history/offset',
                component: SensorOffsetHistory,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Offset-History'
                        }
                    ]
                },
            },
            {
                path: 'history/operation-rate',
                component: SensorOperationRateHistory,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Operation-Rate-History'
                        }
                    ]
                },
            },
            {
                path: 'replacement',
                component: SensorReplace,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Replace Sensor'
                        }
                    ]
                },
            },
            {
                path: 'replace',
                component: SensorReplaceV2,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Replace Sensor'
                        }
                    ]
                },
            },
            {
                path: 'alerts',
                component: AlertList,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Sensors', link:'/sensors',
                        },
                        {
                            name: 'Alerts List'
                        }
                    ]
                },
            }
        ]
    },
];