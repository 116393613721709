<template>
    <b-container fluid>
        <b-row class="justify-content-center align-content-center align-items-center my-3">
            <b-col cols="3" class="mb-2">
                <h3 class="clr-orange">
                    Fiches de retour
                    <span v-if="completed"> complétées</span>
                    <span v-else> actives</span>
                </h3>
            </b-col>
            <b-col cols="3">
                <b-row class="justify-content-center">
                    <p class="col-12 clr-dark-grey text-center">TYPE DE CAPTEUR</p>
                    <b-form-group v-slot="{ ariaDescribedby }" class="m-0">
                        <b-form-radio-group
                            :aria-describedby="ariaDescribedby"
                            name="radio-inline"
                            buttons
                            class="row"
                            button-variant="outline-primary"
                            v-model="completed"
                        >
                            <b-form-radio @click="completed = false" :value="false">
                                Actives
                            </b-form-radio>
                            <b-form-radio @click="completed = true" :value="true">
                                Complétées
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-row>
            </b-col>
            <b-col cols="4" class="mb-3">
                Rechercher une référence :
                <b-form-input v-model="search"></b-form-input>
            </b-col>
        </b-row>
        <b-row class="justify-content-center align-content-center">
            <b-col
                v-if="filteredSheets.length === 0"
                class="clr-darker-grey shadow rounded bg-white p-3 mb-2"
                cols="10"
            >
                <b-row>
                    <b-col cols="12" class="mb-1">
                        <h4 class="text-center my-5">
                            Aucune fiche active pour le moment
                        </h4>
                    </b-col>
                </b-row>
            </b-col>
            <b-col
                v-for="(item,i) in filteredSheets"
                :key="item.returnNumber + '_' + i"
                class="clr-darker-grey shadow rounded bg-white p-3 mb-2"
                cols="10"
            >
                <b-row class="align-content-start">
                    <b-col cols="12" class="mb-1">
                        <h4>
                            Client : <span class="clr-orange" v-if="'account' in item.replacementPayload[0]">
                            {{ item.replacementPayload[0].account.name }}
                            </span>
                            <span v-else> - - -</span>
                            <span v-if="showComplete" class="float-right add-cursor">Valider le Retour </span>

                            <span>
                            <b-icon icon="check-circle" class="float-right add-cursor"
                                    @click="completeReturn(item.returnNumber)"></b-icon>
                        </span>
                            <b-modal :id="'modal-force-validation_'+item.returnNumber" title="Êtes-vous sûr ?"
                                     @ok="confirmWarning(i,item)">
                                <p class="h2 text-center clr-orange">
                                    <b-icon-exclamation-triangle/>
                                </p>
                                <p class="my-4">Vous êtes sur le point de forcer la validation de ce retour. Cela
                                    forcera également l'association des données de remplacements et cloturera le retour
                                    sans possibilité de revenir en arrière.</p>
                            </b-modal>
                            <b-modal size="xl" :id="'modal-associate-sensor_'+item.returnNumber"
                                     :hide-footer="endedForm">
                                <div v-if="!endedForm">
                                    <b-col cols="12" class=" p-3 mb-4 bg-lighter-grey" v-if="replacementPayloadItem">
                                        <b-row>
                                            <b-col cols="12" class="text-center font-weight-bolder clr-orange p-2">
                                                <h4>
                                                    Association Manuelle de Capteurs
                                                    ({{ itemRank + 1 }}/{{ replacementPayloadItem.length }})
                                                </h4>
                                            </b-col>
                                            <b-col cols="5"
                                                   class="justify-content-center align-content-center align-self-center">
                                                <b-row class="my-2">
                                                    <b-col>
                                                        <h6 class="bold clr-darker-grey">
                                                            Capteur à remplacer :
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col cols="12" class="ml-2" style="line-height: 0.6em;">
                                                        <p @click="goToProfile(replacementPayloadItem[itemRank].serialNumber)">
                                                            Numéro de série : <span class="clr-orange add-cursor">{{
                                                                replacementPayloadItem[itemRank].serialNumber
                                                            }}</span>
                                                        </p>
                                                        <p>Ville : {{
                                                                replacementPayloadItem[itemRank].sensorAddress.locality
                                                            }}<span></span></p>
                                                        <p>Département : {{
                                                                replacementPayloadItem[itemRank].sensorAddress.administrativeAreaLvl2
                                                            }}<span></span></p>
                                                        <p>Région : {{
                                                                replacementPayloadItem[itemRank].sensorAddress.administrativeAreaLvl1
                                                            }}<span></span></p>
                                                        <p>Pays : {{
                                                                replacementPayloadItem[itemRank].sensorAddress.countryLongName
                                                            }}<span></span></p>
                                                        <p>GPS : [<span class="clr-orange">{{
                                                                replacementPayloadItem[itemRank].sensorAddress.lat
                                                            }}</span>,<span class="clr-orange">{{
                                                                replacementPayloadItem[itemRank].sensorAddress.lon
                                                            }}</span>]<span></span></p>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <iframe
                                                            class="shadow"
                                                            width="400"
                                                            height="250"
                                                            style="border:0"
                                                            loading="lazy"
                                                            allowfullscreen
                                                            referrerpolicy="no-referrer-when-downgrade"
                                                            :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${replacementPayloadItem[itemRank].sensorAddress.lat},${replacementPayloadItem[itemRank].sensorAddress.lon}&zoom=15`"
                                                        >
                                                        </iframe>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="2"
                                                   class="d-flex justify-content-center align-self-center align-items-center">
                                                <b-icon icon="arrow-right-circle" class="clr-orange text-center"
                                                        font-scale="4"></b-icon>
                                            </b-col>
                                            <b-col cols="5" class="justify-content-center  ">
                                                <b-row class="my-2">
                                                    <b-col>
                                                        <h6 class="bold clr-darker-grey">
                                                            Capteur remplaçant :
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col cols="12" v-if="chosenSerialNumber">
                                                        <b-row>
                                                            <b-col cols="12" class="ml-2" style="line-height: 0.6em;">
                                                                <p class="d-inline-flex justify-content-center align-items-center align-self-center p-0 mb-2">
                                                                    Numéro de série :
                                                                    <span
                                                                        @click="goToProfile(replacementPayloadItem[itemRank].serialNumber)"
                                                                        class="clr-orange add-cursor mx-3">{{
                                                                            chosenSerialNumber
                                                                        }}
                                                                </span>
                                                                    <b-form-checkbox
                                                                        v-model="chosenSerialNumber"
                                                                        name="checkbox-1"
                                                                        :value="chosenSerialNumber"
                                                                    >
                                                                    </b-form-checkbox>
                                                                </p>

                                                                <p>Ville : {{
                                                                        replacementPayloadItem[itemRank].sensorAddress.locality
                                                                    }}<span></span></p>
                                                                <p>Département : {{
                                                                        replacementPayloadItem[itemRank].sensorAddress.administrativeAreaLvl2
                                                                    }}<span></span></p>
                                                                <p>Région : {{
                                                                        replacementPayloadItem[itemRank].sensorAddress.administrativeAreaLvl1
                                                                    }}<span></span></p>
                                                                <p>Pays : {{
                                                                        replacementPayloadItem[itemRank].sensorAddress.countryLongName
                                                                    }}<span></span></p>
                                                                <p>GPS : [<span class="clr-orange">{{
                                                                        replacementPayloadItem[itemRank].sensorAddress.lat
                                                                    }}</span>,<span class="clr-orange">{{
                                                                        replacementPayloadItem[itemRank].sensorAddress.lon
                                                                    }}</span>]<span></span></p>
                                                            </b-col>
                                                            <b-col cols="6">
                                                                <iframe
                                                                    class="shadow"
                                                                    width="400"
                                                                    height="250"
                                                                    style="border:0"
                                                                    loading="lazy"
                                                                    allowfullscreen
                                                                    referrerpolicy="no-referrer-when-downgrade"
                                                                    :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${chosenSensorAddress.sensorAddress.lat},${chosenSensorAddress.sensorAddress.lon}&zoom=15`"
                                                                >
                                                                </iframe>
                                                            </b-col>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="my-auto"
                                                           v-else-if="itemRank < replacementPayloadItem.length && !chosenSerialNumber">
                                                        <b-row
                                                            v-for="(serialNumber, s) in filteredSensorsBaseOnChosenOnes"
                                                            :key="serialNumber+ '-3' +10*s"
                                                        >
                                                            <b-form-checkbox
                                                                :id="'checkbox-'+item.id"
                                                                v-model="chosenSerialNumber"
                                                                name="checkbox-1"
                                                                :value="true"
                                                                :unchecked-value="null"
                                                                @change="setChosenSerialNumbers(serialNumber)"
                                                                :disabled="chosenSerialNumber && chosenSerialNumber !== serialNumber"
                                                            >
                                                            </b-form-checkbox>
                                                            <p>
                                                                {{ serialNumber }}
                                                            </p>
                                                        </b-row>
                                                    </b-col>
                                                    <b-col cols="12" class="mx-auto my-auto" v-else>
                                                        <div style="font-size: 4rem;" class="clr-orange">
                                                            <b-icon-check-circle-fill/>
                                                        </div>
                                                        Tous les capteurs ont été attribués
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </div>
                                <b-row class="justify-content-center align-content-center align-self-center" v-else>
                                    <b-col cols="12" md="8" class="justify-content-center" v-if="associationDone">
                                        <b-row class="justify-content-center align-content-center">
                                            <b-col cols="12" class="justify-content-center">
                                                <div class="clr-dark-grey text-uppercase text-center">
                                                    <p>Opération réalisée avec succès</p>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" class="text-center mt-2">
                                                <p class="h1 clr-orange sensors-success">
                                                    <b-icon-check-circle scale="1.5"/>
                                                </p>
                                            </b-col>
                                            <b-col cols="12" class="text-center mt-3">
                                                <b-row v-for="(item,z) in dualSensorReplacementPayload"
                                                       :key="item[0]+'_4'+z*100000"
                                                       class="justify-content-center align-self-center align-items-center">
                                                    <b-col cols="3" class="text-center">
                                                        <b-badge class="clr-orange bg-white border-orange">
                                                            {{ item[0] }}
                                                        </b-badge>
                                                    </b-col>
                                                    <b-col cols="2" class="flex-row text-center">
                                                        Remplace
                                                        <div style="font-size: 2rem;" class="clr-orange">
                                                            <b-icon-arrow-right/>
                                                        </div>
                                                    </b-col>
                                                    <b-col cols="3" class="text-center">
                                                        <b-badge class="clr-orange bg-white border-orange">
                                                            {{ item[1] }}
                                                        </b-badge>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col cols="12" class="text-center my-3">
                                                <b-button @click="closeModalAfterSuccess"
                                                          class="text-center bg-orange clr-white border-orange">
                                                    Terminer
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="12" md="8" class="d-flex justify-content-center clr-orange" v-else>
                                        <b-spinner class="text-center mr-2"></b-spinner>
                                        <p class="my-auto">Processing...</p>
                                    </b-col>
                                </b-row>
                                <template #modal-footer="{ ok, cancel }">
                                    <div v-if="replacementPayloadItem !== null && !endedForm">
                                        <b-button size="sm" class="bg-white clr-orange border-orange mr-1"
                                                  @click="cancel()">
                                            Cancel
                                        </b-button>
                                        <b-button size="sm"
                                                  class="bg-orange clr-white border-orange mr-1"
                                                  @click="nextStep([replacementPayloadItem[itemRank].serialNumber,chosenSerialNumber])"
                                                  :disabled="chosenSerialNumber === null"
                                                  v-if="itemRank+1 < replacementPayloadItem.length"
                                        >
                                            Suivant
                                        </b-button>
                                        <b-button size="sm" class="bg-white clr-orange border-white"
                                                  @click="nextStep([replacementPayloadItem[itemRank].serialNumber,chosenSerialNumber]).then(()=> submit())">
                                            Terminer
                                        </b-button>
                                    </div>
                                    <div v-else>
                                    </div>
                                </template>
                            </b-modal>
                        </h4>
                    </b-col>
                    <b-col cols="10"
                           class="mb-5 border-top justify-content-center align-self-center align-items-center mx-auto"></b-col>
                    <b-col cols="4">
                        <p v-if="item.replacementPayload" class="bold">
                            Fiche <span class="clr-orange "> N°{{ item.returnNumber }}</span>
                        </p>
                        <p>
                            Début : <span class="clr-dark-grey">{{ item.createdAt | dayDate }}</span>
                        </p>
                        <p>
                            Dernière MAJ : <span class="clr-dark-grey">{{ item.updatedAt | dateDayMonth }}</span>
                        </p>
                    </b-col>
                    <b-col cols="4">
                        <p>
                            Liste des capteurs sur le retour :
                        </p>
                        <p class="clr-orange add-cursor d-inline-flex"
                           v-for="(sensor,j) in item.sensorsReturning.map(a => a.serialNumber)"
                           :key="sensor+'_'+j*100"
                           @click="goToProfile(sensor)"
                        >
                            {{ sensor }}
                            <span v-if="item.sensorsReturning.length > j +1" class="mr-1">
                            ,
                        </span>
                        </p>
                        <p v-if="item.replacementSensors.length > 0">
                            Liste des capteurs remplaçants :
                        </p>
                        <p class="clr-orange add-cursor d-inline-flex"
                           v-for="(sensor,j) in item.replacementSensors.map(a => a.serialNumber)"
                           :key="sensor+'_1'+j*100"
                           @click="goToProfile(sensor)"
                        >
                            {{ sensor }}
                            <span v-if="item.sensorsReturning.length > j +1" class="mr-1">
                            ,
                        </span>
                        </p>
                    </b-col>
                    <b-col cols="10"
                           class="border-top justify-content-center align-self-center align-items-center mx-auto"
                           v-show="showDetails.includes(item.returnNumber)"></b-col>
                    <b-col cols="12" class="text-center">
                        <b-button class="clr-orange bg-white border-white" @click="toggleCards(item.returnNumber)"
                                  v-show="!showDetails.includes(item.returnNumber)">
                            Voir plus
                            <b-icon icon="plus-circle" font-scale="1" class=""></b-icon>
                        </b-button>
                    </b-col>
                    <hr>
                    <b-col cols="12" v-show="showDetails.includes(item.returnNumber)">
                        <b-row class="justify-content-center align-content-center">
                            <b-col cols="12" class="text-center">
                                <h4 class="clr-darker-grey mb-2">Informations de remplacement </h4>
                            </b-col>
                            <b-col cols="11" v-for="(payload,i) in item.replacementPayload"
                                   :key="payload.serialNumber+'_2'+i*100"
                                   class="shadow rounded p-3 mb-4 bg-lighter-grey">
                                <b-row>
                                    <b-col cols="12" class="text-center">
                                        <h5 @click="goToProfile(payload.serialNumber)">
                                            Capteur : <span class="clr-orange">{{ payload.serialNumber }}</span>
                                        </h5>
                                        <h6 v-if="payload.dataReference">
                                            Référence de données : <span
                                            class="clr-dark-grey">{{ payload.dataReference.referenceId }}</span>
                                        </h6>
                                    </b-col>
                                    <b-col cols="6"
                                           class="justify-content-center align-content-center align-self-center">
                                        <b-row>
                                            <b-col cols="12">
                                                <p>Ville : {{ payload.sensorAddress.locality }}<span></span></p>
                                                <p>Département : {{
                                                        payload.sensorAddress.administrativeAreaLvl2
                                                    }}<span></span></p>
                                                <p>Région : {{
                                                        payload.sensorAddress.administrativeAreaLvl1
                                                    }}<span></span></p>
                                                <p>Pays : {{ payload.sensorAddress.countryLongName }}<span></span></p>
                                                <p>Latitude : {{ payload.sensorAddress.lat }}<span></span></p>
                                                <p>Longitude : {{ payload.sensorAddress.lon }}<span></span></p>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                    <b-col cols="6">
                                        <iframe
                                            class="float-right align-self-end align-content-end"
                                            width="400"
                                            height="400"
                                            style="border:0"
                                            loading="lazy"
                                            allowfullscreen
                                            referrerpolicy="no-referrer-when-downgrade"
                                            :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${payload.sensorAddress.lat},${payload.sensorAddress.lon}&zoom=15`"
                                        >
                                        </iframe>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="text-center">
                        <b-button
                            class="clr-orange bg-white border-white"
                            @click="toggleCards(item.returnNumber)"
                            v-show="showDetails.includes(item.returnNumber)"
                        >
                            Fermer
                            <b-icon icon="plus-circle" font-scale="1" style="transform: rotate(45deg);"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins";

export default {
    name: "SensorReturningSheets",
    data() {
        return {
            showDetails: [],
            apiKey: 'AIzaSyAPqh1NQJL9t_XBAHhw_amgAaJqmp2yY7U',
            search: null,
            showComplete: false,
            replacementPayloadItem: null,
            replacementPayload: null,
            returningPayload: null,
            chosenSerialNumber: null,
            itemRank: 0,
            dualSensorReplacementPayload: [],
            sheetNumber: null,
            associationDone: false,
            endedForm: false,
            actualReturnNumber: '',
            completed: false
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setReturningSheet: 'setReturningSheet',
            sensorReplaceV2: 'sensorReplaceV2',
            updateReturningSheet: 'updateReturningSheet',
            updateReturningSheetList: 'updateReturningSheetList'
        }),
        goToProfile(id) {
            let route = this.$router.resolve({path: "/sensors/" + id + "/detail"});
            window.open(route.href);
        },
        toggleCards(value) {
            let index = this.showDetails.indexOf(value);

            if (index === -1) {
                this.showDetails.push(value);
                return true;
            } else {
                this.showDetails.splice(index, 1);
                return false
            }

        },
        completeReturn(returnNumber) {
            this.actualReturnNumber = returnNumber
            this.$bvModal.show('modal-force-validation_' + this.actualReturnNumber)
        },
        confirmWarning(sheetNumber, payload) {
            this.replacementPayloadItem = payload.replacementPayload
            this.replacementPayload = payload.replacementSensors
            this.returningPayload = payload.sensorsReturning
            this.sheetNumber = sheetNumber
            this.$bvModal.hide('modal-force-validation_' + this.actualReturnNumber)
            this.$bvModal.show('modal-associate-sensor_' + this.actualReturnNumber)
        },
        async nextStep(data) {
            if (this.replacementPayloadItem && this.itemRank < this.replacementPayloadItem.length) {
                this.itemRank += 1
                this.dualSensorReplacementPayload.push(data)
            }
            this.chosenSerialNumber = null
        },
        resetData() {
            Object.assign(this.$data, this.$options.data())
        },
        setChosenSerialNumbers(serialNumber) {
            if (serialNumber === this.chosenSerialNumber) {
                this.chosenSerialNumber = null
            } else {
                this.chosenSerialNumber = serialNumber
            }
        },
        closeModalAfterSuccess() {
            let returnNumber = this.actualReturnNumber
            this.$bvModal.hide('modal-associate-sensor_' + returnNumber)
            this.resetData()
            this.updateReturningSheetList(returnNumber)
        },
        async submit() {
            this.endedForm = true
            for (let i = 0; i < this.dualSensorReplacementPayload.length; i++) {
                let queryStringForSheetsUpdate = this.generateQueryStringForSheetsUpdate(this.dualSensorReplacementPayload[i])

                if (i + 1 === this.dualSensorReplacementPayload.length) {
                    await this.updateReturningSheet(queryStringForSheetsUpdate)
                    setTimeout(() => {
                        Toast.fire(
                            {
                                icon: 'success',
                                title: 'La fiche à été mise à jour !',
                            }
                        )
                        this.associationDone = true
                    }, 2000)
                }
            }
        },
        generateQueryStringForSheetsUpdate() {
            let fields = new FormData();
            for (let i = 0; i < this.dualSensorReplacementPayload.length; i++) {
                fields.set('sensorPairs[]', [this.dualSensorReplacementPayload[i]])
            }

            return [this.actualReturnNumber, fields]
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            sensorReturningSheets: 'returningSheets'
        }),
        filteredSheets() {
            let resultSheets = this.sensorReturningSheets

            if (this.completed) {
                resultSheets = resultSheets.filter(item => item.completedAt !== null)
            } else {
                resultSheets = resultSheets.filter(item => item.completedAt === null)
            }

            if (this.search) {

                resultSheets = resultSheets.filter(item => item.returnNumber.includes(this.search))
            }

            return resultSheets
        },
        filteredSensorsBaseOnChosenOnes() {
            let replacementSensors = this.replacementPayload
            let chosenSensorForReplacement = this.dualSensorReplacementPayload

            if (chosenSensorForReplacement.length === 0) {
                return replacementSensors.map(a => a.serialNumber)
            } else {
                return [...new Set(replacementSensors.filter(sensor => {
                    const flattenedArray = chosenSensorForReplacement.flatMap(subArray => subArray[1]);

                    return !flattenedArray.includes(sensor.serialNumber)
                }).map(a => a.serialNumber))]
            }

        },
        chosenSensorAddress() {
            return this.sensorReturningSheets[this.sheetNumber].replacementSensors.find(sensor => sensor.serialNumber = this.chosenSerialNumber)
        }
    },
    async created() {
        await this.setReturningSheet()
    }

}
</script>

<style scoped lang="scss">

</style>