<template>
    <div class="row">
        <div class="col-xl-12 row justify-content-center align-self-center my-5 mx-auto">
            <b-form @submit.prevent="onSubmit" class="mb-5 card p-3">
                <b-form-group
                    label="Nom de version"
                    label-for="appCurrentVersion"
                >
                    <b-form-input
                        id="appCurrentVersion"
                        :value="form.lastAppVersion"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Nouvelle version majeur"
                label-for="appNewMajorVersion">
                    <b-form-input
                        id="appNewMajorVersion"
                        type="number"
                        v-model="form.majorLimitVersion"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Nouvelle version mineur"
                    label-for="appNewMinorVersion">
                    <b-form-input
                        id="appNewMinorVersion"
                        type="number"
                        v-model="form.minorLimitVersion"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="updateMessage_fr">Message de mise a jour français</label>
                    <vue-editor id="updateMessage_fr" v-model="form.message.fr" aria-required="true"/>
                    <p class="error" v-if="this.form.message.fr.length === 0">Veuillez remplir ce champ</p>
                    <label for="updateMessage_en">Message de mise a jour anglais</label>
                    <vue-editor id="updateMessage_en" v-model="form.message.en" aria-required="true" />
                    <p class="error" v-if="this.form.message.en.length === 0">Veuillez remplir ce champ</p>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="form.isActive" switch size="lg">Active</b-form-checkbox>
                </b-form-group>
                <b-button type="submit" variant="primary">{{ isEdit ? 'Mettre a jour' : 'Valider' }}</b-button>
            </b-form>
            <b-table-simple
                responsive
                hover
                sticky-header
                sort-icon-left
                class="sensor-array table-height"
                :key="arrayKey"
                v-if="getMobileSettingsList"
            >
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>Id</b-th>
                        <b-th>Version</b-th>
                        <b-th>Message</b-th>
                        <b-th>Création</b-th>
                        <b-th>Actif</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(settings, idx) in getMobileSettingsList"
                          :key="idx"
                          class="clr-dark-blue add-cursor"
                          @click="isEdit = true;form = settings"
                    >
                        <b-td class="col-xl-1 text-center">{{settings.id}}</b-td>
                        <b-td class="col-xl-1 text-center">{{settings.majorLimitVersion}}.{{settings.minorLimitVersion}}.0</b-td>
                        <b-td v-html="settings.message ? settings.message.fr : ''" style="max-width: 500px"></b-td>
                        <b-td class="col-xl-2 text-center">{{settings.createdAt | convertDate}}</b-td>
                        <b-td class="col-xl-1 text-center">{{settings.isActive}}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import Vuex from "vuex";
import {VueEditor} from "vue2-editor";
export default {
    name: "MobileSettings",
    components: {VueEditor},
    data(){
        return {
            currentVersion: '2.13.0',
            form:{
                majorLimitVersion:'',
                minorLimitVersion:'',
                message:{
                    "fr": '',
                    "en":''
                },
                isActive: false,
                lastAppVersion: ''
            },
            arrayKey:0,
            isEdit: false,
        }
    },
    computed: {
        ...Vuex.mapGetters('mobile', {
            getMobileSettingsList: 'getMobileSettingsList'
        }),
    },
    methods:{
        ...Vuex.mapActions('mobile', {
            createMobileSetting: 'createMobileSetting',
            setMobileSettingList: 'setMobileSettingList',
            editMobileSettings: 'editMobileSettings'
        }),
        async onSubmit(){
            if(this.form.message.fr.length === 0 || this.form.message.en.length === 0){
                return
            }
            let query = {};
            if(!this.form.lastAppVersion.length > 0)
                this.form.lastAppVersion = this.form.majorLimitVersion + '.' + this.form.minorLimitVersion + '.0';

            if(this.isEdit){
                query.id = this.form.id;
                query.form = this.generateQueryString();
                await this.editMobileSettings(query);
            }else {
                query = this.generateQueryString();
                await this.createMobileSetting(query)
            }

            await this.setMobileSettingList();
        },
        generateQueryString(){
          let data = new FormData();
          data.append('majorLimitVersion', this.form.majorLimitVersion);
          data.append('minorLimitVersion', this.form.minorLimitVersion);
          data.append('message', JSON.stringify(this.form.message));
          data.append('isActive', this.form.isActive);
          data.append('lastAppVersion',this.form.lastAppVersion);
          return data;
        },
    },
    async created() {
        await this.setMobileSettingList();
        this.getMobileSettingsList.length > 0 ?
            this.form.lastAppVersion = this.getMobileSettingsList.find(s => s.isActive).lastAppVersion:
            false;
    }

}
</script>

<style scoped>

</style>