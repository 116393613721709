<template>
    <apexchart v-once :height="height" type="radialBar" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
    name: "RadialChart",
    props: {
        chartOptions: {
            type: Object,
            default: () => {
                return {}
            }
        },
        series: {
            type: Array,
            default: () => {
                return [{
                    name: '',
                    data: []
                }]
            }
        },
        height: {
            type: String||Number,
            default: '280'
        }
    }
}
</script>

<style scoped>

</style>