const axios = require('axios');
export default {
    getConsumption,
    authenticateToMatooma
}

const MatoomaAPI = axios.create({
    baseURL: 'https://api.matooma.com'
})


async function authenticateToMatooma() {
    let basic64Hash = 'Basic ' + btoa(process.env.VUE_APP_MATOOMA_CLIENT_KEY + ':' + process.env.VUE_APP_MATOOMA_SECRET_KEY);
    return MatoomaAPI.post(
        '/token',
        {'grant_type': "client_credentials"},
        {headers: {
                'Authorization': basic64Hash,
                'Content-Type': "application/x-www-form-urlencoded"
            }}
    )
}


async function getConsumption(token,iccid, period) {
    // const response = await authenticateToMatooma();
    // const token = response.data.access_token;
    let basic64Hash = 'Bearer ' + token;
    let headers = {
        headers: {
            'Authorization': basic64Hash,
            'Accept': 'application/json',
        }
    };
    const consumptionTotal = MatoomaAPI.get(`/v1/consumptions/total/${iccid}?start=${period.start}&end=${period.end}`, headers);
    const consumptionDaily = MatoomaAPI.get(`/v1/consumptions/daily/${iccid}?start=${period.start}&end=${period.end}`, headers);
    const simCardInfo = MatoomaAPI.get(`/v1/simcards?page=1&size=10&iccid=${iccid}`, headers);
    return {total: consumptionTotal, daily: consumptionDaily, simInfo: simCardInfo}
}


