import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import sensor from "./modules/sensor/sensor";
import user from "./modules/user/user";
import account from "./modules/account/account";
import accountGroup from "./modules/account/accountGroup";
import accountContact from "./modules/account/accountContact";
import parameter from "./modules/parameter/parameter";
import analytics from "./modules/analytics/analytics";
import matooma from "@/store/modules/matooma/matooma";
import campaign from "@/store/modules/account/campaign";
import dataAccess from "@/store/modules/account/dataAccess";
import migration from "@/store/modules/developerTools/migration";
import monitoring from "@/store/modules/monitoring/monitoring";
import mobile from "@/store/modules/mobile/mobile";
import deliveryTracking from "@/store/modules/deliveryTracking/deliveryTracking";
import sensorSetup from "@/store/modules/sensor/sensorSetup";
import contentGeneration from "@/store/modules/contentGeneration/contentGeneration";
import sensorList from "@/store/modules/sensor/sensorList";
import sensorEvent from "@/store/modules/sensor/sensorEvent";
import i18n from '@/i18n'

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		login,
		sensor,
		user,
		account,
		accountGroup,
		parameter,
		analytics,
		matooma,
		campaign,
		dataAccess,
		migration,
		monitoring,
		mobile,
		deliveryTracking,
		contentGeneration,
		sensorSetup,
		sensorList,
		accountContact,
		sensorEvent
	},
	state: {
		packageVersion: process.env.VUE_APP_VERSION,
		appLanguage: localStorage.getItem("appLanguage") || process.env.VUE_APP_I18N_LOCALE || 'fr_fr'
	},
	getters: {
		appVersion: (state) => {
			return state.packageVersion
		},
		getAppLanguage: (state) => state.appLanguage
	},
	mutations: {
		setAppLanguage(state, language) {
			state.appLanguage = language;
			localStorage.setItem("language", language); // Whenever we change the appLanguage we save it to the localStorage
			i18n.lang
		}
	}
});

export default store

