<template>
    <b-row class="justify-content-center align-content-center" v-if="isDataAccessLoading">
        <b-col cols="12" md="8" class="text-center align-self-center shadow rounded p-5 my-2">
            <p class="text-center dataAccessFormTitle clr-dark-grey">OUTILS DE CREATION D'ACCÈS PERSONNALISES</p>
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" class="text-center"></b-spinner>
            <p class="text-center">Vous allez être redirigé !</p>
        </b-col>
    </b-row>
    <b-row class="justify-content-center align-content-center" v-else>
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Création d'accès pour le compte
                <span>
                    <br>
                    <b-badge class="mr-1 section-title clr-white"
                             size="small"
                             variant="primary"
                             v-for="(account,index) in accounts.filter(item => formData.accountLookingForAccess.includes(item.id))"
                             :key="index">
                        {{ account.name }}
                    </b-badge>
                </span>
            </h2>
            <b-row v-if="formStep === 0" class="justify-content-center align-content-center mb-5">
                <b-button @click="stepUp" class="btn-action my-5">Commencer</b-button>
            </b-row>
            <b-row v-if="formStep === 1" class="justify-content-center align-content-center mb-5">
                <b-col cols="10">
                    <b-form-group label="Veuillez choisir le groupe de compte qui bénéficiera de cet accès :"
                                  v-slot="{ ariaDescribedby }"
                                  label-for="accounts"
                                  class="dataAccessFormSubTitle">
                        <b-form-select v-model="formData.accountGroupLookingForAccess"
                                       :select-size="4"
                                       id="accounts"
                                       required
                                       :state="isStepOk ? true : null">
                            <b-form-select-option
                                :aria-describedby="ariaDescribedby"
                                :value="[accountGroup.id]"
                                v-for="(accountGroup, index) in groupsBasedOnChosenAccounts"
                                :key="index"
                            >
                                {{ accountGroup.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <h3 v-if="formData.accountGroupLookingForAccess.length > 0">
                        <b-badge class="mr-1" variant="primary">
                            {{ getAccountGroupById(formData.accountGroupLookingForAccess)[0].name }}
                        </b-badge>
                    </h3>
                </b-col>
            </b-row>
            <b-row v-if="formStep === 2" class="justify-content-center align-items-center mb-5">
                <b-col cols="10">
                    <p class="dataAccessFormSubTitle">
                        Choix d'une catégorie de ressource :
                        <br>
                        <span class="clr-light-grey font-weight-light">
                            (il est possible de choisir des items dans les différentes catégories à la fois)
                            </span>
                    </p>
                </b-col>
                <b-col cols="12">
                    <b-row v-if="formStep === 2" class="justify-content-center align-items-center">
                        <b-col cols="auto">
                            <div
                                :class="chosenRessourcesCategory === 'account' ? 'active':''"
                                @click="setChosenRessourcesCategory('account')"
                                class="rounded categoryCard">
                                GROUPES DE COMPTE
                            </div>
                        </b-col>
                        <b-col cols="auto">
                            <div
                                @click="setChosenRessourcesCategory('sensor')"
                                :class="chosenRessourcesCategory === 'sensor' ? 'active':''"
                                class="rounded categoryCard">
                                CAPTEURS
                            </div>
                        </b-col>
                        <b-col cols="auto">
                            <div
                                @click="setChosenRessourcesCategory('location')"
                                :class="chosenRessourcesCategory === 'location' ? 'active':''"
                                class="rounded categoryCard">
                                LOCATIONS
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="10" class="mt-3 mt-md-5">
                    <div v-if="chosenRessourcesCategory === 'sensor'">
                        <b-form-group
                            label="Veuillez choisir le ou les capteurs :"
                            v-slot="{ ariaDescribedby }"
                            label-for="sensors">
                            <b-input-group
                                class="my-2"
                                @submit.prevent="querySensor"
                                @keyup.enter="querySensor">
                                <b-form-input
                                    v-model="search"
                                    placeholder="Recherche par numéro">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-button
                                        type="submit"
                                        variant="primary"
                                        @click="querySensor">
                                        Go
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-select
                                v-model="formData.chosenSensors"
                                multiple
                                :select-size="4"
                                id="sensors"
                                required
                                :state="formData.chosenSensors.length > 0 ? true : null">
                                <b-form-select-option
                                    :aria-describedby="ariaDescribedby"
                                    :value="sensor.id"
                                    v-for="(sensor, index) in sensorsNotAssociated(excludedSensors)"
                                    :key="index">
                                    {{ sensor.serialNumber }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text
                                id="input-live-help">
                                Sélection au clique multiple en maintenant le
                                bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1"
                                     variant="primary"
                                     v-for="(sensor,index) in sensors.filter(item => formData.chosenSensors.includes(item.id))"
                                     :key="index">
                                {{ sensor.serialNumber }}
                            </b-badge>
                        </h3>
                    </div>
                    <div v-if="chosenRessourcesCategory === 'location'">
                        <p class="dataAccessFormSubTitle">
                            Choix d'une catégorie de Lieux :
                            <br>
                            <span class="clr-light-grey font-weight-light">
                                (il est possible de choisir des items dans les deux catégories à la fois)
                            </span>
                        </p>
                        <b-row class="justify-content-center align-content-center mb-3">
                            <b-col cols="6" @click="locationType = 'landmark'">
                                <div
                                    class="text-center rounded categoryCard"
                                    :class="locationType === 'landmark' ? 'active':''">
                                    <p class="m-0">Point d'intérêts administratifs</p>
                                </div>
                            </b-col>
                            <b-col cols="6" @click="locationType = 'GPS'">
                                <div
                                    class="text-center rounded categoryCard"
                                    :class="locationType === 'GPS' ? 'active':''">
                                    <p class="m-0">Coordonnées GPS</p>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center justify-content-center"
                               v-if="locationType === 'landmark'">
                            <b-col cols="12">
                                <b-form-group label="Choisir un accès par Lieux (Pays, Région, Département, Ville)"
                                              label-for="sensors">
                                    <b-form-select v-model="chosenLocationType"
                                                   :options="locationTypesOptions">
                                    </b-form-select>
                                    <b-input-group class="my-2">
                                        <b-form-input v-model="locationTypeFilter"
                                                      placeholder="Recherche par nom du lieux">
                                        </b-form-input>
                                    </b-input-group>
                                    <b-form-select
                                        v-model="formData.locations[chosenLocationType]"
                                        multiple
                                        :select-size="4" id="sensors"
                                        required
                                        v-if="getFilteredLocations.length >0">
                                        <b-form-select-option
                                            :value="location"
                                            v-for="(location, index) in getFilteredLocations"
                                            :key="index">
                                            {{ location }}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <p v-else>Aucun élément disponible au choix</p>
                                    <b-form-text
                                        id="input-live-help"
                                        class=""
                                        v-if="getFilteredLocations.length > 0"
                                    >
                                        Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou
                                        "Pomme(MacOS)" du clavier.
                                    </b-form-text>
                                </b-form-group>
                                <p>
                                    Pays(s) Sélectionnée(s) :
                                    <b-badge class="mr-1"
                                             variant="primary"
                                             v-for="(item,index) in formData.locations.countryLongName"
                                             :key="index">
                                        {{ item }}
                                    </b-badge>
                                </p>
                                <p>
                                    Région(s) Sélectionné(s) :
                                    <b-badge class="mr-1"
                                             variant="primary"
                                             v-for="(item,index) in formData.locations.administrativeAreaLvl1"
                                             :key="index">
                                        {{ item }}
                                    </b-badge>
                                </p>
                                <p>
                                    Département(s) Sélectionné(s) :
                                    <b-badge class="mr-1"
                                             variant="primary"
                                             v-for="(item,index) in formData.locations.administrativeAreaLvl2"
                                             :key="index">
                                        {{ item }}
                                    </b-badge>
                                </p>
                                <p>
                                    Ville(s) Sélectionné(s) :
                                    <b-badge class="mr-1"
                                             variant="primary"
                                             v-for="(item,index) in formData.locations.locality"
                                             :key="index">
                                        {{ item }}
                                    </b-badge>
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center justify-content-center" v-if="locationType === 'GPS'">
                            <b-col cols="12">
                                <b-form @submit.prevent="setNewLocation()" class="mb-1">
                                    <b-form-group
                                        label="Nom du lieux associé aux coordonnées :"
                                        label-for="coordinates">
                                        <b-input-group class="my-1">
                                            <b-form-input
                                                v-model="coordinateName"
                                                placeholder="Ex: Gare SNCF Orléans">
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group label="Latitude :">
                                        <b-input-group class="my-2">
                                            <b-form-input
                                                v-model="coordinateLat"
                                                placeholder="Ex : 1.9">
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group label="Longitude :">
                                        <b-input-group class="my-2">
                                            <b-form-input
                                                v-model="coordinateLon"
                                                placeholder="Ex : 47.3">
                                            </b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-button
                                        type="submit"
                                        variant="primary"
                                        class="clr-white text-center">
                                        Ajouter les coordonnées
                                    </b-button>
                                </b-form>
                                <p class="mt-5">
                                    Point(s) d'intérêt(s) ajoutée(s) :
                                </p>
                                <p v-for="(item,index) in formData.coordinates" :key="index">
                                    <b-badge class="mr-1"
                                             variant="primary">
                                        {{ Object.keys(item)[0] | capitalize }}
                                    </b-badge>
                                    - Coordonnées: [{{ item[Object.keys(item)[0]][0] }} ,
                                    {{ item[Object.keys(item)[0]][1] }}]
                                    <b-icon icon="x-circle-fill"
                                            class="clr-red"
                                            @click="removeGps(Object.keys(item)[0])"/>
                                </p>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="chosenRessourcesCategory === 'account'">
                        <b-form-group
                            label="Veuillez choisir le ou les comptes :"
                            v-slot="{ ariaDescribedby }"
                            label-for="accounts"
                            class="dataAccessFormSubTitle">
                            <b-form-select
                                v-model="formData.accountGivingAccess"
                                multiple
                                :select-size="4"
                                id="accounts"
                                required
                                :state="formData.accountGivingAccess.length > 0 ? true : null">
                                <b-form-select-option
                                    :aria-describedby="ariaDescribedby"
                                    :value="account.id"
                                    v-for="(account, index) in getAccountsInsteadMine"
                                    :key="index"
                                >
                                    {{ account.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help">
                                Sélection au clique multiple en
                                maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1"
                                     variant="primary"
                                     v-for="(account,index) in accounts.filter(item => formData.accountGivingAccess.includes(item.id))"
                                     :key="index"> {{ account.name }}
                            </b-badge>
                        </h3>
                        <b-form-group
                            v-if="formData.accountGivingAccess.length > 0"
                            label="Veuillez choisir le ou les groupe(s) de comptes :"
                            v-slot="{ ariaDescribedby }" label-for="accounts"
                            class="dataAccessFormSubTitle mt-4">
                            <p class="d-flex d-inline"> Tout sélectionner :
                                <b-form-checkbox
                                    v-model="addAllAccountGroupsGiver"
                                    name="check-button"
                                    switch
                                    class="ml-2">
                                </b-form-checkbox>
                            </p>
                            <b-form-select
                                v-model="formData.accountGroupGivingAccess"
                                multiple
                                :select-size="4"
                                id="accounts"
                                required
                                :state="formData.accountGroupGivingAccess.length > 0 ? true : null">
                                <b-form-select-option
                                    :aria-describedby="ariaDescribedby"
                                    :value="account.id"
                                    v-for="(account, index) in getAccountGroupsByAccountIdInsteadMine"
                                    :key="index">
                                    {{ account.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help">
                                Sélection au clique multiple en
                                maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1"
                                     variant="primary"
                                     v-for="(account,index) in groupsBasedOnChosenAccountsGiver.filter(item => formData.accountGroupGivingAccess.includes(item.id))"
                                     :key="index">
                                {{ account.name }}
                            </b-badge>
                        </h3>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="formStep === 3" class="justify-content-around align-content-center mb-5">
                <b-col cols="10">
                    <b-form-group label="Type d'accès :">
                        <b-form-select
                            :state="formData.accessType !== '' ? true : null"
                            v-model="formData.accessType"
                            :options="accessTypes"/>
                    </b-form-group>
                    <b-form-group label="Date de fin d'accès (optionnel) :">
                        <b-form-datepicker v-model="formData.endAt"></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="formStep === 4" class="justify-content-center align-content-center mb-5">
                <b-col cols="12">
                    <p class="text-center dataAccessFormTitle clr-dark-grey">
                        Résumé de la création d'accès
                    </p>
                    <b-row class="justify-content-center my-5">
                        <b-col cols="10" md="5" class="bg-lighter-grey shadow-complete-data-access my-2 p-4 p-md-5">
                            <b-row>
                                <p class="clr-dark-grey mt-1">
                                    Ressources qui vont recevoir les accès
                                </p>
                            </b-row>
                            <b-row v-if="formData.accountLookingForAccess.length > 0">
                                <p>Les comptes : </p>
                                <p class="ml-2 clr-orange">
                                    <span v-for="(accountReceiver,key1) in formData.accountLookingForAccess"
                                          :key="key1">
                                        {{ getAccountObjectById(accountReceiver).name }}
                                    </span>
                                </p>
                            </b-row>
                            <b-row
                                v-if="!isEditMode() && getAccountGroupsByAccountId([formData.accountLookingForAccess,formData.accountGroupLookingForAccess]).length >0">
                                <p>Les groupes : </p>
                                <p class="ml-2 clr-orange">
                                    <span
                                        v-for="(groupReceiver,key2) in getAccountGroupById(formData.accountGroupLookingForAccess)"
                                        :key="key2">
                                        {{ groupReceiver.name }}
                                    </span>
                                </p>
                            </b-row>
                            <b-row v-else-if="isEditMode() && getAccountGroupById([accountGroupToEdit]).length >0">
                                <p>Les groupes : </p>
                                <p class="ml-2 clr-orange">
                                    <span v-for="(groupReceiver,key2) in getAccountGroupById([accountGroupToEdit])"
                                          :key="key2">
                                        {{ groupReceiver.name }}
                                    </span>
                                </p>
                            </b-row>
                        </b-col>
                        <b-col cols="1" class="align-self-center d-none d-md-block">
                            <b-icon
                                icon="arrow-bar-right"
                                animation="cylon"
                                variant="primary"
                                scale="3"
                            >
                            </b-icon>
                        </b-col>
                        <b-col cols="10" md="5" class="bg-lighter-grey shadow-complete-data-access my-2 p-4 p-md-5">
                            <b-row class="justify-content-start">
                                <p class="clr-dark-grey">
                                    Accès autorisés
                                </p>
                            </b-row>
                            <b-row v-if="formData.accountGivingAccess.length > 0">
                                <p>Les comptes : </p>
                                <p class="clr-orange">
                                    <span
                                        v-for="(accountGiver,key3) in formData.accountGivingAccess"
                                        :key="key3">
                                        {{ getAccountObjectById(accountGiver).name }}
                                    </span>
                                </p>
                            </b-row>
                            <b-row v-else-if="getAccountGroupById(formData.accountGroupGivingAccess).length > 0">
                                <p>Les groupes : </p>
                                <p class="clr-orange">
                            <span
                                v-for="(groupGiver,key4) in getAccountGroupById(formData.accountGroupGivingAccess)"
                                :key="key4">
                                {{ groupGiver.name }}
                            </span>
                                </p>
                            </b-row>
                            <b-row v-if="sensors.filter(item => formData.chosenSensors.includes(item.id)).length > 0">
                                <p>Les sensors : </p>
                                <p>
                                    <span
                                        v-for="(sensor,key5) in sensors.filter(item => formData.chosenSensors.includes(item.id))"
                                        :key="key5">
                                       <b-badge class="mx-1"
                                                variant="primary">
                                           {{ sensor.serialNumber }}
                                       </b-badge>
                                    </span>
                                </p>
                            </b-row>
                            <b-row v-if="isLocationsSet || formData.coordinates.length > 0"
                                   class="justify-content-start">
                                <b-col cols="12" class="p-0 m-0 text-left">Les Point d'intérêt ou lieux :</b-col>
                                <b-col cols="12" class="text-left p-0 m-0" v-for="(location,key6) in formData.locations"
                                       :key="key6">
                                    <b-row v-if="location.length > 0" cols="12" class="ml-2 mt-2 justify-content-start">
                                        <p v-if="location.length > 0" class="clr-dark-grey">
                                            {{ locationTypesOptions.find(item => item.value === key6).text }} : &nbsp;
                                        </p>
                                        <div v-if="key6 !== 'id'">
                                            <p class="clr-orange p-0 m-0" v-for="(item,key7) in location"
                                               :key="key7">
                                                {{ item }} <span v-if="key7 > 0">,</span><br>
                                            </p>
                                        </div>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="p-0 m-0 text-left" v-if="formData.coordinates.length > 0">
                                    <p class="">
                                        Coordonnées Gps
                                    </p>
                                    <p class="ml-2 clr-orange" v-for="(location,key8) in formData.coordinates"
                                       :key="key8">
                                        <b-badge
                                            class="mr-1"
                                            variant="primary"
                                        >
                                            {{ Object.keys(location)[0] | capitalize }}
                                        </b-badge>
                                        - Coordonnées: [{{ location[Object.keys(location)[0]][0] }} ,
                                        {{ location[Object.keys(location)[0]][1] }}]
                                    </p>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row
                        class="justify-content-start align-content-start bg-lighter-grey mb-5 p-3 shadow-edit-data-access">
                        <b-col cols="12" md="6" class="text-center">
                            <p class="m-0">Type d'accès :
                                <b-badge :class="`bg-${colorsByAccessType[formData.accessType]}`" class="ml-2 p-2">
                                    {{ formData.accessType | toUpperCase }}
                                </b-badge>
                            </p>
                        </b-col>
                        <b-col cols="12" md="6" class="text-center">
                            <p class="m-0">Date de fin :
                                <span class="ml-2 clr-orange" v-if="formData.endAt">{{
                                        formData.endAt | dayDate
                                    }} </span>
                                <span class="ml-2 clr-orange" v-else>Aucune</span>
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center align-items-center text-center">
                        <b-col cols="6">
                            <b-button class="btn-action" @click="sendAccess()" size="lg">
                                Créer les accès
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="justify-content-end mt-5 mr-2" v-if="formStep > 0">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="stepDown">Précédent</b-button>
                </b-col>
                <b-col cols="auto" v-if="formStep !== 4">
                    <b-button
                        class="btn-action"
                        :disabled="!isStepOk"
                        @click="stepUp">
                        Valider
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "AccountCreateCustomAccess",
    props: {
        passedAccount: {
            type: Object,
            default: null
        },
        accountGroupToEdit: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            formStep: 0,
            addAllAccountGroupsReceiver: false,
            addAllAccountGroupsGiver: false,
            chosenRessourcesCategory: '',
            chosenLocationType: 'countryLongName',
            locationTypesOptions: [
                {value: 'countryLongName', text: 'Pays'},
                {value: 'administrativeAreaLvl2', text: 'Région'},
                {value: 'administrativeAreaLvl1', text: 'Département'},
                {value: 'locality', text: 'Ville'}
            ],
            locationTypeFilter: '',
            switchLocationToCoordinate: false,
            locationType: 'GPS',
            coordinateName: '',
            coordinateLat: '',
            coordinateLon: '',
            formData: {
                accountLookingForAccess: [],
                accountGroupLookingForAccess: [],
                accountGivingAccess: [],
                accountGroupGivingAccess: [],
                chosenSensors: [],
                locations: {
                    countryLongName: [],
                    administrativeAreaLvl1: [],
                    administrativeAreaLvl2: [],
                    locality: [],
                },
                coordinates: [],
                accessType: 'standard',
                endAt: null
            },
            search: '',
            excludedSensors: [],
            excludedAccountGroups: [],
            excludedLocations: [],
            excludedCoordinates: [],
            accessTypes: [
                {value: 'standard', text: 'Standard'},
                {value: 'pro', text: 'Entreprise'},
                {value: 'scientific', text: 'Scientifique'}
            ],
            colorsByAccessType: {
                'standard': 'green3',
                'pro': 'light-green',
                'scientific': 'yellow',
            }
        }
    },
    methods: {
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('dataAccess', {
            createDataAccess: 'createDataAccess',
            getRequest: 'getRequest',
            setDataAccessByAccountGroup: 'setDataAccessByAccountGroup',
            setDataAccessByAccount: 'setDataAccessByAccount'
        }),
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors'
        }),
        stepUp() {
            if (this.isStepOk) {
                this.formStep += 1
            } else {
                alert("Veuillez choisir au moins un compte avant de passer à l'étape suivante")
            }
        },
        stepDown() {
            if (this.formStep > 0) {
                this.formStep -= 1
            }
        },
        setChosenRessourcesCategory(category) {
            this.chosenRessourcesCategory = category
        },
        async querySensor() {
            let currentPage = {paginated: false}

            this.search.length >= 3 ? currentPage.search = this.search : '';
            currentPage.type = this.chosenType
            currentPage.status = 'prod';
            await this.setSensors(currentPage)

        },
        setNewLocation() {
            const key = this.coordinateName;
            const lat = this.coordinateLat;
            const lon = this.coordinateLon;
            let existingKey = ''
            let existingLat = ''
            let existingLon = ''

            if (this.excludedLocations.length > 0) {
                let accesses = this.excludedLocations.map(a => a.accesses)

                let reduceAccessOnLocationType =
                    accesses.reduce((acc, cur) => ({...acc, ['coordinates']: cur['coordinates']}), [])
                let coordinates = reduceAccessOnLocationType.coordinates
                if (coordinates !== undefined) {
                    for (let i = 0; i < Object.keys(coordinates).length; i++) {
                        existingKey = Object.keys(coordinates)[i]
                        existingLat = coordinates[Object.keys(coordinates)[i]][0]
                        existingLon = coordinates[Object.keys(coordinates)[i]][1]
                    }
                }
            }

            if (key && lat && lon && (existingKey !== key && (existingLat !== lat || existingLon !== lon))) {
                const value = {
                    [key]: [lat, lon]
                };

                this.formData.coordinates.push(value)

                this.coordinateName = '';
                this.coordinateLat = '';
                this.coordinateLon = '';

            } else if (!key || !lat || !lon) {
                alert("Le formulaire n'est pas complet")
            } else if (existingKey !== key && (existingLat === lat || existingLon === lon)) {
                alert("Ces données existe déjà ! Veuillez formuler une autre demande")
            } else {
                alert('Cette entrée existe déjà!')
            }
        },
        getAccountObjectById(id) {
            return this.getAccountsById(id)
        },
        generateQueryString() {
            let data = new FormData();

            data.append('owner', 'renter');
            if (this.formData.accountGroupGivingAccess.length > 0) {
                for (var i = 0; i < this.formData.accountGroupGivingAccess.length; i++) {
                    data.append('fromAccountGroups[]', this.formData.accountGroupGivingAccess[i]);
                }
            }

            if (this.formData.accountGroupLookingForAccess.length > 0) {
                for (var y = 0; y < this.formData.accountGroupLookingForAccess.length; y++) {
                    data.append('toAccountGroups[]', this.formData.accountGroupLookingForAccess[y]);
                }
            }

            if (this.formData.chosenSensors.length > 0) {
                for (let z = 0; z < this.formData.chosenSensors.length; z++) {
                    data.append('sensors[]', this.formData.chosenSensors[z]);
                }
            }

            if (this.formData.accessType !== '') {
                data.append('accessType', this.formData.accessType);
            }

            if (this.formData.endAt) {
                data.append('endAt', this.formData.endAt);
            }

            if (this.formData.coordinates.length > 0) {
                for (let x = 0; x < this.formData.coordinates.length; x++) {
                    let coordinate = this.formData.coordinates[x]
                    let coordinateName = Object.keys(coordinate)[0]
                    data.append('locations[coordinates][' + Object.keys(coordinate)[0] + ']', coordinate[coordinateName][0].toString() + ',' + coordinate[coordinateName][1].toString());
                }
            }

            if (this.isLocationsSet) {
                for (const property in this.formData.locations) {
                    let ressources = ''
                    for (let u = 0; u < this.formData.locations[property].length; u++) {
                        if (u === (this.formData.locations[property].length - 1)) {
                            ressources += this.formData.locations[property][u]
                        } else {
                            ressources += this.formData.locations[property][u] + ','
                        }
                    }
                    data.append('locations[' + property + ']', ressources);
                }
            }

            return data
        },
        async sendAccess() {
            let data = this.generateQueryString()
            await this.createDataAccess(data)
            this.formData = {
                accountLookingForAccess: [],
                accountGroupLookingForAccess: [],
                accountGivingAccess: [],
                accountGroupGivingAccess: [],
                chosenSensors: [],
                locations: {
                    countryLongName: [],
                    administrativeAreaLvl1: [],
                    administrativeAreaLvl2: [],
                    locality: [],
                },
                coordinates: []
            }
        },
        isEditMode() {
            return this.accountGroupToEdit !== null
        },
        limitAccessFromExistingAccesses(accesses) {
            let sensorsAccess = accesses.sensors
            let locations = accesses.locations;
            let accountGroups = accesses.accountGroups;

            for (let i = 0; i < locations.length; i++) {
                let item = locations[i]
                this.excludedLocations.push(item)
            }

            if (sensorsAccess.length > 0) {
                let sensors = sensorsAccess.map(a => a.accesses.map(b => b.sensor.id))
                for (let j = 0; j < sensors.length; j++) {
                    for (let k = 0; k < sensors[j].length; k++) {
                        this.excludedSensors.push(sensors[j][k])
                    }
                }
            }

            if (accountGroups.length > 0) {
                for (let j = 0; j < accountGroups.length; j++) {
                    this.excludedAccountGroups.push(accountGroups[j].id)
                }
            }
        },
        removeGps(item) {
            this.formData.coordinates.forEach((coordinate, key) => {
                if (coordinate[item]) {
                    this.formData.coordinates.splice(key, 1);
                }
            })
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
            getAccountGroupsByAccountId: 'getAccountGroupsByAccountId',
            getAccountsByAccountIdNotChosen: 'getAccountsByAccountIdNotChosen',
            getAccountsById: 'getAccountsById',
            getAccountGroupById: 'getAccountGroupById'
        }),
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
            sensorsNotAssociated: 'sensorsNotAssociated',
            paginator: 'paginator',
            getLocationsAvailable: 'getLocationsAvailable',
        }),
        ...Vuex.mapGetters('dataAccess', {
            getDataAccess: 'getDataAccess',
            getDataAccessByGroup: 'getDataAccessByGroup',
            isDataAccessLoading: 'isDataAccessLoading',
        }),
        isLocationsSet() {
            let locations = this.formData.locations;
            return locations.administrativeAreaLvl2.length > 0 ||
                locations.administrativeAreaLvl1.length > 0 ||
                locations.countryLongName.length > 0 ||
                locations.locality.length > 0

        },
        isStepOk() {
            if (this.formStep === 0) {
                return true
            } else if (this.formStep === 1) {
                return this.formData.accountGroupLookingForAccess.length > 0
            } else if (this.formStep === 2) {
                return this.formData.accountGroupLookingForAccess.length > 0 ||
                    this.formData.chosenSensors.length > 0 ||
                    this.formData.locations.length > 0
            } else if (this.formStep === 3) {
                return this.formData.accessType !== null
            } else {
                return false
            }
        },
        groupsBasedOnChosenAccounts() {
            return this.getAccountGroupsByAccountId([this.formData.accountLookingForAccess, this.formData.accountGroupGivingAccess])
        },
        groupsBasedOnChosenAccountsGiver() {
            return this.getAccountGroupsByAccountId([this.formData.accountGivingAccess, this.formData.accountGroupLookingForAccess])
        },
        getFilteredLocations() {
            if (this.chosenLocationType && this.getLocationsAvailable(this.chosenLocationType).length > 0) {
                let accesses = this.excludedLocations.map(a => a.accesses)

                let reduceAccessOnLocationType =
                    accesses.reduce((acc, cur) => ({
                        ...acc,
                        [this.chosenLocationType]: cur[this.chosenLocationType]
                    }), [])
                if (accesses.length > 0 && reduceAccessOnLocationType[this.chosenLocationType] && Object.keys(reduceAccessOnLocationType).length > 0) {
                    return this.getLocationsAvailable(this.chosenLocationType).filter(name => !reduceAccessOnLocationType[this.chosenLocationType].includes(name) && name.startsWith(this.locationTypeFilter))
                } else {
                    return this.getLocationsAvailable(this.chosenLocationType)
                }
            } else {
                return []
            }
        },
        getAccountsInsteadMine() {
            const excludedAccount = this.formData.accountLookingForAccess;
            let accounts = [];
            this.accounts.forEach((account) => {
                if (account.id !== excludedAccount || account.accountGroups.length > 1) {
                    accounts.push(account);
                }
            })
            return accounts;
        },
        getAccountGroupsByAccountIdInsteadMine() {
            let accounts = this.accounts.filter(account => this.formData.accountGivingAccess.includes(account.id))
            let groups = []
            for (let i = 0; i < accounts.length; i++) {
                for (let y = 0; y < accounts[i].accountGroups.length; y++) {
                    if (!this.formData.accountGroupLookingForAccess.includes(accounts[i].accountGroups[y].id)) {
                        groups.push(accounts[i].accountGroups[y])
                    }
                }
            }
            return groups
        },
    },
    async created() {
        this.setAccounts();

        if (Object.keys(this.passedAccount).length > 0) {
            this.formData.accountLookingForAccess.push(this.passedAccount.id)
        }
    },
    watch: {
        'formData.accountGroupLookingForAccess': {
            async handler(val) {
                if (this.accountGroupToEdit === null && val !== undefined && this.formData.accountGroupLookingForAccess[0] !== undefined) {
                    await this.setDataAccessByAccountGroup(this.formData.accountGroupLookingForAccess[0])
                    let accesses = this.getDataAccessByGroup
                    this.limitAccessFromExistingAccesses(accesses)
                }
            },
            deep: true
        },
        addAllAccountGroupsReceiver: function (val) {
            if (val) {
                let groups = []
                for (let i = 0; i < this.groupsBasedOnChosenAccounts.length; i++) {
                    groups.push(this.groupsBasedOnChosenAccounts[i].id)
                }
                this.formData.accountGroupLookingForAccess = groups
            } else {
                this.formData.accountGroupLookingForAccess = []
            }
        },
        addAllAccountGroupsGiver: function (val) {
            if (val) {
                let groups = []
                for (let i = 0; i < this.groupsBasedOnChosenAccountsGiver.length; i++) {
                    groups.push(this.groupsBasedOnChosenAccountsGiver[i].id)
                }
                this.formData.accountGroupGivingAccess = groups
            } else {
                this.formData.accountGroupGivingAccess = []
            }
        },
        chosenRessourcesCategory: function (val) {
            if (val === 'sensor' || val === 'location') {
                this.querySensor()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#body {
    background-color: grey;
}

.categoryCard {
    background-color: $orange;
    color: white;
    cursor: pointer;
    padding: 10px 15px;

    &.active {
        background-color: white;
        color: $orange;
        border: $orange 1px solid;
    }
}

.dataAccessChoiceCard {
    background-color: $light-grey;
}

.dataAccessFormTitle {
    font-size: 1.2rem;
}

.dataAccessFormSubTitle {
    font-size: 1.1rem;
}

.shadow-complete-data-access {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.shadow-edit-data-access {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>
