<template>
    <div class="row align-items-center align-content-center">
        <div class="col-3 my-5">
            <b-button @click="$router.go(-1)" class="clr-white font-weight-bolder">RETOUR</b-button>
        </div>
        <div class="text-center col-6 my-5">
            <h2>Suivi des Offset</h2>
        </div>
        <div class="col-7 mb-5 row mx-auto justify-content-around data-filters">
            <b-form-datepicker v-model="startAt" locale="fr" class="col-4" placeholder="Date de début"
                               required></b-form-datepicker>
            <b-form-datepicker v-model="endAt" locale="fr" class="col-4" placeholder="Date de fin"
                               required></b-form-datepicker>
            <b-button @click="updateData" class="clr-white bg-dark-blue font-weight-bolder col-2">Filtrer</b-button>
        </div>
        <div class="col-xl-8 mx-auto">
            <daily-offset-chart
                id="customChart"
                :options="options"
                :chart-data="chartData"
                :key="key"></daily-offset-chart>
        </div>
    </div>
</template>

<script>
import DailyOffsetChart from "@/components/chart/SensoryOffsetChart";
import Vuex from "vuex";

export default {
    name: "SensorOffsetHistory",
    components: {
        DailyOffsetChart
    },
    data() {
        return {
            dataCollection: {
                labels: [],
                datasets: []
            },
            options: [],
            key: 0,
            startAt: null,
            endAt: null
        }
    },
    props: {
        type: String,
    },
    computed: {
        chartData() {
            return this.dataCollection
        },
        ...Vuex.mapGetters('sensor', {
            actualOffsetHistory: "actualOffsetHistory"
        }),
        loadingProgression() {
            return this.progress
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setSensorOffsetHistory: "setSensorOffsetHistory"
        }),
        setOptions() {
            this.options = {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: true
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            // Disabled rotation for performance
                            autoSkipPadding: 10,
                            autoSkip: true,
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                gridLines: {
                    display: false
                },
            }
        },
        setData: function () {
            let labels = [];
            let v1 = [];
            let v2 = [];
            let v3 = [];
            let v4 = [];

            for (let i = 0; i < this.actualOffsetHistory.length; i++) {
                labels.push(this.actualOffsetHistory[i].date)
                v1.push(this.actualOffsetHistory[i].offsetv1)
                v2.push(this.actualOffsetHistory[i].offsetv2)
                v3.push(this.actualOffsetHistory[i].offsetv3)
                v4.push(this.actualOffsetHistory[i].offsetv4)
            }


            this.dataCollection = {
                labels: labels,
                datasets: [
                    {
                        label: 'Voie1',
                        data: v1,
                        backgroundColor: "transparent",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        pointBackgroundColor: "rgba(171, 71, 188, 1)"
                    },
                    {
                        label: 'Voie2',
                        backgroundColor: "transparent",
                        borderColor: "rgb(1,188,70)",
                        pointBackgroundColor: "rgb(234,255,1)",
                        data: v2
                    },
                    {
                        label: 'Voie3',
                        backgroundColor: "transparent",
                        borderColor: "rgb(255,144,30)",
                        pointBackgroundColor: "rgb(137,43,26)",
                        data: v3
                    },
                    {
                        label: 'Voie4',
                        backgroundColor: "transparent",
                        borderColor: "rgb(255,221,3)",
                        pointBackgroundColor: "rgb(194,5,109)",
                        data: v4
                    }
                ]
            }
        },
        async updateData() {
            let query = {sensorId: this.$route.params.id, data: {}}
            if (this.startAt) query.data.startAt = this.startAt;
            if (this.endAt) query.data.endAt = this.endAt;
            await this.setSensorOffsetHistory(query)
            await this.setOptions();
            await this.setData();
        }
    },
    async created() {
        await this.setSensorOffsetHistory({sensorId: this.$route.params.id})
        await this.setOptions();
        await this.setData();
    },
}
</script>

<style lang="scss" scoped>
.data-filters {
    background-color: #ececec;
    border-radius: 10px;
    padding: 20px;
}
</style>
