import axios from "../../plugins/axios";
const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
	getSensors,
	getSensorsList,
	getAdminSensors,
	createSensors,
	updateSensor,
	destockSensor,
	stockSensor,
	deleteSensor,
	getSensorHistories,
	getSensorAlerts,
	addSensorNote,
	editStatus,
	updateAddress,
	getSensorOperationRate,
	getSensorOffsetHistories,
	getIndividualSensors,
	replaceSensorV2,
	getSensorDataAccess,
	changeSensorAccount,
	getAvailableAddresses,
	createNewReturningSheet,
	getReturningSheet,
	updateReturningSheet
}
const prefixUrl = '/sensors';
const adminPrefixUrl = '/admin/sensors';
const prefixHistory = '/histories'

function getSensors(data) {
	data.isFullData = true;
	return axios.get(adminPrefixUrl, {params: data, ...authHeader.classicalHeader()})
}
function getSensorsList() {
	return axios.get(adminPrefixUrl+'?'+querystring.stringify({isFullData: false}),authHeader.classicalHeader())
}

function getIndividualSensors(data) {
	return axios.get(prefixUrl+'/'+data,authHeader.classicalHeader())
}

function getAdminSensors() {
	return axios.get('/admin'+prefixUrl,authHeader.classicalHeader())
}

function getSensorHistories(data) {
	return axios.get(prefixHistory + '/' + data,authHeader.classicalHeader())
}
function getSensorAlerts(data) {
	return axios.get(prefixUrl+'/'+data.sensor+'/alerts'+'?'+querystring.stringify(data.paginator),authHeader.classicalHeader())
}

// New function to creates multiple pollen sensors
function createSensors(data) {
	return axios.post(`${adminPrefixUrl}/${data.type}/create`, data.data, authHeader.formDataHeader())
}

function destockSensor(data) {
	return axios.post(adminPrefixUrl + '/destock', data.data, authHeader.formDataHeader())
}

function stockSensor(data) {
	return axios.post(adminPrefixUrl+'/'+data[0]+'/stock', data[1],authHeader.formDataHeader())
}

function updateSensor(data) {
	return axios.post(prefixUrl+'/'+data[0], data[1],authHeader.formDataHeader())
}

function deleteSensor(data) {
	return axios.delete(adminPrefixUrl+'/'+data,authHeader.classicalHeader())
}

function addSensorNote(data) {
	return axios.post(prefixHistory + '/create',data,authHeader.formDataHeader())
}

function editStatus(data){
	return axios.post(prefixHistory + '/' + data[0], data[1], authHeader.formDataHeader())
}
function updateAddress(data) {
	return axios.post('/sensors-address/'+data[0],data[1],authHeader.formDataHeader())

}

function getSensorOperationRate(data) {
	return axios.get(prefixUrl+'/operation-rate'+'?period='+data.period+'&'+data.sensors,authHeader.classicalHeader())
}

function getSensorOffsetHistories(query) {
	return axios.get(
		prefixUrl+'/'+query.sensorId+'/histories/offset?' + querystring.stringify(query.data),
		authHeader.formDataHeader()
	)
}

function replaceSensorV2(data) {
	return axios.post(adminPrefixUrl+'/replace',data,authHeader.formDataHeader())
}

function getSensorDataAccess(data) {
	return axios.get(adminPrefixUrl+'/'+data+'/accesses',authHeader.classicalHeader())
}

function getAvailableAddresses() {
	return axios.get(adminPrefixUrl + '/addresses',authHeader.classicalHeader())
}

function changeSensorAccount(sensor, data) {
	return axios.post(`${adminPrefixUrl}/${sensor}/accounts/edit`, data, authHeader.formDataHeader())
}

function createNewReturningSheet(data) {
	return axios.post(`${adminPrefixUrl}/returning-sheets/new`, data, authHeader.formDataHeader())
}
function getReturningSheet() {
	return axios.get(`${adminPrefixUrl}/returning-sheets`, authHeader.formDataHeader())
}

function updateReturningSheet(data) {
	return axios.post(`${adminPrefixUrl}/returning-sheets/`+data[0],data[1], authHeader.formDataHeader())
}