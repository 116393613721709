import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

const state = {
    accountGroups: [],
    loadingStatus: false,
    request:{}
};

const mutations = {
    SET_ACCOUNT_GROUPS: (state, accountGroups) => {
        state.loadingStatus = true
        state.accountGroups = accountGroups;
        state.loadingStatus = false
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request = {status: requestStatus[1], message: requestStatus[0]}
    },
    ACCOUNT_GROUPS_ARE_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus
    },
    CREATE_ACCOUNT: (state, account) => {
        state.loadingStatus = true
        state.accountGroups.push(account);
        state.loadingStatus = false
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
};

const actions = {
    setAccountGroups: async (store,data) => {
        await api.account.getAccountsGroups(data)
            .then( response =>  {
                store.commit('SET_ACCOUNT_GROUPS', response.data.data);
            })
    },
    emptyAccountsGroup: async (store) => {
        store.commit('SET_ACCOUNT_GROUPS', {});
        store.commit('ACCOUNT_GROUPS_ARE_LOADING_STATUS');
    },
    createAccountGroup: async (store,data) => {
        await api.account.createAccountGroup(data)
            .then( response =>  {
                // store.commit('SENSORS_IS_LOADING_STATUS');
                store.commit('CREATE_ACCOUNT', response.data.data);
                store.commit('SET_REQUEST', response.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    editAccountGroup: async (store,data) => {
        await api.account.updateAccountGroup(data)
            .then( response =>  {
                store.commit('SET_REQUEST', response.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
    },
    deleteAccountGroup: async (store,data) => {
        await api.account.deleteAccountGroup(data)
            .then( response =>  {
                store.commit('SET_REQUEST', response.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
};

const getters = {
    accountGroups: state => {
        return state.accountGroup
    },
    getAccountGroupById: (state) => (id) => {
        return state.accountGroup.find(group => group.id === id)
    },
    loadingStatus : state => {
        return state.loadingStatus
    },
    getRequest : state => {
        return state.request
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
