<template>
  <div class="row align-items-center align-content-center">
    <div class="col-xl-3 my-5">
      <b-button @click="$router.go(-1)" class="clr-white font-weight-bolder">RETOUR</b-button>
    </div>
    <div class="text-center col-xl-6 my-5">
      <h2>Suivi des Taux de fonctionnement du capteur {{ $route.params.id }}</h2>
    </div>
    <div class="row col-xl-8 mx-auto justify-content-center align-content-center" style="background-color: #ececec;border-radius: 10px;padding: 2px">
      <div class="mb-3 col-xl-3">
        Periode :
        <b-form-select v-model="periodSelected" :options="optionsChart">
        </b-form-select>
      </div>
      <div class="mb-3 offset-xl-1 col-xl-8 " v-if="sensorsOptions">
        Rerchercher un capteur à ajouter
        <!--(Maintenir "maj" pour selection multiple ou "ctrl" pour selection spécifique) :-->
<!--        <b-form-select v-model="sensorsSelected" multiple :options="sensorsOptions" style="max-width: 500px;">-->
<!--        </b-form-select>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
        <vue-bootstrap-typeahead
            :data="sensorsOptions"
            v-model="searchInput"
            size="sm"
            :min-matching-chars="1"
            :serializer="s => s.text"
            placeholder="Numéro de série ou Compte Client"
            @hit="addSensor($event.value)"
            @keydown.enter="addSensor($event.value)"
            style="max-height: 80px"
            :key="key"
        ></vue-bootstrap-typeahead>
      </div>
        <div class="mb-3 col-xl-12 " v-if="sensorsOptions">
          Capteurs Selectionné(s)
          <b-form-tags
              v-model="sensorsSelected"
              separator="  "
              placeholder=" "
              remove-on-delete
              no-add-on-enter
              tag-pills
              tag-variant="primary"
              style="border: none"
          ></b-form-tags>
        </div>

    </div>

    <div class="col-xl-8 mx-auto">
      <SensorOpetationRateChart
          id="customChart"
          :options="options"
          :chart-data="chartData"
          :key="key"
      ></SensorOpetationRateChart>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";
import moment from "moment";
import SensorOpetationRateChart from "@/components/chart/SensorOpetationRateChart";

export default {
  name: "SensorOperationRateHistory",
  components: {
    SensorOpetationRateChart,
  },
  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: []
      },
      key: 0,
      optionsChart: [
        {value: 'week', text: 'Semaine'},
        {value: 'month', text: 'Mois'},
      ],
      periodSelected: 'week',
      options: [],
      sensorsOptions: [],
      sensorsSelected: [this.$route.params.id],
      colorLoop: 0,
      chartColors: ["#00876c", "#6dae7c", "#b6d494", "#fff9b7", "#f5c17a", "#ea8357", "#d43d51"],
      searchInput:''
    }
  },
  props: {
    type: String,
  },
  computed: {
    chartData() {
      return this.dataCollection
    },
    ...Vuex.mapGetters('sensor', {
          sensorsRate: "sensorsRate",
          individualSensor: "individualSensor",
          getSensorsIds: "getSensorsIds",
    }),
    loadingProgression() {
      return this.progress
    },
    actualSensor(){
      return this.individualSensor;
    }

  },
  methods: {
    ...Vuex.mapActions('sensor', {
      setOperationRateData: "setOperationRateData",
      setSensors: "setSensors",
      setIndividualSensors: "setIndividualSensors"
    }),
    setOptions() {
      this.options = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMax: 80,
            },
            gridLines: {
              display: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Taux de fonctionnement en pourcentage(%)'
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: 'Date de du relevé'
            }
          }]
        },
        legend: {
          display: true
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            // eslint-disable-next-line no-unused-vars
            label: function (tooltipItems, data) {
              return 'Taux de Fonctionnement : ' + tooltipItems.yLabel;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              borderColor: "#4ecca3",
              value: 70,
              borderDash: [4, 4],
              label: {
                content: 'Limite de fonctionnement idéale : En dessous, nécéssité de vérifier la dispersion des données',
                enabled: true,
                position: "top",
                backgroundColor: '#4ecca3',
                fontSize: 10,
              }
            },
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              borderColor: '#ff8400',
              value: 35,
              borderDash: [4, 4],
              label: {
                content: 'Limite Critique : Données Inexploitables en dessous',
                enabled: true,
                position: "top",
                backgroundColor: '#ff8400',
                fontSize: 10,
              }
            }
          ]
        }
      }
    },
    groupByKey(array) {
      return array
          .reduce((r, a) => {
            r[a.serialNumber] = [...r[a.serialNumber] || [], a];
            return r;
          }, {});
    },
    async setRate() {
      let query = this.generateQueryString()
      await this.setOperationRateData(query);
    },
    generateQueryString() {
      // let query = new URLSearchParams();
      let sensors = this.sensorsSelected
      const parameterizeArray = (key, arr) => {
        arr = arr.map(encodeURIComponent)
        return key + '[]=' + arr.join('&' + key + '[]=')
      }

      let period = this.periodSelected

      return {
        period: period,
        sensors: parameterizeArray('sensors', sensors)
      }
    },
    setData: async function () {
      if (this.getSensorsIds.length === 0) {
        await this.setSensors({isFullData:true, paginated: false})
      }

      const value = this.groupByKey(this.sensorsRate)
      let labels = []
      let datasets = []
      let labelsOnce = 0;
      for (const item in value) {
        this.colorLoop += 1
        let data = []
        for (let i = 0; i < value[item].length; i++) {
          if (labelsOnce < 1) {
            labels.push(moment(value[item][i].createdAt).format('LL'))
          }
          data.push(value[item][i].rate)

        }
        labelsOnce++
        let graphInfo = {value: '', text: ''};

        if(this.actualSensor.dataReference.accountEquipment !== null){
          graphInfo = {
            text: this.actualSensor.dataReference.accountEquipment.account.name + ' - ' + this.actualSensor.serialNumber,
            value: this.actualSensor.serialNumber
          }
        }else{
          graphInfo = {
            text: 'compte inconnu - ' + this.actualSensor.serialNumber,
            value: this.actualSensor.serialNumber
          }
        }
        if (this.colorLoop > 7) {
          const randomColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
          this.chartColors.push(randomColor)
        }
        datasets.push(
            {
              label: graphInfo.text,
              data: data,
              backgroundColor: this.chartColors[this.colorLoop],
              borderColor: "transparent",
              pointBackgroundColor: this.chartColors[this.colorLoop]
            }
        )
      }

      this.dataCollection = {
        labels: labels,
        datasets: datasets
      }
    },
    addSensor(sensorID){
      this.searchInput = ''
      this.sensorsSelected.push(sensorID)
    }
  },
  async mounted() {
    if (this.getSensorsIds.length === 0) {
        this.setSensors({isFullData:true})
    }
    await this.setIndividualSensors(this.sensorsSelected);
    this.sensorsOptions = this.getSensorsIds
    await this.setRate();
    await this.setOptions();
    await this.setData();

  },
  watch: {
    'periodSelected': async function () {
      this.colorLoop = 0
      await this.setRate();
      await this.setOptions();
      await this.setData();

      this.key += 1
    },
    'sensorsSelected': async function () {
      this.colorLoop = 0
      await this.setRate();
      await this.setOptions();
      await this.setData();
      this.searchInput = ''

      this.key += 1
    },
  }
}
</script>

<style scoped>

</style>