import DataGenerator from "@/views/DataGenerator";
import IframeGenerator from "@/views/IframeGenerator";

export default [
    {
        path: '/generator/data',
        component: DataGenerator,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Generateur de données'
                }
            ]
        }
    },
    {
        path: '/generator/iframe',
        name: 'IframeGenerator',
        component: IframeGenerator,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Generateur de Widget'
                }
            ]
        },
    },
];