<template>
    <b-col cols="12" class="" v-if="!isLoading">
        <b-row class="justify-content-center mt-3">
            <b-col cols="12">
                <h3 class="clr-lifyair text-center">
                    Synthèse du Territoire
                </h3>
            </b-col>
            <b-col cols="12">
                <h4 class="clr-dark-grey">
                    Vue Pollen
                </h4>
            </b-col>
        </b-row>
        <b-row class="my-3">
            <b-col cols="12" class="my-3">
                <div
                    class="shadow bg-white rounded global-chart-display p-2 w-100 h-100"
                    v-if="presenceOfPollenHeatMapSeries.length > 0 && !isLoading"
                >
                    <HeatMapChart
                        :series="presenceOfPollenHeatMapSeries"
                        :chartOptions="presenceOfPollenHeatMapOptions"
                        :width="'95%'"
                        :height="'100%'"
                        class="p-3 w-100 h-100"
                        :key="200"
                    >
                    </HeatMapChart>
                </div>
                <div class="d-flex shadow bg-white rounded scrollable global-chart-display p-2 justify-content-center align-content-center align-items-center" v-else-if="presenceOfPollenHeatMapSeries.length === 0 && !isLoading">
                    <div>
                        <h2>
                            {{
                                'Présence Pollinique comparative (année sélectionnée)'
                            }}
                        </h2>
                        <h1 class="clr-orange">
                            NO DATA
                        </h1>
                    </div>
                </div>
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-else>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </div>
            </b-col>
            <b-col cols="12" class="my-3">
                <div
                    class="shadow bg-white rounded global-chart-display p-2 w-100 h-100"
                    v-if="presenceOfPollenComparedHeatMapSeries.length > 0 && !isLoading"
                >
                    <HeatMapChart
                        :series="presenceOfPollenComparedHeatMapSeries"
                        :chartOptions="presenceOfPollenComparedHeatMapOptions"
                        :width="'95%'"
                        :height="'100%'"
                        class="p-3 w-100 h-75"
                        :key="100"
                    >
                    </HeatMapChart>
                </div>
                <div class="d-flex shadow bg-white rounded scrollable global-chart-display p-2 justify-content-center align-content-center align-items-center" v-else-if="presenceOfPollenComparedHeatMapSeries.length === 0 && !isLoading">
                    <div class="text-center justify-content-center">
                        <h2>
                            {{
                                'Présence Pollinique comparative (année précédente)'
                            }}
                        </h2>
                        <h1 class="clr-orange">
                            NO DATA
                        </h1>
                    </div>
                </div>
                <div class="shadow bg-white rounded scrollable global-chart-display p-2" v-else>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </div>
            </b-col>
        </b-row>
    </b-col>
    <b-col cols="12" v-else>
        <b-row class="justify-content-center align-content-center align-items-center mt-3">
            <b-col cols="12" class="text-center">
                <span class="loader"></span>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
import Vuex from "vuex"
import moment from "moment/moment";
import HeatMapChart from "@/components/analytics/HeatMapChart";
export default {
    name: "PollenAnalytics",
    components: {HeatMapChart},
    props: {
        startDate: String,
        endDate: String,
    },
    data() {
        return {
            series: [],
            presenceOfPollenHeatMapSeries: [],
            presenceOfPollenComparedHeatMapSeries: [],
            presenceOfPollenHeatMapOptions: {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show:true
                    }
                },
                plotOptions: {
                    heatmap: {
                        distributed: false,
                        radius: 1,
                        useFillColorAsStroke: false,
                        colorScale: {
                            ranges: [
                                {
                                    name: 'Pas de Pollen',
                                    color: '#f8f7f7',
                                    from: 10,
                                    to: 10
                                },
                                {
                                    name: 'Aucun Risque',
                                    color: '#4BA68D',
                                    from:0,
                                    to:0
                                },
                                {
                                    name: 'Risque Faible',
                                    color: '#F6C90F',
                                    from:1,
                                    to:1
                                },
                                {
                                    name: 'Risque Moyen',
                                    color: '#FF9C5B',
                                    from:2,
                                    to:2
                                },
                                {
                                    name: 'Risque For',
                                    color: '#D9534F',
                                    from:3,
                                    to:3
                                }
                            ]
                        },
                        enableShades: false,
                    }
                },
                yaxis: {
                    type: 'category',
                    labels: {
                        show:true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                title: {
                    text: 'Présence Pollinique sur la période du '+ this.startDate + ' au ' + this.endDate
                },
                tooltip:{
                    y: {
                        formatter: (val) => {
                            let risk = {
                                10:'Risque aucun',
                                0:'Risque aucun',
                                1:'Risque faible',
                                2:'Risque moyen',
                                3:'Risque fort'
                            }
                            return risk[val]
                        }
                    },
                }
            },
            presenceOfPollenComparedHeatMapOptions: {
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show:true
                    }
                },
                plotOptions: {
                    heatmap: {
                        distributed: false,
                        radius: 1,
                        useFillColorAsStroke: false,
                        colorScale: {
                            ranges: [
                                {
                                    name: 'Pas de Pollen',
                                    color: '#f8f7f7',
                                    from: 10,
                                    to:10
                                },
                                {
                                    name: 'Aucun Risque',
                                    color: '#4BA68D',
                                    from:0,
                                    to:0
                                },
                                {
                                    name: 'Risque Faible',
                                    color: '#F6C90F',
                                    from:1,
                                    to:1
                                },
                                {
                                    name: 'Risque Moyen',
                                    color: '#FF9C5B',
                                    from:2,
                                    to:2
                                },
                                {
                                    name: 'Risque Fort',
                                    color: '#D9534F',
                                    from:3,
                                    to:3
                                }
                            ]
                        },
                        enableShades: false,
                    }
                },
                yaxis: {
                    type: 'category',
                    // tickAmount: moment(this.endDate).diff(moment(this.startDate), 'days')+1,
                    // min: moment(this.startDate).format('YYYY-MM-DD'),
                    // max: moment(this.endDate).add(1,'days').format('YYYY-MM-DD'),
                    labels: {
                        show:true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                title: {
                    text: 'Présence Pollinique sur la période du '+
                        moment(this.startDate).subtract(1,'year').format('YYYY-MM-DD') +
                        ' au ' + moment(this.endDate).subtract(1,'year').format('YYYY-MM-DD')
                },
                tooltip:{
                    y: {
                        formatter: (val) => {
                            let risk = {
                                10:'Pas de Pollen',
                                0:'Risque aucun',
                                1:'Risque faible',
                                2:'Risque moyen',
                                3:'Risque fort'
                            }
                            return risk[val]
                        }
                    },
                }
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('analytics',{
            pollenAnalytics: 'pollenAnalytics',
            getSensorList: 'getSensorList',
            isLoading: 'loadingAnalytics'
        })
    },
    methods: {
        setPresenceOfPollenSeries() {
            let data = [];
            this.presenceOfPollenHeatMapSeries = [];
            // let colors = ['#4BA68D','#F6C90F','#FF9C5B','#D9534F']

            if (Object.entries(this.pollenAnalytics.presenceOfPollen).length > 0 ){
                Object.entries(this.pollenAnalytics.presenceOfPollen).forEach((pollen) => {
                    this.individualOptions = []

                    const [key, value] = pollen;
                    let serie = {name: this.$t('pollen.type.' + key), data: []}

                    let numberOfDaysInPeriod = moment(this.endDate).diff(moment(this.startDate), 'days')+1
                    for (let i = 0; i < numberOfDaysInPeriod; i++) {
                        let loopDate = moment(this.startDate).add(i,'days').format('YYYY-MM-DD')
                        if (value.find(item => item[0] === loopDate) !== undefined){
                            serie.data.push({
                                x: value.find(item => item[0] === loopDate)[0],
                                y: value.find(item => item[0] === loopDate)[1],
                            })
                        } else {
                            serie.data.push({
                                x: loopDate,
                                y: 10,
                            })
                        }
                    }

                    data.push(serie)
                })
            }
            this.presenceOfPollenHeatMapSeries = data;
        },
        setPresenceOfPollenComparedSeries() {
            let data = [];
            this.presenceOfPollenComparedHeatMapSeries = [];
            // let colors = ['#4BA68D','#F6C90F','#FF9C5B','#D9534F']

            if (Object.entries(this.pollenAnalytics.presenceOfPollenCompared).length > 0 ){
                Object.entries(this.pollenAnalytics.presenceOfPollenCompared).forEach((pollen) => {
                    this.individualOptions = []
                    const [key, value] = pollen;

                    let serie = {name: this.$t('pollen.type.' + key), data: []}
                    let startDateYearAgo = moment(this.startDate).subtract(1,'year')
                    let endDateYearAgo = moment(this.endDate).subtract(1,'year')
                    let numberOfDaysInPeriod = moment(endDateYearAgo).diff(moment(startDateYearAgo), 'days')+1
                    for (let i = 0; i < numberOfDaysInPeriod; i++) {
                        let loopDate = moment(startDateYearAgo).add(i,'days').format('YYYY-MM-DD')
                        if (value.find(item => item[0] === loopDate) !== undefined){
                            serie.data.push({
                                x: value.find(item => item[0] === loopDate)[0],
                                y: value.find(item => item[0] === loopDate)[1],
                            })
                        } else {
                            serie.data.push({
                                x: loopDate,
                                y: 10,
                            })
                        }
                    }

                    data.push(serie)
                })
            }
            this.presenceOfPollenComparedHeatMapSeries = data;
        },
    },
    created() {
        this.setPresenceOfPollenSeries()
        this.setPresenceOfPollenComparedSeries()
    },
    watch: {
        pollenAnalytics: {
            handler() {
                this.setPresenceOfPollenSeries()
                this.setPresenceOfPollenComparedSeries()
            },
            deep: true
        }
    }
}
</script>


<style lang="scss" scoped>
    .scrollable {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .global-chart-display{
        min-height: 40vh;
        max-height:50vh;
    }

    .loader {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: inline-block;
        border-top: 4px solid #FFF;
        border-right: 4px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    .loader::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border-bottom: 4px solid $dark-blue;
        border-left: 4px solid transparent;
    }
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>