<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Modifier un compte
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    label="Nom du compte :"
                    label-for="account-name"
                >
                    <b-form-input
                        id="account-name"
                        v-model="name"
                        type="text"
                        required
                        placeholder="Client TEST"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Plan"
                    label-for="plan-level"
                >
                    <b-form-select
                        id="plan-level"
                        v-model="plan"
                        :options="optionsPlan">
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    label="Logo"
                    label-for="logo">
                    <b-form-file
                        id="logo"
                        v-model="logo"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <b-img v-if="logo"
                           :src="logoSrc"
                           class="mb-3 p-3 text-center mx-auto"
                           style="width: 128px"
                           fluid block rounded>
                    </b-img>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins";

const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export default {
    name: "AccountEdit",
    data() {
        return {
            accountName: '',
            plan: '',
            logo: null,
            optionsPlan: [
                {value: 'standard', text: 'Standard'},
                {value: 'individual', text: 'Mobile'},
                {value: 'pro', text: 'Entreprise'},
            ],
            logoSrc: null
        }
    },
    methods: {
        ...Vuex.mapActions('account', {
            editAccount: 'editAccount',
            setAccounts: 'setAccounts',
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.editAccount(query).then(() => {
                Toast.fire({
                    icon: 'success',
                    title: this.getRequest.message,
                    timer: 1000
                }).then(() => {
                        this.goToAccountProfile()
                    }
                )
            });
        },
        generateQueryString() {
            let data = []
            data.push(this.$route.params.id)
            let fields = new FormData();
            if (this.name !== this.actualAccount) {
                fields.append('name', this.name)
            }

            if (this.plan) {
                fields.append('planLevel', this.plan)
            }

            if (this.logo) {
                fields.append('logo', this.logo)
            }
            data.push(fields)
            return data
        },
        goToAccountProfile() {
            this.$router.push('/accounts/' + this.$route.params.id + '/information');
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            getAccountsById: 'getAccountsById',
            getRequest: 'getRequest',
            accounts: 'accounts'
        }),
        actualAccount() {
            return this.getAccountsById(parseInt(this.$route.params.id))
        }
    },
    watch: {
        logo(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.logoSrc = value;
                        })
                        .catch(() => {
                            this.logoSrc = null;
                        });
                } else {
                    this.logoSrc = null;
                }
            }
        }
    },
    async created() {
        if (this.accounts.length === 0) {
            await this.setAccounts();
        }
        this.name = this.actualAccount.name
        this.plan = this.actualAccount.planLevel
    }
}
</script>

<style lang="scss" scoped>

</style>
