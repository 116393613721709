<template>
    <b-row class="justify-content-center align-content-center" v-if="isDataAccessLoading">
        <b-col cols="8" class="text-center align-self-center shadow rounded p-5 my-2" >
            <p class="text-center dataAcceFormTitle clr-dark-grey">OUTILS DE CREATION D'ACCES PERSONNALISES</p>
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" class="text-center"></b-spinner>
            <p class="text-center">Vous allez être redirigé !</p>
        </b-col>
    </b-row>
    <b-row class="justify-content-center align-content-center" v-else>
        <b-col cols="8" v-if="formStep === 0" class="text-center align-self-center shadow rounded p-5 my-2" >
            <p class="text-center dataAcceFormTitle clr-dark-grey">OUTILS DE CREATION D'ACCES PERSONNALISES</p>
            <b-button @click="stepUp" class="bg-blue clr-white" size=lg>Commencer</b-button>
        </b-col>
        <b-col  cols="8" v-if="formStep === 1" class=" align-self-center shadow rounded p-5" >
            <p class="text-center dataAcceFormTitle clr-dark-grey">COMPTE(S) BENEFICIANT(S) DE L'ACCES PERSONNALISE</p>
            <b-row class="justify-content-center align-items-center align-content-center my-5">
                <b-col cols="6">
                    <b-form-group label="Veuillez choisir le ou les comptes à qui bénéficieront ces accès :" v-slot="{ ariaDescribedby }" label-for="accounts" class="dataAcceFormSubTitle clr-dark-grey">
                        <b-form-select v-model="formData.accountLookingForAccess" multiple :select-size="4" id="accounts" required :state="formData.accountLookingForAccess.length > 0 ? true : null">
                            <b-form-select-option :aria-describedby="ariaDescribedby"
                                                  :value="account.id" v-for="(account, index) in accounts" :key="index">
                                {{ account.name }}
                            </b-form-select-option>
                        </b-form-select>
                        <b-form-text id="input-live-help" class="clr-light-grey">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
                    </b-form-group>
                    <h3>
                        <b-badge size="lg" class="mr-1" variant="primary" v-for="(account,index) in accounts.filter(item => formData.accountLookingForAccess.includes(item.id))" :key="index"> {{account.name}}  </b-badge>
                    </h3>
                </b-col>
            </b-row>
        </b-col>
        <b-col  cols="8" v-if="formStep === 2" class="align-self-center shadow rounded p-5" >
            <p class="text-center dataAcceFormTitle clr-dark-grey">CHOIX DU OU DES SOUS COMPTE(S) BENEFICIANT(S) DE L'ACCES</p>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-form-group label="Veuillez choisir le ou les groupe(s) compte(s) qui bénéficieront de cet accès :" v-slot="{ ariaDescribedby }" label-for="accounts" class="dataAcceFormSubTitle clr-dark-grey">
                        <p class="d-flex d-inline clr-dark-blue"> Tout sélectionner :
                            <b-form-checkbox v-model="addAllAccountGroupsReceiver" name="check-button" switch class="ml-2"></b-form-checkbox>
                        </p>
                        <b-form-select v-model="formData.accountGroupLookingForAccess" multiple :select-size="4" id="accounts" required :state="isStepOk ? true : null">
                            <b-form-select-option :aria-describedby="ariaDescribedby"
                                                  :value="accountGroup.id" v-for="(accountGroup, index) in groupsBasedOnChosenAccounts" :key="index">
                                {{ accountGroup.name }}
                            </b-form-select-option>
                        </b-form-select>
                        <b-form-text id="input-live-help" class="">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
                    </b-form-group>
                    <h3>
                        <b-badge class="mr-1" variant="primary" v-for="(account,index) in groupsBasedOnChosenAccounts.filter(item => formData.accountGroupLookingForAccess.includes(item.id))" :key="index"> {{account.name}}  </b-badge>
                    </h3>
                </b-col>
            </b-row>
<!--            <b-col cols="12" class="align-self-center align-content-center align-items-center text-center">-->
<!--                <b-button class="mr-1 text-center" @click="stepDown">Précédent</b-button>-->
<!--                <b-button class="text-center" @click="stepUp" :class="isStepOk ? 'bg-blue clr-white' : ''">Valider</b-button>-->
<!--            </b-col>-->
        </b-col>
        <b-col  cols="8" v-if="formStep === 3" class="shadow p-5" >
            <b-col>
                <p class="dataAcceFormTitle">CHOIX DES RESSOURCES POUR LESQUELLES L'ACCES VA ETRE AUTORISEES</p>
            </b-col>
            <b-row class="m-0 p-0 align-content-center justify-content-center align-items-center my-5">
                <b-col cols="12">
                    <p class="clr-dark-blue dataAcceFormSubTitle">
                        Choix d'une catégorie de ressource :
                        <br><span class="clr-light-grey font-weight-light">(il est possible de choisir des items dans les différentes catégroies à la fois)</span>
                    </p>
                </b-col>
                <b-col cols="4" class="d-flex justify-content-center align-content-center align-items-center " @click="setChosenRessourcesCategory('account')">
                    <div class="w-75 text-center rounded categoryCard mr-1 d-flex justify-content-center align-content-center align-items-center" :class="chosenRessourcesCategory === 'account' ? 'active':''">
                        <p class="m-0">ACCOUNT GROUPS</p>
                    </div>
                </b-col>
                <b-col cols="4" class="d-flex justify-content-center align-items-center" @click="setChosenRessourcesCategory('sensor')">
                    <div class="w-75 text-center rounded categoryCard mr-1 d-flex justify-content-center align-items-center" :class="chosenRessourcesCategory === 'sensor' ? 'active':''">
                        <p class="m-0">SENSORS</p>
                    </div>
                </b-col>
                <b-col cols="4" class="d-flex justify-content-center align-items-center" @click="setChosenRessourcesCategory('location')">
                    <div class="w-75 text-center rounded categoryCard mr-1 d-flex justify-content-center align-items-center" :class="chosenRessourcesCategory === 'location' ? 'active':''">
                        <p class="m-0">LOCATION</p>
                    </div>
                </b-col>
            </b-row>
            <b-col v-if="chosenRessourcesCategory === 'account'" class="bg-lighter-grey">
                <b-row class="align-items-center">
                    <b-col offset="2" cols="8" class="my-5">
                        <p class="m-0 p-0"><b>COMPTE</b></p>
                        <b-form-group label="Veuillez choisir le ou les comptes qui donnerons cet accès :" v-slot="{ ariaDescribedby }" label-for="accounts" class="dataAcceFormSubTitle clr-dark-grey">
                            <b-form-select v-model="formData.accountGivingAccess" multiple :select-size="4" id="accounts" required :state="formData.accountGivingAccess.length > 0 ? true : null">
                                <b-form-select-option :aria-describedby="ariaDescribedby"
                                                      :value="account.id" v-for="(account, index) in getAccountsByAccountIdNotChosen([formData.accountGroupLookingForAccess])" :key="index">
                                    {{ account.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1" variant="primary" v-for="(account,index) in accounts.filter(item => formData.accountGivingAccess.includes(item.id))" :key="index"> {{account.name}}  </b-badge>
                        </h3>
                    </b-col>
                    <b-col offset="2" cols="8" v-if="formData.accountGivingAccess.length > 0" class="my-5">
                        <p class="m-0 p-0"><b>GROUPE</b></p>
                        <b-form-group label="Veuillez choisir le ou les groupe(s) de comptes qui donneront cet accès :" v-slot="{ ariaDescribedby }" label-for="accounts" class="dataAcceFormSubTitle clr-dark-grey">
                            <p class="d-flex d-inline clr-dark-blue"> Tout sélectionner :
                                <b-form-checkbox v-model="addAllAccountGroupsGiver" name="check-button" switch class="ml-2"></b-form-checkbox>
                            </p>
                            <b-form-select v-model="formData.accountGroupGivingAccess" multiple :select-size="4" id="accounts" required :state="formData.accountGroupGivingAccess.length > 0 ? true : null">
                                <b-form-select-option :aria-describedby="ariaDescribedby"
                                                      :value="account.id" v-for="(account, index) in getAccountGroupsByAccountId([formData.accountGivingAccess,formData.accountLookingForAccess])" :key="index">
                                    {{ account.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1" variant="primary" v-for="(account,index) in groupsBasedOnChosenAccountsGiver.filter(item => formData.accountGroupGivingAccess.includes(item.id))" :key="index"> {{account.name}}  </b-badge>
                        </h3>
                    </b-col>
                </b-row>
            </b-col>
            <b-col v-if="chosenRessourcesCategory === 'sensor'" class="bg-lighter-grey">
                <b-row class="justify-content-center align-content-center p-5">
                    <b-col cols="8">
                        <b-form-group label="Veuillez choisir le ou les sensors qui donnerons cet accès :" v-slot="{ ariaDescribedby }" label-for="sensors">
                            <b-input-group class="my-2" v-on:submit.prevent="querySensor"
                                           v-on:keyup.enter="querySensor">
                                <b-form-input v-model="search" placeholder="Recherche par numéro de série ou Nom de Compte"></b-form-input>
                                <b-input-group-append>
                                    <b-button type="submit" variant="primary" @click="querySensor">Go</b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-select v-model="formData.chosenSensors" multiple :select-size="4" id="sensors" required :state="formData.chosenSensors.length > 0 ? true : null">
                                <b-form-select-option :aria-describedby="ariaDescribedby"
                                                      :value="sensor.id" v-for="(sensor, index) in sensors" :key="index">
                                    {{ sensor.serialNumber }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1" variant="primary" v-for="(sensor,index) in sensors.filter(item => formData.chosenSensors.includes(item.id))" :key="index"> {{sensor.serialNumber}}  </b-badge>
                        </h3>
                    </b-col>
                </b-row>
            </b-col>
            <b-col v-if="chosenRessourcesCategory === 'location'" class="bg-lighter-grey">
                <b-row class="justify-content-center align-items-center">
                    <b-col cols="8">
                        <b-row class="m-0 p-0 align-content-center justify-content-center align-items-center my-5">
                            <b-col cols="12">
                                <p class="clr-dark-blue dataAcceFormSubTitle">
                                    Choix d'une catégorie de Lieux :
                                    <br><span class="clr-light-grey font-weight-light">(il est possible de choisir des items dans les deux catégroies à la fois)</span>
                                </p>
                            </b-col>
                            <b-col cols="6" class="d-flex justify-content-center align-content-center align-items-center " @click="switchLocationToCoordinate = !switchLocationToCoordinate">
                                <div class="w-100 text-center rounded categoryCard mr-1 d-flex justify-content-center align-content-center align-items-center" :class="!switchLocationToCoordinate ? 'active':''">
                                    <p class="m-0">Point d'interets administratifs</p>
                                </div>
                            </b-col>
                            <b-col cols="6" class="d-flex justify-content-center align-content-center align-items-center " @click="switchLocationToCoordinate = !switchLocationToCoordinate">
                                <div class="w-100 text-center rounded categoryCard mr-1 d-flex justify-content-center align-content-center align-items-center" :class="switchLocationToCoordinate ? 'active':''">
                                    <p class="m-0">Coordonnées GPS</p>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="align-items-center justify-content-center" v-if="!switchLocationToCoordinate">
                    <b-col cols="6">
                        <b-form-group label="Choisir un accés par Lieux (Pays, Région, Département, Ville)" label-for="sensors">
                            <b-form-select v-model="chosenLocationType" :options="locationTypesOptions"></b-form-select>
                            <b-input-group class="my-2">
                                <b-form-input v-model="locationTypeFilter" placeholder="Recherche par nom du lieux"></b-form-input>
                            </b-input-group>
                            <b-form-select v-model="formData.locations[chosenLocationType]" multiple :select-size="4" id="sensors" required>
                                <b-form-select-option
                                                      :value="location" v-for="(location, index) in getFilteredLocations" :key="index">
                                    {{ location }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.</b-form-text>
                        </b-form-group>
                        <p>
                            Ville(s) Selectionnée(s) :
                            <b-badge class="mr-1" variant="primary" v-for="(item,index) in formData.locations.countryLongName" :key="index"> {{item}}  </b-badge>
                        </p>
                        <p>
                            Région(s) Selectionné(s) :
                            <b-badge class="mr-1" variant="primary" v-for="(item,index) in formData.locations.administrativeAreaLvl1" :key="index"> {{item}}  </b-badge>
                        </p>
                        <p>
                            Département(s) Selectionné(s) :
                            <b-badge class="mr-1" variant="primary" v-for="(item,index) in formData.locations.administrativeAreaLvl2" :key="index"> {{item}}  </b-badge>
                        </p>
                        <p>
                            Ville(s) Selectionné(s) :
                            <b-badge class="mr-1" variant="primary" v-for="(item,index) in formData.locations.locality" :key="index"> {{item}}  </b-badge>
                        </p>
                    </b-col>
                </b-row>
                <b-row class="align-items-center justify-content-center" v-else>
                    <b-col cols="6">
                        <b-form @submit.prevent="setNewLocation()" class="mb-1">
                            <b-form-group label="Nom du lieux associé aux coordonnées :" label-for="coordinates">
                                <b-input-group class="my-1">
                                    <b-form-input v-model="coordinateName" placeholder="Ex: Gare SNCF Orléans"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Latitude :">
                                <b-input-group class="my-2">
                                    <b-form-input v-model="coordinateLat" placeholder="Ex : 1.9"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Longitude :">
                                <b-input-group class="my-2">
                                        <b-form-input v-model="coordinateLon" placeholder="Ex : 47.3"></b-form-input>
                                    </b-input-group>
                            </b-form-group>
                            <b-button type="submit" variant="primary" class="clr-white text-center">Ajouter les coordonnées</b-button>
                        </b-form>
                        <p class="mt-5">
                            Point(s) d'interêt(s) ajoutée(s) :
                        </p>
                        <p v-for="(item,index) in formData.coordinates" :key="index">
                            <b-badge class="mr-1" variant="primary">{{ Object.keys(item)[0] | capitalize }}</b-badge> - Coordonnées: [{{ item[Object.keys(item)[0]][0] }} , {{ item[Object.keys(item)[0]][1] }}]
                        </p>
                    </b-col>
                </b-row>
            </b-col>
        </b-col>
        <b-col  cols="8" v-if="formStep === 4" class=" align-self-center shadow rounded p-2" >
            <p class="text-center dataAcceFormTitle clr-dark-grey">RESUME DE LA CREATION D'ACCESS</p>
            <b-row class="justify-content-center my-5">
                <b-col offset="1" cols="5" class="my-2">
                    <p class="clr-dark-grey">RESSOURCES QUI VONT RECEVOIR LES ACCES</p>
                    <div v-if="formData.accountLookingForAccess.length > 0">
                        <p>Les comptes:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(accountReceiver,key1) in formData.accountLookingForAccess" :key="key1">
                                {{ getAccountObjectById(accountReceiver).name }}
                            </span>
                        </p>
                    </div>
                    <div v-if="!isEditMode() && getAccountGroupsByAccountId([formData.accountLookingForAccess,formData.accountGroupLookingForAccess]).length >0">
                        <p>Les groupes:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(groupReceiver,key2) in getAccountGroupById(formData.accountGroupLookingForAccess)" :key="key2">
                                {{groupReceiver.name}}
                            </span>
                        </p>
                    </div>
                    <div v-else-if="isEditMode() && getAccountGroupById([accountGroupToEdit]).length >0">
                        <p>Les groupes:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(groupReceiver,key2) in getAccountGroupById([accountGroupToEdit])" :key="key2">
                                {{groupReceiver.name}}
                            </span>
                        </p>
                    </div>
                </b-col>
                <b-col cols="5" class="my-2">
                    <p class="clr-dark-grey">RESSOURCE QUI AUTORISENT LES ACCES</p>
                    <div v-if="formData.accountGivingAccess.length >0">
                        <p>Les comptes:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(accountGiver,key3) in formData.accountGivingAccess" :key="key3">
                                {{ getAccountObjectById(accountGiver).name }}
                            </span>
                        </p>
                    </div>
                    <div v-if="!isEditMode && groupsBasedOnChosenAccountsGiver.length >0">
                        <p>Les groupes:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(groupGiver,key4) in groupsBasedOnChosenAccountsGiver.filter(item => formData.accountGroupGivingAccess.includes(item.id))" :key="key4">
                                {{groupGiver.name}}
                            </span>
                        </p>
                    </div>
                    <div v-else-if="getAccountGroupById(formData.accountGroupGivingAccess).length > 0">
                        <p>Les groupes:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(groupGiver,key4) in getAccountGroupById(formData.accountGroupGivingAccess)" :key="key4">
                                {{ groupGiver.name}}
                            </span>
                        </p>
                    </div>
                    <div v-if="sensors.filter(item => formData.chosenSensors.includes(item.id)).length > 0">
                        <p>Les sensors:</p>
                        <p class="ml-2 clr-dark-blue">
                            <span v-for="(sensor,key5) in sensors.filter(item => formData.chosenSensors.includes(item.id))" :key="key5">
                               <b-badge class="mr-1" variant="primary">{{sensor.serialNumber}}</b-badge>
                            </span>
                        </p>
                    </div>
                    <b-row v-if="isLocationsSet() || formData.coordinates.length > 0">
                        <b-col cols="12" class="mb-1">Les Point d'intérêt ou lieux :</b-col>
                        <div v-for="(location,key6) in formData.locations" :key="key6" >
                            <b-col v-if="location.length > 0" cols="4" class="ml-1">
                                <p v-if="location.length > 0">
                                    {{ locationTypesOptions.find(item => item.value === key6).text}}
                                </p>
                                <div v-if="key6 !== 'id'">
                                    <p class="clr-dark-blue p-0 m-0" v-for="(item,key7) in location" :key="key7">
                                    <span>
                                        {{item}}
                                    </span>
                                    </p>
                                </div>
                            </b-col>
                        </div>
                        <b-col cols="12" v-if="formData.coordinates.length > 0">
                            <p class="">
                                Coordonnées Gps
                            </p>
                            <p class="ml-2 clr-dark-blue" v-for="(location,key8) in formData.coordinates" :key="key8">
                                <b-badge class="mr-1" variant="primary">{{ Object.keys(location)[0] | capitalize }}</b-badge> - Coordonnées: [{{ location[Object.keys(location)[0]][0] }} , {{ location[Object.keys(location)[0]][1] }}]
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center text-center">
                <b-col cols="6">
                    <b-button class="bg-green clr-white" @click="sendAccess()" size="lg">Créer les accès</b-button>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="8" class="mt-2 text-center" v-if="formStep > 0">
            <b-progress :value="formStep" :max="4" class="mb-3" :variant="formStep === 4 ? 'success':'primary'"></b-progress>
            <b-button class="mr-1 bg-light-grey clr-white" @click="stepDown">Précédent</b-button>
            <b-button class="text-center" @click="stepUp" :class="isStepOk ? 'bg-blue clr-white' : ''" v-if="formStep !== 4">Valider</b-button>
        </b-col>
    </b-row>
</template>

<script>
    import Vuex from 'vuex'

    export default {
        name: "DataAccessCreate",
        props:{
            accountGroupToEdit: {
                type: Number,
                default: null
            }
        },
        data(){
            return {
                formStep:0,
                addAllAccountGroupsReceiver:false,
                addAllAccountGroupsGiver:false,
                chosenRessourcesCategory:'',
                chosenLocationType:'countryLongName',
                locationTypesOptions:[
                    { value: 'countryLongName', text: 'Pays' },
                    { value: 'administrativeAreaLvl2', text: 'Région' },
                    { value: 'administrativeAreaLvl1', text: 'Département'},
                    { value: 'locality', text: 'Ville'}
                ],
                locationTypeFilter:'',
                switchLocationToCoordinate:false,
                coordinateName:'',
                coordinateLat:'',
                coordinateLon:'',
                formData:{
                    accountLookingForAccess:[],
                    accountGroupLookingForAccess:[],
                    accountGivingAccess:[],
                    accountGroupGivingAccess:[],
                    chosenSensors:[],
                    locations: {
                        countryLongName: [],
                        administrativeAreaLvl1: [],
                        administrativeAreaLvl2: [],
                        locality: [],
                    },
                    coordinates:[]
                },
                search:''
            }
        },
        methods:{
            ...Vuex.mapActions('account',{
                setAccounts:'setAccounts'
            }),
            ...Vuex.mapActions('dataAccess',{
                createDataAccess:'createDataAccess',
                getRequest:'getRequest',
                setDataAccessByAccountGroup:'setDataAccessByAccountGroup'
            }),
            ...Vuex.mapActions('sensor',{
                setSensors:'setSensors'
            }),
            stepUp(){
                if (this.isStepOk){
                    this.formStep += 1
                } else {
                    alert("Veuillez choisir au moins un compte avant de passer à l'étape suivante")
                }
            },
            stepDown(){
                if (this.formStep > 0){
                    this.formStep -= 1
                }
            },
            setChosenRessourcesCategory(category){
                this.chosenRessourcesCategory = category
            },
            async querySensor() {
                let currentPage = {}
                if (this.paginator.current > 1 && this.paginator.current !== null && this.paginator.current !== undefined) {
                    currentPage.page = this.paginator.current
                } else {
                    currentPage.page = 1;
                }

                this.paginator.numItemsPerPage !== undefined ? currentPage.limit = this.paginator.numItemsPerPage : currentPage.limit = 50

                this.search.length >= 3 ? currentPage.search = this.search : '';
                currentPage.type = this.chosenType
                currentPage.status = 'prod';
                await this.setSensors(currentPage)

            },
            setNewLocation(){
                const key = this.coordinateName;
                const lat = this.coordinateLat;
                const lon = this.coordinateLon;

                if (key && lat && lon){
                    const value = {
                        [key]: [lat,lon]
                    };

                    this.formData.coordinates.push(value)

                    this.coordinateName = '';
                    this.coordinateLat  = '';
                    this.coordinateLon = '';
                } else if (!key || !lat || !lon) {
                    alert("Le formulaire n'est pas complet")
                } else {
                    alert('Cette entrée existe déja!')
                }
            },
            getAccountObjectById(id){
                return this.getAccountsById(id)
            },
            generateQueryString() {
                let data = new FormData();

                if (this.formData.accountGroupGivingAccess.length > 0) {
                    for (var i = 0; i < this.formData.accountGroupGivingAccess.length; i++) {
                        data.append('fromAccountGroups[]', this.formData.accountGroupGivingAccess[i]);
                    }
                }

                if (this.formData.accountGroupLookingForAccess.length > 0) {
                    for (var y = 0; y < this.formData.accountGroupLookingForAccess.length; y++) {
                        data.append('toAccountGroups[]', this.formData.accountGroupLookingForAccess[y]);
                    }
                }


                if (this.formData.chosenSensors.length > 0) {
                    for (let z = 0; z < this.formData.chosenSensors.length; z++) {
                        data.append('sensors[]', this.formData.chosenSensors[z]);
                    }
                }

                if (this.formData.coordinates.length > 0) {
                    for (let x = 0; x < this.formData.coordinates.length; x++) {
                        let coordinate = this.formData.coordinates[x]
                        let coordinateName = Object.keys(coordinate)[0]
                        data.append('locations[coordinates]['+Object.keys(coordinate)[0]+']', coordinate[coordinateName][0].toString()+','+coordinate[coordinateName][1].toString());
                    }
                }

                if (this.isLocationsSet) {
                    for (const property in this.formData.locations) {
                        let ressources = ''
                        for (let u = 0 ; u < this.formData.locations[property].length;u++){
                            if (u === (this.formData.locations[property].length -1) ){
                                ressources += this.formData.locations[property][u]
                            } else {
                                ressources += this.formData.locations[property][u]+','
                            }
                        }
                        data.append('locations['+property+']', ressources);
                    }
                }
                return data
            },
            async sendAccess(){
                let data = this.generateQueryString()
                await this.createDataAccess(data)
                this.formData = {
                    accountLookingForAccess:[],
                    accountGroupLookingForAccess:[],
                    accountGivingAccess:[],
                    accountGroupGivingAccess:[],
                    chosenSensors:[],
                    locations: {
                        countryLongName: [],
                        administrativeAreaLvl1: [],
                        administrativeAreaLvl2: [],
                        locality: [],
                    },
                    coordinates:[]
                }
            },
            setFormDataBasedOnExistingAccess(){
                let access = this.getDataAccessByGroup
                this.formData.accountGroupLookingForAccess.push(this.accountGroupToEdit)

                if (access){
                    let locations = access.locations;
                    let sensors = access.sensors;
                    let accountGroups = access.accountGroups;

                    if (locations !== undefined) {
                        for (let i = 0; i < Object.keys(locations).length; i++) {
                            if (Object.keys(locations)[i] !== 'coordinates' && locations[Object.keys(locations)[i]]) {
                                this.formData.locations[Object.keys(locations)[i]] = locations[Object.keys(locations)[i]]
                            } else if (Object.keys(locations)[i] === 'coordinates' && locations[Object.keys(locations)[i]]) {
                                this.formData.coordinates.push(locations[Object.keys(locations)[i]])
                            }
                        }
                    }

                    if (sensors !== undefined){
                        for (let j =0;j<sensors.granted.accesses.length;j++){
                            this.formData.chosenSensors.push(sensors.granted.accesses[j].id)
                        }
                    }

                    if (accountGroups !== undefined){
                        for (let k =0;k<accountGroups.accesses.length;k++){
                            this.formData.accountGroupGivingAccess.push(accountGroups.accesses[k].id)
                        }
                    }

                    this.formStep = 3
                }
            },
            isEditMode(){
                return this.accountGroupToEdit !== null
            },
            isLocationsSet(){
                let locations = this.formData.locations
                return locations.administrativeAreaLvl2.length ||
                    locations.administrativeAreaLvl1.length ||
                    locations.countryLongName.length ||
                    locations.locality.length

            }
        },
        computed:{
            ...Vuex.mapGetters('account', {
                accounts: 'accounts',
                getAccountGroupsByAccountId:'getAccountGroupsByAccountId',
                getAccountsByAccountIdNotChosen:'getAccountsByAccountIdNotChosen',
                getAccountsById:'getAccountsById',
                getAccountGroupById:'getAccountGroupById'
            }),
            ...Vuex.mapGetters('sensor', {
                sensors: 'sensors',
                paginator:'paginator',
                getLocationsAvailable:'getLocationsAvailable',
            }),
            ...Vuex.mapGetters('dataAccess', {
                getDataAccessByGroup:'getDataAccessByGroup',
                isDataAccessLoading:'isDataAccessLoading'
            }),
            isStepOk(){
                if (this.formStep === 0 ){
                    return true
                } else if (this.formStep === 1 ){
                    return this.formData.accountLookingForAccess.length > 0
                } else if (this.formStep === 2 ){
                    return this.formData.accountGroupLookingForAccess.length > 0
                }else if (this.formStep === 3 ){
                    return this.formData.accountGroupLookingForAccess.length > 0 || this.formData.chosenSensors.length > 0 || this.formData.locations.length > 0
                } else {
                    return false
                }
            },
            groupsBasedOnChosenAccounts(){
                return this.getAccountGroupsByAccountId([this.formData.accountLookingForAccess,this.formData.accountGroupGivingAccess])
            },
            groupsBasedOnChosenAccountsGiver(){
                return this.getAccountGroupsByAccountId([this.formData.accountGivingAccess,this.formData.accountGroupLookingForAccess])
            },
            getFilteredLocations(){
                if (this.chosenLocationType && this.getLocationsAvailable(this.chosenLocationType).length > 0){
                    return this.getLocationsAvailable(this.chosenLocationType).filter(name => name.startsWith(this.locationTypeFilter))
                } else {
                    return []
                }
            }
        },
        async created() {
            this.setAccounts();
            if (this.accountGroupToEdit){
                await this.setDataAccessByAccountGroup(this.accountGroupToEdit)
                this.setFormDataBasedOnExistingAccess()
            }
        },
        watch: {
            addAllAccountGroupsReceiver: function (val) {
                if (val){
                    let groups = []
                    for (let i = 0 ; i < this.groupsBasedOnChosenAccounts.length;i++){
                        groups.push(this.groupsBasedOnChosenAccounts[i].id)
                    }
                    this.formData.accountGroupLookingForAccess = groups
                } else {
                    this.formData.accountGroupLookingForAccess = []
                }
            },
            addAllAccountGroupsGiver: function (val) {
                if (val){
                    let groups = []
                    for (let i = 0 ; i < this.groupsBasedOnChosenAccountsGiver.length;i++){
                        groups.push(this.groupsBasedOnChosenAccountsGiver[i].id)
                    }
                    this.formData.accountGroupGivingAccess = groups
                } else {
                    this.formData.accountGroupGivingAccess = []
                }
            },
            chosenRessourcesCategory: function (val){
                if (val === 'sensor' || val === 'location'){
                    this.querySensor()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
#body {
  background-color: grey;
}

.categoryCard {
  background-color: $blue;
  color: white;
  min-height: 40px;
  &.active{
    background-color: white;
    color: $blue;
    border:$blue 1px solid;
  }
}
.dataAccessChoiceCard {
  background-color: $light-grey;
}

.dataAcceFormTitle {
  font-size: 1.2rem;
}

.dataAcceFormSubTitle {
  font-size: 1.1rem;
}

</style>
