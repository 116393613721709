<template>
    <b-row class="justify-content-center">
        <b-col lg="10" md="12" sm="12" cols="12" v-if="accounts.length > 0" class="p-0">
            <!-- HEADER -->
            <b-row class="justify-content-center align-content-center align-items-center">
                <b-col md="6" sm="6" cols="12">
                    <p class="h5 clr-dark-grey add-cursor responsive-font">
                        <span @click="goToAccountsList">
                            <b-icon icon="chevron-left"></b-icon>
                            Retour à la liste
                        </span>
                    </p>
                </b-col>
                <b-col md="6" sm="6" cols="12" class="text-sm-right text-md-right">
                    <b-dropdown
                        toggle-class="bg-orange clr-white"
                        menu-class="clr-orange"
                        split-class="clr-orange bg-white"
                        variant="primary"
                        right
                        text="Action sur le compte"
                    >
                        <b-dropdown-item @click="gotToAccountEdit()" variant="primary">
                            <b-icon icon="pencil"></b-icon>
                            Editer les informations du compte
                        </b-dropdown-item>
                        <b-dropdown-item @click="goToAccountCustomAccessCreation()" variant="primary">
                            <b-icon icon="clipboard-plus"></b-icon>
                            Créer des accès sur mesure
                        </b-dropdown-item>
                        <b-dropdown-item @click="askDeleteAccount()" variant="primary">
                            <b-icon icon="trash-fill"></b-icon>
                            Supprimer le capteur
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>

            <!-- ACCOUNT INFORMATIONS -->
            <b-col cols="12" class="basic-card p-3 mt-4 mb-3">
                <b-row class="justify-content-center align-content-center">
                    <b-col cols="12" md="6">
                        <b-row>
                            <b-col cols="12">
                                <p class="h4 mb-3 clr-dark-grey">Informations Générales</p>
                            </b-col>
                            <b-col cols="12">
                                <p class="h4 mb-2 clr-darker-grey">
                                    <b-icon icon="people-fill" class="mr-3"></b-icon>
                                    Nom du compte : <span class="clr-orange">{{
                                        actualAccount.name | capitalize
                                    }}</span>
                                </p>
                            </b-col>
                            <b-col cols="12">
                                <p class="h4 mb-2 clr-darker-grey">
                                    <b-icon icon="card-checklist" class="mr-3"></b-icon>
                                    Plan : <span class="clr-orange">{{ actualAccount.planLevel | capitalize }}</span>
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="6" class="my-auto text-center clr-darker-grey">
                        <b-img :src="`${assetsBucket}/assets/account/${actualAccount.logoName}`"
                               class="logo-account" v-if="actualAccount.logoName"/>
                        <p v-else>Aucun logo</p>
                    </b-col>
                </b-row>
            </b-col>

            <!-- ACCOUNT SENSOR EVENTS -->
            <b-row
                v-if="isEventsLoading"
                class="mb-3 text-center">
                <b-col cols="12">
                    <b-spinner class="clr-orange" type="grow"/>
                </b-col>
            </b-row>
            <b-row v-else-if="accountsSensorEvents.length > 0">
                <b-col
                    cols="12"
                    class="mb-3"
                    v-for="(event, index) in accountsSensorEvents"
                    :key="index + '_account_event'"
                    :md="accountsSensorEvents.length === 1 ? 12 : 6">
                    <SensorEventDetails
                        :event="event"
                        from="Account"/>
                </b-col>
            </b-row>

            <!-- ACCOUNT CONTACTS -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <b-row class="justify-content-between mb-3">
                    <b-col cols="auto">
                        <p class="h4 clr-dark-grey">Contacts associés</p>
                    </b-col>
                    <b-col cols="auto">
                        <p @click="showNewContactForm()"
                           class="add-cursor add-button animate-circle clr-orange mr-3">
                            <b-icon icon="plus-circle" scale="2"></b-icon>
                        </p>
                    </b-col>
                </b-row>
                <AccountContactTable
                    :account-contact="actualAccount.accountContacts"
                    @editContact="showNewContactForm"
                    @deleteContact="showDeleteAccountContact">
                </AccountContactTable>
            </b-col>

            <!-- SENSORS ASSOCIATED WITH THIS ACCOUNT -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <p class="h4 mb-3 clr-dark-grey">Capteurs associés ({{ sensors.length }})</p>
                <SensorProductionTable
                    v-if="sensors.length > 0"
                    :shadow="false"
                    :simple-version="true"
                    :sensors="sensors"
                    :loading-status="loadingStatus"
                    :hoveredSensor="hoverSensor"
                    :full="false"
                    v-on:set-sorting="setSort"
                    v-on:hovered-sensor="setHoveredSensor"
                ></SensorProductionTable>
                <div v-else class="col-12 my-3 justify-content-center align-content-center row">
                    <p>
                        Pas de Capteur associé pour le moment
                    </p>
                </div>
            </b-col>

            <!-- ACCOUNT GROUPS ASSOCIATED WITH THIS ACCOUNT -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <b-row class="justify-content-between mb-3">
                    <b-col cols="auto">
                        <p class="h4 clr-dark-grey">Groupes associés</p>
                    </b-col>
                    <b-col cols="auto">
                        <p @click="showNewGroupForm"
                           class="add-cursor add-button animate-circle clr-orange mr-3">
                            <b-icon icon="plus-circle" scale="2"></b-icon>
                        </p>
                    </b-col>
                </b-row>
                <div v-if="actualAccount.accountGroups.length >= 1" class="col-xl-12 my-2">
                    <AccountGroupTable :account-group="actualAccount.accountGroups"/>
                </div>
            </b-col>

            <!-- USERS ASSOCIATED WITH THIS ACCOUNT -->
            <b-col cols="12" class="basic-card p-3 mb-3" v-if="getUsersList && !loadingAccountStatus">
                <p class="h4 mb-3 clr-dark-grey">Utilisateurs associés</p>
                <AccountUserList/>
            </b-col>

            <!-- DATA ACCESSES ASSOCIATED WITH THIS ACCOUNT -->
            <b-col cols="12" class="basic-card p-3 mb-3">
                <p class="h4 mb-3 clr-dark-grey">Accès du compte</p>
                <b-tabs pills class="col-12" align="center">
                    <b-tab title="Sensors" active>
                        <AccessSensorTable class="mt-3"/>
                    </b-tab>
                    <b-tab title="AccountGroups">
                        <AccessAccountGroupTable class="mt-3"/>
                    </b-tab>
                    <b-tab title="Locations">
                        <AccessLocationTable class="mt-3"/>
                    </b-tab>
                    <b-tab title="Coordinates">
                        <AccessCoordinatesTable class="mt-3"/>
                    </b-tab>
                </b-tabs>
            </b-col>
            <b-modal
                id="modal-new-group"
                ref="modal"
                centered
                :title="'Ajout d\'un group au compte '+ actualAccount.name"
                @ok="handleOk"
            >
                <p>Vous êtes sur le point d'ajouter un groupe</p>
                <b-form @submit.stop.prevent="handleSubmitGroup">
                    <b-form-group
                        id="input-group-1"
                        label="Nom du groupe :"
                        label-for="input-1"
                        description="Il représente le sous-groupe ou le sous-client du compte"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="newAccountGroup.name"
                            placeholder="Entrez le nom du groupe"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Logo"
                        label-for="logo-input"
                        invalid-feedback="Logo is required"
                    >
                        <b-form-file
                            v-model="newAccountGroup.logo"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <b-img v-if="newAccountGroup.logo" :src="logoSrc" class="mb-3 p-3 text-center mx-auto"
                               style="width: 100px" fluid block rounded></b-img>
                    </b-form-group>
                </b-form>
            </b-modal>
            <b-modal
                id="modal-new-contact"
                ref="modal"
                centered
                :cancel-disabled="isLoadingContact"
                :title="(isEdit ? 'Edit d\'un' : 'Ajout d\'un')+' contact au compte '+ actualAccount.name"
                @ok="handleContactOk"
            >
                <div v-if="isLoadingContact" class="text-center w-100">
                    <b-spinner
                        label="loader"
                        class="text-center spinner clr-orange"/>
                </div>
                <b-form
                    v-else
                    @submit.stop.prevent="handleSubmitContact">
                    <b-form-group
                        id="input-group-1"
                        label="Fiche Contact :"
                    >
                        <b-form-input
                            id="input-1" class="my-2 clr-light-grey"
                            v-model="newAccountContact.firstName"
                            placeholder="Entrez le prénom du contact"
                            type="text"
                            required
                        ></b-form-input>
                        <b-form-input
                            id="input-2" class="my-2 clr-light-grey"
                            v-model="newAccountContact.lastName"
                            placeholder="Entrez le nom du contact"
                            type="text"
                            required
                        ></b-form-input>
                        <b-form-input
                            id="input-5" class="my-2 clr-light-grey"
                            v-model="newAccountContact.position"
                            placeholder="Entrez le Poste du contact"
                            type="text"
                            required
                        ></b-form-input>
                        <b-form-input
                            id="input-3" class="my-2 clr-light-grey"
                            v-model="newAccountContact.email"
                            placeholder="Entrez l'email du contact"
                            type="email"
                            :state="isEmailValid"
                            required
                        ></b-form-input>
                        <b-form-input
                            id="input-3" class="my-2 clr-light-grey"
                            v-model="newAccountContact.phoneNumber"
                            type="tel"
                            placeholder="Entrez le Numéro de téléphone du contact"
                            required
                        ></b-form-input>
                        <b-form-select
                            id="input-4" class="my-2 clr-light-grey"
                            v-model="newAccountContact.sensors"
                            :options="sensorOptions"
                            placeholder="Entrez le Numéro du contact"
                            multiple
                            required
                        ></b-form-select>
                    </b-form-group>
                </b-form>
            </b-modal>
            <b-modal
                id="modal-delete-contact"
                ref="modal"
                centered
                :title="'Suppression d\'un contact'"
                @ok="removeContact()"
            >
                <p>Vous êtes sur le point de supprimer un contact</p>
            </b-modal>
        </b-col>
        <b-spinner v-else></b-spinner>
    </b-row>
</template>

<script>
import Vuex from 'vuex';
import Toast from "@/services/swal2/mixins"
import AccountGroupTable from "@/components/account/AccountGroupTable";
import AccessSensorTable from "@/components/account/AccessSensorTable";
import AccessAccountGroupTable from "@/components/account/AccessAccountGroupTable";
import AccessLocationTable from "@/components/account/AccessLocationTable";
import AccessCoordinatesTable from "@/components/account/AccessCoordinatesTable";
import AccountUserList from "@/components/account/AccountUserList";
import SensorProductionTable from "@/components/sensor/Production/SensorProductionTable.vue";
import AccountContactTable from "@/components/account/AccountContactTable.vue";
import SensorEventDetails from "@/components/sensor/Detail/SAV/SensorEventDetails.vue";

const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export default {
    name: "AccountInformation",
    components: {
        SensorEventDetails,
        AccountContactTable,
        SensorProductionTable,
        AccountGroupTable,
        AccessSensorTable,
        AccessAccountGroupTable,
        AccessLocationTable,
        AccessCoordinatesTable,
        AccountUserList
    },
    data() {
        return {
            assetsBucket: process.env.VUE_APP_AWS_ASSETS_BUCKET,
            hoverSensor: null,
            direction: 'desc',
            sort: 'sensor.lastUpdate',
            newAccountContact: {
                firstName:"",
                lastName:"",
                position: "",
                email:"",
                phoneNumber:"",
                sensors:[]
            },
            newAccountGroup: {
                name: null,
                logo: null,
                account: null
            },
            logoSrc: '',
            isEdit: false,
            actualContact:null,
            contactToDelete:null,
            isLoadingContact: false
        }
    },
    methods: {
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts',
            deleteAccount: 'deleteAccount',
            setUsersList: 'setUsersList'
        }),
        ...Vuex.mapActions('accountGroup', {
            createAccountGroup: 'createAccountGroup'
        }),
        ...Vuex.mapActions('accountContact', {
            createAccountContact: 'createAccountContact',
            editAccountContact: 'editAccountContact',
            deleteAccountContact: 'deleteAccountContact'
        }),
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors',
        }),
        ...Vuex.mapActions('dataAccess', {
            setDataAccessByAccount: 'setDataAccessByAccount',
            unbindData: 'unbindData'
        }),
        ...Vuex.mapActions('sensorEvent', {
            setAccountSensorEvents: 'setAccountSensorEvents',
            resetAccountSensorEvents: 'resetAccountSensorEvents'
        }),
        askDeleteAccount() {
            this.$swal.fire({
                title: 'Etes vous sur de vouloir supprimer ce compte ?',
                text: "Cette action est irréversible",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, je suis sur!'
            }).then((result) => {
                if (result.value) {
                    this.deleteAccount(this.$route.params.id)
                }
            })
        },
        goToAccountsList() {
            this.$router.push('/accounts');
        },
        gotToAccountEdit() {
            this.$router.push('/accounts/' + this.$route.params.id + '/edit');
        },
        async querySensor() {
            const params = {
                sort: this.sort,
                direction: this.direction,
                account: this.actualAccount.id,
                type: 'pollen',
                paginated: false
            }
            await this.setSensors(params)
        },
        setSort(data) {
            this.sort = data
            this.direction === 'desc' ? this.direction = 'asc' : this.direction = 'desc';
            this.querySensor()
        },
        setHoveredSensor(id) {
            this.hoverSensor = id;
        },
        showNewGroupForm() {
            this.$bvModal.show('modal-new-group')
        },
        showNewContactForm(contact = null) {
            this.isEdit = false
            if (contact) {
                this.newAccountContact.id = contact.id
                this.newAccountContact.firstName = contact.firstName
                this.newAccountContact.lastName = contact.lastName
                this.newAccountContact.position = contact.position
                this.newAccountContact.email = contact.email
                this.newAccountContact.phoneNumber = contact.phoneNumber
                this.newAccountContact.sensors = contact.dataReferences.map(item => item.referenceId)
                this.isEdit = true
            } else {
                this.newAccountContact = {
                    firstName:"",
                    lastName:"",
                    position: "",
                    email:"",
                    phoneNumber:"",
                    sensors: []
                }
            }
            this.$bvModal.show('modal-new-contact')
        },
        showDeleteAccountContact(contact) {
            this.contactToDelete = contact.id
            this.$bvModal.show('modal-delete-contact')
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitGroup()
        },
        handleContactOk(bvModalEvt) {
            if (!this.isLoadingContact) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmitContact()
            }
        },
        async handleSubmitGroup() {
            let query = this.generateQueryStringGroup()
            await this.createAccountGroup(query);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-new-group')
                await this.setAccounts()
            })
        },
        async removeContact() {
            await this.deleteAccountContact([this.actualAccount.id,this.contactToDelete]);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-delete-contact')
                await this.setAccounts()
            })
        },
        async handleSubmitContact() {
            this.isLoadingContact = true;
            let query = this.generateQueryStringContact()

            if (this.isEdit) {
                await this.editAccountContact([this.actualAccount.id, this.newAccountContact.id, query]);
            } else {
                await this.createAccountContact([this.actualAccount.id, query]);
            }

            this.$nextTick(async () => {
                this.isLoadingContact = false;
                this.$bvModal.hide('modal-new-contact')
                this.isEdit = false
                await this.setAccounts()
            })
        },
        generateQueryStringGroup() {
            let fields = new FormData();
            if (this.newAccountGroup.name !== '') {
                fields.append('name', this.newAccountGroup.name)
            }

            if (this.newAccountGroup.logo !== '') {
                fields.append('logo', this.newAccountGroup.logo)
            }

            fields.append('account', this.actualAccount.id)

            return fields
        },
        generateQueryStringContact() {
            let fields = new FormData();
            fields.append('firstName', this.newAccountContact.firstName)
            fields.append('lastName', this.newAccountContact.lastName)
            fields.append('position', this.newAccountContact.position)
            fields.append('email', this.newAccountContact.email)
            fields.append('phoneNumber', this.newAccountContact.phoneNumber)

            for (let i = 0; i < this.newAccountContact.sensors.length; i++) {
                fields.append('dataReferences[]', this.newAccountContact.sensors[i])
            }

            fields.append('account', this.actualAccount.id)

            return fields
        },
        goToAccountCustomAccessCreation() {
            this.$router.push({name: 'DataAccess', params: {passedAccount: this.actualAccount}});
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            getAccountsById: 'getAccountsById',
            accounts: 'accounts',
            getRequest: 'getRequest',
            getUsersList: 'getUsersList',
            loadingAccountStatus: 'loadingStatus'
        }),
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
            loadingStatus: 'loadingStatus',
        }),
        ...Vuex.mapGetters('dataAccess', {
            getDataAccess: 'getDataAccess',
        }),
        ...Vuex.mapGetters('sensorEvent', {
            accountsSensorEvents: 'accountsSensorEvents',
            isEventsLoading: 'isLoading'
        }),
        isEmailValid: function() {
            if (this.newAccountContact === undefined || !this.newAccountContact.email) {
                return null
            } else {
                return !!this.newAccountContact.email.match('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
            }
        },
        sensorOptions() {
            let options = []
            if (this.accesses.length > 0){
                for (let i = 0; i < this.accesses.length; i++) {
                    for (let j = 0; j < this.accesses[i].accesses.length; j++) {
                        if (this.accesses[i].accesses[j].sensor && this.accesses[i].accesses[j].referenceId) {
                            options.push(
                                {
                                    value:this.accesses[i].accesses[j].referenceId,
                                    text: this.accesses[i].accesses[j].sensor.sensorName ? this.accesses[i].accesses[j].sensor.sensorName : this.accesses[i].accesses[j].sensor.serialNumber
                                }
                            )
                        }
                    }
                }
            }
            return options

        },
        actualAccount() {
            return this.getAccountsById(parseInt(this.$route.params.id))
        },
        accesses() {
            if (this.getDataAccess.sensors) {
                return this.getDataAccess.sensors.filter(dataAccess =>
                    dataAccess.accesses.filter(access => access.sensor).length > 0 && dataAccess.ownership === 'owner'
                );
            } else {
                return [];
            }
        }
    },
    watch: {
        getRequest: function () {
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
            }).then(
                setTimeout(() => {
                    this.goToAccountsList()
                }, 3050)
            )
        },
        'newAccountGroup.logo'(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.logoSrc = value;
                        })
                        .catch(() => {
                            this.logoSrc = null;
                        });
                } else {
                    this.logoSrc = null;
                }
            }
        }
    },
    async mounted() {
        if (this.accounts.length === 0) {
            this.setAccounts().then(() => {
                this.querySensor()
            })
        } else {
            await this.querySensor();
        }
        await this.setDataAccessByAccount(this.$route.params.id);
        await this.setUsersList({account: this.$route.params.id});
        await this.setAccountSensorEvents(this.$route.params.id);
    },
    destroyed() {
        this.unbindData()
        this.resetAccountSensorEvents()
    }
}
</script>

<style lang="scss" scoped>

@keyframes animate-circle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.account-action {
    &:hover {
        background-color: rgba(62, 159, 218, 0.22);
    }
}

.logo-account {
    width: 128px;
}

.add-button {
    background-color: white;

    &:hover {
        color: #058bcc;
    }
}

.animate-circle {
    &:hover {
        animation: animate-circle 1s 1 running;
    }
}
</style>