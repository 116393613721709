<template>
    <b-row class="justify-content-center p-3">
        <b-col cols="12" md="10">
            <b-form-group
                id="ssid"
                label="SSID :"
                label-for="ssid"
            >
                <b-form-input
                    id="ssid"
                    v-model="ssid"
                    type="text"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="password"
                label="Password :"
                label-for="password"
            >
                <b-form-input
                    id="password"
                    v-model="password"
                    type="text"
                    required
                ></b-form-input>
            </b-form-group>
            <b-button
                :disabled="!ssid ||!password"
                class="btn-action mt-4"
                @click="startScanning">
                <b-spinner
                    label="loader"
                    class="spinner"
                    v-if="isScanning"/>
                <span v-else>Start Scanning</span>
            </b-button>
            <p>{{ step }}</p>
            <p class="mt-5"><span class="bold">Numéro de série :</span> {{ serialNumber }}</p>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "WifiBluetoothConnector",
    data() {
        return {
            ssid: null,
            password: null,
            serialNumber: null,
            isScanning: false,
            step: ''
        };
    },
    methods: {
        async startScanning() {
            this.isScanning = true;
            try {
                const options = {
                    acceptAllDevices: true,
                    optionalServices: ['0000aa01-0000-1000-8000-00805f9b34fb'],
                    // filters: [{name: 'LIFY_LE_BT'}]
                };
                this.step = 'Request device...';
                const device = await navigator.bluetooth.requestDevice(options);

                this.step = 'Connecting to GATT Server...';
                const server = await device.gatt.connect();

                this.step = 'Getting Service';
                const service = await server.getPrimaryService('0000aa01-0000-1000-8000-00805f9b34fb');

                this.step = 'Getting Characteristic...';
                const characteristic = await service.getCharacteristic('0000bb02-0000-1000-8000-00805f9b34fb');

                this.step = 'Writing Characteristic...';

                const jsonData = {SSID: this.ssid, motdepasseSSID: this.password};
                const encodedData = btoa(JSON.stringify(jsonData));
                const value = "recordSSID" + encodedData;
                let encoder = new TextEncoder();
                characteristic.writeValue(encoder.encode(value));

                this.step = 'Getting Descriptors...';
                const descriptor = await characteristic.getDescriptor('0000cc03-0000-1000-8000-00805f9b34fb');

                await descriptor.readValue().then(value => {
                    let decoder = new TextDecoder('utf-8');
                    this.serialNumber = decoder.decode(value);
                });

                this.step = 'Process is over';
                this.isScanning = false;
            } catch (error) {
                this.isScanning = false;
                console.error('Error:', error);
            }
        },
    },
};
</script>