import api from '@/services/api/index.js'

const state = {
    consumption: {},
    dailyConsumption: {},
    loadingStatus: false,
    request:{},
    simInfo:{},
    token:null
};


const mutations = {
    SET_CONSUMPTION: (state, consumption) => {
        state.consumption = consumption;
    },
    SET_DAILY_CONSUMPTION: (state, consumption) => {
        state.dailyConsumption = consumption;
    },
    SET_SIM_INFO: (state, simInfo) => {
        state.simInfo = simInfo
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request = {status: requestStatus[1], message: requestStatus[0]}
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
    SET_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus;
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    }
};
const actions = {
    setConsumption: async (store, params) => {
        store.commit('SET_LOADING_STATUS');
        await api.matooma.getConsumption(state.token,params.iccid, params.period)
            .then( response =>  {
                store.commit('SET_CONSUMPTION', response.total);
                store.commit('SET_DAILY_CONSUMPTION', response.daily);
                store.commit('SET_SIM_INFO', response.simInfo);
                store.commit('SET_LOADING_STATUS');
            })
    },
    setToken: async (store) => {
        await api.matooma.authenticateToMatooma()
            .then( response =>  {
                store.commit('SET_TOKEN', response.data.access_token);
            })
    }
};

const getters = {
    getConsumption: state => {
        return state.consumption;
    },
    getDailyConsumption: state => {
        return state.dailyConsumption;
    },
    getSimInfo: state => {
        return state.simInfo;
    },
    getLoadingStatus: state => {
        return state.loadingStatus;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
