import AWS from 'aws-sdk';

AWS.config.update({
	accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.VUE_APP_AWS_DEFAULT_REGION
});

let dynamoDB = new AWS.DynamoDB();

export default {
	dynamoDB
}