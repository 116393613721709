<template>
    <div ref="SensorStockTable">
        <b-col cols="12" class="basic-card p-2">
            <b-table-simple
                responsive
                hover
                sticky-header
                borderless
                class="table-height"
                v-if="sensors.length > 0 && !loadingStatus"
            >
                <b-thead class="clr-darker-grey ">
                    <b-tr class="my-2">
                        <b-th></b-th>
                        <b-th @click="setSort('sensor.serialNumber')" class="add-cursor">
                            Numéro de série
                            <b-icon
                                icon="caret-down"
                                scale="1"
                                v-if="sort === 'sensor.serialNumber' && direction === 'desc'"
                            >
                            </b-icon>
                            <b-icon
                                icon="caret-up"
                                scale="1"
                                v-else-if="sort === 'sensor.serialNumber'"
                            >
                            </b-icon>
                        </b-th>
                        <b-th>Dernière connexion</b-th>
                        <b-th>Dernier traitement de données</b-th>
                        <b-th>Type</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item,i) in sensors"
                          :key="`sensor_stock_${i}`"
                          class="clr-dark-grey add-cursor"
                          :class="hoveredSensor === item.id ? 'bg-lighter-orange' : 'bg-white'"
                          @mouseover="setHoveredSensor(item.serialNumber)"
                          @mouseout="setHoveredSensor(null)"
                    >
                        <b-td>
                            <b-form-checkbox
                                :id="'checkbox-'+item.id"
                                v-model="item.checked"
                                name="checkbox-1"
                                :value="true"
                                :unchecked-value="false"
                                @change="setCheckedSensors"
                            >
                            </b-form-checkbox>
                        </b-td>
                        <b-td
                            @click="$router.push('/sensors/'+item.serialNumber+'/detail')"
                        >
                            {{ item.serialNumber }}
                        </b-td>
                        <b-td>{{ item.lastUpdate }}</b-td>
                        <b-td>{{ item.lastDataFlowUpdate }}</b-td>
                        <b-td v-if="chosenType === 'pollution'">
                            Pollution
                        </b-td>
                        <b-td v-else>
                            Pollen
                        </b-td>

                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
                v-else-if="loadingStatus"
                class="table-height"
            ></b-skeleton-table>
            <p v-else>{{ $t('error.noItems') | capitalize }}</p>
        </b-col>
    </div>
</template>

<script>
import moment from "moment";
import Vuex from "vuex";

export default {
    name: "SensorStockTable",
    props: {
        sensors: Array,
        dataLocalisation: Object,
        loadingStatus: Boolean,
        hoveredSensor: String,
        sort: String,
        direction: String,
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            chosenType: 'chosenType'
        }),
        onlyCheckedSensor() {
            return this.sensors.filter(sensor => sensor.checked)
        }
    },
    methods: {
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        setHoveredSensor(sensorId) {
            this.$emit('hovered-sensor', sensorId)
        },
        setCheckedSensors() {
            this.$emit('checked-sensors', this.onlyCheckedSensor)
        },
        isOnline(date, offset = 2) {
            let utcData = moment.utc(date);
            let hoursAgo = moment.utc().subtract(offset, 'hours').toDate().toUTCString();
            return utcData.isAfter(hoursAgo);
        },
        lastTimeOnline(date) {
            let utcData = moment.utc(date);
            return moment(utcData, "YYYY-MM-DD HH:mm:SS", true).fromNow()
        }
    }
}
</script>
