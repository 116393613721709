import i18n from '@/i18n'
import Vue from 'vue'

export const item = {
	dashboard: {
		scales: {
			yAxes: [
				{
					gridLines: {
						drawOnChartArea: true,
						drawBorder: true,
						drawTicks: true,
					},
					ticks: {
						display: true
					},
				},
			],
			xAxes: [
				{
					gridLines: {
						drawOnChartArea: false,
						drawBorder: false,
						drawTicks: false,
					},
					ticks: {
						display: false,
						beginAtZero: true,
						min: 0,
					},
				},
			],
		},
		legend: {
			display: false,
		},
		tooltips: {
			showTooltips: false,
			enabled: false
		},
		responsive: true,
		maintainAspectRatio: false,
		annotation: {
			annotations: []
		}
	},
	pollenConcentration: {
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'Concentration (g/m³)',
					fontColor:'#3E9FDA',
					fontSize:16
				},
				ticks: {
					beginAtZero:false,
					display:true,
					padding: 10,
					fontColor:'#3E9FDA',
				},
				gridLines: {
					color: '#3E9FDA',
					fontColor:'#3E9FDA',
					drawOnChartArea: false,
					zeroLineColor: '#3E9FDA',
					offsetGridLines: false,
					drawTicks:true,
					borderDashOffset: 0,
					drawBorder: true,
					tickMarkLength:10,
					lineWidth: 2
				},
			}],
			xAxes: [
				{
					display:true,
					scaleLabel: {
						display: true,
						labelString: 'Temps (h)',
						fontColor:'#3E9FDA',
						fontSize:16
					},
					type: "time",
					distribution:'series',
					time: {
						// format: "YYYY-MM-DD HH:mm",
						unit: 'day',
						// // 	// unitStepSize: 1,
						displayFormats:     {
							// 'minute': 'HH:mm',
							// 'hour': 'HH:mm',
							'day':'DD-MM'
						}
					},
					gridLines: {
						drawOnChartArea: false,
						lineWidth: 2,
						fontColor:'#3E9FDA'
					},
					ticks: {
						display: true,
						fontColor: '#3E9FDA',
						beginAtZero:false,
						fontSize: 16,
						maxRotation: 0,
						minRotation: 0,

					},
				},
			],
		},
		legend: {
			display: true
		},
		responsive: true,
		maintainAspectRatio: false,
		annotation: {
			annotations: []
		},
		animation: {
			duration: 0
		},
		hover: {
			animationDuration: 0
		},
		responsiveAnimationDuration: 0
	},
	weatherReports: {
		scales: {
			yAxes: [
				{
					id: 'A',
					scaleLabel: {
						display: true,
						labelString: 'Temperature en °C',
						fontColor:'#3E9FDA',
						fontSize:16
					},
					ticks: {
						beginAtZero:false,
						display:true,
						padding: 10,
						fontColor: '#3E9FDA',
					},
					gridLines: {
						color: '#3E9FDA',
						drawOnChartArea: false,
						zeroLineColor: '#3E9FDA',
						offsetGridLines: false,
						drawTicks:true,
						borderDashOffset: -5,
						drawBorder: true,
						tickMarkLength:5,
						lineWidth: 2
					},
				},
				{
					id: 'B',
					position: 'right',
					scaleLabel: {
						display: true,
						labelString: 'Humidity en %',
						fontColor:'#EE7F47',
						fontSize:16
					},
					ticks: {
						beginAtZero:false,
						display:true,
						padding: 10,
						fontColor: '#EE7F47',
						callback: function (value) {
							return (value / this.max * 100).toFixed(0) + ' %'; // convert it to percentage
						},
						min:0,
						max:100,
						stepSize: 10
					},
					gridLines: {
						color: '#EE7F47',
						drawOnChartArea: false,
						zeroLineColor: '#EE7F47',
						offsetGridLines: true,
						drawTicks:true,
						drawBorder: true,
						tickMarkLength: 5,
						lineWidth: 2
					},
				}
			],
			xAxes: [
				{
					display:true,
					scaleLabel: {
						display: true,
						// labelString: 'Temps (h)',
						fontColor:'#3E9FDA',
						fontSize:10
					},
					type: "time",
					distribution:'series',
					// time: {
					// 	// format: "YYYY-MM-DD HH:mm",
					// 	unit: 'day',
					// // // 	// unitStepSize: 1,
					// 	displayFormats:     {
					// 		// 'minute': 'HH:mm',
					// 		// 'hour': 'HH:mm',
					// 		'day':'DD-MM'
					// 	}
					// },
					gridLines: {
						drawOnChartArea: false,
						lineWidth: 2,
						color: '#3E9FDA'
					},
					ticks: {
						display: true,
						fontColor: '#3E9FDA',
						autoSkip: true,
						// maxTicksLimit: 7,
						maxRotation: 0,
						minRotation: 0,
					},
				},
			],
		},
		legend: {
			display: true
		},
		responsive: true,
		maintainAspectRatio: false,
		annotation: {
			annotations: []
		},
		animation: {
			duration: 0
		},
		hover: {
			animationDuration: 0
		},
		responsiveAnimationDuration: 0
	},
	pollenRisks: {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						display: true,
						autoSkip: true,
						maxTicksLimit: 4,
						fontColor: '#3E9FDA',
						callback: function (value) {
							// let colors = ['#FFF','#cbea86','#ee7f47','#CE362C']
							// this.options.ticks.fontColor = colors[value]

							const risks = [
								Vue.options.filters.capitalize(i18n.t('common.chart.legend.none')),
								Vue.options.filters.capitalize(i18n.t('common.chart.legend.low')),
								Vue.options.filters.capitalize(i18n.t('common.chart.legend.medium')),
								Vue.options.filters.capitalize(i18n.t('common.chart.legend.high')),
							]
							return risks[value]
						},
						max: 3,
					},
					gridLines: {
						display: false,
						lineWidth: [1,1,1,1],
						drawBorder:false,
						zeroLineWidth: 0,
					},
					scaleLabel: {
						display: true,
						// labelString: 'Temps (h)',
						fontColor: '#3E9FDA',
						fontSize: 8
					},
				}
			],
			xAxes: [{
				gridLines: {
					display: false
				},
				type: "time",
				distribution: 'series',
				time: {
					// format: "YYYY-MM-DD HH:mm",
					unit: 'day',
					unitStepSize: 1,
					displayFormats: {
						// 'minute': 'HH:mm',
						// 'hour': 'HH:mm',
						'day': 'ddd'
					}
				},
			}]
		},
		legend: {
			display: false
		},
		responsive: true,
		maintainAspectRatio: false,
		lineTension: 1,
		annotation: {
			events: ["click"],
			annotations: [
				{
					drawTime: "afterDatasetsDraw",
					id: "hline",
					type: "line",
					mode: "horizontal",
					scaleID: "y-axis-0",
					value: 1,
					borderColor: "rgba(170,170,170,0)",
					borderWidth: 0,
					// label: {
					// 	backgroundColor: "#fff",
					// 	content: Vue.options.filters.capitalize(i18n.t('common.chart.legend.none')),
					// 	enabled: true,
					// 	fontColor: "#000000",
					// },
					// onClick: function (e) {
					// 	// The annotation is is bound to the `this` variable
					// 	console.log("Annotation", e.type, this);
					// }
				},
				{
					drawTime: "beforeDatasetsDraw",
					type: "box",
					xScaleID: "x-axis-0",
					yScaleID: "y-axis-0",
					yMin: 0,
					yMax: 1,
					borderWidth: 1,
					borderColor: "rgb(163,255,67)",
					backgroundColor: "#fff",

				},
				{
					drawTime: "beforeDatasetsDraw",
					type: "box",
					xScaleID: "x-axis-0",
					yScaleID: "y-axis-0",
					yMin: 1,
					yMax: 2,
					borderWidth: 1,
					borderColor: "rgba(255,160,62,0.31)",
					backgroundColor: "#fff",
				},
				{
					drawTime: "beforeDatasetsDraw",
					type: "box",
					xScaleID: "x-axis-0",
					yScaleID: "y-axis-0",
					yMin: 2,
					yMax: 3,
					borderWidth: 1,
					borderColor: "rgba(255,37,45,0.49)",
					backgroundColor: "#fff",

				}
			]
		},
		layout: {
			padding: {
				left: 0,
				right: 40,
				top: 20,
				bottom: 0
			}
		}
	},
	dataConsumption: {
		chart: {
			height: 280,
			type: 'radialBar'
		},
		noData:{
			text:'Aucune donnée disponible',
		},
		labels: [],
		plotOptions:{
			radialBar: {
				dataLabels:{
					total: {
						show: true,
						label: [],
						formatter: function () {
							return ''
						}
					}
				}
			}
		},
		fill:{
			type: 'solid',
			colors: ['#188cc6']
		}
	},
};
