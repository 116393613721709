<template>
    <b-row style="height : calc(100vh - 56px)" class="overflow-hidden">
        <b-col cols="2" class="bg-white flex-fill" style="position: relative">
            <div class="p-3">
                <h4 id="sidebar-no-header-title" class="clr-dark-grey">Suivi des données Pollen</h4>
                <nav class="mb-3">
                    <b-nav vertical>
                        <b-nav-item
                            active-class="active"
                            :active="selectedBoard === 'GlobalAnalytics'"
                            @click="setAnalyticsBoard('GlobalAnalytics')"
                        >
                            | Vue Globale
                        </b-nav-item>
                        <b-nav-item
                            active-class="active"
                            :active="selectedBoard === 'PollenAnalytics'"
                            @click="setAnalyticsBoard('PollenAnalytics')"
                        >
                            | Vue Pollen</b-nav-item>
                        <b-nav-item
                            active-class="active"
                            :active="selectedBoard === 'SensorAnalytics'"
                            @click="setAnalyticsBoard('SensorAnalytics')"
                        >
                            | Vue Sensor</b-nav-item>
                    </b-nav>
                </nav>
            </div>
        </b-col>
        <b-col class="col-10 m-0 p-0 scrollable" style="height: 100%">
            <b-row class="justify-content-center mt-3">
                <b-col cols="12" class="text-center font-weight-bolder h2"><p>Filtrer les données</p></b-col>
                <b-col cols="3">
                    <b-form-select v-model="selectedFilters.accounts" :options="accountOptions"></b-form-select>
                    <p
                        @click="removeFilters('accounts')"
                        v-if="selectedFilters.accounts.length > 0"
                        class="clr-orange add-cursor float-right"
                    >reset X</p>
                </b-col>
                <b-col cols="3">
                    <b-form-select v-model="selectedFilters.sensors" :options="sensorOptions" multiple></b-form-select>
                    <b-row>
                        <b-col cols="12">
                            <p
                                @click="removeFilters('sensors')"
                                v-if="selectedFilters.sensors.length > 0"
                                class="clr-orange add-cursor float-right"
                            >
                                reset X
                            </p>
                        </b-col>
                        <b-col cols="3" v-for="item in selectedFilters.sensors" :key="item" class="mr-2">
                            <b-badge pill variant="primary">{{ item |capitalize }}</b-badge>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="3">
                    <b-form-select v-model="selectedFilters.species" :options="pollenOptions" multiple></b-form-select>
                    <p
                        @click="removeFilters('species')"
                        v-if="selectedFilters.species.length > 0"
                        class="clr-orange add-cursor float-right"
                    >reset X</p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center mt-3">
                <b-col cols="3" class="mt-5">
                    <b-form-datepicker v-model="selectedFilters.startDate" :min="minDate" :max="maxDate"></b-form-datepicker>
                </b-col>
                <b-col cols="3" class="mt-5">
                    <b-form-datepicker v-model="selectedFilters.endDate" :min="minDate" :max="maxDate"></b-form-datepicker>
                </b-col>
                <b-col cols="3" class="mt-5">
                    <b-button @click.prevent="submit()">Rechercher</b-button>
                </b-col>
            </b-row>
            <component
                class="my-5"
                v-bind:is="selectedBoard"
                :startDate="selectedFilters.startDate"
                :endDate="selectedFilters.endDate"
            >
            </component>
        </b-col>
    </b-row>
</template>

<script>
import PollenAnalytics from "@/components/analytics/PollenAnalytics";
import GlobalAnalytics from "@/components/analytics/GlobalAnalytics";
import SensorAnalytics from "@/components/analytics/SensorAnalytics";
import moment from "moment";
import Vuex from "vuex";
import SecureLS from "@/plugins/secureLS";

export default  {
    name: "HomeAnalytics",
    components: {GlobalAnalytics,PollenAnalytics,SensorAnalytics},
    data() {
        return {
            selectedBoard:'GlobalAnalytics',
            availableBoard: {
                list:[
                    GlobalAnalytics,
                    PollenAnalytics
                ]
            },
            selectedFilters: {
                sensors:[],
                accounts:'',
                species:[],
                startDate:moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate:moment().format('YYYY-MM-DD'),
            },
            optionsDate:[],
            minDate: moment('2022-03-01').format('YYYY-MM-DD'),
            maxDate: moment().format('YYYY-MM-DD')
        }
    },
    computed:{
        ...Vuex.mapGetters('analytics',{
            accountOptions:'accountOptions',
            sensorOptions:'sensorOptions',
            pollenOptions:'pollenOptions',
            globalAnalytics:'globalAnalytics',
            loadingAnalytics:'loadingAnalytics'
        })
    },
    methods: {
        ...Vuex.mapActions('analytics', {
            setSensorOptions:'setSensorOptions',
            setAccountOptions:'setAccountOptions',
            setPollenOptions:'setPollenOptions',
            setGlobalAnalytics:'setGlobalAnalytics'
        }),
        setAnalyticsBoard(value) {
            this.selectedBoard = value
            this.submit()
        },
        async submit(){
            if (
                this.selectedFilters.accounts ||
                this.selectedFilters.species ||
                this.selectedFilters.sensors ||
                this.selectedFilters.startDate ||
                this.selectedFilters.endDate
            ) {
                let params = {}

                Object.entries(this.selectedFilters).forEach((filter) => {
                    const [key,value] = filter

                    if (value.length > 0 && key === 'sensors') {
                        if (!Object.values(params).includes(value)) {
                            params[key] = value;
                        } else {
                            delete params[key]
                        }
                    } else if (value.length > 0 && key === 'species' && value !== '') {
                        if (!Object.values(params).includes(value)) {
                            params[key] = value;
                        } else {
                            delete params[key]
                        }
                    } else if (value.length > 0 && ['accounts', 'startDate', 'endDate'].includes(key) && value !== '') {
                        if (!Object.values(params).includes(value)) {
                            params[key] = value;
                        } else {
                            delete params[key]
                        }
                    }
                })

                let boardParam = ''
                switch (this.selectedBoard) {
                    case "GlobalAnalytics":
                        boardParam = 'global'
                        break
                    case "PollenAnalytics":
                        boardParam = 'pollen'
                        break
                    case "SensorAnalytics":
                        boardParam = 'sensor'
                        break
                    default:
                        boardParam = 'global'
                        break
                }
                await this.setGlobalAnalytics([boardParam,{params:params}])
            }
        },
        removeFilters(filterType){
            if (filterType !== 'accounts'){
                this.selectedFilters[filterType] = []
            } else {
                this.selectedFilters[filterType] = this.accountOptions[1].value
            }
            this.submit()
        }
    },
    async created() {
        let currentUser = SecureLS.get('currentUser');
        if (currentUser && currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SUPER_ADMIN')) {
            this.$nextTick(async () => {
                await this.setAccountOptions()
                this.selectedFilters.accounts = this.accountOptions[1].value
                let params = {}
                params['accounts'] = this.selectedFilters.accounts[0]
                await this.setSensorOptions({params:params})
                await this.setPollenOptions()
                await this.submit()
            });
        } else if (currentUser || !currentUser.roles.includes('ROLE_ADMIN') || !currentUser.roles.includes('ROLE_SUPER_ADMIN')) {
            currentUser.roles.includes('ROLE_ADMIN')
            return this.$router.go(-1);
        } else {
            return this.$router.push('login');
        }
    },
    watch: {
        'selectedFilters.accounts': {
            handler(val){
                let params = {}
                params['accounts'] = val
                this.setSensorOptions({params:params})
            },
            deep: true
        }
    }

}
</script>

<style lang="scss" scoped>
.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
}

.active {
    background-color: $dark-blue;
    color: white;
}
</style>