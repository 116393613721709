<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card" v-if="loadingStatus || isLoading">
            <h2 class="mb-5 text-center">
                Mise à jour de la position du capteur
                <span class="clr-orange">
                    <br>N° {{ $route.params.id | toUpperCase }}
                </span>
            </h2>
            <b-skeleton
                v-for="(skeleton, index) in skeletons"
                :key="index"
                :width="randomWidth()"
                :height="skeleton.height"
                animation="wave"
                class="mb-4"/>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" md="8" class="basic-card" v-else>
            <h2 class="mb-5 text-center">
                Mise à jour de la position du capteur
                <span v-if="individualSensor" class="clr-orange">
                    <br>N° {{ individualSensor.serialNumber | toUpperCase }}
                </span>
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    label="Latitude"
                    label-for="latitude"
                >
                    <b-form-input
                        id="latitude"
                        v-model="address.lat"
                        placeholder="47.987623164">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Longitude"
                    label-for="longitude"
                >
                    <b-form-input
                        id="longitude"
                        v-model="address.lon"
                        placeholder="1.9873210">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Ville"
                    label-for="locality"
                >
                    <b-form-input
                        id="locality"
                        v-model="address.locality"
                        placeholder="Orléans">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Département"
                    label-for="administrative-area-lvl2"
                >
                    <b-form-input
                        id="administrative-area-lvl2"
                        v-model="address.administrativeAreaLvl2"
                        placeholder="Loiret">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Région"
                    label-for="administrative-area-lvl1"
                >
                    <b-form-input
                        id="administrative-area-lvl1"
                        v-model="address.administrativeAreaLvl1"
                        placeholder="Centre Val de Loire">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Pays (long)"
                    label-for="country"
                >
                    <b-form-input
                        id="country"
                        v-model="address.countryLongName"
                        placeholder="France">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    label="Pays (court)"
                    label-for="short-country"
                >
                    <b-form-input
                        id="short-country"
                        v-model="address.countryShortName"
                        placeholder="FR">
                    </b-form-input>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorAddressUpdate",
    data() {
        return {
            address: {
                locality: '',
                lat: '',
                lon: '',
                countryLongName: '',
                countryShortName: '',
                administrativeAreaLvl2: '',
                administrativeAreaLvl1: ''
            },
            skeletons: [
                {height: "30px"},
                {height: "50px"},
                {height: "20px"},
                {height: "50px"},
                {height: "50px"},
                {height: "20px"},
                {height: "50px"},
                {height: "50px"},
                {height: "50px"},
                {height: "50px"}
            ],
            isLoading: false
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            updateAddress: 'updateAddress',
            setIndividualSensors: 'setIndividualSensors'
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.updateAddress(query).then(() => {
                this.goToSensorProfile();
            });
        },
        generateQueryString() {
            const data = [this.$route.params.id];
            const fields = new FormData();

            for (let key in this.address) {
                fields.append(key, this.address[key]);
            }
            data.push(fields)
            return data
        },
        goToSensorProfile() {
            this.$router.push('/sensors/' + this.$route.params.id + '/detail');
        },
        randomWidth() {
            return `${Math.random() * (100 - 60) + 60}%`;
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            loadingStatus: 'loadingStatus'
        })
    },
    async created() {
        this.isLoading = true;
        if (Object.keys(this.individualSensor).length === 0 ||
            this.individualSensor.serialNumber !== this.$route.params.id) {
            await this.setIndividualSensors(this.$route.params.id);
        }
        this.address = this.individualSensor.sensorAddress
        delete this.address.lastUpdate
        this.isLoading = false;
    }
}
</script>

<style lang="scss" scoped>

</style>
