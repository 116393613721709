import CampaignCreate from "@/components/campaign/CampaignCreate";
import CampaignList from "@/components/campaign/CampaignList";
import CampaignInformation from "@/components/campaign/CampaignInformation";
import CampaignEdit from "@/components/campaign/CampaignEdit";
// import UserAssociationToAccount from "@/components/user/UserAssociationToAccount";

export default [
    {
        path: '/campaigns/new',
        component: CampaignCreate,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Campaigns',
                    link: '/campaigns'
                },
                {
                    name: 'New'
                }
            ]
        },
    },
    {
        path: '/campaigns/:id?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Campaign.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                component: CampaignList,
                name: 'campaigns',
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Campaigns'
                        }
                    ]
                },
            },
            {
                path: 'information',
                component: CampaignInformation,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Campaigns', link:'/campaigns',
                        },
                        {
                            name: 'Campaign Information'
                        }
                    ]
                },
            },
            {
                path: 'edit',
                component: CampaignEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Campaigns', link:'/campaigns',
                        },
                        {
                            name: 'Campaign Edit'
                        }
                    ]
                },
            },
            // {
            //     path: 'account',
            //     component: UserAssociationToAccount,
            //     meta: {
            //         requiresAuth: true,
            //         breadCrumb: [
            //             {
            //                 name: 'Home',
            //                 link: '/'
            //             },
            //             {
            //                 name: 'Users', link:'/campaigns',
            //             },
            //             {
            //                 name: 'Account'
            //             }
            //         ]
            //     },
            // },
        ]
    },
];
