import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

const state = {
    accountContact:[],
    loadingStatus: false,
    request:{}
};

const mutations = {
    CREATE_ACCOUNT_CONTACT: (state, account) => {
        state.loadingStatus = true
        state.accountContact.push(account);
        state.loadingStatus = false
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
};

const actions = {
    createAccountContact: async (store,data) => {
        await api.account.createAccountContact(data)
            .then( response =>  {
                store.commit('CREATE_ACCOUNT_CONTACT', response.data.data);
                store.commit('SET_REQUEST', response.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                console.log(error)
            });
    },
    editAccountContact: async (store,data) => {
        await api.account.editAccountContact(data)
            .then( response =>  {
                store.commit('SET_REQUEST', response.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    deleteAccountContact: async (store,data) => {
        await api.account.deleteAccountContact(data)
            .then( response =>  {
                store.commit('SET_REQUEST', response.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    }
};

const getters = {
    loadingStatus : state => {
        return state.loadingStatus
    },
    getRequest : state => {
        return state.request
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
