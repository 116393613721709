import api from '@/services/api/index.js'
import SecureLS from '@/plugins/secureLS.js'
import router from "../../router";
import {parseJWT} from "@/services/api/JWTParser";
import Toast from "@/services/swal2/mixins";
import i18n from "@/i18n";

const token = SecureLS.get(btoa('token'));
const state = token
	? {
		status: {loggedIn: true},
		token,
		request: {},
		resetPasswordMailSent: false,
		isResetTokenValid: false,
		connexionIsLoading: false
	}
	: {
		status: {},
		token: null,
		request: {},
		resetPasswordMailSent: false,
		isResetTokenValid: false,
		connexionIsLoading: false
	};


const mutations = {
	AUTHENTICATE: (state, token) => {
		state.status = {loggedIn: true};
		state.token = token;
		state.request = {};
	},
	LOGOUT: (state) => {
		state.status = {loggedIn: false};
		state.request = {};
		state.token = null;
	},
	CONNEXION_IS_LOADING_STATUS: (state) => {
		state.connexionIsLoading = !state.connexionIsLoading
	},
	BAD_AUTHENTICATE: (state, requestStatus) => {
		state.status = {loggedIn: false};
		state.token = null;
		state.request = requestStatus[0]
	},
	RESET_PASSWORD_MAIL: (state) => {
		state.status = {resetPasswordMailSent: true};
	},
	VALIDATE_TOKEN: (state, isValid) => {
		state.isResetTokenValid = isValid;
	}

};
const actions = {
	logMeIn: async (store, authenticate) => {
		store.commit('CONNEXION_IS_LOADING_STATUS');
		await api.authentication.authenticate(authenticate[0], authenticate[1])
			.then( response =>  {
				// store.commit('CONNEXION_IS_LOADING_STATUS');
				let navHistory = router.history._startLocation;
				if (response.data){
					let JWTToken = response.data.token
					let parsedToken = parseJWT(response.data.token)
					const roles = parsedToken.roles

					let tokenDate = new Date(parsedToken.exp * 1000)
					let timestampedToken = {
						token:JWTToken,
						timestamp:tokenDate
					}

					if (roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_LIFYAIR_COLLABORATOR')) {
						store.commit('AUTHENTICATE', timestampedToken);
						SecureLS.set(btoa('token'), timestampedToken);
						SecureLS.set('currentUser', {email:parsedToken.email, roles: roles});
						Toast.fire({
							icon: 'success',
							title: 'Bienvenue sur l\'administration LIFY AIR',
						}).then(
							setTimeout( () => { navHistory === '/login' ? router.push({path: '/'}) : router.push({ path: router.history._startLocation})
							}, 1000)
						)
					} else {
						Toast.fire({
							icon: 'error',
							title: i18n.t('error.request.unauthorized'),
						})
					}
					store.commit('CONNEXION_IS_LOADING_STATUS');

				} else {
					store.commit('CONNEXION_IS_LOADING_STATUS');
					const requestStatus = [response.data.message.message];
					store.commit('BAD_AUTHENTICATE', requestStatus);
				}


			})
			.catch(error => {
				store.commit('CONNEXION_IS_LOADING_STATUS');
				const requestStatus = [error.response.data];
				store.commit('BAD_AUTHENTICATE', requestStatus);
			});
	},
	logout: (store) => {
			store.commit('LOGOUT')
			SecureLS.removeAll()
			setTimeout( () => router.push({ path: '/login'})
				.catch(() => {}), 1000);
	},
	askForPasswordReset: async (store, data) => {
		await api.authentication.resetPassword(data)
			.then(() => {
				store.commit('RESET_PASSWORD_MAIL')
				Toast.fire({
					icon: 'success',
					title: 'Votre demande de récupération de mot de passe vous a été envoyée par mail!',
				}).then(
					setTimeout( () => {
						router.push('reset-password')
					}, 2000)
				)
			})
			.catch(error => {
				store.commit('CONNEXION_IS_LOADING_STATUS');
				//let response = error.response.data;
				const requestStatus = [error.response.data];
				store.commit('BAD_AUTHENTICATE', requestStatus);
			});
	},
	confirmToken: async (store, data) => {
		await api.authentication.confirmResetPasswordToken(data)
			.then(() => {
				store.commit('VALIDATE_TOKEN', true)
				Toast.fire({
					icon: 'success',
					title: 'Le code de vérification est correct',
				})
			} )
			.catch(() => {
				store.commit('VALIDATE_TOKEN', false);
				Toast.fire({
					icon: 'error',
					title: 'Le code de vérification est incorrect',
				})
			});
	},
	resetPassword: async (store, data) => {
		await api.authentication.sendNewPassword(data)
			.then(() => {
				Toast.fire({
					icon: 'success',
					title: 'Votre mot de passe à bien été mis à jour',
				}).then(
					setTimeout( () => router.push({ path: '/login'}), 2000)
				)
			})
			.catch(error => {
				store.commit('CONNEXION_IS_LOADING_STATUS');
				//let response = error.response.data;
				const requestStatus = [error.response.data];
				store.commit('BAD_AUTHENTICATE', requestStatus);
				Toast.fire({
					icon: 'error',
					title: 'Une erreur c\'est produite lors de la mise à jour de votre mot de passe',
				})
			});
	},
};

const getters = {
	authStatus: state => {
		return state.request
	},
	resetMailSent: state => {
		return state.resetPasswordMailSent
	},
	getConnexionIsLoading: state => {
		return state.connexionIsLoading
	},
	getIsResetTokenValid: state => {
		return state.isResetTokenValid;
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
