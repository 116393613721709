<template>
    <b-col sm="12" class="rounded shadow bg-white">
        <b-row class="bg-white justify-content-center align-content-center">
            <b-col cols="4"
                   class=" rounded add-cursor text-center pt-3"
                   @click="subPage = 'ongoing'"
                   :class="subPage === 'ongoing' ? 'clr-orange bg-white' : 'clr-dark-grey bg-lighter-grey'"
            >
                <p class="">
                    EN COURS
                </p>
            </b-col>
            <b-col cols="4"
                   class="rounded add-cursor text-center pt-3"
                   @click="subPage = 'history'"
                   :class="subPage === 'history' ? 'clr-orange bg-white' : 'clr-dark-grey bg-lighter-grey'">
                <p class="">
                    HISTORIQUE
                </p>
            </b-col>


            <b-col cols="4"
                   class="rounded text-center py-2 bg-lighter-grey">
                <b-button
                    v-b-modal.modal-sensor-event
                    variant="primary"
                    class="float-right">
                    <b-icon-plus-circle class="mr-2" scale="1.4"/>
                    Créer un événement
                </b-button>
            </b-col>
        </b-row>

        <b-row class="p-0 my-3" v-if="subPage === 'ongoing'">
            <b-col cols="12" class="bg-white ">
                <b-col cols="12" class="text-center my-5" v-if="isLoading">
                    <b-spinner style="width: 3rem; height: 3rem;" variant="primary"/>
                </b-col>
                <b-row class="px-0 px-md-5" v-else-if="ongoingSensorEvents.length > 0">
                    <SensorEventDetails
                        v-for="(event, index) in ongoingSensorEvents"
                        :event="event"
                        :key="index + '_ongoing_event'"/>
                </b-row>
                <b-col cols="12" class="text-center my-5" v-else>
                    <p>Aucun événement en cours sur ce capteur.</p>
                </b-col>
            </b-col>
        </b-row>

        <b-row class="p-0 my-3" v-else-if="subPage === 'history'">
            <b-col cols="12" class="bg-white ">
                <b-col cols="12"
                       class="sensor-monitoring text-center row"
                       v-if="completedSensorEvents.length > 0 || isLoading">
                    <SensorEventHistory :is-busy="isLoading" :events="completedSensorEvents"/>
                </b-col>
                <b-col cols="12" class="text-center my-5" v-else>
                    <p>Aucun événement n'a été créé pour ce capteur.</p>
                </b-col>
            </b-col>
        </b-row>

        <!-- CREATE NEW SENSOR EVENT-->
        <b-modal
            id="modal-sensor-event"
            ref="modal"
            title="Création d'un événement"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Type"
                    label-for="type-select"
                    invalid-feedback="Veuillez sélectionner un type"
                    :state="newSensorEventState.type">
                    <b-form-select
                        id="type-select"
                        :state="newSensorEventState.type"
                        v-model="newSensorEvent.type"
                        :options="typeOptions"/>
                </b-form-group>
                <b-form-group
                    label="Titre"
                    label-for="input-title">
                    <b-form-input
                        id="input-title"
                        :state="newSensorEventState.title"
                        v-model="newSensorEvent.title"
                        placeholder="Entrez le titre..."/>
                </b-form-group>
                <b-form-group
                    label="Description"
                    label-for="textarea-context">
                    <b-form-textarea
                        id="textarea-context"
                        v-model="newSensorEvent.context"
                        placeholder="Description de l'événement..."/>
                </b-form-group>
            </form>
        </b-modal>
    </b-col>
</template>

<script>
import Vuex from 'vuex';
import SecureLS from "@/plugins/secureLS";
import SensorEventDetails from "@/components/sensor/Detail/SAV/SensorEventDetails.vue";
import SensorEventHistory from "@/components/sensor/Detail/SAV/SensorEventHistory.vue";

export default {
    name: "SensorEvent",
    components: {SensorEventHistory, SensorEventDetails},
    data() {
        return {
            subPage: 'ongoing',
            newSensorEvent: {
                title: null,
                sensor: null,
                account: null,
                context: null,
                type: 'incident'
            },
            newSensorEventState: {
                title: null,
                sensor: null,
                account: null,
                context: null,
                type: true
            },
            typeOptions: [
                { text: 'Incident', value: 'incident', disabled: false }
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensorEvent', {
            setSensorEvents: 'setSensorEvents',
            createSensorEvent: 'createSensorEvent'
        }),
        checkFormValidity() {
            if (!this.newSensorEvent.sensor) this.newSensorEventState.sensor = false;
            if (!this.newSensorEvent.account) this.newSensorEventState.account = false;
            if (!this.newSensorEvent.type) this.newSensorEventState.type = false;
            if (!this.newSensorEvent.title) this.newSensorEventState.title = false;
            const states = Object.values(this.newSensorEventState);
            return !(states.some((state) => state === false));
        },
        resetModal() {
            this.newSensorEvent = {
                account: null,
                context: null,
                type: null
            }
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            if (!this.newSensorEvent.sensor) {
                this.newSensorEvent.sensor = this.individualSensor.id;
            }
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            const form = new FormData();
            for (const item in this.newSensorEvent) {
                form.append(item, this.newSensorEvent[item])
            }
            this.createSensorEvent(form);
            this.resetModal();
            this.$nextTick(() => {
                this.$bvModal.hide('modal-sensor-event')
            })
        }
    },
    computed: {
        ...Vuex.mapGetters('sensorEvent', {
            ongoingSensorEvents: 'ongoingSensorEvents',
            completedSensorEvents: 'completedSensorEvents',
            isLoading: 'isLoading'
        }),
        ...Vuex.mapGetters('sensor', {
            getSensorAccess: 'getSensorAccess',
            individualSensor: 'individualSensor',
        }),
        account() {
            return SecureLS.get('currentUser');
        },
    },
    mounted() {
        this.newSensorEvent.sensor = this.individualSensor.id;
        if (this.getSensorAccess.length > 0) {
            this.newSensorEvent.account = this.getSensorAccess[0]?.accountGroup?.account?.id
        }
    }
}
</script>

<style scoped lang="scss">

</style>