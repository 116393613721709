<template>
    <div class="row">
        <div class="col-xl-6 justify-content-center my-3">
            <p class="clr-white bg-dark-grey rounded p-1 my-1">Url de L'iFrame</p>
            <br>
            <span class="p-1 mt-3">URL</span><br>
            <b-form-input
                    id="url"
                    v-model="iframeParameters.url"
            ></b-form-input>
            <p class="mt-3 mb-0">Mode bannière</p>
            <b-form-checkbox v-model="iframeMode.isBanner" name="check-button" switch>
                {{ iframeMode.isBanner ? 'Oui' : 'Non' }}
            </b-form-checkbox>
        </div>
<!--        <div id="options" class="col-xl-6 my-3">-->
<!--            <p class="clr-white bg-dark-grey rounded p-1 my-1">Proportions</p><br>-->
<!--            <span class="p-1 mt-3">Hauteur : {{ iframeSize.height }} px</span><br>-->
<!--            <b-form-input id="height-range" type="range" v-model="iframeSize.height" min="340" max="1000" step="10"></b-form-input>-->
<!--            <span class="p-1 mt-3">Largeur : {{ iframeSize.width }} px</span><br>-->
<!--            <b-form-input id="width-range" type="range" v-model="iframeSize.width" min="270" max="1000" step="10"></b-form-input>-->
<!--        </div>-->
        <div class="col-xl-6 my-3 justify-content-center">
            <p class="clr-white bg-dark-grey rounded p-1 my-1">Authentification du Widget</p> <br>
            <span class="p-1 mt-3">Key</span><br>
            <b-form-input style="width: 100%" id="key" type="url" v-model="iframeParameters.key"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "IframeParameters",
    data(){
        return {
            iframeSize:{
                width:300,
                height:340
            },
            iframeMode:{
                isBanner:false,
            },
            iframeParameters:{
                url: 'https://app.live-pollen.com/widget/home',
                key:'',
            },
        }
    },
    methods:{
        prepareFrame(){
            let div = document.createElement('div');

            let divStyle = "overflow: hidden;";

            div.setAttribute('style',divStyle)
            // div.style.width = this.iframeSize.width;
            // div.style.height = this.iframeSize.height;
            // div.position = 'relative';
            // div.style.overflow = 'hidden';
            div.style.width = '100%';

            let ifrm = document.createElement("iframe");

            let fullUrl =
                this.iframeParameters.url + "?apiKey=" + this.iframeParameters.key;
            if (this.iframeMode.isBanner) fullUrl += "&isBanner=true"
            ifrm.setAttribute("src", fullUrl);
            // ifrm.setAttribute("width", this.iframeSize.width);
            // ifrm.setAttribute("height", this.iframeSize.height);
            // ifrm.style.width = this.iframeSize.width;
            // ifrm.style.height = this.iframeSize.height;
            // position: absolute;
            ifrm.style.minHeight = '380px';
            if (this.iframeMode.isBanner) ifrm.style.minHeight = '200px';
            ifrm.style.border = 0;

            ifrm.style.width = '100%';

            div.appendChild(ifrm)
            this.$emit('html-code',div)
        }
    },
    watch: {
        // whenever question changes, this function will run
        iframeParameters:  {
            handler(){
                if (this.iframeParameters.key) {
                    this.prepareFrame()
                }
            },
            deep:true
        },
        iframeSize:  {
            handler(){
                this.prepareFrame()
            },
            deep:true
        },
        iframeMode:  {
            handler(){
                this.prepareFrame()
            },
            deep:true
        }
    },
}
</script>

<style scoped>

</style>