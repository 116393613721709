<template>
    <b-row class="mb-5">
        <b-row class="col-12 pdf-banner">
            <b-col cols="12" md="6">
                <b-button class="btn-action w-100" @click.prevent="previewReport">
                    <b-icon icon="fullscreen"></b-icon>
                    Prévisualiser le rapport
                </b-button>
            </b-col>
            <b-col cols="12" md="6">
                <b-button class="btn-action w-100" @click.prevent="generateReport">
                    <b-icon icon="download"></b-icon>
                    Télécharger le rapport
                </b-button>
            </b-col>
        </b-row>
        <b-col cols="12" class="my-2">
            <p class="font-weight-bold">Résumé du test effectué :</p>
        </b-col>
        <b-row class="col-12" v-for="(step, index) in splicedSteps" :key="index">
            <b-row class="col-12">
                <b-col cols="9">
                    <p>{{ step.name }}</p>
                </b-col>
                <b-col cols="3" class="clr-orange">
                    <p class="h4">
                        <b-icon-check-circle v-if="step.completed"/>
                    </p>
                </b-col>
            </b-row>
        </b-row>
        <div class="divider mt-2"/>
        <b-col cols="12" class="mt-4 mb-2">
            <p class="font-weight-bold">Informations du capteur :</p>
        </b-col>
        <b-row class="col-12" v-for="(item, index) in sensorFields" :key="`${item.name}_field_index_${index}`">
            <b-row class="col-12">
                <b-col cols="9">
                    <p>{{ item.name }}</p>
                </b-col>
                <b-col cols="3" class="clr-orange">
                    <p v-if="item.fields.length > 1">{{ individualSensor[item.fields[0]][item.fields[1]] }}</p>
                    <p v-else>{{ individualSensor[item.fields[0]] }}</p>
                </b-col>
            </b-row>
        </b-row>
        <div class="divider mt-2"/>
        <b-row class="col-12 mt-4">
            <b-col cols="10">
                <p class="font-weight-bold">Commentaire:</p>
            </b-col>
            <b-col cols="2" class="clr-orange text-right">
                <p class="h5 add-cursor" @click="showEditContext = !showEditContext">
                    <b-icon-pencil-fill/>
                </p>
            </b-col>
        </b-row>
        <b-row class="col-12">
            <b-col cols="12" v-if="showEditContext">
                <b-form @submit.prevent="editContext">
                    <b-form-textarea
                        id="textarea"
                        v-model="context"
                        rows="3"
                        max-rows="10"
                    />
                    <b-button class="btn-action mt-2 col-12" type="submit">
                        OK
                    </b-button>
                </b-form>
            </b-col>
            <b-col cols="12" v-else-if="context">
                <p>{{ context }}</p>
            </b-col>
            <b-col cols="12" v-else>
                <p class="clr-dark-grey text-center">Aucun commentaire</p>
            </b-col>
        </b-row>
        <PDFTemplate
            :setup="sensorSetup"
            :sensor="individualSensor.serialNumber"
            :onlyPreview="onlyPreview"
            template="SensorTestingReportTemplate"
        ></PDFTemplate>
    </b-row>
</template>

<script>
import Vuex from "vuex";
import EventBus from "@/utils/event-bus";
import PDFTemplate from "@/components/pdf/PDFTemplate";

export default {
    name: "SensorTestReport",
    components: {
        PDFTemplate
    },
    data() {
        return {
            sensorFields: [
                {fields: ['serialNumber'], name: 'Numéro de série'},
                {fields: ['sensorParameters', 'simId'], name: 'Numéro de carte SIM'},
                {fields: ['sensorParameters', 'laserPower'], name: 'Puissance du laser'},
                {fields: ['sensorParameters', 'firmwareVersion'], name: 'Version du firmware'},
            ],
            showEditContext: false,
            context: null,
            onlyPreview: false,
            reportDownloaded: false
        }
    },
    methods: {
        ...Vuex.mapActions('sensorSetup', {
            editSensorSetup: 'editSensorSetup'
        }),
        async editContext() {
            let form = new FormData;
            form.append('context', this.context);
            await this.editSensorSetup({id: this.sensorSetup.id, params: form});
            this.showEditContext = false;
        },
        generateReport() {
            this.onlyPreview = false
            EventBus.$emit('generate-pdf', false);
        },
        previewReport() {
            this.onlyPreview = true
            EventBus.$emit('generate-pdf', true);
        },
    },
    computed: {
        ...Vuex.mapGetters('sensorSetup', {
            sensorSetup: 'sensorSetup',
            steps: 'steps'
        }),
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor'
        }),
        splicedSteps() {
            let steps = this.steps;
            return steps.slice(0, -1)
        }
    },
    created() {
        this.context = this.sensorSetup.context;
    }
}
</script>

<style lang="scss" scoped>
.divider {
    @include divider($lighter-grey, 90%, 3px);
}

.pdf-banner {
    margin-top: -3rem;
    margin-bottom: 30px;
}
</style>