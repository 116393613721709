export default [
    {
        path: '/publish',
        name: 'contentGenerator',
        component: () => import('@/components/publisher/ContentGenerator.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name:'Home',
                    link: '/'
                },
                {
                    name: 'Publi Twitter',
                    link: '/tweet'
                }
            ]
        }
    }
]