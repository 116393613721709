<template>
    <b-container>
        <b-row class="justify-content-center shadow rounded p-3 p-md-5">
            <b-col cols="12" md="10">
                <h2 class="text-center">Outils de développeur</h2>
                    <b-tabs class="mt-3 mt-md-5 bg-lighter-grey lighter-shadow" pills card vertical>
                        <b-tab title="Migration" class="bg-lighter-grey" active>
                            <h4 class="text-center">Migration vers la nouvelle architecture</h4>
                            <NewArchitectureMigration/>
                        </b-tab>
                        <b-tab title="Bluetooth" class="bg-lighter-grey">
                            <h4 class="text-center">Connecter un capteur en wifi via BLE</h4>
                            <WifiBluetoothConnector/>
                        </b-tab>
                    </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import NewArchitectureMigration from "@/components/developerTools/NewArchitectureMigration";
import WifiBluetoothConnector from "@/components/developerTools/WifiBluetoothConnector.vue";

export default {
    name: "DeveloperTools",
    components: {
        WifiBluetoothConnector,
        NewArchitectureMigration
    },
}
</script>

<style lang="scss" scoped>
.lighter-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>