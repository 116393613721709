<template>
    <b-col cols="12" v-if="isSensorMonitoringLoading">
        <b-card >
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
        </b-card>
    </b-col>
    <b-col cols="12" class="sensor-monitoring text-center" v-else-if="undefined !== sensorMonitoringHistory && Object.keys(sensorMonitoringHistory).length > 0">
        <b-table-simple
                responsive
                hover
                sticky-header
                class="sensor-array table-borderless"

        >
            <b-thead class="text-center ">
                <b-tr>
                    <b-th></b-th>
                    <b-th>Erreur(s) Constatée(s)</b-th>
                    <b-th>Créée le</b-th>
                    <b-th>Derniere alerte</b-th>
                    <b-th>Nombre d'apparition</b-th>
                    <b-th>Alertes Envoyés</b-th>
                    <b-th>Résolue</b-th>
                </b-tr>
            </b-thead>
            <b-tbody
            >
                <b-tr
                        v-for="(item,i) in sensorMonitoringHistory"
                        :key="i"
                        class="text-center add-cursor rounded"
                        :class="item.resolved ? 'clr-green' : 'clr-red'"
                >
                    <b-td>
                        <b-icon :icon="item.resolved ? 'check2-circle' : 'exclamation-triangle'"
                                :class="item.resolved ? 'clr-green' : 'clr-red'"
                                scale="1.5"
                        />
                    </b-td>
                    <b-td>{{ item.context }}</b-td>
                    <b-td >{{ item.createdAt | convertDate}}</b-td>
                    <b-td >{{ item.lastUpdate | dateDayMonth}}</b-td>
                    <b-td>{{ item.count }}</b-td>
                    <b-td>{{ item.notificationSent ? 'Oui' : 'Non' }}</b-td>
                    <b-td>
                        {{ item.resolved ? 'Oui' : 'Non' }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-row class="m-0 p-0 d-none" v-if="Object.keys(sensorMonitoringHistory).length > 0">
            <b-col cols="3" class="font-weight-bold d-flex float-left my-auto">
                Total Erreurs : {{ paginator.totalCount }} <br>
                Erreur par Page : {{ paginator.numItemsPerPage }}
            </b-col>
            <b-col cols="6" class="mt-3">
                <h6 class="text-center">Page(s)</h6>
                <b-pagination
                        v-model="paginator.current"
                        pills
                        :total-rows="paginator.totalCount"
                        :per-page="paginator.numItemsPerPage"
                        align="center"
                >
                </b-pagination>
            </b-col>
            <b-col cols="3" class="my-auto d-none">
                <b-form-select v-model="paginator.numItemsPerPage" :options="optionsLimits">
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="h-100" v-else>
            <b-col class="clr-darker-grey bold">
                NO ERRORS
            </b-col>
        </b-row>
    </b-col>
    <b-col cols="12" class="clr-darker-grey bg-lighter-grey text-center" v-else>
        Aucune données historique de monitoring pour le moment. Le capteur à probablement été initialisé il y a peu.
    </b-col>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "SensorMonitoringHistory",
    props: {
        sensor: {
            type: Object,
            default: () => {
            }
        },
        ptuStatus:{
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            direction: 'desc',
            optionsLimits: [
                {value: 25, text: '25'},
                {value: 50, text: '50'}
            ],
            errors: [
                [
                    {icon: 'droplet-half', text: 'PTU', context: 'ERROR_PTU'},
                    {icon: 'phone-vibrate', text: 'GSM', context: 'ERROR_GSM'},
                    {icon: 'geo-alt', text: 'GPS', context: 'ERROR_GPS'},
                    {icon: 'asterisk', text: 'PHOTODIODE 1', context: 'ERROR_PHOTODIODE_BOARD_1'},
                    {icon: 'asterisk', text: 'PHOTODIODE 2', context: 'ERROR_PHOTODIODE_BOARD_2'},
                    {icon: 'asterisk', text: 'PHOTODIODE 3', context: 'ERROR_PHOTODIODE_BOARD_3'},

                ],
                [
                    {icon: 'asterisk', text: 'PHOTODIODE 4', context: 'ERROR_PHOTODIODE_BOARD_4'},
                    {icon: 'lamp', text: 'ALL PHOTODIODE BOARD', context: 'ERROR_ALL_PHOTODIODE_BOARD'},
                    {icon: 'key', text: 'ID', context: 'ERROR_ID'},
                    {icon: 'soundwave', text: 'OFFSET LOW 1', context: 'ERROR_OFFSETV1'},
                    {icon: 'soundwave', text: 'OFFSET LOW 2', context: 'ERROR_OFFSETV2'},
                    {icon: 'soundwave', text: 'OFFSET LOW 3', context: 'ERROR_OFFSETV3'},
                ],
                [
                    {icon: 'soundwave', text: 'OFFSET LOW 4', context: 'ERROR_OFFSETV4'},
                    {icon: 'node-plus', text: 'COUNTING', context: 'ERROR_NO_COUNTING',},
                    {icon: 'x-square', text: 'NOISE', context: 'ERROR_NOISE'},
                    {icon: 'sun', text: 'LASER', context: 'ERROR_LASER'}
                ]
            ],
            fields: ['icon', 'text', 'status', 'updatedAt'],
            allPhotoDiodes : false
        }
    },
    methods: {
        ...Vuex.mapActions('monitoring', {
            setSensorMonitoringHistory: 'setSensorMonitoringHistory'
        })
    },
    computed: {
        ...Vuex.mapGetters('monitoring', {
            getSensorMonitoringHistory: 'getSensorMonitoringHistory',
            isSensorMonitoringLoading: 'isSensorMonitoringLoading',
            paginator:'paginator'
        }),
        sensorMonitoringHistory() {
            const excludedContexts = ['ERROR_KO', 'ERROR_DISCONNECTION']
            return this.getSensorMonitoringHistory.filter(item => !excludedContexts.includes(item.context))
        }
    },
    mounted() {
        let params = []
        params.push(this.sensor.serialNumber)
        params.push(this.paginator)
        this.setSensorMonitoringHistory(params);
    },
}
</script>

<style lang="scss">
.sensor-monitoring {
    .monitoring-table {
        thead {
            display: none;
        }

        .monitoring-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $cadetblue;
            color: $white;

            &.fail {
                background-color: $indianred;
            }
        }
    }
}

.responsive-font{
  font-size: 1rem
}

</style>