<template>
    <div class="offset-xl-4 col-xl-4 align-self-center justify-content-center w-100 col-sm-12 mt-sm-5">
        <h2 class="mb-5">Association du capteur {{ actualSensor }} à un compte</h2>
<!--        <p class="font-weight-bolder" v-if="Ac.memberships.length > 0">Attention : Cette Utilisateur est déja associé au compte suivant :-->
<!--            <span class="clr-dark-blue">{{ actualUser.memberships[0].accountGroup.account.name }}</span>-->
<!--        </p>-->
        <b-form @submit.prevent="submit()">
            <b-form-group
                    id="input-group-4"
                    label="Compte à associer"
                    label-for="input-4"
            >
                <b-form-select v-model="chosenAccount" :options="loadedAccounts" class="mb-3" required></b-form-select>
            </b-form-group>
            <b-button type="submit" variant="primary" v-if="true">Modifier</b-button>
            <b-button type="submit" variant="primary" v-else><b-spinner small></b-spinner></b-button>
        </b-form>
    </div>
</template>

<script>
    import Vuex from 'vuex'
    import Toast from "@/services/swal2/mixins"

    export default {
        name: "SensorAssociationToAccount",
        data() {
            return {
                chosenAccount: null,
                optionsAccounts: [],
            }
        },
        props:{
            actualSensor:Object
        },
        methods: {
            ...Vuex.mapActions('sensor', {
                editSensor:'sensor/setIndividualSensorAccess',
                setSensors:'setSensors'
            }),
            ...Vuex.mapActions('account', {
                setAccounts:'setAccounts'
            }),
            ...Vuex.mapActions('accountGroup', {
                setAccountGroups:'setAccountGroups',
            }),
            async submit() {
                let query = this.generateQueryString()
                if (query !== null){
                    await this.editSensor(query)
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: "Le capteur est déja associé !",
                    })
                }
            },
            generateQueryString(){
                let data = []
                data.push(this.$route.params.id)
                let fields = new FormData();

                fields.set('account',this.chosenAccount)
                data.push(fields)
                return data
            },
            async setOptionsAccounts(){
                for (let i = 0 ; i < this.accounts.length; i++){
                    if (!this.optionsAccounts.includes(this.accounts[i].name)){
                        this.optionsAccounts.push(this.accounts[i].name)
                    }
                }
            },
            goToSensorsList(){
                this.$router.push('/sensors/stock');
            },
            goToSensorsProfile(){
                this.$router.push('/sensors/'+this.$route.params.id+'/detail')
            }
        },
        computed: {
            ...Vuex.mapGetters('account', {
                accounts:'accounts'
            }),
            loadedAccounts(){
                this.setOptionsAccounts()
                return this.optionsAccounts
            }
        },
        async created() {
            await this.setAccounts()
            await this.setSensors({paginated: false})
        },
        watch:{
            getRequest(){
                Toast.fire({
                    icon: 'success',
                    title: this.getRequest.message,
                }).then(
                    setTimeout(this.goToSensorsProfile, 1500)
                )
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
