<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Remplacer un capteur
            </h2>
            <div class="text-center w-100" v-if="getLoadingStatus">
                <b-spinner label="Loading Replacement Options"/>
            </div>
            <div class="text-center w-100" v-else-if="optionsStockedSensor.length === 0">
                <p>Aucun capteur testé</p>
            </div>
            <b-form v-else @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    label="Capteur à remplacer"
                    label-for="sensor-to-be-replaced"
                >
                    <b-form-input
                        id="sensor-to-be-replaced"
                        v-model="sensorToBeReplaced"
                        required
                        :disabled="!!$route.params.id"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Capteur à déstocker"
                    label-for="sensor-to-be-destocked"
                >
                    <b-form-select
                        id="sensor-to-be-destocked"
                        v-model="newSensor"
                        :options="optionsStockedSensor">
                    </b-form-select>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button :disabled="!newSensor" class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorReplaceV2",
    data() {
        return {
            oldSensor: null,
            newSensor: null,
            optionsStockedSensor: []
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensor: 'editSensor',
            sensorReplaceV2: 'sensorReplaceV2'
        }),
        ...Vuex.mapActions('sensorList', {
            setSensorsByState: 'setSensorsByState',
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.sensorReplaceV2(query).then(() =>
                setTimeout(() => {
                    this.goToSensorProfile();
                }, 500)
            )
        },
        generateQueryString() {
            let fields = new FormData();

            fields.set('oldSensor', this.sensorToBeReplaced)
            fields.set('newSensor', this.sensorToBeDestocked)

            return fields
        },
        setOptionsStocked() {
            this.optionsStockedSensor = this.sensors.map(item => item.serialNumber);
        },
        goToSensorProfile() {
            this.$router.push('/sensors/' + this.oldSensor + '/detail');
        },
    },
    computed: {
        ...Vuex.mapGetters('sensorList', {
            sensors: 'sensors',
            getLoadingStatus: 'getLoadingStatus',
        }),
        sensorToBeReplaced() {
            return this.oldSensor
        },
        sensorToBeDestocked() {
            return this.newSensor
        }
    },
    async created() {
        await this.setSensorsByState({state: 'tested'})
        this.setOptionsStocked()
        this.oldSensor = this.$route.params.id
    },
}
</script>

<style lang="scss" scoped>

</style>
