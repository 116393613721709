<template>
	<div>
		<div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
	}
</script>
