<template>
    <div class="offset-xl-4 col-xl-4 col-sm-auto align-self-center justify-content-center w-100">
        <h2 class="mb-5 clr-dark-blue text-center">
            ECHANGE DE CAPTEUR
        </h2>
        <b-form @submit.prevent="submit()" v-if="optionsStockedSensor.length > 0" >
            <b-form-group
                    id="input-group-4"
                    label="Capteur à remplacer"
                    label-for="sensorToBeReplaced"
            >
                <b-form-input
                        id="input-1"
                        v-model="sensorToBeReplaced"
                        required
                        :disabled="!!$route.params.id"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                    id="input-group-4"
                    label="Capteur à déstocker"
                    label-for="sensorToBeDestocked"
            >
                <b-form-select v-model="newSensor" :options="optionsStockedSensor" class="mb-3">
                </b-form-select>
            </b-form-group>
            <div class="mx-auto">
                <b-button type="submit" variant="primary"  class="mr-2">Editer</b-button>
                <b-button @click="goToSensorProfile" variant="danger">Annuler</b-button>
            </div>
        </b-form>
        <b-spinner label="Loading Replacement Options" v-else></b-spinner>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins"

export default {
    name: "SensorReplace",
    data() {
        return {
            oldSensor:null,
            newSensor:null,
            optionsStockedSensor:[]
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensor:'editSensor',
            setStockedSensors:'setStockedSensors',
            sensorExchange:'sensorExchange'
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.sensorExchange(query).then(
                setTimeout(() => {
                    this.$router.push('/sensors');
                }, 2000)
            )
        },
        generateQueryString(){
            let fields = new FormData();

            fields.set('oldSensor',this.sensorToBeReplaced)
            fields.set('newSensor',this.sensorToBeDestocked)

            return fields
        },
        setOptionsStocked(){
            this.optionsStockedSensor = [];
            for (let i = 0 ; i < this.stockedSensors.length; i++){
                this.optionsStockedSensor.push(this.stockedSensors[i].serialNumber)
            }
        },
        goToSensorProfile(){
            this.$router.push('/sensors/'+ this.oldSensor+'/detail');
        },
    },
    computed:{
        ...Vuex.mapGetters('sensor',{
            stockedSensors:'stockedSensors',
            paginator:'paginator',
            loadingStatus:'loadingStatus',
            lastRefresh:'lastRefresh',
        }),
        sensorToBeReplaced(){
            return this.oldSensor
        },
        sensorToBeDestocked(){
            return this.newSensor
        },
        loadedAccounts(){
            return this.optionsStockedSensor
        }
    },
    watch:{
        getRequest(){
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
            }).then(
                setTimeout(() => {
                    this.goToSensorProfile()
                }, 3000)
            )
        },
        'stockedSensors': function () {
            this.setOptionsStocked()
        }
    },
    async created() {
        await this.setStockedSensors({status: 'stock', sort: 'sensor.serialNumber', limit: 0})
        this.setOptionsStocked()
        this.oldSensor = this.$route.params.id
    },
}
</script>

<style lang="scss" scoped>

</style>
