<template>
    <div ref="accountGroupTable">
        <b-col cols="12">
            <b-table-simple
                responsive
                hover
                sticky-header
                borderless
                v-if="accountGroup.length > 0 && !isBusy"
            >
                <b-thead>
                    <b-tr>
                        <b-th>Nom du groupe</b-th>
                        <b-th v-if="full">Logo</b-th>
                        <b-th v-if="full"> Action</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        v-for="(item,i) in accountGroup"
                        :key="i"
                        class="add-cursor clr-dark-grey justify-content-center align-items-center"
                    >
                        <b-td class="align-middle">{{ item.name }}</b-td>
                        <b-td class="align-middle" v-if="item.logoName">
                            <b-img :src="`${assetsBucket}/assets/account/${item.logoName}`"
                                   class="logo-account" v-if="item.logoName" style="width: 64px"/>
                            <p v-else>Aucun logo</p>
                        </b-td>
                        <b-td class="align-middle" v-else>No Logo</b-td>
                        <b-td class="align-middle">
                            <b-row class="align-items-center clr-orange p-0 m-0">
                                <p @click="showGroupDetails(item)"
                                   class="m-0 p-0 col-xl-2 justify-content-center align-items-center">
                                    <b-icon icon="pencil-fill" aria-hidden="true" class="mr-2"></b-icon>
                                </p>
                                <p @click="deleteGroup(item)" class="m-0 p-0 col-xl-2 text-center">
                                    <b-icon icon="dash-square" aria-hidden="true"></b-icon>
                                </p>
                            </b-row>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-modal
                v-if="updatedItem"
                id="modal-edit-group"
                ref="modal1"
                centered
                :title="'Mise à jour logo du group : '+updatedItem.name "
                @ok="handleOk"
            >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group
                        id="input-group-1"
                        label="Nom du groupe :"
                        label-for="input-1"
                        description="Il représente le sous-groupe ou le sous-client du compte"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="updatedItem.name"
                            placeholder="Entrez le nom du groupe"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Logo"
                        label-for="logo-input"
                        invalid-feedback="Logo is required"
                    >
                        <b-form-file
                            v-model="updatedItem.logo"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <b-img v-if="updatedItem.logo" :src="logoSrc" class="mb-3 p-3 text-center mx-auto"
                               style="width: 100px" fluid block rounded></b-img>
                    </b-form-group>
                </form>
            </b-modal>
            <b-modal
                v-if="selectedItem"
                id="modal-delete-group"
                ref="modal2"
                centered
                :title="'Suppression du groupe : '"
                @ok="handleOk"
            >
                <p>Êtes vous sur de vouloir supprimer ce groupe ? Les utilsateurs associés ne seront plus lié !</p>
            </b-modal>
        </b-col>
    </div>
</template>

<script>
import Vuex from "vuex";

const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export default {
    name: "AccountGroupTable",
    props: {
        accountGroup: Array,
        dataLocalisation: Object,
        loadingStatus: Boolean,
        sort: String,
        direction: String,
        isBusy: Boolean,
        full: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            viewMenu: false,
            top: '0px',
            left: '0px',
            isDeployingOrTesting: false,
            deploySteps: {
                2: 'Attente données brutes',
                3: 'Attente données métiers',
                4: 'Attente de validation'
            },
            testSteps: {
                2: 'Attente données brutes',
                3: 'Attente données brutes',
                4: 'Attente données métiers',
                5: 'Attente de validation'
            },
            assetsBucket: process.env.VUE_APP_AWS_ASSETS_BUCKET,
            selectedModal: '',
            selectedItem: null,
            updatedItem: null,
            logo: null,
            logoSrc: null,
        }
    },
    methods: {
        ...Vuex.mapActions('accountGroup', {
            deleteAccountGroup: 'deleteAccountGroup',
            editAccountGroup: 'editAccountGroup'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        async handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            if (this.selectedModal === 'edit') {
                await this.handleSubmit()
            } else if (this.selectedModal === 'delete') {
                await this.handleDelete()
            }

        },
        async handleSubmit() {
            let query = this.generateQueryString()
            await this.editAccountGroup(query);

            this.$nextTick(async () => {
                this.$bvModal.hide('modal-edit-group')
                await this.setAccounts()
            })
        },
        async handleDelete() {
            await this.deleteAccountGroup(this.selectedItem.id);
            this.$nextTick(async () => {
                this.$bvModal.hide('modal-delete-group')
                await this.setAccounts()
            })
        },
        generateQueryString() {
            let data = []
            data.push(this.updatedItem.id)
            let fields = new FormData();

            if (this.updatedItem.name !== '') {
                fields.append('name', this.updatedItem.name)
            }

            if (this.updatedItem.logo !== '') {
                fields.append('logo', this.updatedItem.logo)
            }

            data.push(fields)
            return data
        },
        setMenu: function (top, left) {
            this.top = (top - 267) + 'px';
            this.left = (left - 61) + 'px';
        },
        closeMenu: function () {
            this.viewMenu = false;
        },
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        showGroupDetails(item) {
            this.selectedItem = item
            this.updatedItem = item
            this.selectedModal = 'edit'
            this.$bvModal.show('modal-edit-group')
        },
        deleteGroup(item) {
            this.selectedItem = item
            this.selectedModal = 'delete'
            this.$bvModal.show('modal-delete-group')
        }
    },
    watch: {
        logo(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.logoSrc = value;
                        })
                        .catch(() => {
                            this.logoSrc = null;
                        });
                } else {
                    this.logoSrc = null;
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

#right-click-menu {
    background: #FAFAFA;
    border: 1px solid #BDBDBD;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 250px;
    z-index: 999999;
}

#right-click-menu li {
    border-bottom: 1px solid #E0E0E0;
    margin: 0;
    padding: 5px 35px;
}

#right-click-menu li:last-child {
    border-bottom: none;
}

#right-click-menu li:hover {
    background: $primary;
    color: #FAFAFA;
}

.dot {
    margin: 5px 0;
}
</style>
