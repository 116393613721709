<template>
  <div id="app">
    <component :is="layout">
      <router-view id="page-wrap"/>
    </component>
  </div>
</template>

<script>
import NoSidebar from "./components/layout/NoSideBar";
import Default from "./components/layout/Default";


const default_layout = "Default";

export default {
  name: 'App',
  components: {
    NoSidebar,
    Default
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout);
    },
  },

}
</script>

<style>

</style>
