<template>
    <apexchart height="300" type="line" :options="options" :series="series" ref="linechart"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,

    },
    props:{
        preSeries: {}
    },
    data() {
        return{
            options: {
                colors : ['#188cc6'],
                chart: {
                    type: 'line'
                },
                stroke:{
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    max: 8,
                    min: 0,
                    forceNiceScale: true,
                },
                tooltip: {
                    x:{
                        show:false
                    },
                    y:{
                        show:false
                    }
                },
                legend:{
                    show: false,
                    onItemHover:{
                        highlightDataSeries: false
                    }
                },
                noData:{
                    text:'Aucune donnée disponible',
                }
            },
            series: [{
                name: 'Cons.',
                data: []
            }],
            dataC: []
        }
    },
    methods:{
        randomCons(){
            const newData = this.series[0].data.map(() => {
                return Math.floor(Math.random() * (8 - 1) + 1)
            })
            // In the same way, update the series option
            this.series = [{
                data: newData
            }]
        },
        async createSeries(data){
            for (let i of data){
                let x = new Date(i.date_range_start).getTime();
                let y = Math.round(i.aggregated_volume * Math.pow(10, -6) * 100) / 100;
                this.dataC.push([
                    x,
                    y
                ])
            }
            this.series[0].data = this.dataC.slice();
            this.$refs.linechart.updateSeries([{
                data: this.dataC.slice()
            }], false, true);
        }
    },
    created() {
        this.dataC = [];
    },
    mounted() {
        this.createSeries(this.preSeries);
    }
}
</script>

<style scoped>

</style>