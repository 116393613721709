<template>
    <apexchart :width="width" :height="height" type="bar" :stacked="false" :options="chartOptions" :series="series"/>
</template>

<script>
export default {
    name: "BarChart",
    props: {
        series: {
            type: Array,
            default: () => {
                return [{
                    name: 'Global Concentration',
                    data: []
                }]
            }
        },
        chartOptions: {
            colors: function ({value}) {
                let riskColor = [
                    '#008FFB',
                    '#78da6f',
                    'rgba(255,90,0,0.71)',
                    'rgba(227,34,0,0.72)'
                ]
                return riskColor[Math.floor(value)];
            }
        },
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        }
    },
}
</script>

<style scoped>

</style>