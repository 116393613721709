<template>
    <GeneratorForm></GeneratorForm>
</template>

<script>
    import GeneratorForm from "@/components/generator/GeneratorForm";
    export default {
        name: "DataGenerator",
        components:{
            GeneratorForm,

        },
    }
</script>

<style scoped>

</style>