import MobileSettings from "@/components/mobile/MobileSettings.vue";

export default [
    {
        path: '/mobile',
        component: () => import('@/views/Mobile.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name:'Home',
                    link: '/'
                },
                {
                    name: 'Mobile',
                    link: '/mobile'
                }
            ]
        },
        children: [
            {
                path: '',
                component: MobileSettings,
                name: 'mobileSettings',
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name:'Home',
                            link: '/'
                        },
                        {
                            name: 'Settings'
                        }
                    ]
                }
            }
        ]
    }
]