<template>
    <b-row class="justify-content-center">
        <!-- FILTERS -->
        <b-col cols="12">
            <b-row class="justify-content-between my-4">
                <b-col cols="12" md="6">
                    <b-button @click="$router.go(-1)" variant="primary">
                        <b-icon icon="arrow-left"></b-icon>
                        Retour
                    </b-button>
                </b-col>
                <b-col cols="12" md="6" class="mt-2 mt-md-0">
                    <b-input-group prepend="Rechercher" class="" v-on:submit.prevent="queryAlert"
                                   v-on:keyup.enter="queryAlert">
                        <b-form-input v-model="search" placeholder="Level / Type / Context"></b-form-input>
                        <b-input-group-append>
                            <b-button type="submit" variant="primary" @click="queryAlert">Go</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-col>

        <b-col cols="12">
            <b-col cols="12" class="basic-card">
                <AlertTable
                    v-if="alerts.length > 0"
                    :alerts="alerts"
                    :dataLocalisation="dataLocalisation"
                    :loading-status="loadingStatus"
                    @set-sorting="setSort"
                ></AlertTable>
                <p class="col-12 p-0 text-center my-5" v-else>
                    Aucun compte dans cette catégorie
                </p>
            </b-col>
        </b-col>

        <!-- PAGINATION -->
        <b-col cols="12" class="mt-3" v-if="!loadingStatus">
            <b-row class="justify-content-center align-items-center w-100">
                <b-col cols="12" md="4" class="font-weight-bold">
                    Total Comptes : {{ paginator.totalCount }} <br>
                    Comptes par Page : {{ paginator.numItemsPerPage }}
                </b-col>
                <b-col cols="12" md="4" class="mt-3">
                    <h6 class="text-center">Page(s)</h6>
                    <b-pagination
                        v-model="paginator.current"
                        pills
                        :total-rows="paginator.totalCount"
                        :per-page="paginator.numItemsPerPage"
                        align="center">
                    </b-pagination>
                </b-col>
                <b-col cols="12" md="4" class="my-3">
                    <b-form-select v-model="paginator.numItemsPerPage" :options="optionsLimits">
                    </b-form-select>
                </b-col>
            </b-row>
        </b-col>

    </b-row>
</template>

<script>
import Vuex from 'vuex';
import AlertTable from "@/components/sensor/Detail/Alert/AlertTable.vue";

export default {
    name: "AlertList",
    components: {AlertTable},
    data() {
        return {
            isBusy: false,
            text: '',
            hoverAlert: null,
            search: '',
            timer: '',
            sort: 'alert.createdAt',
            direction: 'desc',
            optionsLimits: [
                {value: 10, text: '10'},
                {value: 25, text: '25'},
                {value: 50, text: '50'}
            ],
            checkedType: false,
            dataLocalisation: {}
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            alerts: 'alerts',
            paginator: 'paginator',
            loadingStatus: 'loadingStatus',
        })
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setAlerts: 'setAlerts',
            setChosenType: 'setChosenType',
            emptyAlerts: 'emptyAlerts'
        }),
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        setHoveredAlert(id) {
            this.hoverAlert = id;
        },
        goToAccountInformation(id) {
            this.$router.push('/account' + id);
        },
        async queryAlert() {
            let currentPage = {}
            if (this.paginator.current > 1 && this.paginator.current !== null && this.paginator.current !== undefined) {
                currentPage.page = this.paginator.current
            } else {
                currentPage.page = 1;
            }

            this.paginator.numItemsPerPage !== undefined ? currentPage.limit = this.paginator.numItemsPerPage : currentPage.limit = 25

            this.search.length >= 3 ? currentPage.search = this.search : '';
            this.sort !== '' ? currentPage.sort = this.sort : '';
            this.direction !== '' ? currentPage.direction = this.direction : '';
            let serialNumber = this.$route.params.id
            await this.setAlerts({sensor: serialNumber, paginator: currentPage})
        },
        cancelAutoUpdate() {
            clearInterval(this.timer)
        },
        setSort(data) {
            this.sort = data
            this.direction === 'desc' ? this.direction = 'asc' : this.direction = 'desc';
            this.queryAlert()
        }
    },
    async created() {
        if (this.alerts.length === 0) {
            this.toggleBusy()
            await this.queryAlert()
            this.toggleBusy()
        }
        this.timer = setInterval(this.queryAlert, 360000)
    },
    watch: {
        'paginator.current'() {
            this.queryAlert()
        },
        'paginator.numItemsPerPage'() {
            this.queryAlert()
        },
        'search'() {
            if (this.search.length < 3) {
                this.queryAlert()
            }
        },
        '$route': function () {
            if (!this.isOnline(this.lastRefresh)) {
                this.queryAlert()
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.emptyAlerts()
    }
}
</script>

<style scoped>
</style>