import DeveloperTools from "@/views/DeveloperTools";

export default [
    {
        path: '/developer-tools',
        component: DeveloperTools,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Outils de développeur'
                }
            ]
        }
    }
];