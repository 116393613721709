<template>
      <b-row>
        <b-col cols="12" class="p-0">
            <b-row class="p-3">
                <b-col cols="6">
                    <h5 class="clr-darker-grey mb-2">
                        Informations SIM
                    </h5>
                </b-col>
                <b-col cols="6">
                    <h5 class="h5 float-right add-cursor add-hover clr-orange"
                        @click="sendToExternalLink('https://m2mmanager.matooma.com/fr/home/parc-management/devices?simcardIccid='+simId)">
                        n° {{ simId }}
                    </h5>
                </b-col>
                <b-col cols="4" class="mb-5">
                <b-row>
                    <b-col cols="12">
                        <p class="mb-2">
                                <span class="clr-orange">
                                    <b-icon
                                            icon="house"
                                            class="mr-3">
                                    </b-icon>
                                    Opérateur:
                                </span>
                            <span class="float-right font-weight-bold clr-darker-grey" v-if="simData">{{ simData.operatorSlug | toUpperCase }}</span>
                        </p>
                    </b-col>
                    <b-col cols="12">
                        <p class="mb-2">
                                <span class="clr-orange">
                                    <b-icon icon="house" class="mr-3"></b-icon>Commandé par:</span>
                            <span class="float-right font-weight-bold" v-if="simData">{{ simData.orderedBy }}</span>
                        </p>
                    </b-col>
                    <b-col cols="12">
                        <p class="mb-2">
                                <span class="clr-orange">
                                    <b-icon
                                            icon="house"
                                            class="mr-3">
                                    </b-icon>
                                    Commandé le:
                                </span>
                            <span class="float-right font-weight-bold" v-if="simData">{{ simFormatDate(simData.simcardOrderDate) }}</span>
                        </p>
                    </b-col>
                    <b-col cols="12">
                        <p class="mb-2">
                                <span class="clr-orange">
                                    <b-icon icon="house" class="mr-3"></b-icon>Etat de la SIM:</span>
                            <span class="float-right font-weight-bold" v-if="simData">{{ simData.simcardState }}</span>
                        </p>
                    </b-col>
                </b-row>
            </b-col>

            </b-row>
        </b-col>
        <b-col cols="12" class="">
            <div class="p-3 bg-white rounded">
                <div>
                    <h5 class="clr-darker-grey add-cursor w-100" v-if="simData">
                        Suivi de consommation
                    </h5>
                    <h6 v-else>
                        Pas de carte sim connectée
                    </h6>
                </div>
                <b-row class="p-3 justify-content-center align-content-center" v-if="simData">
                    <b-col
                        cols="6"
                        class=" d-flex flex-column align-content-center align-items-center justify-content"
                        v-if="consumptionData">
                        <div class="mb-2">
                            <b-icon icon="arrow-down-up" class="clr-orange" scale="1.3"></b-icon>
                            <span class="h4 m-0 ml-2 clr-orange">Consommation Totale</span>
                        </div>
                        <SensorSIMConsumptionChart :consumption="consumptionData" :maxcons="125"
                                                   :progress="consumptionData / 125 * 100"></SensorSIMConsumptionChart>
                    </b-col>
                    <b-col cols="6"
                           class=" d-flex flex-column align-content-center align-items-center justify-content"
                           v-if="consumptionDailyData">

                        <div class="mb-2">
                            <b-icon icon="arrow-down-up" class="clr-orange" scale="1.3"></b-icon>
                            <span class="h4 m-0 ml-2 clr-orange">Consommation Journaliere</span>
                        </div>
                        <SensorSIMConsumptionChart v-if="consumptionDailyData.last"
                                                   :consumption="consumptionDailyData.last" :maxcons="3.2"
                                                   :progress="consumptionDailyData.last/3.2*100"></SensorSIMConsumptionChart>
                    </b-col>
                    <b-col cols="12"
                           class="text-center"
                           v-if="consumptionDailyData && consumptionDailyData.aggregated"
                    >
                        <b-form-row class="mb-2">
                            <b-icon icon="arrow-down-up" class="clr-orange" scale="1.3"></b-icon>
                            <span class="h4 m-0 ml-2 clr-orange">Conso. Journa.</span>
                        </b-form-row>
                        <SensorSIMConsumptionDaily
                            :pre-series="consumptionDailyData.aggregated"></SensorSIMConsumptionDaily>
                    </b-col>
                </b-row>
            </div>
        </b-col>
      </b-row>
</template>

<script>
import SensorSIMConsumptionDaily from "@/components/chart/SensorSIMConsumptionDaily.vue";
import SensorSIMConsumptionChart from "@/components/chart/SensorSIMConsumptionChart.vue";
import Vuex from "vuex";

export default {
    name: "MatoomaFollowUp",
    components: {SensorSIMConsumptionChart, SensorSIMConsumptionDaily},
    props:{
        simId:String,

    },
    data(){
        return {
            simData: null,
            consumptionData: null,
            consumptionDailyData: null,
        }
    },
    computed:{
        ...Vuex.mapGetters('matooma', {
            getConsumption: 'getConsumption',
            getDailyConsumption: 'getDailyConsumption',
            getSimInfo: 'getSimInfo',
            isConsumptionLoading: 'getLoadingStatus'
        }),
    },
    methods: {
        ...Vuex.mapActions('matooma', {
            setConsumption: 'setConsumption',
            setToken:'setToken'
        }),
        sendToExternalLink(url) {
            let win = window.open(url, '_blank');
            win.focus();
        },
        simFormatDate(input) {
            let datePart = input.match(/\d+/g),
                year = datePart[0].substring(2), // get only two digits
                month = datePart[1], day = datePart[2];

            return day + '/' + month + '/' + year;
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatDate(date) {
            return [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
            ].join('-');
        },
        async consumptionTotal() {
            let date = new Date();
            date.setDate(date.getDate() - new Date().getDate() - 1);
            let params = {
                iccid: this.simId,
                period: {start: this.formatDate(date), end: this.formatDate(new Date())}
            }
            await this.setConsumption(params);
        },
        async setConsumptionTotal() {
            this.getConsumption.then(result => {
                if (result.data) {
                    this.consumptionData = Math.round(result.data.consumption[0].offer.data * Math.pow(10, -6));
                }
            })
        },
        async setSimData() {
            await this.getSimInfo.then(result => {
                if (result.data) {
                    this.simData = result.data[0];
                }
            })
        },
        async setConsumptionDaily() {
            this.getDailyConsumption.then(result => {
                if (result.data) {
                    this.consumptionDailyData = {
                        aggregated: result.data.data_offer.aggregated_volumes.slice(0, -1),
                        last: Math.round(result.data.data_offer.aggregated_volumes.at(-2).aggregated_volume * Math.pow(10, -6) * 100) / 100
                    }
                }
            })
        }
    },
    async created() {
        await this.setToken()
        await this.consumptionTotal();
        await this.setSimData();
        await this.setConsumptionDaily();
        await this.setConsumptionTotal();
    },
    watch: {

    }
}
</script>

<style scoped>
.check-box-orange:checked.check-box-orange:before { color: #fff; border-color: #ff5a00; background-color: #ff5a00;}
</style>