<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Création d'un utilisateur
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    id="input-group-1"
                    label-for="input-1"
                    description="Email de l'utilisateur à créer"
                >
                    <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                            Email
                        </b-input-group-prepend>
                        <b-form-input
                            id="input-1"
                            v-model="email"
                            type="email"
                            required
                            placeholder="client@client.com"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    description="Mot de passe Aléatoire en cliquant sur les fléches"
                >
                    <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                            Password
                        </b-input-group-prepend>
                        <b-form-input
                            id="input-2"
                            v-model="password"
                            required
                            placeholder="*************"
                        ></b-form-input>
                        <b-input-group-append is-text>
                            <b-icon icon="arrow-repeat" @click="generateRandomPassword(8)"></b-icon>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    id="input-group-4"
                    label-for="input-4"
                    description="Le compte auquel sera rattaché l'utilisateur"
                >
                    <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                            Compte
                        </b-input-group-prepend>
                        <b-form-select v-model="chosenAccount" :options="loadedAccounts" class=""></b-form-select>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    id="input-group-5"
                    label-for="input-5"
                    v-if="chosenAccount"
                    description="Le Groupe auquel sera rattaché l'utilisateur"
                >
                    <b-input-group size="md" class="mb-2">
                        <b-input-group-prepend is-text>
                            Groupe
                        </b-input-group-prepend>
                        <b-form-select v-model="chosenGroup" :options="loadedAccountGroups" class=""></b-form-select>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    label="Envoyer l'email d'onboarding client ?"
                    label-for="send-email"
                    description="Si OUI le client recevra un courriel contenant les étapes à suivre pour se connecter au superviseur.">
                    <b-form-checkbox
                        id="send-email"
                        v-model="sendEmail"
                        name="check-send-email"
                        switch>
                        <span class="bold" :class="sendEmail ? 'clr-orange' : ''">{{ sendEmail ? 'OUI' : 'NON' }}</span>
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    id="input-group-3"
                    label="Role"
                    label-for="input-3"
                    description="Role principal de l'utilisateur"
                >
                    <b-input-group class="mb-2 ml-3">
                        <b-form-radio-group v-model="roles">
                            <b-form-radio v-for="(role) in optionsRoles" :key="role.value" :value="role.value">
                                {{ role.text }}
                            </b-form-radio>
                        </b-form-radio-group>
                        <b-form-checkbox v-model="access" value="ROLE_API">Ce compte accède uniquement à l'api
                        </b-form-checkbox>
                    </b-input-group>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Créer</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import generator from "@/utils/generator";

export default {
    name: "UserCreate",
    data() {
        return {
            email: '',
            password: '',
            roles: '',
            chosenGroup: null,
            chosenAccount: null,
            optionsRoles: [
                {value: 'ROLE_ACCOUNT_MANAGER', text: 'Ce compte sera le gérant de ce compte'},
                {value: 'ROLE_COLLABORATOR', text: 'Ce compte est un collaborateur de ce compte'},
            ],
            access: false,
            optionsAccounts: [],
            optionsAccountGroups: [],
            other: true,
            sendEmail: true
        }
    },
    methods: {
        ...Vuex.mapActions('user', {
            createUser: 'createUser',
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('accountGroup', {
            setAccountGroups: 'setAccountGroups',
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.createUser(query).then(() => {
                setTimeout(() => {
                    this.$router.push(`/users/${this.getRequest.data.id}/information`)
                }, 1000)
            })
        },
        generateQueryString() {
            let data = new FormData();
            data.append('email', this.email)
            data.append('password', this.password)
            if (this.roles !== '')
                data.append('roles[]', this.roles)
            if (this.access === 'ROLE_API') {
                data.append('roles[]', 'ROLE_API');
            }
            data.append('account', this.chosenAccount)
            data.append('sendEmail', this.sendEmail)
            data.append('accountGroup', this.chosenGroup)
            return data
        },
        setOptionsAccounts() {
            this.optionsAccounts = [];
            this.accounts.forEach((account => {
                this.optionsAccounts.push(account.name)
            }))
        },
        setOptionsAccountGroups() {
            this.optionsAccountGroups = []
            let accountList = this.accounts
            let selectedAccount = accountList.filter(item => item.name === this.chosenAccount)[0]
            for (let i = 0; i < selectedAccount.accountGroups.length; i++) {
                this.optionsAccountGroups.push(selectedAccount.accountGroups[i].name)
            }
        },
        goToUsersList() {
            this.$router.push('/users');
        },
        generateRandomPassword(l = 8) {
            this.password = generator.generatePassword(l)
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
        ...Vuex.mapGetters('user', {
            getRequest: "getRequest"
        }),
        loadedAccounts() {
            this.setOptionsAccounts()
            return this.optionsAccounts
        },
        loadedAccountGroups() {
            this.setOptionsAccountGroups()
            return this.optionsAccountGroups
        }
    },
    async created() {
        if (this.$route.params.type === 'mobile') this.sendEmail = false;
        await this.setAccounts()
    },
    watch: {
        chosenAccount: function (val) {
            let acc = this.accounts.find(account => account.name === val);
            if (val === 'LIFYAIR') {
                this.optionsRoles = [
                    {value: 'ROLE_ADMIN', text: 'Ce compte est un administrateur'},
                    {value: 'ROLE_LIFYAIR_COLLABORATOR', text: 'Ce compte est un collaborateur de lify air'},
                ]
                this.roles = 'ROLE_LIFYAIR_COLLABORATOR';
                this.access = '';
            } else {
                this.optionsRoles = [
                    {value: 'ROLE_ACCOUNT_MANAGER', text: 'Ce compte sera le gérant de ce compte'},
                    {value: 'ROLE_COLLABORATOR', text: 'Ce compte est un collaborateur de ce compte'},
                ]
                if (acc.planLevel !== 'pro') {
                    this.roles = 'ROLE_COLLABORATOR';
                    this.access = '';
                }
                if (acc.planLevel === 'pro') {
                    this.roles = ''
                }
            }
        },
        selectedRoles: function (val) {
            let acc = this.accounts.find(account => account.name === this.chosenAccount);
            if (val.includes('ROLE_ADMIN') || val.includes('ROLE_LIFYAIR_COLLABORATOR') || val.includes('ROLE_ACCOUNT_MANAGER' && acc.planLevel !== 'pro')) {
                this.access = '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
