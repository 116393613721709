<template>
    <div class="row">
        <div class="col-xl-12">
            <p class="font-weight-bolder"> SUIVI DES API :</p>
            <b-card class="p-1" no-body>
                <b-tabs content-class="mt-3">
                    <b-tab :title="item.EnvironmentName" active v-for="(item,i) in status.Environments" :key="i">
                        <p><span class="font-weight-bolder">Nom d'Environnement :</span> {{ item.EnvironmentName }}</p>
                        <p><span class="font-weight-bolder">Nom d'Application :</span> {{ item.ApplicationName }}</p>
                        <p><span class="font-weight-bolder">Version d'Application :</span> {{ item.VersionLabel }}</p>
                        <p><span class="font-weight-bolder">Stack Server :</span> {{ item.SolutionStackName }}</p>
                        <p><span class="font-weight-bolder">EndPoint Url :</span> {{ item.EndpointURL }}</p>
                        <p><span class="font-weight-bolder">Dernière MAJ :</span> {{ item.DateUpdated }}</p>
                        <p><span class="font-weight-bolder">Status :</span> {{ item.Status }}</p>
                        <p><span class="font-weight-bolder">Santé de l'application :</span> {{ item.HealthStatus.toUpperCase() }}<span class="ml-2 dot" :class="'bg-'+item.Health.toLowerCase()"></span></p>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
import AWS from "aws-sdk";
export default {
    name: "AWSEBS",
    data() {
        return {
            envStatus:[],
            envHealth:[]
        }
    },
    methods:{
        async getApplication(){
            AWS.config.update({
                accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
                region: process.env.VUE_APP_AWS_DEFAULT_REGION
            });

            let elasticBeanstalk = new AWS.ElasticBeanstalk();

            await elasticBeanstalk.describeEnvironments((err, data) => {
                if (err) {
                    console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
                } else {
                   this.envStatus = data;
                }
            });
        },
    },
    computed:{
        status(){
            return this.envStatus;
        }
    },
    async mounted() {
        await this.getApplication()
    }
}
</script>

<style scoped>

</style>