"use strict";
import axios from 'axios'
import store from "@/store";
import Toast from "@/services/swal2/mixins";
import SecureLS from "@/plugins/secureLS";
import i18n from "@/i18n";
import moment from "moment";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + '/v1',
})
const errorInterceptor = error => {

    // check if it's a server error
    if (!error.response) {
        return Promise.reject(error);
    }

    if (error.response.data.error_code) {
        switch (error.response.data.error_code) {
            case 400:
                Toast.fire({
                    icon: 'error',
                    title: JSON.stringify(error.response.data.message),
                })
                break;
            default:
                Toast.fire({
                    icon: 'error',
                    title: "Request Failed",
                })
        }
    } else {
        // all the error responses
        switch (error.response.status) {
            case 400:
                console.error(error.response.status, error.message);
                break;

            case 401: // authentication error, logout the user
                if(error.response.data.message === "Invalid credentials.") {
                    SecureLS.removeAll()
                    Toast.fire({
                        icon: 'error',
                        title: i18n.t('error.request.credentials'),
                    })
                } else if(error.response.data.message === "Expired JWT Token") {
                    store.dispatch('login/logout')
                    Toast.fire({
                        icon: 'error',
                        title: i18n.t('error.request.disconnected'),
                    })
                } else if(error.response.data.message === "An authentication exception occurred.") {
                    store.dispatch('login/logout')
                    Toast.fire({
                        icon: 'error',
                        title: i18n.t('error.request.disconnected'),
                    })
                }
                break;

            default:
                Toast.fire({
                    icon: 'error',
                    title: i18n.t('error.request.requestFailed'),
                })
        }
    }


    return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = response => {
    switch (response.status) {
        case 200:
            // yay!
            break;
        // any other cases
        default:
            Toast.fire({
                icon: 'success',
                title: "Request Succeded",
            })
    }

    return response;
}

const requestInterceptor = request => {
    const now = moment().format('YYYYMMDD')
    request.headers['x-api-host'] =
        btoa(process.env.VUE_APP_PROJECT_ID + '_' + process.env.VUE_APP_PROJECT_ENV + '.' + now);

    return request;
}

service.interceptors.response.use(responseInterceptor, errorInterceptor);
service.interceptors.request.use(requestInterceptor);

export default service