<template>
    <b-row class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel">
                <b-row>
                    <b-col cols="12" class="px-3 mb-4">
                        <b-row class="sidebar-title align-items-center justify-content-center no-gutters">
                            <b-col cols="8">
                                <div class="mb-0 d-inline">
                                    <h6 class="mb-0 clr-darker-grey">ADMIN</h6>
                                    <h5 class="clr-orange">
                                        LIVE POLLEN
                                    </h5>
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <select class="select-css d-inline float-right" v-model="$i18n.locale">
                                    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{
                                        lang
                                        }}
                                    </option>
                                </select>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="px-3 mb-4">
                        <b-nav vertical class="sidebar-item" v-for="(item,i) in menu" :key="i">
                            <div
                                    :class="getRoutePath === item.childs[0].routePath ? 'nav-link active add-cursor' : 'nav-link add-cursor'"
                            >
                                <b-row class="align-content-center"
                                       v-if="!item.category && checkRole(item.childs[0].roles)"
                                       @click="menuRouteDispatcher(item)">
                                    <div
                                            class="text-center bg-white d-flex justify-content-center align-items-center px-3 bg-transparent"
                                            style="height: 1.5em;width: 1.5em;">
                                        <b-icon :icon="item.childs[0].icon"
                                                :class="getRoutePath === item.childs[0].routePath ? 'clr-orange' : ''"/>
                                    </div>
                                    <span> &nbsp; {{ item.childs[0].text }}</span>
                                </b-row>
                                <b-row
                                        @click="expandDiv(i)"
                                        class="align-content-center"
                                        v-else
                                >
                                    <div
                                            class="text-center bg-white d-flex justify-content-center align-items-center px-3"
                                            style="height: 1.5em;width: 1.5em;">
                                        <b-icon :icon="item.icon"
                                                :class="getRoutePath === item.childs[0].routePath ? 'clr-orange' : ''"/>
                                    </div>
                                    <span> &nbsp;{{ item.category }}</span>
                                    <b-row v-if="expandedDiv.includes(i)" transition="expand" class="subCategory">
                                        <div v-for="(child,j) in item.childs" :key="j">
                                            <div
                                                    v-if="checkRole(child.roles)"
                                                    :class="getRoutePath === item.childs[0].routePath ? 'active add-cursor' : 'add-cursor'"
                                                    @click="menuRouteDispatcher(child)"
                                                    class="my-1"
                                            >
                                                <b-row class="align-content-center">
                                                    <div
                                                            class="text-center bg-white d-flex justify-content-center align-items-center px-3"
                                                            style="height: 1.5em;width: 1.5em;">
                                                        <b-icon :icon="child.icon"/>
                                                    </div>
                                                    <span> &nbsp;{{ child.text }}</span>
                                                </b-row>
                                            </div>
                                        </div>
                                    </b-row>
                                </b-row>
                            </div>
                        </b-nav>
                    </b-col>
                </b-row>
                <div class="bg-dark-grey p-2 add-cursor" style="position:absolute;bottom:0;left:0;right:0;">
                    <a @click="logout" class="clr-white text-center">
                        <h6>DÉCONNEXION &nbsp;
                            <b-icon icon="door-open-fill"/>
                        </h6>
                    </a>
                </div>
            </div>
        </transition>
    </b-row>
</template>
<script>
import {navigation, mutations} from '@/store/modules/navigation.js'
import SecureLS from "@/plugins/secureLS";
import Vuex from "vuex";

export default {
    data() {
        return {
            langs: ["fr", "en"],
            breadCrumbList: [],
            expandedDiv: [],
            menu: [
                {
                    childs: [
                        {
                            to: '/',
                            routePath: 'dashboard',
                            icon: 'house-fill',
                            text: 'Tableau de bord',
                            roles: []
                        }
                    ],
                    category: ''
                },
                {
                    childs: [
                        {
                            to: '/sensors',
                            routePath: 'sensors',
                            icon: 'gear-fill',
                            text: 'Sensors',
                            roles: []
                        }
                    ],
                    category: ''
                },
                {
                    childs: [
                        {
                            to: '/users/supervisor',
                            routePath: 'userList',
                            icon: 'people-fill',
                            text: 'Utilisateurs Clients',
                            roles: []
                        },
                        {
                            to: '/users/mobile',
                            routePath: 'userList',
                            icon: 'people-fill',
                            text: 'Utilisateurs Mobiles',
                            roles: []
                        }
                    ],
                    category: 'Users',
                    icon: 'people-fill'
                },
                {
                    childs: [{
                        to: '/accounts',
                        routePath: 'accounts',
                        icon: 'diagram2-fill',
                        text: 'Comptes',
                        roles: []
                    }],
                    category: ''
                },
                // {
                //     childs: [{
                //         to: '/campaigns',
                //         routePath: 'campaigns',
                //         icon: 'wallet-fill',
                //         text: 'Campagnes',
                //         roles: []
                //     }],
                //     category: ''
                // },
                // {
                //     childs: [{
                //         to: '/parameters',
                //         routePath: 'parameters',
                //         icon: 'globe2',
                //         text: 'Gestion Paramètres',
                //         roles: []
                //     }],
                //     category: ''
                // },
                {
                    childs: [{
                        to: '/infrastructures',
                        routePath: 'infrastructures',
                        icon: 'hdd-network-fill',
                        text: 'Infrastructure',
                        roles: ['ROLE_SUPER_ADMIN']
                    }],
                    category: '',
                },
                // {
                //     childs: [{
                //         to: '/dataviz',
                //         routePath: 'dataviz',
                //         icon: 'pie-chart-fill',
                //         text: 'Dataviz',
                //         roles: []
                //     }],
                //     category: ''
                // },
                {
                    childs: [
                        {
                            to: '/developer-tools',
                            routePath: 'developerTools',
                            icon: 'code-square',
                            text: 'Developer Tools',
                            roles: []
                        },
                        {
                            to: '/mobile',
                            routePath: 'mobile',
                            icon: 'phone',
                            text: 'Parametre Mobile',
                            roles: ["ROLE_SUPER_ADMIN"]
                        }
                    ],
                    category: 'Developer Tools',
                    icon: 'code-square'
                },
                {
                    childs: [
                        {
                            to: '/generator/data',
                            routePath: 'generator',
                            icon: 'dice2',
                            text: 'Générateur de données',
                            roles: []
                        },
                        {
                            to: '/generator/iframe',
                            routePath: 'generator',
                            icon: 'bounding-box-circles',
                            text: 'Générateur de Widget',
                            roles: ["ROLE_ADMIN"]
                        }
                    ],
                    category: '',
                    icon: 'code-square'
                },
                {
                    childs: [
                        {
                            to: '/publish',
                            routePath: 'contentGenerator',
                            icon: 'twitter',
                            text: 'Générateur de contenu',
                            roles: []
                        }
                    ],
                    category: ''
                },
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),
        closeSidebarPanel: mutations.toggleNav,
        menuRouteDispatcher(route) {
            let filterRoute = ''
            if ('category' in route && route.category === '') {
                filterRoute = route.childs[0].to
            } else if (!('category' in route)) {
                filterRoute = route.to
            }
            this.$router.push(filterRoute)
            mutations.toggleNav()
        },
        checkRole(roles) {
            if (roles.length > 0) {
                for (let i = 0; i < roles.length; i++) {
                    if (this.currentUser.roles.includes(roles[i])) {
                        return true
                    } else {
                        return false
                    }
                }
            } else {
                return true
            }
        },
        expandDiv(i) {
            if (this.expandedDiv.includes(i)) {
                let index = this.expandedDiv.indexOf(i)
                if (index > -1) { // only splice array when item is found
                    this.expandedDiv.splice(index, 1); // 2nd parameter means remove one item only
                }
            } else {
                this.expandedDiv.push(i)
            }
        }
    },
    computed: {
        isPanelOpen() {
            return navigation.isNavOpen
        },
        getRoutePath() {
            return this.$route.name
        },
        currentUser() {
            return SecureLS.get('currentUser');
        },
        canGenerateWidget() {
            return this.currentUser.roles.includes('ROLE_SUPER_ADMIN')
                || this.currentUser.roles.includes('ROLE_ADMIN')
        },
        isSuperAdmin() {
            return this.currentUser.roles.includes('ROLE_SUPER_ADMIN');
        }
    }
}
</script>
<style>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.4s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
}

.sidebar-backdrop {
    background-color: rgba(0, 0, 0, .3);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1000;
}

.sidebar-panel {
    color: rgba(0, 0, 0, .5);
    overflow-y: auto;
    /*background-color: white;*/
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 3rem 20px 2rem 20px;
    width: 300px;
    box-shadow: 1px 1px 12px grey;
    z-index: 1001;
    /*background-image: linear-gradient(326deg, #71AFD2 0%, #37EBBA 74%);*/
    background-color: white !important;
}
</style>
