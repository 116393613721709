<template>
    <div class="justify-content-center">
        <StreamBarcodeReader @decode="onDecode" class="mx-auto"/>
    </div>
</template>

<script>
import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
    name: "SensorScan",
    components: {
        StreamBarcodeReader
    },
    data() {
        return {
            decodedCode: [],
        }
    },
    methods: {
        onDecode(result) {
            const decoded = result.split(';')
            let decodedData = [];
            decoded.forEach((text) => {
                const code = text.split(':');
                const name = code[0];
                decodedData[name] = code[1];
            })
            this.decodedCode = decodedData
            this.$emit('fillInformation', this.decodedCode)
        },
    },
    computed: {
        code() {
            return this.decodedCode;
        }
    }
}
</script>

<style scoped>

</style>