import axios from "../../plugins/axios";
import authHeader from "@/services/api/authHeader";

export default {
    transfertAccountEquipmentToDataAccessByAccount,
}

function transfertAccountEquipmentToDataAccessByAccount(account) {
    return axios.get('/admin/developer-tools/migration/account/'+ account +'/sensors',authHeader.classicalHeader())
}
