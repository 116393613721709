<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Ajout d'une note pour le capteur :
                <span v-if="actualSensor" class="clr-orange">
                    <br>N° {{ actualSensor |toUpperCase }}
                </span>
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    label="Sujet"
                    label-for="status"
                >
                    <b-form-input
                        id="status"
                        v-model="action"
                        :state="nameState"
                        placeholder="Entrer le type de note (ex: Réparation)"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                        Au minimum 3 lettres sont requises
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Context associé"
                    label-for="context"
                >
                    <b-form-textarea
                        id="context"
                        v-model="context"
                        placeholder="Context de la note (ex: Capteur en réparation suite à une chute)"
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorCreateNote",
    data() {
        return {
            optionsAction: [
                {value: 'STOCKED', text: 'En Stock'},
                {value: 'SENT', text: 'Envoyé'},
                {value: 'INSTALLED', text: 'Installé'},
                {value: 'RETURNED', text: 'Retour client'},
                {value: 'FIXING', text: 'En réparation'},
                {value: 'FIXED', text: 'Réparé'},
            ],
            action: '',
            context: ''
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
                addSensorNote: 'addSensorNote'
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.addSensorNote(query).then(() => {
                this.goToSensorProfile();
            });
        },
        generateQueryString() {
            const fields = new FormData();
            fields.set('sensor', this.individualSensor.id)
            fields.set('action', this.action)
            fields.set('context', this.context)
            fields.set('icon', 'pen')
            fields.set('variant', 'light')
            return fields
        },
        goToSensorProfile() {
            this.$router.push('/sensors/' + this.$route.params.id + '/detail');
        },
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            getSensorsById: 'getSensorsById',
            individualSensor: 'individualSensor'
        }),
        nameState() {
            return this.action.length > 3
        },
        actualSensor() {
            return this.$route.params.id
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
