import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";
import router from "@/router";

const state = {
    reportTypes: [],
    paginator: {
        current:1,
    },
    loadingStatus: false,
    request:{}
};


const mutations = {
    SET_REPORT_TYPES: (state, reportTypes) => {
        state.loadingStatus = true
        state.reportTypes = reportTypes;
        state.loadingStatus = false
    },
    SET_PAGINATOR: (state, paginator) => {
        state.loadingStatus = true
        state.paginator = paginator;
        state.loadingStatus = false
    },
    UPDATE_INDIVIDUAL_ReportType: (state, data) => {
        state.loadingStatus = true
        let selectedReportType = state.reportTypes.find(reportType => reportType.id === data.id)
        Object.assign(selectedReportType, data);
        state.loadingStatus = false
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request = {status: requestStatus[1], message: requestStatus[0]}
    },
    CREATE_REPORT_TYPE: (state, reportType) => {
        state.loadingStatus = true
        state.reportTypes.push(reportType);
        state.loadingStatus = false
    },
    SET_REQUEST: (state, request) => {
        state.request = request;
    },
    REPORT_TYPES_IS_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus
    },
    UPDATE_REPORT_TYPES_AFTER_DELETE: (state, data) => {
        state.loadingStatus = true
        let selectedReportTypes = state.reportTypes.map(item => item.id).indexOf(data);
        state.reportTypes.splice(selectedReportTypes,1)
        state.loadingStatus = false
    },
};
const actions = {
    setReportTypes: async (store) => {
        await api.parameter.getReportType()
            .then( response =>  {
                // store.commit('SENSORS_IS_LOADING_STATUS');
                store.commit('SET_REPORT_TYPES', response.data.data);
                // store.commit('SET_PAGINATOR', response.data.paginator);
            })
    },
    createReportType: async (store,data) => {
        await api.parameter.createReportType(data)
            .then( response =>  {
                // store.commit('SENSORS_IS_LOADING_STATUS');
                store.commit('CREATE_REPORT_TYPE', response.data.data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                }).then(
                    router.push('/parameters/report-types')
                )
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    editReportType: async (store,data) => {
        await api.reportType.updateReportType(data)
            .then( response =>  {
                store.commit('UPDATE_INDIVIDUAL_REPORT_TYPE', response.data.data);
                store.commit('SET_REQUEST', response.data);
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    emptyReportTypes: async (store) => {
        store.commit('SET_REPORT_TYPES', []);
    },
    deleteReportType: async (store,data) => {
        await api.parameter.deleteReportType(data)
            .then( response =>  {
                store.commit('UPDATE_REPORT_TYPES_AFTER_DELETE', data);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
                .then(
                    setTimeout(router.push('/parameters/report-types'), 3050)
                )
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
};

const getters = {
    reportTypes: state => {
        return state.reportTypes
    },
    paginator: state => {
        return state.paginator
    },
    getReportTypesById: (state) => (id) => {
        return state.reportTypes.find(reportType => reportType.id === id)
    },
    loadingStatus : state => {
        return state.loadingStatus
    },
    getRequest : state => {
        return state.request
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
