<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card" v-if="loadingStatus || isLoading">
            <h2 class="mb-5 text-center">
                Modifier le statut du capteur
                <span class="clr-orange">
                    <br/>
                    {{ $route.params.id |toUpperCase }}
                </span>
            </h2>
            <b-skeleton
                v-for="(skeleton, index) in skeletons"
                :key="index"
                :width="randomWidth()"
                :height="skeleton.height"
                animation="wave"
                class="mb-4"/>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" md="8" class="basic-card" v-else>
            <h2 class="mb-5 text-center">
                Modifier le statut du capteur
                <span v-if="individualSensor" class="clr-orange">
                    <br/>
                    {{ individualSensor.serialNumber |toUpperCase }}
                </span>
            </h2>
            <b-row class="px-1 px-md-5">
                <b-col cols="12">
                    <p>Passer le capteur en status :</p>
                </b-col>
                <b-col
                    cols="12"
                    md="auto"
                    v-for="(item, index) in statusOptions"
                    :key="`status_${index}`">
                    <b-badge
                        pill
                        class="add-cursor subtitle px-3 py-2 mb-2 mb-md-0"
                        :class="status === item.value ? 'bg-orange clr-white' : 'bg-white clr-orange border-orange'"
                        @click="choseStatus(item.value)">
                        {{ item.text }}
                    </b-badge>
                </b-col>
            </b-row>
            <b-row class="px-1 px-md-5 mt-4" v-if="status">
                <b-col cols="12">
                    <p>Passer le capteur en état :</p>
                </b-col>
                <b-col
                    cols="12"
                    md="auto"
                    v-for="(item, index) in statusStepOptions[status]"
                    :key="`status_step_${index}`">
                    <b-badge
                        pill
                        class="add-cursor subtitle px-3 py-2 mb-2 mb-md-0"
                        :class="statusStep === item.value ? 'bg-orange clr-white' : 'bg-white clr-orange border-orange'"
                        @click="choseStatusStep(item.value, item.helper)">
                        {{ item.text }}
                    </b-badge>
                </b-col>
                <b-col cols="12" class="mt-3">
                    <b-form-text>{{ helper }}</b-form-text>
                </b-col>
            </b-row>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="goToSensorProfile">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" :disabled="!status || !statusStep" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorEditState",
    data() {
        return {
            isLoading: false,
            status: null,
            statusStep: null,
            helper: '',
            statusOptions: [
                {value: 'stock', text: 'Stock'},
                {value: 'test', text: 'Test'},
                {value: 'deploy', text: 'Déploiement'},
                {value: 'prod', text: 'Production'},
                {value: 'out', text: 'Out'},
            ],
            statusStepOptions: {
                stock: [
                    {value: 1, text: 'En stock', helper: 'Le capteur est en stock'},
                    {
                        value: 2,
                        text: 'En cours de retour',
                        helper: 'La capteur a été renvoyé par le client. (en transit)'
                    },
                    {
                        value: 3,
                        text: 'En attente de maintenance',
                        helper: 'La capteur est arrivé chez Lifyair et attend la réparation ou maintenance'
                    }
                ],
                test: [
                    {value: 1, text: 'En cours de test', helper: 'Le capteur est en cours de test'},
                    {value: 2, text: 'Testé', helper: 'Le capteur est testé et validé'}
                ],
                deploy: [
                    {value: 1, text: 'En transit', helper: 'La capteur est remis au service de livraison'},
                    {value: 2, text: 'En attente d\'installation', helper: 'Le capteur est arrivé chez le client'}
                ],
                prod: [
                    {value: 1, text: 'En ligne', helper: 'Le capteur fonctionne'},
                    {
                        value: 2,
                        text: 'En vigilance',
                        helper: 'La capteur à un dysfonctionnement réglable à distance'
                    },
                    {
                        value: 3,
                        text: 'En quarantaine',
                        helper: 'La capteur est mis en quarantaine car il a un dysfonctionnement qui nécessite une maintenance OU qu’il doit être retourné pour une autre raison.'
                    }
                ],
                out: [
                    {
                        value: 1,
                        text: 'Abandon client',
                        helper: 'Le capteur à été abandonné par le client. Il ne sera pas installé.'
                    },
                    {value: 2, text: 'Hors cycle', helper: 'La capteur est obsolète pour une raison spécifique.'}
                ],
            },
            skeletons: [
                {height: "30px"},
                {height: "50px"},
                {height: "50px"},
                {height: "50px"}
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            editSensor: 'editSensor',
            setIndividualSensors: 'setIndividualSensors'
        }),
        async submit() {
            if (this.status === 'prod' && this.statusStep === 3) {
                this.$swal.fire({
                    title: 'Mise en quarantaine',
                    html: "Cela signifie que le client a déjà été contacté et que la situation est en attente.<br/>Le capteur sera mis en <b>PRIVÉ</b> et ne sera donc plus visible par les utilisateurs !!",
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'Annuler',
                    confirmButtonColor: '#FF5A01',
                    cancelButtonColor: '#AAB4C5',
                    confirmButtonText: 'Oui, je suis sûr!'
                }).then(async (result) => {
                    if (result.value) {
                        let query = this.generateQueryString()
                        await this.editSensor(query).then(() => {
                            this.goToSensorProfile();
                        });
                    }
                })
            } else {
                let query = this.generateQueryString()
                await this.editSensor(query).then(() => {
                    this.goToSensorProfile();
                });
            }
        },
        generateQueryString() {
            let data = []
            data.push(this.$route.params.id)
            let fields = new FormData();
            fields.set('status', this.status)
            fields.set('statusStep', this.statusStep)

            data.push(fields)
            return data
        },
        goToSensorProfile() {
            this.$router.go(-1);
        },
        choseStatus(status) {
            this.status = status;
            this.helper = this.statusStepOptions[status][0].helper;
        },
        choseStatusStep(statusStep, helper) {
            this.statusStep = statusStep;
            this.helper = helper;
        },
        randomWidth() {
            return `${Math.random() * (100 - 60) + 60}%`;
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            loadingStatus: 'loadingStatus'
        })
    },
    async created() {
        this.isLoading = true;
        if (Object.keys(this.individualSensor).length === 0 ||
            this.individualSensor.serialNumber !== this.$route.params.id) {
            await this.setIndividualSensors(this.$route.params.id);
        }
        this.status = this.individualSensor.sensorParameters.status;
        this.statusStep = this.individualSensor.sensorParameters.statusStep;
        this.helper = this.statusStepOptions[this.status].find(step => step.value === this.statusStep).helper
        this.isLoading = false;
    }
}
</script>

<style lang="scss" scoped>

</style>
