import moment from "moment";

export default {
	calculateOperationRateLevel,
	calculateOperationRateViability
}

function calculateOperationRateLevel(rate){
	let level = 'high';

	if (rate >= 35 && rate < 70) {
		level = 'medium';
	} else if (rate >= 70) {
		level = 'low'
	}
	return level;
}

function calculateOperationRateViability(data) {
	let result = {isRecent: true, period: null};
	if (data !== null) {
		let operationRateDate = moment(data.createdAt);
		let operationRatePeriod = moment().diff(operationRateDate, 'days')
		result.period = operationRatePeriod;
		if (operationRatePeriod > 1) {
			result.isRecent = false;
		}
	}
	return result;
}
