import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";
import router from "@/router";
import moment from "moment";
import Vuex from 'vuex'

const state = {
	sensors: [],
	sensorList: [],
	individualSensor:{},
	individualSensorAccess:{},
	stockedSensors:[],
	paginator: {
		current:1,
		limit:50
	},
	loadingStatus: false,
	request: {},
	lastRefresh: moment().format(),
	histories: [],
	associationHistories:[],
	alerts: [],
	actualOffsetHistory: [],
	chosenType:'pollen',
	chosenStatus: 'prod',
	chosenStatusStep: 1,
	operationRate:[],
	adminSensors:[],
	availableLocations: [],
	createdSensors: [],
	returningSheets:[],
	createdNote: null
};


const mutations = {
	SET_SENSORS: (state, sensors) => {
		state.loadingStatus = true;
		state.sensors = sensors;
		state.lastRefresh = moment().format();
		state.loadingStatus = false;
	},
	SET_SENSOR_LIST: (state, sensors) => {
		state.loadingStatus = true;
		state.sensorList = sensors;
		state.loadingStatus = false;
	},
	SET_INDIVIDUAL_SENSOR: (state, sensor) => {
		state.loadingStatus = true;
		state.individualSensor = sensor;
		state.loadingStatus = false;
	},
	SET_INDIVIDUAL_SENSOR_ACCESS: (state, access) => {
		state.loadingStatus = true;
		state.individualSensorAccess = access;
		state.loadingStatus = false;
	},
	SET_ADMIN_SENSORS: (state, sensors) => {
		state.loadingStatus = true;
		state.adminSensors = sensors;
		state.lastRefresh = moment().format();
		state.loadingStatus = false;
	},
	SET_SENSORS_OPERATION_RATE: (state, data) => {
		state.loadingStatus = true;
		state.operationRate = data;
		state.loadingStatus = false;
	},
	SET_STOCKED_SENSORS: (state, sensors) => {
		state.loadingStatus = true;
		state.stockedSensors = sensors;
		state.sensors = sensors;
		state.lastRefresh = moment().format();
		state.loadingStatus = false;
	},
	SET_HISTORIES: (state, data) => {
		state.loadingStatus = true;
		state.histories = data.histories;
		state.associationHistories = data.associationHistories;
		state.loadingStatus = false;
	},
	SET_ALERTS: (state, alerts) => {
		state.loadingStatus = true;
		state.alerts = alerts;
		state.loadingStatus = false;
	},
	SET_PAGINATOR: (state, paginator) => {
		state.loadingStatus = true
		state.paginator = {...paginator};
		state.loadingStatus = false
	},
	SET_REQUEST: (state, request) => {
		state.request = request;
	},
	BAD_REQUEST: (state, requestStatus) => {
		state.request = requestStatus;
	},
	SET_CREATED_SENSORS: (state, sensors) => {
		state.createdSensors = sensors;
	},
	UPDATE_INDIVIDUAL_SENSOR: (state, data) => {
		state.loadingStatus = true
		let selectedSensor = data.serialNumber
		Object.assign(selectedSensor, data);
		state.loadingStatus = false
	},
	UPDATE_SENSORS_AFTER_DELETE: (state, data) => {
		state.loadingStatus = true
		let selectedSensor = state.sensors.map(item => item.serialNumber).indexOf(data);
		state.sensors.splice(selectedSensor,1)
		state.loadingStatus = false
	},
	SET_CHOSEN_TYPE: (state,data) => {
		state.chosenType = data
	},
	SET_CHOSEN_STATUS: (state,data) => {
		state.chosenStatus = data
	},
	SET_CHOSEN_STATUS_STEP: (state,data) => {
		state.chosenStatusStep = data
	},
	SET_LOADING_STATUS: (state,value) => {
		state.loadingStatus = value;
	},
	SET_ACTUAL_OFFSET_HISTORY: (state,data) => {
		state.actualOffsetHistory = data;
	},
	SET_AVAILABLE_LOCATIONS: (state,data) => {
		state.availableLocations = data;
	},
	SET_RETURNING_SHEETS: (state,data) => {
		state.returningSheets = data;
	},
	COMPLETE_RETURNING_SHEETS: (state,data) => {
		state.returningSheets = state.returningSheets.filter(obj => obj.returnNumber !== data);
	},
	SET_CREATED_NOTE: (state, data) => {
		state.createdNote = data;
	}
};
const actions = {
	setSensors: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		const response = await api.sensor.getSensors(data);
		const sensors = response.data.data.sensors;
		const paginator = response.data.data.paginator;
		if (paginator) {
			store.commit('SET_PAGINATOR', paginator);
		}
		store.commit('SET_SENSORS', sensors);
		store.commit('SET_LOADING_STATUS', false);
	},
	setSensorList: async (store) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.getSensorsList()
			.then( response =>  {
				store.commit('SET_SENSOR_LIST', response.data.data);
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	setIndividualSensors: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.getIndividualSensors(data)
			.then( response =>  {
				store.commit('SET_INDIVIDUAL_SENSOR', response.data.data);
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	setIndividualSensorAccess: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.getSensorDataAccess(data)
			.then( response =>  {
				store.commit('SET_INDIVIDUAL_SENSOR_ACCESS', response.data.data);
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	setAdminSensors: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.getAdminSensors(data)
			.then( response =>  {
				store.commit('SET_ADMIN_SENSORS', response.data.data);
				const paginator = response.data.data.paginator;
				if (paginator) {
					store.commit('SET_PAGINATOR', paginator);
				}
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	setStockedSensors: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		data.status = 'stock'
		await api.sensor.getSensors(data)
			.then( response =>  {
				store.commit('SET_STOCKED_SENSORS', response.data.data.sensors);
				const paginator = response.data.data.paginator;
				if (paginator) {
					store.commit('SET_PAGINATOR', paginator);
				}
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	setHistories: async (store,data) => {
		await api.sensor.getSensorHistories(data)
			.then( response =>  {
				store.commit('SET_HISTORIES', response.data.data);
			})
	},
	setAlerts: async (store,data) => {
		await api.sensor.getSensorAlerts(data)
			.then( response =>  {
					store.commit('SET_ALERTS', response.data.alerts);
					const paginator = response.data.paginator;
					if (paginator) {
						store.commit('SET_PAGINATOR', paginator);
					}
				}).catch(error => {
					if (error.response.status === 401) {
						Vuex.dispatch('login/logout')
					}
				});
	},
	setOperationRateData: async (store,data) => {
		await api.sensor.getSensorOperationRate(data)
			.then( response =>  {
				store.commit('SET_SENSORS_OPERATION_RATE',response.data.data);
			}).catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
				if (error.response.status === 401) {
					Vuex.dispatch('login/logout')
				}
			});
	},
	emptyHistories: async (store) => {
		store.commit('SET_HISTORIES', {});
	},
	emptyAlerts: async (store) => {
		store.commit('SET_ALERTS', []);
		store.commit('SET_PAGINATOR', {
			current:1,
			limit:25
		});
	},
	emptyIndividualSensor: async (store) => {
		store.commit('SET_INDIVIDUAL_SENSOR', []);
		store.commit('SET_INDIVIDUAL_SENSOR_ACCESS', {});
	},
	stockSensor: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.stockSensor(data)
			.then( (response) =>  {
				store.commit('UPDATE_INDIVIDUAL_SENSOR', response.data.data);
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				}).then(
					setTimeout(() => {
						router.go(-1)
					}, 2000)
				)
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	destockSensor: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.destockSensor(data)
			.then( (response) =>  {
				store.commit('SET_REQUEST', response.data);
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	editSensor: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.updateSensor(data)
			.then( (response) =>  {
				store.commit('UPDATE_INDIVIDUAL_SENSOR', response.data.data);
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch((error) => {
				const requestStatus = {
					message: error.response.data.message,
					status: error.response.data.error_code,
					success: false
				};
				store.commit('BAD_REQUEST', requestStatus);
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	editSensorSimple: async (store,data) => {
		store.commit('SET_LOADING_STATUS', true);
		await api.sensor.updateSensor(data)
			.then((response) => {
				store.commit('SET_INDIVIDUAL_SENSOR', response.data.data);
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				const requestStatus = {
					message: error.response.data.message,
					status: error.response.data.error_code,
					success: false
				};
				store.commit('BAD_REQUEST', requestStatus);
			});
		store.commit('SET_LOADING_STATUS', false);
	},
	deleteSensor: async (store,data) => {
		await api.sensor.deleteSensor(data)
			.then( response =>  {
				store.commit('UPDATE_SENSORS_AFTER_DELETE', data);
				Toast.fire({
						icon: 'success',
						title: response.data.message,
					}
				).then(
					setTimeout(() => {
						router.push('/sensors')
					}, 1000)
				)
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	createSensors: async (store,data) => {
		await api.sensor.createSensors(data)
			.then(response => {
				store.commit('SET_CREATED_SENSORS', response.data.data.sensors);
				store.commit('SET_REQUEST', response.data);
				return true
			})
			.catch(error => {
				const requestStatus = {
					message: error.response.data.message,
					status: error.response.data.error_code,
					success: false
				};
				store.commit('BAD_REQUEST', requestStatus);
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
				return false
			});
	},
	addSensorNote: async (store,data) => {
		await api.sensor.addSensorNote(data)
			.then( response =>  {
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
				store.commit('SET_CREATED_NOTE', response.data.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	editStatus: async(store,data) => {
		await api.sensor.editStatus(data)
			.then( response => {
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message
				})
			})
	},
	updateAddress: async (store,data) => {
		await api.sensor.updateAddress(data)
			.then( response =>  {
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	setChosenType:(store,data) => {
		store.commit('SET_CHOSEN_TYPE',data);
	},
	setChosenStatus:(store,data) => {
		store.commit('SET_CHOSEN_STATUS',data);
	},
	setChosenStatusStep:(store,data) => {
		store.commit('SET_CHOSEN_STATUS_STEP',data);
	},
	setSensorOffsetHistory: async (store,query) => {
		await api.sensor.getSensorOffsetHistories(query)
			.then( response =>  {
				store.commit('SET_ACTUAL_OFFSET_HISTORY', response.data.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	sensorExchange: async (store,data) => {
		await api.sensor.replaceSensor(data)
			.then( response =>  {
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	sensorReplaceV2: async (store,data) => {
		await api.sensor.replaceSensorV2(data)
			.then( response =>  {
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	changeSensorAccount: async (store, params) => {
		await api.sensor.changeSensorAccount(params.sensor, params.data)
			.then( response =>  {
				if (params.multiPush){
					Toast.fire({
						icon: 'success',
						title: response.data.message,
					})
				} else {
					Toast.fire({
						icon: 'success',
						title: response.data.message,
					})
				}
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	createNewReturningSheet: async (store, params) => {
		await api.sensor.createNewReturningSheet(params)
			.then( response =>  {
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	setReturningSheet: async (store, params) => {
		await api.sensor.getReturningSheet(params)
			.then(response =>  {
				store.commit('SET_RETURNING_SHEETS',response.data.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	updateReturningSheet: async (store, data) => {
		await api.sensor.updateReturningSheet(data)
			.then(() =>  {})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	updateReturningSheetList: async (store, returnNumber) => {
		store.commit('COMPLETE_RETURNING_SHEETS',returnNumber);
	},
	setAvailableLocations: async (store) => {
		await api.sensor.getAvailableAddresses()
			.then( response =>  {
				store.commit('SET_AVAILABLE_LOCATIONS',response.data.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
};

const getters = {
	sensors: state => {
		return state.sensors
	},
	sensorsNotAssociated: (state) => (data) => {
		return state.sensors.filter(item => !data.includes(item.id))
	},
	individualSensor: state => {
		return state.individualSensor
	},
	adminSensors: state => {
		return state.adminSensors
	},
	adminStockedSensors: state => {
		return state.adminSensors.filter((sensor) => {
			if (sensor.sensorParameters) {
				return sensor.sensorParameters.status === 'stock'
			}
		})
	},
	stockedSensors: state => {
		return state.stockedSensors
	},
	histories: state => {
		return state.histories
	},
	associationHistories: state => {
		return state.associationHistories
	},
	alerts: state => {
		return state.alerts
	},
	paginator: state => {
		return state.paginator
	},
	getSensorsById: (state) => (serialNumber) => {
		return state.sensors.find(sensor => sensor.serialNumber === serialNumber)
	},
	getSensorsIds: state => {
		return state.sensors.map(item => {
			if (item.dataReference.accountEquipment !== null){
				return { value: item.serialNumber, text: item.dataReference.accountEquipment.account.name+' - '+item.serialNumber}
			} else {
				return { value: item.serialNumber, text: 'Compte inconnu - '+item.serialNumber}
			}
		})
	},
	getLocationsAvailable: (state) => (filter) => {
		// On ne prend que les objects correspondant au sensorAddress
		let sensorAddressList = state.sensors.map(({sensorAddress}) => sensorAddress)
		// On génère un tableau trié par type de location, et sans valeur dupliquée (grâce à Set())
		let filteredArray = [...new Set(sensorAddressList.map(item => item[filter]))]
		return filteredArray.filter(x=>x);
	},
	getAvailableLocations: (state) => {
		return state.availableLocations;
	},
	loadingStatus : state => {
		return state.loadingStatus
	},
	getRequest : state => {
		return state.request
	},
	lastRefresh: state => {
		return state.lastRefresh
	},
	actualOffsetHistory: state => {
		return state.actualOffsetHistory
	},
	chosenType: state => {
		return state.chosenType
	},
	chosenStatus: state => {
		return state.chosenStatus
	},
	chosenStatusStep: state => {
		return state.chosenStatusStep
	},
	sensorsRate: state => {
		return state.operationRate
	},
	getSensorAccess: state => {
		return state.individualSensorAccess
	},
	getSensorList: state => {
		return state.sensorList;
	},
	createdSensors: state => {
		return state.createdSensors;
	},
	returningSheets: state => {
		return state.returningSheets;
	},
	createdNote: state => {
		return state.createdNote;
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
