<template>
    <div class="container mt-1 p-5">
        <div class="row justify-content-center align-content-center">
            <div class="col-xl-6">
                <img src="@/assets/logo.png" alt="logo lifyair" style="max-height: 50px;"/>
            </div>
            <div class="col-xl-6 float-right justify-content-end">
                <span class="float-right">le {{ Date.now() | dayDate }}</span>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-xl-12 text-center">
                <p class="font-weight-bold">
                    FICHE DE TEST <br> Sensor n°{{ individualSensor.serialNumber }}
                </p>
            </div>
        </div>
        <div class="row mt-5" v-if="setup">
            <div class="col-xl-12 mb-5">
                <p>
                    Testé du
                    <span class="bold">
                        {{ setup.createdAt | dayDate }}
                    </span>
                    au
                    <span class="bold" v-if=" setup.completedAt">
                            {{ setup.completedAt | dayDate }}
                    </span>
                    <span class="bold" v-else>{{ Date.now() | dayDate }}</span>
                    par
                    <span class="font-weight-bold clr-orange" v-if="setup.user">
                    {{ setup.user.email }}
                </span>
                </p>
                <p class="h5 mb-3">
                    Fiche Capteur :
                </p>
                <p class="mb-2 bg-lighter-grey">
                    <span class="clr-orange">
                        <b-icon icon="tag" class="mr-3"/>
                        Numéro de Série
                    </span>:
                    <span class="ml-3">
                        {{ individualSensor.serialNumber }}
                    </span>
                    <span class="float-right">
                        <b-icon icon="check2-circle" class="mr-3" variant="primary"></b-icon>
                    </span>
                </p>
                <p class="mb-2">
                    <span class="clr-orange">
                        <b-icon icon="cpu" class="mr-3"/>
                        Version du capteur
                    </span>:
                    <span class="ml-3">
                        {{ individualSensor.sensorParameters.firmwareVersion }}
                    </span>
                    <span class="float-right">
                        <b-icon icon="check2-circle" class="mr-3" variant="primary"></b-icon>
                    </span>
                </p>
                <p class="mb-2 bg-lighter-grey">
                    <span class="clr-orange">
                        <b-icon icon="calendar-check" class="mr-3"/>
                        Date de création
                    </span>:
                    <span class="ml-3">
                        {{ individualSensor.createdAt | convertDate }}
                    </span>
                    <span class="float-right">
                        <b-icon icon="check2-circle" class="mr-3" variant="primary"></b-icon>
                    </span>
                </p>
                <p class="mb-2">
                    <span class="clr-orange">
                        <b-icon icon="phone-vibrate" class="mr-3"/>
                        ID Sim Matooma
                    </span>:
                    <span class="ml-3">
                         {{ individualSensor.sensorParameters.simId }}
                    </span>
                    <span class="float-right" ref="contentToCopy">
                        <b-icon icon="check2-circle" class="mr-3" variant="primary"></b-icon>
                    </span>
                </p>
                <p class="mb-2 bg-lighter-grey">
                    <span class="clr-orange">
                        <b-icon icon="sun" class="mr-3"/>
                        Puissance Laser Initiale
                    </span> :
                    <span class="ml-3">
                    {{ individualSensor.sensorParameters.laserPower }} mW
                    </span>
                    <span class="float-right">
                        <b-icon icon="check2-circle" class="mr-3" variant="primary"></b-icon>
                    </span>
                </p>
            </div>
            <div class="col-xl-12 mb-5">
                <p class="h5 mb-3">
                    Tests effectués :
                </p>
                <p class="mb-2 bg-lighter-grey">
                    <span class="clr-orange">
                        <b-icon icon="wrench" class="mr-3">
                        </b-icon> Configuration du capteur
                    </span>: {{ isSensorConfigValid ? 'OK' : 'ERREUR' }}
                    <span class="float-right">
                        <b-icon :icon="isSensorConfigValid ?  'check2-circle' : ''"
                                class="mr-3"
                                variant="primary"/>
                    </span>
                </p>
                <p class="mb-2">
                    <span class="clr-orange">
                        <b-icon icon="server" class="mr-3">
                        </b-icon> Données Brutes
                    </span>: {{ setup.rawData ? 'OK' : 'ERREUR' }}
                    <span class="float-right">
                        <b-icon :icon="setup.rawData ?  'check2-circle' : ''"
                                class="mr-3"
                                variant="primary"/>
                    </span>
                </p>
                <p class="mb-2 bg-lighter-grey">
                    <span class="clr-orange">
                        <b-icon icon="circle-square" class="mr-3">
                        </b-icon> Mise à jour firmware
                    </span>: {{ setup.firmwareUpdate ? 'OK' : 'ERREUR' }}
                    <span class="float-right">
                        <b-icon :icon="setup.firmwareUpdate ?  'check2-circle' : ''"
                                class="mr-3"
                                variant="primary"/>
                    </span>
                </p>
            </div>
            <div class="col-xl-12 mb-5">
                <p class="h5 mb-3">
                    Informations supplémentaires :
                </p>
                <p class="clr-darker-grey">
                    {{ setup.context }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "SensorTestingReportTemplate",
    props: {
        setup: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
        }),
        ...Vuex.mapGetters('sensorSetup', {
            isLoading: 'isLoading',
        }),
        isSensorConfigValid() {
            return this.setup.gsm && this.setup.laser && this.setup.crossComparison
        }
    },
}
</script>

<style scoped>
</style>