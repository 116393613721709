export default {
    checkByStep,
    checkGps

}

function checkByStep(step, data) {
    let result = null
    switch (step) {
        case 0:
            result = checkGsm(data);
            break;
        case 1:
            result = checkPtu(data);
            break;
        case 2:
            result = checkId(data);
            break;
        // case 3:
        //     result = checkGps(data);
        //     break;
        case 3:
            result = checkLaser(data);
            break;
        case 4:
            result = checkCounting(data);
            break;
        case 5:
            result = checkG1AndG2(data);
            break;
        case 6:
            result = checkG10AndG19(data);
            break;
        default:
            break;
    }

    return result;
}

function checkGsm(data) {
    let GSM_q, box, result, message;
    box = {};

    for (let i = 0; i < data.length; i++) {
        GSM_q = parseInt(data[i]["GSM"]);

        //(" Marginal")
        box["A"] = -109 <= GSM_q && GSM_q <= -95;

        //(" Ok")
        box["B"] = -93 <= GSM_q && GSM_q <= -85;

        //(" Good")
        box["C"] = -83 <= GSM_q && GSM_q <= -75;

        //(" Excellent")
        box["D"] = -73 <= GSM_q && GSM_q <= -40;

        //(" défaillant ")
        if (-998 <= GSM_q && GSM_q <= -109) {
            message = `GSM value : ${GSM_q} (défaillant)`
            box["E"] = true;
            break;
        } else {
            box["E"] = false;
        }

        //(" pas de réseaux ")
        if (GSM_q !== -999) {
            box["F"] = false;
        } else {
            message = `GSM value : ${GSM_q} (pas de réseaux)`
            box["F"] = true;
            break;
        }
    }

    // GSM OK if box["E"] AND box["F"] === false
    result = box['E'] === false && box['F'] === false;
    return {isValid: result, message: message};
}

function checkPtu(data) {
    let box, humidity, pressure, temp, result, message;
    box = false;

    for (let i = 0; i < data.length; i++) {
        temp = parseFloat(data[i]["T"]);
        humidity = parseFloat(data[i]["U"]);
        pressure = parseFloat(data[i]["P"]);

        if (temp < -25 || temp > 60) {
            message = `Pressure : ${pressure} | Temperature : ${temp} | Humidity : ${humidity}`;
            box = false;
            break;
        } else if (temp === 0) {
            message = `Pressure : ${pressure} | Temperature : ${temp} | Humidity : ${humidity}`;
            box = false;
            break;
        } else {
            box = true;
        }
    }

    // PTU OK if box === true
    result = box;
    return {isValid: result, message: message};
}

function checkId(data) {
    let id, box, result, message;
    box = {
        "A": false,
        "B": false,
        "C": false
    };

    for (let i = 0; i < data.length; i++) {
        id = data[i]["ID"];

        if (id === "0") {
            message = `ID value : ${id}`;
            box["A"] = true;
            break;
        }

        if (id.length !== 15) {
            message = `ID value : ${id}`;
            box["B"] = true;
            break;
        }

        if (id === "failure") {
            message = `ID value : ${id}`;
            box["C"] = true;
            break;
        }
    }

    // ID OK if every box keys === false
    result = Object.values(box).every(val => val === false);
    return {isValid: result, message: message};
}

function checkGps(data) {
    let A, latDiff, lonDiff, firstLat, lastLat, firstLon, lastLon, result, message;

    firstLat = data[0]['lat'];
    lastLat = data[data.length - 1]['lat']
    firstLon = data[0]['lon'];
    lastLon = data[data.length - 1]['lon']

    latDiff = parseFloat(firstLat) - parseFloat(lastLat);
    lonDiff = parseFloat(firstLon) - parseFloat(lastLon);

    if (parseInt(firstLat) !== 0 && parseInt(firstLat) !== 0) {
        A = !(latDiff > 0.001 || latDiff < -0.001 || lonDiff > 0.001 || lonDiff < -0.001);
        if (!A) {
            message = `Latitude deviation : ${latDiff} | Longitude deviation : ${lonDiff}`
        }
    } else {
        message = `Latitude and longitude are 0`
        A = false
    }

    // GPS OK if A === false
    result = A;
    return {isValid: result, message: message};
}

function checkLaser(data) {
    let box, v1, v2, v3, v4, result, message;
    box = {
        "A": false,
        "B": false,
        "C": false,
        "D": false,
        "E": false
    };

    for (let i = 0; i < data.length; i++) {
        // Récupération de l'offset des voies 1,2,3,4.
        v1 = data[i]["trame"][1];
        v2 = data[i]["trame"][21];
        v3 = data[i]["trame"][41];
        v4 = data[i]["trame"][61];

        // Les valeurs d'offset sont correctes si les voies 1 et 4 sont supérieures à 3000
        // et les voies 2 et 3 sont supérieures à 300
        if (v1 < 3000 && v2 < 300 && v3 < 300 && v4 < 3000) {
            box["A"] = true;
        }

        if (v1 < 3000) {
            box["B"] = true;
        }

        if (v2 < 300) {
            box["C"] = true;
        }

        if (v3 < 300) {
            box["D"] = true;
        }

        if (v4 < 3000) {
            box["E"] = true;
        }
    }


    // Laser OK if every box keys === false

    result = Object.values(box).every(val => val === false);
    if (!result) {
        message = `Erreur d'offset sur : `;
        if (!box["B"]) message += `Voie 1, `;
        if (!box["C"]) message += `Voie 2, `;
        if (!box["D"]) message += `Voie 3, `;
        if (!box["E"]) message += `Voie 4, `;
    }
    return {isValid: result, message: message};
}

function checkCounting(data) {
    let box, result, message;
    box = {
        "A": false,
        "B": false,
        "C": false,
        "D": false
    };

    // On vérifie que les comptages pour chaque gamme dans chaque voie soient supérieure à 0.
    for (let i = 0; i < data.length; i++) {
        for (let j = 2; j < 21; j++) {
            if (data[i]["trame"][j] !== 0) {
                box["A"] = true;
                return {isValid: box["A"]};
            } else {
                box["A"] = false;
            }
        }

        for (let j = 22; j < 41; j++) {
            if (data[i]["trame"][j] !== 0) {
                box["B"] = true;
                return {isValid: box["B"]};
            } else {
                box["B"] = false;
            }
        }

        for (let j = 42; j < 61; j++) {
            if (data[i]["trame"][j] !== 0) {
                box["C"] = true;
                return {isValid: box["C"]};
            } else {
                box["C"] = false;
            }
        }

        for (let j = 62; j < 81; j++) {
            if (data[i]["trame"][j] !== 0) {
                box["D"] = true;
                return {isValid: box["D"]};
            } else {
                box["D"] = false;
            }
        }
    }

    // Counting OK if every box keys === true
    result = Object.values(box).every(val => val === true);
    if (!result) {
        message = `Erreur de contage sur sur : `;
        if (!box["A"]) message += `Voie 1, `;
        if (!box["B"]) message += `Voie 2, `;
        if (!box["C"]) message += `Voie 3, `;
        if (!box["D"]) message += `Voie 4, `;
    }
    return {isValid: result, message: message};
}

function checkG1AndG2(data) {
    let V1_G1, V1_G2, V2_G1, V2_G2, V3_G1, V3_G2, V4_G1, V4_G2, box, result, message;
    box = {
        "A": false,
        "B": false,
        "C": false,
        "D": false
    };

    for (let i = 0; i < data.length; i++) {
        // Première deux gammes de taille de la voie 1
        V1_G1 = data[i]["trame"][2];
        V1_G2 = data[i]["trame"][3];

        // Première deux gammes de taille de la voie 2
        V2_G1 = data[i]["trame"][22];
        V2_G2 = data[i]["trame"][23];

        // Première deux gammes de taille de la voie 3
        V3_G1 = data[i]["trame"][42];
        V3_G2 = data[i]["trame"][43];

        // Première deux gammes de taille de la voie 4
        V4_G1 = data[i]["trame"][62];
        V4_G2 = data[i]["trame"][63];

        box["A"] = V1_G1 > 30000 || V1_G2 > 30000;
        box["B"] = V2_G1 > 30000 || V2_G2 > 30000;
        box["C"] = V3_G1 > 30000 || V3_G2 > 30000;
        box["D"] = V4_G1 > 30000 || V4_G2 > 30000;
    }

    // G1_G2 OK if every box keys === false
    result = Object.values(box).every(val => val === false);
    if (!result) {
        message = `Erreur sur les deux premières gammes de tailles 1_2 sur : `;
        if (!box["A"]) message += `Voie 1, `;
        if (!box["B"]) message += `Voie 2, `;
        if (!box["C"]) message += `Voie 3, `;
        if (!box["D"]) message += `Voie 4, `;
    }
    return {isValid: result, message: message};
}

function checkG10AndG19(data) {
    let box, v1_G10_G19, v2_G10_G19, v3_G10_G19, v4_G10_G19, voie1_G10_G19, voie2_G10_G19, voie3_G10_G19,
        voie4_G10_G19, trame, result, message;
    box = {
        "A": false,
        "B": false,
        "C": false,
        "D": false
    };

    for (let i = 0; i < data.length; i++) {
        trame = data[i]["trame"]
        voie1_G10_G19 = trame.slice(11, 21);
        voie2_G10_G19 = trame.slice(31, 41);
        voie3_G10_G19 = trame.slice(51, 61);
        voie4_G10_G19 = trame.slice(71, 81);

        for (let j = 0; j < 10; j++) {
            v1_G10_G19 = voie1_G10_G19[j];
            box["A"] = v1_G10_G19 > 100;

            v2_G10_G19 = voie2_G10_G19[j];
            box["B"] = v2_G10_G19 > 100;

            v3_G10_G19 = voie3_G10_G19[j];
            box["C"] = v3_G10_G19 > 100;

            v4_G10_G19 = voie4_G10_G19[j];
            box["D"] = v4_G10_G19 > 100;
        }
    }

    // G1_G2 OK if every box keys === false
    result = Object.values(box).every(val => val === false);
    if (!result) {
        message = `Erreur sur les gammes de tailles 10_19 sur : `;
        if (!box["A"]) message += `Voie 1, `;
        if (!box["B"]) message += `Voie 2, `;
        if (!box["C"]) message += `Voie 3, `;
        if (!box["D"]) message += `Voie 4, `;
    }
    return {isValid: result, message: message};
}