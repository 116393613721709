import UserCreate from "@/components/user/UserCreate";
import UserInformation from "@/components/user/UserInformation";
import UserEdit from "@/components/user/UserEdit";
import UserAssociationToAccount from "@/components/user/UserAssociationToAccount";
import UserEditRoles from "@/components/user/UserEditRoles";
import UserList from "@/components/user/UserList";

export default [
    {
        path: '/users/:type/new',
        name: 'UserCreate',
        component: UserCreate,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Users',
                    link: '/users'
                },
                {
                    name: 'New'
                }
            ]
        },
    },
    {
        path: '/users/:type?/:id?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/User.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                component: UserList,
                name: 'userList',
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Users'
                        }
                    ],

                },
            },
            {
                path: 'information',
                component: UserInformation,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Users'
                        },
                        {
                            name: 'User Information'
                        }
                    ]
                },
            },
            {
                path: 'edit',
                component: UserEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Users', link:'/users',
                        },
                        {
                            name: 'User Edit'
                        }
                    ]
                },
            },
            {
                path: 'roles',
                component: UserEditRoles,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Users', link:'/users',
                        },
                        {
                            name: 'User Manage Roles'
                        }
                    ]
                },
            },
            {
                path: 'account',
                component: UserAssociationToAccount,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Users', link:'/users',
                        },
                        {
                            name: 'Account'
                        }
                    ]
                },
            },
        ]
    },
];
