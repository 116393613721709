<template>
    <div class="row m-0 p-0">
        <div class="col-xl-12 row justify-content-center align-self-centers my-5 mx-auto">
            <div class=" col-xl-2 col-sm-12">
                <b-button @click="$router.push('/sensors/new')" variant="primary">
                    <b-icon icon="plus-circle" ></b-icon>
                    Ajouter un Capteur
                </b-button>
            </div>
            <div class=" col-xl-3 col-sm-12">
                <b-button variant="primary" v-b-modal.modal-unstock-sensors>
                    <b-icon icon="check2-circle" ></b-icon>
                    Destocker les capteurs selectionnés
                </b-button>
            </div>
        </div>
        <b-modal size="lg" id="modal-unstock-sensors" title="Gestionnaire de Destockage" class="clr-dark-blue" :hide-footer="true">
            <div class="d-block" v-if="this.checkedSensors">
                <div>
                    <p class="medium">Destockage des références suivante : </p>
                    <span v-for="item in this.checkedSensors" :key="item.id" class="font-weight-bolder">{{ item.serialNumber }},</span>
                </div>
                <br>
                <div>
                    <p class="medium">Veuillez selectionner un compte à associer : </p>
                    <SensorEditStocked :sensors="this.checkedSensors" v-on:refresh-sensors="querySensor"></SensorEditStocked>
                </div>
            </div>
            <div v-else>Aucune référence selectionnée</div>
        </b-modal>
        <div class="col-xl-12 ">
            <SensorStockTable
                    v-if="!checkedType"
                    :sensors="sensors"
                    :loading-status="loadingStatus"
                    :hoveredSensor="hoverSensor"
                    v-on:set-sorting="setSort"
                    v-on:hovered-sensor="setHoveredSensor"
                    v-on:checked-sensors="setCheckedSensors"
            ></SensorStockTable>
        </div>

        <div class="row col-xl-12 justify-content-center align-items-center" v-if="!loadingStatus">
            <div class="col-xl-4 font-weight-bold">
                Total Capteur : {{ paginator.totalCount }} <br>
                Capteur par Page : {{ paginator.numItemsPerPage }}
            </div>
            <div class="mt-3 col-xl-4">
                <h6 class="text-center">Page(s)</h6>
                <b-pagination
                        v-model="paginator.current"
                        pills
                        :total-rows="paginator.totalCount"
                        :per-page="paginator.numItemsPerPage"
                        align="center"
                >
                </b-pagination>
            </div>
            <div class="my-3  col-xl-4">
                <b-form-select v-model="paginator.numItemsPerPage" :options="optionsLimits">
                </b-form-select>
            </div>
        </div>

    </div>
</template>

<script>
import Vuex from 'vuex';
import SensorStockTable from "@/components/sensor/Stock/SensorStockTable.vue";
import SensorEditStocked from "@/components/sensor/Management/Edit/SensorEditStocked.vue";

export default {
    name: "SensorStock",
    components: {SensorEditStocked, SensorStockTable},
    data() {
        return {
            isBusy: false,
            text: '',
            hoverSensor: null,
            checkedSensors: null,
            search:'',
            timer: '',
            sort:'sensor.serialNumber',
            direction:'desc',
            optionsLimits: [
                { value: 10, text: '10' },
                { value: 25, text: '25' },
                { value: 50, text: '50' }
            ],
            sensorType:"stock",
            checkedType: false,
        }
    },
    computed:{
        ...Vuex.mapGetters('sensor',{
            sensors:'stockedSensors',
            paginator:'paginator',
            loadingStatus:'loadingStatus',
            lastRefresh:'lastRefresh',
        }),
        hoveredSensor(){
            return this.hoverSensor
        },
    },
    methods: {
        ...Vuex.mapActions('sensor',{
            setStockedSensors:'setStockedSensors',
            setChosenType:'setChosenType'
        }),
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        setHoveredSensor(id){
            this.hoverSensor = id;
        },
        setCheckedSensors(sensors){
            this.checkedSensors = sensors;
        },
        goToAccountInformation(id){
            this.$router.push('/account'+id);
        },
        async querySensor(){
            let currentPage = {}
            currentPage.type = this.chosenType
            if (this.paginator.current > 1 && this.paginator.current !== null && this.paginator.current !== undefined){
                currentPage.page = this.paginator.current
            } else {
                currentPage.page = 1;
            }

            this.paginator.numItemsPerPage !== undefined ? currentPage.limit = this.paginator.numItemsPerPage : currentPage.limit = 25

            this.search.length >= 3 ? currentPage.search = this.search :'';
            this.sort !== '' ? currentPage.sort = this.sort : '';
            this.direction !== '' ? currentPage.direction = this.direction : '';
            await this.setStockedSensors(currentPage)
        },
        setSort(data){
            this.sort = data
            this.direction === 'desc' ? this.direction = 'asc' : this.direction = 'desc';
            this.querySensor()
        }
    },
    async created () {
        this.setChosenType()
        this.toggleBusy()
        await this.querySensor()
        this.toggleBusy()
    },
    watch: {
        'search'(){
            if (this.search.length < 3){
                this.querySensor()
            }
        }
    }
}
</script>

<style scoped>
</style>
