<template>
    <b-row class="justify-content-center">
        <!-- FILTERS -->
        <b-col cols="12">
            <b-row class="justify-content-center my-4">
                <b-col cols="12" md="7" class="text-left">
                    <b-button @click="$router.push(`/users/${$route.params.type}/new`)" variant="primary">
                        <b-icon icon="plus-circle"></b-icon>
                        {{ $t('user.addNew') | capitalize }}
                    </b-button>
                </b-col>
                <b-col cols="12" md="5" class="align-items-end align-self-end mt-2 mt-md-0">
                    <b-input-group class="" v-on:submit.prevent="queryUsers"
                                   v-on:keyup.enter="queryUsers">
                        <b-form-input v-model="search"
                                      :placeholder="$t('user.search') | capitalize"></b-form-input>
                        <b-input-group-append>
                            <b-button type="submit" variant="primary" @click="queryUsers">
                                {{ $t('common.search')| capitalize }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12">
            <b-col cols="12" class="basic-card">
                <b-table-simple
                        responsive
                        hover
                        sticky-header
                        borderless
                        class="table-height"
                        v-if="users.length > 0 && !isBusy"
                        :key="arrayKey"
                >
                    <b-thead>
                        <b-tr>
                            <b-th>Id</b-th>
                            <b-th>Email</b-th>
                            <b-th>Compte</b-th>
                            <b-th>Groupe</b-th>
                            <b-th>Roles</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(item,i) in users"
                              :key="i"
                              class="clr-dark-grey add-cursor"
                              :class="hoveredUser === item.id ? 'bg-lighter-orange' : 'bg-white'"
                              @click="$router.push('/users/'+$route.params.type+'/'+item.id+'/information')"
                              @mouseover="setHoveredUser(item.id)"
                              @mouseout="setHoveredUser(null)"
                        >
                            <b-td>{{ item.id }}</b-td>
                            <b-td class="clr-orange">{{ item.email }}</b-td>
                            <b-td v-if="item.memberships.length > 0 ">
                                {{ item.memberships[0].accountGroup.account.name }}
                            </b-td>
                            <b-td>
                                <p>
                            <span v-for="(membership,i) in item.memberships" :key="i">
                                {{ membership.accountGroup.name }}
                                <span v-if="(i+1) !== item.roles.length">,</span>
                            </span>
                                </p>
                            </b-td>
                            <b-td>
                                <p>
                            <span v-for="(role,i) in item.roles" :key="i">
                                {{ role }} <span v-if="(i+1) !== item.roles.length">,</span>
                            </span>
                                </p>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <p class="col-12 p-0 text-center my-5" v-else-if="!isBusy && users.length === 0">
                    Aucun utilisateur
                </p>
                <b-col cols="12" class="p-0" v-else>
                    <b-skeleton-table
                            :rows="5"
                            :columns="4"
                            :table-props="{ bordered: true, striped: true }"
                            class="table-height"
                    ></b-skeleton-table>
                </b-col>
            </b-col>
        </b-col>

        <!-- PAGINATION -->
        <b-col cols="12" class="mt-3" v-if="!isBusy">
            <b-row class="justify-content-center align-items-center w-100">
                <b-col cols="12" md="4" class="font-weight-bold">
                    Total Utilisateurs : {{ paginator.totalCount }} <br>
                    Utilisateurs par Page : {{ paginator.numItemsPerPage }}
                </b-col>
                <b-col cols="12" md="4" class="mt-3">
                    <h6 class="text-center">Page(s)</h6>
                    <b-pagination
                            v-model="paginator.current"
                            pills
                            :total-rows="paginator.totalCount"
                            :per-page="paginator.numItemsPerPage"
                            align="center">
                    </b-pagination>
                </b-col>
                <b-col cols="12" md="4" class="my-3">
                    <b-form-select v-model="paginator.numItemsPerPage" :options="optionsLimits">
                    </b-form-select>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex';

export default {
    name: "UserList",
    data() {
        return {
            isBusy: false,
            text: '',
            rows: 100,
            search: '',
            currentPage: 1,
            hoverUser: null,
            optionsLimits: [
                {value: 10, text: '10'},
                {value: 25, text: '25'},
                {value: 50, text: '50'}
            ],
            direction: 'desc',
            arrayKey: 0
        }
    },
    computed: {
        ...Vuex.mapGetters('user', {
            paginator: 'paginator',
            loadingStatus: 'loadingStatus',
            users: 'users'
        }),
        hoveredUser() {
            return this.hoverUser
        }
    },
    methods: {
        ...Vuex.mapActions('user', {
            setUsers: 'setUsers'
        }),
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        setHoveredUser(id) {
            this.hoverUser = id;
        },
        async queryUsers() {
            this.toggleBusy()
            let query = {}
            this.arrayKey += 1;
            // let currentPage = {}
            if (this.paginator.current > 1) {
                query.page = this.paginator.current
            } else {
                query.page = 1;
            }

            this.paginator.numItemsPerPage !== undefined ? query.limit = this.paginator.numItemsPerPage : query.limit = 25

            query.isIndividual = this.$route.params.type === 'mobile';
            this.sort !== '' ? query.sort = this.sort : '';
            this.direction !== '' ? query.direction = this.direction : '';
            this.search.length >= 3 ? query.search = this.search : '';

            await this.setUsers(query)
            this.toggleBusy()
        }
    },
    watch: {
        'paginator.current'() {
            if (this.users.length > 0) {
                this.queryUsers()
            }
        },
        'paginator.numItemsPerPage'() {
            if (this.users.length > 0) {
                this.queryUsers()
            }
        }
    },
    async mounted() {
        await this.queryUsers()
    }
}
</script>

<style scoped>

</style>
