import AccountCreate from "@/components/account/AccountCreate";
import AccountList from "@/components/account/AccountList";
import AccountInformation from "@/components/account/AccountInformation";
import AccountEdit from "@/components/account/AccountEdit";
import AccountCreateCustomAccess from "@/components/account/AccountCreateCustomAccess";

export default [
    {
        path: '/access-data/:id?',
        name:'DataAccess',
        component: AccountCreateCustomAccess,
        props:true,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'New'
                }
            ]
        },
    },
    {
        path: '/accounts/new',
        component: AccountCreate,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Accounts',
                    link: '/accounts'
                },
                {
                    name: 'New'
                }
            ]
        },
    },
    {
        path: '/accounts/:id?',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Account.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                component: AccountList,
                name: 'accounts',
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Accounts'
                        }
                    ]
                },
            },
            {
                path: 'information',
                component: AccountInformation,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Accounts', link:'/accounts',
                        },
                        {
                            name: 'Account Information'
                        }
                    ]
                },
            },
            {
                path: 'edit',
                component: AccountEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Accounts', link:'/accounts',
                        },
                        {
                            name: 'Account Edit'
                        }
                    ]
                },
            },
            {
                path: 'account-groups/:acid?',
                component: AccountEdit,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Accounts', link:'/accounts',
                        },
                        {
                            name: 'Account Group'
                        }
                    ]
                },
            }
        ]
    },
];