import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './plugins/axios'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import "./filters"
import "./plugins/moment"
import VuePageTransition from 'vue-page-transition';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BootstrapVue ,BootstrapVueIcons} from 'bootstrap-vue';
import AWS from 'aws-sdk';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueEllipseProgress from 'vue-ellipse-progress';
import VueApexCharts from 'vue-apexcharts'
import Amplify from 'aws-amplify'
import VueFab from 'vue-float-action-button'
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueHtml2pdf from 'vue-html2pdf'

Vue.use(VueHtml2pdf)

library.add(fas);
library.add(far);
Vue.component("font-awesome-icon", FontAwesomeIcon);
dom.watch();

store.dispatch('analytics/checkHealth')
// Amplify environment variables config.
Amplify.configure({
  VUE_APP_BASE_URL: 'VUE_APP_BASE_URL',
  VUE_APP_API_URL: 'VUE_APP_API_URL',
  VUE_APP_I18N_LOCALE: 'VUE_APP_I18N_LOCALE',
  VUE_APP_I18N_FALLBACK_LOCALE:'VUE_APP_I18N_FALLBACK_LOCALE',
  encodingType: 'ENCODING_TYPE',
  encryptionSecret: 'ENCRYPTION_SECRET',
  VUE_APP_AWS_ACCESS_KEY_ID: 'ACCESS_KEY_ID',
  VUE_APP_AWS_SECRET_ACCESS_KEY: 'SECRET_ACCESS_KEY',
  VUE_APP_AWS_DEFAULT_REGION: 'VUE_APP_AWS_DEFAULT_REGION',
  VUE_APP_AWS_SDK_LOAD_CONFIG: 1,
  VUE_APP_AWS_ASSETS_BUCKET: 'VUE_APP_AWS_ASSETS_BUCKET'
})

// Global registration
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('apexchart', VueApexCharts);
Vue.use(BootstrapVue, {});
Vue.use(BootstrapVueIcons, {})
Vue.use(VueSweetalert2);
Vue.use(VuePageTransition)
Vue.use(VueEllipseProgress);
Vue.use(VueFab)
i18n.locale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';

new Vue({
  router,
  i18n,
  store,
  AWS,
  render: h => h(App)
}).$mount('#app')
