import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";
import Vuex from 'vuex'
import swal from "sweetalert2";
import Vue from "vue";
import i18n from "@/i18n";
import router from "@/router";

const state = {
	users: [],
	user: {},
	paginator: {
		current:1,
		limit:25
	},
	loadingStatus: false,
	request:{}
};


const mutations = {
	SET_USERS: (state, users) => {
		state.loadingStatus = true
		state.users = users;
		state.loadingStatus = false
	},
	SET_USER: (state, user) => {
		state.loadingStatus = true
		state.user = user;
		state.loadingStatus = false
	},
	SET_PAGINATOR: (state, paginator) => {
		state.loadingStatus = true
		state.paginator = paginator;
		state.loadingStatus = false
	},
	UPDATE_INDIVIDUAL_USER: (state, data) => {
		state.loadingStatus = true
		let selectedUser = state.users.find(user => user.id === data.id)
		Object.assign(selectedUser, data);
		state.loadingStatus = false
	},
	BAD_REQUEST: (state, requestStatus) => {
		state.request = {status: requestStatus[1], message: requestStatus[0]}
	},
	CREATE_USER: (state, user) => {
		state.loadingStatus = true
		state.users.push(user);
		state.loadingStatus = false
	},
	SET_REQUEST: (state, request) => {
		state.request = request;
	},
	USERS_IS_LOADING_STATUS: (state) => {
		state.loadingStatus = !state.loadingStatus
	},
};
const actions = {
	setUsers: async (store,data) => {
		await api.user.getUsers(data)
			.then( response =>  {
				// store.commit('SENSORS_IS_LOADING_STATUS');
				store.commit('SET_USERS', response.data.users);
				store.commit('SET_PAGINATOR', response.data.paginator);
			}).catch(error => {
				if (error.response.status === 401) {
					Vuex.dispatch('login/logout')
				}
				store.commit('BAD_REQUEST', error);
			});
	},
	setUser: async (store,data) => {
		await api.user.getUser(data)
			.then( response =>  {
				store.commit('SET_USER', response.data.data);
			}).catch(error => {
				if (error.response.status === 401) {
					Vuex.dispatch('login/logout')
				}
				store.commit('BAD_REQUEST', error);
			});
	},
	createUser: async (store,data) => {
		await api.user.createUser(data)
			.then( response =>  {
				store.commit('CREATE_USER', response.data.data);
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})

			})
			.catch((error) => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message.password,
				})
			});
	},
	editUser: async (store,data) => {
		await api.user.updateUser(data)
			.then( response =>  {
				store.commit('UPDATE_INDIVIDUAL_USER', response.data.data);
				store.commit('SET_REQUEST', response.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	manageUserRoles: async (store,params) => {
		await api.user.manageUserRoles(params.user, params.data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	emptyUsers: async (store) => {
		store.commit('SET_USERS', []);
	},
	deleteUser: async (store,data) => {
		await api.user.deleteUser(data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
				router.push('users');
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	generateApiKey: async (store,data) => {
		await api.user.generateApiKey(data)
			.then( response =>  {
				swal.fire({
					icon: 'warning',
					title: Vue.options.filters.capitalize(i18n.t('apiKey.this')),
					html: `<p>${Vue.options.filters.capitalize(i18n.t('apiKey.save'))}</p>
					<br>
					<p class="text-left font-weight-bold">api_key: ${response.data.data.api_key}</p>
					<p class="text-left font-weight-bold">api_key_secret: ${response.data.data.api_key_secret}</p>`,
					confirmButtonColor: '#FF5A01',
					confirmButtonText: Vue.options.filters.capitalize(i18n.t('common.button.continue')),
					customClass: {
						html: 'text-left'
					}
				}).then((result) => {
					if (result.value) {
						store.commit('SET_REQUEST', response.data);
					}
				});
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	editApiKey: async (store,data) => {
		await api.user.editApiKey(data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	deleteApiKey: async (store,data) => {
		await api.user.deleteApiKey(data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	linkedUserToGroup: async (store,data) => {
		await api.user.linkedUserToGroup(data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	deleteLinkedUserGroup: async (store,data) => {
		await api.user.deleteLinkedUserGroup(data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	removeUserFromCampaign: async (store, data) => {
		await api.user.removeUserFromCampaign(data.user, data.campaign)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
				Toast.fire({
					icon: 'success',
					title: response.data.message,
				})
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
};

const getters = {
	users: state => {
		return state.users
	},
	paginator: state => {
		return state.paginator
	},
	getUsersById: (state) => (id) => {
		return state.users.find(user => user.id === id)
	},
	getUser: state => {
		return state.user
	},
	loadingStatus : state => {
		return state.loadingStatus
	},
	getRequest : state => {
		return state.request
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
