import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";
// eslint-disable-next-line no-unused-vars
import moment from "moment";

const state = {
    currentSensorMonitoring: [],
    sensorMonitoring: [],
    sensorMonitoringHistory: [],
    paginator: {
        current:1,
        limit:50
    },
    isSensorMonitoringLoading: false,
    errorMatrix: [
        {"ERROR": "ERROR_PTU", "NOTIFICATION": false, "RECOVERYTIME": 1, "PRIORISATION": 0, "maxCriticalAlertPerDay": 150, "DESCRIPTION": "Erreur liée au capteur PTU."},
        {"ERROR": "ERROR_GSM", "NOTIFICATION": false, "RECOVERYTIME": 1, "PRIORISATION": 1, "maxCriticalAlertPerDay": 30, "DESCRIPTION": "Erreur de communication GSM."},
        {"ERROR": "ERROR_ID", "NOTIFICATION": true, "RECOVERYTIME": 1, "PRIORISATION": 2, "maxCriticalAlertPerDay": 2, "DESCRIPTION": "Erreur d'identification."},
        {"ERROR": "ERROR_GPS", "NOTIFICATION": true, "RECOVERYTIME": 0, "PRIORISATION": 2, "maxCriticalAlertPerDay": 1, "DESCRIPTION": "Erreur du signal GPS."},
        {"ERROR": "ERROR_PHOTODIODE_BOARD_1", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur liée à la carte de photodiode 1."},
        {"ERROR": "ERROR_PHOTODIODE_BOARD_2", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur liée à la carte de photodiode 2."},
        {"ERROR": "ERROR_PHOTODIODE_BOARD_3", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur liée à la carte de photodiode 3."},
        {"ERROR": "ERROR_PHOTODIODE_BOARD_4", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur liée à la carte de photodiode 4."},
        {"ERROR": "ERROR_ALL_PHOTODIODE_BOARD", "NOTIFICATION": true, "RECOVERYTIME": 1, "PRIORISATION": 2, "maxCriticalAlertPerDay": 2, "DESCRIPTION": "L'ensemble des photodiodes sont dysfonctionnelles."},
        {"ERROR": "ERROR_OFFSET_LOWV1", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales basses de fonctionnement V1."},
        {"ERROR": "ERROR_OFFSET_LOWV2", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales basses de fonctionnement V2."},
        {"ERROR": "ERROR_OFFSET_LOWV3", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales basses de fonctionnement V3."},
        {"ERROR": "ERROR_OFFSET_LOWV4", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales basses de fonctionnement V4."},
        {"ERROR": "ERROR_OFFSET_HIGHV1", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales hautes de fonctionnement V1."},
        {"ERROR": "ERROR_OFFSET_HIGHV2", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales hautes de fonctionnement V2."},
        {"ERROR": "ERROR_OFFSET_HIGHV3", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales hautes de fonctionnement V3."},
        {"ERROR": "ERROR_OFFSET_HIGHV4", "NOTIFICATION": false, "RECOVERYTIME": 3, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de valeurs dépassant les normales hautes de fonctionnement V4."},
        {"ERROR": "ERROR_NO_COUNTING", "NOTIFICATION": true, "RECOVERYTIME": 1, "PRIORISATION": 2, "maxCriticalAlertPerDay": 2, "DESCRIPTION": "Erreur de comptage manqué."},
        {"ERROR": "ERROR_NOISE", "NOTIFICATION": false, "RECOVERYTIME": 24, "PRIORISATION": 1, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur de bruit."},
        {"ERROR": "ERROR_LASER", "NOTIFICATION": true, "RECOVERYTIME": 1, "PRIORISATION": 2, "maxCriticalAlertPerDay": 24, "DESCRIPTION": "Erreur du laser."},
        {"ERROR": "ERROR_DISCONNECTION", "NOTIFICATION": false, "RECOVERYTIME": 1, "PRIORISATION": 2, "maxCriticalAlertPerDay": 3, "DESCRIPTION": "Erreur de déconnexion."},
        {"ERROR": "ERROR_KO", "NOTIFICATION": true, "RECOVERYTIME": 1, "PRIORISATION": 2, "maxCriticalAlertPerDay": 1, "DESCRIPTION": "Capteur Hors service depuis plus de 24H."}
    ]
};

const mutations = {
    SET_ACTUAL_SENSOR_MONITORING: (state, sensorMonitoring) => {
        state.currentSensorMonitoring = sensorMonitoring;
    },
    SET_SENSOR_MONITORING: (state, sensorMonitoring) => {
        state.sensorMonitoring = sensorMonitoring;
    },
    SET_SENSOR_MONITORING_HISTORY: (state, sensorMonitoring) => {
        state.sensorMonitoringHistory = sensorMonitoring;
    },
    SET_PAGINATOR: (state, paginator) => {
        state.paginator = {...paginator};
    },
    SET_IS_LOADING: (state) => {
        state.isSensorMonitoringLoading = !state.isSensorMonitoringLoading;
    },
};

const actions = {
    setCurrentSensorMonitoringBySensor: async (store, sensor) => {
        store.commit('SET_IS_LOADING')

        await api.monitoring.getCurrentSensorMonitoringBySensor(sensor)
            .then(response => {
                store.commit('SET_ACTUAL_SENSOR_MONITORING', response.data.data.sensorMonitorings[sensor])
                store.commit('SET_IS_LOADING')
            })
            .catch(error => {
                store.commit('SET_IS_LOADING')
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    setCurrentSensorMonitoring: async (store, sensor) => {
        store.commit('SET_IS_LOADING')
        await api.monitoring.getCurrentSensorMonitoring(sensor)
            .then(response => {
                store.commit('SET_SENSOR_MONITORING', response.data.data.sensorMonitorings)
                store.commit('SET_IS_LOADING')
            })
            .catch(error => {
                store.commit('SET_IS_LOADING')
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            });
    },
    setSensorMonitoringHistory: async (store, params) => {
        store.commit('SET_IS_LOADING')
        await api.monitoring.getSensorMonitoringPaginated(params)
            .then(response => {
                // filter
                let filteredErrors = {}

                for (const obj of response.data.data.sensorMonitorings) {
                    if (!filteredErrors[obj.context]) {
                        filteredErrors[obj.context] = []
                    }

                    filteredErrors[obj.context].push(obj)
                }

                let finalErrorArray = []

                for (const error in filteredErrors) {
                    // Remember previous error to compare
                    let previousError = null

                    // position dans le tableau de résultat final
                    let finalErrorRank = 0

                    // informations pour la résolution ou non de l'erreur
                    let recoveryTime = state.errorMatrix.find(item => item.ERROR === error).RECOVERYTIME
                    const maxDuration = moment.duration(recoveryTime, 'hours');


                    // on boucle sur les erreurs du meme type
                    for (let i = 0; i < Object.keys(filteredErrors[error]).length; i++) {

                        let actualError = filteredErrors[error][i]

                        // S'il n'en existait pas création + si il n'y en a qu'une -> verification de la résolution
                        if (!previousError) {
                            let filledValue = {...actualError, count:1, lastUpdate: actualError.createdAt, resolved:false}

                            if (Object.keys(filteredErrors[error]).length === 1) {
                                let lastUpdate = moment(filledValue.lastUpdate)
                                let limitTime = moment().subtract(recoveryTime, 'hours')
                                filledValue.resolved = lastUpdate < limitTime
                            }

                            previousError = filledValue
                            finalErrorArray.push(filledValue)

                        } else { // Sinon on fusionne avec l'erreur commune si les date correspondent + resolution
                            // CHECK PREVIOUS ERROR
                            let actualDate = moment(actualError.createdAt)
                            let previousDate =  moment(previousError.createdAt)

                            let difference = previousDate.diff(actualDate)

                            if (actualError.notificationSent){
                                finalErrorArray[finalErrorRank].notificationSent = true
                            }

                            if (difference <= maxDuration) {
                                // ADD TO PREVIOUS ERROR GROUP
                                finalErrorArray[finalErrorRank].count ++
                                finalErrorArray[finalErrorRank].createdAt = actualError.createdAt
                                previousError = actualError
                            } else {
                                // CHECK IF PREVIOUS GROUP IS RESOLVED BEFORE CLOSING
                                let lastUpdate = moment(finalErrorArray[finalErrorRank].lastUpdate)
                                let limitTime = moment().subtract(recoveryTime, 'hours')
                                finalErrorArray[finalErrorRank].resolved = lastUpdate <= limitTime

                                // AND THEN LETS GO FOR NEW ERROR GROUP
                                let filledValue = {...actualError, count:1, lastUpdate:actualError.createdAt, resolved:false}
                                lastUpdate = moment(filledValue.lastUpdate)
                                filledValue.resolved = lastUpdate <= limitTime
                                finalErrorArray.push(filledValue)
                                finalErrorRank ++
                                previousError = filledValue
                            }
                        }

                        // RESET WHEN CHANGING ERROR TYPE
                        if ((i +1) === Object.keys(filteredErrors[error]).length) {
                            previousError = null
                        }
                    }
                }

                const sortedArray = finalErrorArray.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
                store.commit('SET_SENSOR_MONITORING_HISTORY', sortedArray)
                store.commit('SET_PAGINATOR', response.data.data.paginator)
                store.commit('SET_IS_LOADING')
            })
            .catch(error => {
                store.commit('SET_IS_LOADING')
                Toast.fire({
                    icon: 'error',
                    title: error,
                })
            });
    },
};

const getters = {
    getCurrentSensorMonitoringBySensor: state => {
        return state.currentSensorMonitoring
    },
    getSensorMonitoringHistory: state => {
        return state.sensorMonitoringHistory
    },
    getCurrentSensorMonitoring: state => {
        return state.sensorMonitoring
    },
    isSensorMonitoringLoading: state => {
        return state.isSensorMonitoringLoading
    },
    paginator: state => {
        return state.paginator
    },
    errorMatrix: state => {
        return state.errorMatrix
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
