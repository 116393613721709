import axios from "../../plugins/axios";

export default {
    getListOfProject,
    getListOfPipelines
}

function getListOfProject() {
    return axios.get('https://gitlab.com/api/v4/groups/7359167/projects?access_token='+process.env.VUE_APP_GITLAB_TOKEN+'&simple=true')
}
function getListOfPipelines(id) {
    return axios.get('https://gitlab.com/api/v4/projects/'+id+'/pipelines?access_token='+process.env.VUE_APP_GITLAB_TOKEN+'&sort=desc&order_by=updated_at')
}
