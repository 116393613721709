<template>
    <div class="offset-xl-4 col-xl-4 col-sm-auto align-self-center justify-content-center w-100">
        <h2 class="mb-5">{{ $t('campaign.create') | capitalize }}</h2>
        <b-form @submit.prevent="submit()">
            <b-form-group
                id="c-name"
                label="Nom de la campagne :"
                label-for="c-name"
            >
                <b-form-input
                    id="c-name"
                    v-model="campaign.name"
                    type="text"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="c-code"
                label="Code :"
                label-for="c-code"
            >
                <b-form-input
                    id="c-code"
                    v-model="campaign.code"
                    type="text"
                    maxlenght="8"
                    required
                    :state="campaign.code === '' ? null : campaign.code.length <= 8"
                ></b-form-input>
                <b-form-invalid-feedback id="c-code">
                    MAX 8 caractères
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Comptes rattachés à la campagne :" v-slot="{ ariaDescribedby }" label-for="accounts">
                <b-form-select v-model="campaign.accounts" multiple :select-size="5" id="accounts">
                    <b-form-select-option :aria-describedby="ariaDescribedby"
                                          :value="account.id" v-for="(account, index) in accounts" :key="index">
                        {{ account.name }}
                    </b-form-select-option>
                </b-form-select>
                <b-form-text id="input-live-help">Sélection multiple.</b-form-text>
            </b-form-group>
            <b-form-group
                id="start-datepicker"
                label="Début :"
                label-for="start-datepicker"
            >
                <b-form-datepicker id="start-datepicker" v-model="campaign.startAt" class="mb-2"></b-form-datepicker>
            </b-form-group>
            <b-form-group
                id="end-datepicker"
                label="Fin :"
                label-for="start-datepicker"
            >
                <b-form-datepicker id="end-datepicker" v-model="campaign.endAt" class="mb-2"></b-form-datepicker>
            </b-form-group>
            <b-button type="submit" variant="primary" v-if="true">Créer</b-button>
            <b-button type="submit" variant="primary" v-else>
                <b-spinner small></b-spinner>
            </b-button>
        </b-form>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins";

export default {
    name: "CampaignCreate",
    data() {
        return {
            campaign: {
                name: '',
                code: '',
                startAt: null,
                endAt: null,
                sensors: [],
                accounts: []
            },
        }
    },
    methods: {
        ...Vuex.mapActions('campaign', {
            createCampaign: 'createCampaign'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        async submit() {
            if (this.campaign.code.length <= 8) {
                let query = this.generateQueryString()
                await this.createCampaign(query);
            }
        },
        generateQueryString() {
            let data = new FormData();
            data.append('name', this.campaign.name)
            data.append('code', this.campaign.code)
            data.append('startAt', this.campaign.startAt)
            data.append('endAt', this.campaign.endAt)
            if (this.campaign.accounts.length > 0) {
                let accounts = this.campaign.accounts;
                accounts = accounts.join(':')
                data.append('accounts', accounts)
            }
            return data
        },
        goToiCampaignList() {
            this.$router.push('/campaigns');
        }
    },
    computed: {
        ...Vuex.mapGetters('campaign', {
            getRequest: "getRequest"
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts'
        }),
    },
    created() {
        this.setAccounts();
    },
    watch: {
        getRequest() {
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
            }).then(
                setTimeout(this.goToiCampaignList, 1500)
            )
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
