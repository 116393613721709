<template>
    <div>
        <b-spinner label="loader" class="text-center spinner" v-if="isDataAccessLoading || !accesses"/>
        <p class="text-center" v-else-if="accesses.length === 0">
            Ce compte n'a accès à aucun <span class="font-weight-bold">capteur</span> pour le moment.
        </p>
        <b-table-simple
            responsive
            hover
            sticky-header
            borderless
            v-else>
            <b-thead>
                <b-tr>
                    <b-th>Id</b-th>
                    <b-th>Groupe</b-th>
                    <b-th>Capteurs</b-th>
                    <b-th>Création</b-th>
                    <b-th>Fin</b-th>
                    <b-th>Type</b-th>
                    <b-th>Propriété</b-th>
                    <b-th> Action</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(access,i) in accesses" :key="i"
                      class="add-cursor justify-content-center align-items-center">
                    <b-td class="align-middle">{{ access.id }}</b-td>
                    <b-td class="align-middle">{{ access.accountGroup.name }}</b-td>
                    <b-td class="align-middle text-left">
                        <b-badge v-for="(ressource, index) in access.accesses.filter(item => item.sensor)" :key="index"
                                 @click="$router.push('/sensors/'+ressource.sensor.serialNumber+'/detail')"
                                 variant="primary" class="mx-1">
                            {{ ressource.sensor.serialNumber }}
                        </b-badge>
                    </b-td>
                    <b-td class="align-middle">{{ access.createdAt | dayDate }}</b-td>
                    <b-td class="align-middle" v-if="access.endAt">{{ access.endAt | dayDate }}</b-td>
                    <b-td class="align-middle" v-else>-</b-td>
                    <b-td class="align-middle">
                        <b-badge :class="`bg-${colorsByAccessType[access.accessType]}`" class="p-2">
                            {{ access.accessType | toUpperCase }}
                        </b-badge>
                    </b-td>
                    <b-td class="align-middle">
                        {{ $t('data.table.field.' + access.ownership) }}
                    </b-td>
                    <b-td class="align-middle">
<!--                        <span v-if="access.ownership === 'owner'" class="clr-light-grey">-</span>-->
                        <b-row class="justify-content-center">
                            <b-col cols="2" @click="gotToAccessEdit(access.id)">
                                <b-icon icon="pencil-fill" aria-hidden="true" class="clr-orange"></b-icon>
                            </b-col>
                            <b-col cols="2" @click="deleteAccess(access.id)">
                                <b-icon icon="dash-square" aria-hidden="true" class="clr-orange"></b-icon>
                            </b-col>
                        </b-row>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <!--    INACTIVE ACCESSES-->
        <div v-if="inactiveAccesses.length > 0" class="mt-5">
            <p class="text-center h5 mb-3 clr-dark-grey add-cursor"
               @click="showInactiveAccesses = !showInactiveAccesses">
                Accès inactifs
                <b-icon icon="chevron-compact-up" class="ml-2" v-if="showInactiveAccesses"/>
                <b-icon icon="chevron-compact-down" class="ml-2" v-else/>
            </p>
            <b-table-simple
                responsive
                hover
                sticky-header
                borderless
                v-if="showInactiveAccesses">
                <b-thead>
                    <b-tr>
                        <b-th>Id</b-th>
                        <b-th>Groupe</b-th>
                        <b-th>Référence</b-th>
                        <b-th>Création</b-th>
                        <b-th>Fin</b-th>
                        <b-th>Type</b-th>
                        <b-th>Propriété</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(access,i) in inactiveAccesses" :key="i"
                          class="add-cursor justify-content-center align-items-center">
                        <b-td class="align-middle">{{ access.id }}</b-td>
                        <b-td class="align-middle">{{ access.accountGroup.name }}</b-td>
                        <b-td class="align-middle text-left">
                            <b-badge v-for="(ressource, index) in access.accesses"
                                     :key="index"
                                     variant="primary" class="mx-1">
                                {{ ressource.referenceId }}
                            </b-badge>
                        </b-td>
                        <b-td class="align-middle">{{ access.createdAt | dayDate }}</b-td>
                        <b-td class="align-middle" v-if="access.endAt">{{ access.endAt | dayDate }}</b-td>
                        <b-td class="align-middle" v-else>-</b-td>
                        <b-td class="align-middle">
                            <b-badge :class="`bg-${colorsByAccessType[access.accessType]}`" class="p-2">
                                {{ access.accessType | toUpperCase }}
                            </b-badge>
                        </b-td>
                        <b-td class="align-middle">
                            {{ $t('data.table.field.' + access.ownership) }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "AccessSensorTable",
    data() {
        return {
            colorsByAccessType: {
                'standard': 'green3',
                'pro': 'light-green',
                'scientific': 'yellow',
            },
            showInactiveAccesses: false
        }
    },
    computed: {
        ...Vuex.mapGetters('dataAccess', {
            isDataAccessLoading: 'isDataAccessLoading',
            getRequest: 'getRequest',
            getDataAccess: 'getDataAccess'
        }),
        accesses() {
            if (this.getDataAccess.sensors) {
                return this.getDataAccess.sensors.filter(dataAccess =>
                    dataAccess.accesses.filter(access => access.sensor).length > 0
                );
            } else {
                return [];
            }
        },
        inactiveAccesses() {
            if (this.getDataAccess.sensors) {
                return this.getDataAccess.sensors.filter(dataAccess =>
                    dataAccess.accesses.filter(access => !access.sensor).length > 0
                );
            } else {
                return [];
            }
        }
    },
    methods: {
        ...Vuex.mapActions('dataAccess', {
            deleteDataAccess: 'deleteDataAccess',
            setDataAccessByAccount: 'setDataAccessByAccount'
        }),
        gotToAccessEdit(id) {
            this.$router.push({name: 'AccountAccessEdit', params: {id: id, type: 'sensor'}});
        },
        deleteAccess(accessId) {
            this.$swal.fire({
                title: 'Êtes vous sûr?',
                text: "Vous ne pourrez pas revenir en arrière !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, supprimer les droits d\'accès!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataAccess(accessId).then(() => {
                        if (this.getRequest.success) {
                            this.setDataAccessByAccount(this.$route.params.id)
                            this.$swal.fire(
                                'Supprimé!',
                                'L\'accès à bien été supprimé!',
                                'success'
                            )
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.spinner {
    width: 3rem;
    height: 3rem;
}
</style>