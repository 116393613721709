<template>
    <div>
        <div class="container-fluid" v-if="breadCrumbList">
            <TheSidebar/>
            <b-row class="row justify-content-center align-items-center">
                <b-col cols="12" class="mb-3 fixed-top d-none d-md-block">
                    <b-row :class="isBurgerActive ? 'bg-white':'bg-lighter-grey'"
                           class="justify-content-center align-items-end align-items-center">
                        <b-col cols="6">
                            <b-row>
                                <div class="d-flex clr-light-grey align-content-center align-items-center">
                                    <Burger></Burger>
                                </div>
                                <div class="d-flex clr-light-grey align-content-center align-items-center">
                                    <div v-for="(breadcrumb,idx) in breadCrumbList"
                                         :key="idx"
                                         @click="routeTo(idx, breadcrumb.name)"
                                         class="d-inline-block add-cursor center">
                                        <span class="clr-darker-grey" v-if="idx === (breadCrumbList.length - 1)">&nbsp; {{ breadcrumb.name }} </span>
                                        <span class="clr-light-grey" v-else>&nbsp; {{ breadcrumb.name }} / </span>
                                    </div>
                                </div>
                            </b-row>
                        </b-col>
                        <b-col cols="6">
                            <b-icon
                                    @click="logout()"
                                    icon="door-open-fill"
                                    class="clr-darker-grey d-flex float-right add-cursor clr-dark-grey"
                                    scale="1.4"
                            />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" class="mb-3 sticky-top d-block d-md-none">
                    <b-row :class="isBurgerActive ? 'bg-white':'bg-lighter-grey'"
                           class="justify-content-center align-items-end align-items-center">
                        <b-col cols="6">
                            <b-row>
                                <div class="d-flex clr-light-grey align-content-center align-items-center">
                                    <Burger></Burger>
                                </div>
                            </b-row>
                        </b-col>
                        <b-col cols="6">
                            <b-icon
                                    @click="logout()"
                                    icon="door-open-fill"
                                    class="clr-darker-grey d-flex float-right add-cursor clr-dark-grey"
                                    scale="1.4"
                            />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12">
                    <div class="container-fluid mt-0 mt-md-5">
                        <vue-page-transition name="fade-in-right">
                            <router-view :key="$route.fullPath">
                                <slot></slot>
                            </router-view>
                        </vue-page-transition>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TheSidebar from "./Menu/TheSidebar";
import SecureLS from "@/plugins/secureLS"
import Burger from "@/components/layout/Menu/Burger.vue";
import Vuex from "vuex";
import {navigation} from "@/store/modules/navigation";

export default {
    data() {
        return {
            breadCrumbList: [],
        }
    },
    components: {
        Burger,
        TheSidebar,
    },
    computed: {
        getRoutePath() {
            return this.$route.name
        },
        currentUser() {
            return SecureLS.get('currentUser');
        },
        canGenerateWidget() {
            return this.currentUser.roles.includes('ROLE_SUPER_ADMIN')
                || this.currentUser.roles.includes('ROLE_ADMIN')
        },
        isSuperAdmin() {
            return this.currentUser.roles.includes('ROLE_SUPER_ADMIN');
        },
        isBurgerActive() {
            return navigation.isNavOpen
        }
    },
    methods: {
        goBackToPreviousContent() {
            this.$router.go(-1)
        },
        goBackHome() {
            this.$router.push('dashboard')
        },
        routeTo(idx, name) {
            if (name === 'Users') {
                this.$router.push('/users/' + this.$route.params.type);
            }
            if (this.breadCrumbList[idx].link) this.$router.push(this.breadCrumbList[idx].link)
        },
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),

    },
    mounted() {
        SecureLS.set('currentPath', this.getRoutePath);
        this.breadCrumbList = this.$route.meta.breadCrumb
    },
    watch: {
        '$route'() {
            this.breadCrumbList = this.$route.meta.breadCrumb

        }
    }
}

</script>

<style lang="scss">
//ul.sidebar-item
//{
//	& > li{
//		color: #526069;
//		border-bottom: solid transparent 1px;
//		& > a {
//			color: #526069;
//		}
//		&.active{
//			//background-color: #526069;
//			& > a {
//				color: #526069;
//			}
//		}
//	}
//	& > li:last-child.nav-item{
//		border-bottom: none;
//	}
//	& > li:hover,li.active{
//      font-weight: 500;
//      box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
//      border-radius: 0.5rem;
//		& > a {
//			color: #526069;
//		}
//	}
//}

/*ul {*/
/*	& > li{*/
/*		border-bottom: solid #17A2B8 1px;*/
/*		&:hover, &.active{*/
/*			background-color: white;*/
/*			color: #17A2B8;*/
/*		}*/
/*	}*/
/*}*/

.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #526069;
  line-height: 1;
  padding: .6em 1.4em .5em .8em;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #526069;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  border-radius: .2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #526069 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  background: transparent;
  /*-webkit-appearance: none;*/
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  border-color: #888;
}

.select-css:focus {
  border-color: #aaa;
  /*box-shadow: 0 0 1px 1px rgba(59, 153, 252, .7);*/
  box-shadow: 0 0 0 1px -moz-mac-focusring;
  color: #222;
  outline: none;

}

.select-css option {
  font-weight: normal;
}

.dropdown-link {
  margin: 0 1rem;
  color: #344767;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  flex-direction: column;
  padding: 0.675rem 0;
  //&.active {
  //  font-weight: 500;
  //  box-shadow: 0 16px 44px 0 rgb(0 0 0 / 10%);
  //  border-radius: 0.5rem;
  //  background-color: #fff;
  //}
}

.nav-link {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  margin: 0 1rem;
  color: #344767;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.active {
    font-weight: 500;
    box-shadow: 0 0px 44px 0 rgb(0 0 0 / 10%);
    border-radius: 0.5rem;
    background-color: #fff;
  }
}

.nav-sub-link {
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
  margin: 0 1rem;
  color: #344767;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.active {
    font-weight: 500;
    box-shadow: 0 0px 44px 0 rgb(0 0 0 / 10%);
    border-radius: 0.5rem;
    background-color: #fff;
  }
}

.subCategory {
  margin: 0 1rem;
  color: #344767;

  &.active {
    font-weight: 500;
    box-shadow: 0 16px 44px 0 rgb(0 0 0 / 10%);
    border-radius: 0.5rem;
    background-color: #fff;
  }

  padding-left: 3em;
}

.expand-transition {
  transition: all 0.5s ease;
}

.expand-enter, .expand-leave {
  height: 0;
  opacity: 0;
}

</style>
