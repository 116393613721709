import Vue from 'vue'
import Router from 'vue-router'
import secureLS from '../plugins/secureLS.js'
import userRoutes from "@/router/userRoutes";
import sensorRoutes from "@/router/sensorRoutes";
import accountRoutes from "@/router/accountRoutes";
import parameterRoutes from "@/router/parameterRoutes";
import infrastructureRoutes from "@/router/infrastructureRoutes";
import generatorRoutes from "@/router/generatorRoutes";
import dataAccessRoutes from "@/router/dataAccessRoutes";

import moment from "moment";
import campaignRoutes from "@/router/campaignRoutes";
import developerToolsRoutes from "@/router/developerToolsRoutes";
import datavizRoutes from "@/router/DatavizRoutes";
import mobileRoutes from "@/router/mobileRoutes";
import deliveryRoutes from "@/router/deliveryRoutes";
import mqttRoutes from "@/router/mqttRoutes";
import publisher from "@/router/publisherRoutes";

Vue.use(Router);

const baseRoutes = [
	{
		path: '/',
		name: 'dashboard',
		component: () => import(/* webpackChunkName: "about" */ '@/views/Dashboard.vue'),
		meta: {
			requiresAuth: true,
			breadCrumb: [
				{
					name: 'Home',
					link: '/'
				}
			]
		}
	},
	{
		path: '/login',
		name: 'login',
		meta: { layout: "NoSidebar", requiresAuth : false},
		component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		meta: { layout: "NoSidebar", requiresAuth : false},
		component: () => import(/* webpackChunkName: "about" */ '@/views/ResetPassword.vue'),
	},
	{
		path: '*',
		name: 'error',
		props: true,
		meta: { layout: "NoSidebar", requiresAuth: false},
		component: () => import(/* webpackChunkName: "about" */ '@/views/Error.vue'),
	},
]

let routes = baseRoutes.concat(
	userRoutes,
	sensorRoutes,
	accountRoutes,
	parameterRoutes,
	infrastructureRoutes,
	generatorRoutes,
	campaignRoutes,
	dataAccessRoutes,
	developerToolsRoutes,
	datavizRoutes,
	mobileRoutes,
	deliveryRoutes,
	mqttRoutes,
	publisher
);

const router = new Router({
	routes:routes,
	// eslint-disable-next-line
	scrollBehavior: (to, from, savedPosition)  => {
		if (to.hash) {
			return {selector: to.hash}
		} else {
			return {x: 0, y: 0}
		}
	},
	mode: 'history',
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login','/reset-password', '/error'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = secureLS.get(btoa('token'));

	window.document.title = to.meta && to.meta.title ? to.meta.title : 'LifyAir Admin';

	if (to.name !== 'error') {
		if (authRequired && !loggedIn) {
			return next('/login');
		} else if (loggedIn.token && !authRequired && loggedIn.timestamp && moment(loggedIn.timestamp) > moment().isAfter(moment().subtract(1, 'days'))) {
			secureLS.remove(btoa('token'))
			return next('/login');
		} else if (loggedIn.token && !authRequired) {
			return next('/');
		}
	}
	next();
});

export default router;
