<template>
    <b-row class="justify-content-center align-content-center" v-if="!sensors">
        <b-col cols="12" md="8" class="text-center align-self-center shadow rounded p-5 my-2">
            <p class="text-center dataAccessFormTitle clr-dark-grey">
                Outils de modification d'accès personnalisés
            </p>
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" class="text-center"></b-spinner>
            <p class="text-center">Vous allez être redirigé !</p>
        </b-col>
    </b-row>
    <b-row class="justify-content-center align-content-center mb-3" v-else>
        <b-col cols="12" md="8" v-if="formStep === 1" class="basic-card p-3 p-md-5">
            <b-col>
                <p class="dataAccessFormTitle text-center mb-4">
                    Choix des
                    <span class="font-weight-bold">{{ ressourceCategory | toUpperCase }}</span>
                    pour lesquels l'accès va être autorisé
                </p>
            </b-col>
            <b-col cols="12" class="py-4 mb-3">
                <b-row class="justify-content-around">
                    <b-form-group label="Type d'accès :">
                        <b-form-select :state="formData.accessType !== '' ? true : null"
                                       v-model="formData.accessType" :options="accessTypes"/>
                    </b-form-group>
                    <b-form-group label="Date de fin d'accès (optionnel) :">
                        <b-form-datepicker v-model="formData.endAt"></b-form-datepicker>
                    </b-form-group>
                </b-row>
            </b-col>

            <!--  >>>>>>>>>>>    ACCOUNT GROUP FORM SECTION       -->
            <b-col v-if="ressourceCategory === 'accountGroup' && getDataAccess.accountGroup">
                <b-row class="justify-content-center">
                    <b-col cols="12" md="10" class="mt-3">
                        <p class="m-0 p-0"><b>COMPTE</b></p>
                        <b-form-group label="Veuillez choisir le ou les comptes qui donnerons cet accès :"
                                      label-for="accounts" class="dataAccessFormSubTitle clr-dark-grey">
                            <b-form-select v-model="chosenAccounts" multiple :select-size="7"
                                           id="accounts" required :state="chosenAccounts.length > 0 ? true : null">
                                <b-form-select-option :value="account.id"
                                                      v-for="(account, index) in getAccountsInsteadMine"
                                                      :key="index">
                                    {{ account.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en
                                maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(account,index) in accounts.filter(item => chosenAccounts.includes(item.id))"
                                     :key="index">
                                {{ account.name }}
                            </b-badge>
                        </h3>
                    </b-col>
                    <b-col cols="12" md="10" v-if="chosenAccounts.length > 0" class="mt-5">
                        <p class="m-0 p-0"><b>GROUPE</b></p>
                        <b-form-group
                            label="Veuillez choisir le ou les groupe(s) de comptes qui donneront cet accès :"
                            v-slot="{ ariaDescribedby }" label-for="accounts"
                            class="dataAccessFormSubTitle clr-dark-grey">
                            <p class="d-flex d-inline clr-dark-blue"> Tout sélectionner :
                                <b-form-checkbox v-model="addAllAccountGroupsGiver" name="check-button"
                                                 switch class="ml-2"></b-form-checkbox>
                            </p>
                            <b-form-select v-model="formData.chosenAccountGroups" multiple
                                           :select-size="7" id="accounts" required
                                           :state="formData.chosenAccountGroups.length > 0 ? true : null">
                                <b-form-select-option :aria-describedby="ariaDescribedby"
                                                      :value="account.id"
                                                      v-for="(account, index) in getAccountGroupsByAccountIdInsteadMine"
                                                      :key="index">
                                    {{ account.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en
                                maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(accountGroup,index) in groupsBasedOnChosenAccountsGiver.filter(item => formData.chosenAccountGroups.includes(item.id))"
                                     :key="index"> {{ accountGroup.name }}
                            </b-badge>
                        </h3>
                    </b-col>
                </b-row>
            </b-col>

            <!--  >>>>>>>>>>>    SENSOR FORM SECTION       -->
            <b-col v-if="ressourceCategory === 'sensor'" class="bg-lighter-grey shadow-complete-data-access">
                <b-row class="justify-content-around align-content-center py-3">
                    <b-col cols="12" md="6">
                        <b-form-group label="Veuillez choisir le ou les sensors à autoriser :"
                                      v-slot="{ ariaDescribedby }" label-for="sensors">
                            <b-input-group class="my-2" v-on:submit.prevent="refreshSensors">
                                <b-form-input v-model="search"
                                              placeholder="Recherche par numéro de série"></b-form-input>
                                <b-input-group-append>
                                    <b-button type="submit" variant="primary" @click="refreshSensors">Go</b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-select v-model="formData.chosenDataRefs" multiple :select-size="7" id="sensors"
                                           required :state="formData.chosenDataRefs.length > 0 ? true : null">
                                <b-form-select-option :aria-describedby="ariaDescribedby"
                                                      :value="sensor.dataReference.id"
                                                      v-for="(sensor, index) in sensors" :key="index">
                                    {{ sensor.serialNumber }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en maintenant le
                                bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <h3>
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(sensor,index) in sensors.filter(item => formData.chosenDataRefs.includes(item.dataReference.id))"
                                     :key="index">
                                {{ sensor.serialNumber }}
                            </b-badge>
                        </h3>
                    </b-col>
                    <b-col cols="12" md="4">
                        <p>Sensors déjà autorisés :</p>
                        <b-row v-for="(sensor, index) in chosenSensors" :key="index">
                            <b-col cols="10">
                                {{ sensor }}
                            </b-col>
                            <b-col cols="2" @click="removeSensor(index)">
                                <b-icon variant="danger" icon="dash-circle-fill"/>
                            </b-col>
                            <div class="divider my-1"/>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>

            <!--  >>>>>>>>>>>    LOCATION FORM SECTION       -->
            <b-col v-if="ressourceCategory === 'location'" class="bg-lighter-grey shadow-complete-data-access">
                <b-row class="justify-content-center align-items-center py-3">
                    <b-col cols="12">
                        <b-row
                            class="align-content-center justify-content-center align-items-center mb-3">
                            <b-col cols="12">
                                <p class="clr-dark-blue dataAccessFormSubTitle">
                                    Choix d'une catégorie de Lieux :
                                    <br><span class="clr-light-grey font-weight-light">(il est possible de choisir des items dans les deux catégroies à la fois)</span>
                                </p>
                            </b-col>
                            <b-col cols="6"
                                   class="d-flex justify-content-center align-content-center align-items-center "
                                   @click="switchLocationToCoordinate = !switchLocationToCoordinate">
                                <div
                                    class="w-100 text-center rounded categoryCard mr-1 d-flex justify-content-center align-content-center align-items-center"
                                    :class="!switchLocationToCoordinate ? 'active':''">
                                    <p class="m-0">Point d'intérêts administratifs</p>
                                </div>
                            </b-col>
                            <b-col cols="6"
                                   class="d-flex justify-content-center align-content-center align-items-center "
                                   @click="switchLocationToCoordinate = !switchLocationToCoordinate">
                                <div
                                    class="w-100 text-center rounded categoryCard mr-1 d-flex justify-content-center align-content-center align-items-center"
                                    :class="switchLocationToCoordinate ? 'active':''">
                                    <p class="m-0">Coordonnées GPS</p>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="align-items-center justify-content-center" v-if="!switchLocationToCoordinate">
                    <b-col cols="12">
                        <b-form-group label="Choisir un accès par Lieux (Pays, Région, Département, Ville)"
                                      label-for="sensors">
                            <b-form-select v-model="chosenLocationType"
                                           :options="locationTypesOptions"></b-form-select>
                            <b-input-group class="my-2">
                                <b-form-input v-model="locationTypeFilter"
                                              placeholder="Recherche par nom du lieux"></b-form-input>
                            </b-input-group>
                            <b-form-select v-model="formData.locations[chosenLocationType]" multiple
                                           :select-size="4" id="sensors" required>
                                <b-form-select-option
                                    :value="location" v-for="(location, index) in getFilteredLocations"
                                    :key="index">
                                    {{ location }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text id="input-live-help" class="">Sélection au clique multiple en
                                maintenant le bouton "CTRL(Windows)" ou "Pomme(MacOS)" du clavier.
                            </b-form-text>
                        </b-form-group>
                        <p>
                            Pay(s) Sélectionnée(s) :
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(item,index) in formData.locations.countryLongName"
                                     :key="index"> {{ item }}
                            </b-badge>
                        </p>
                        <p>
                            Région(s) Sélectionné(s) :
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(item,index) in formData.locations.administrativeAreaLvl2"
                                     :key="index"> {{ item }}
                            </b-badge>
                        </p>
                        <p>
                            Département(s) Sélectionné(s) :
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(item,index) in formData.locations.administrativeAreaLvl1"
                                     :key="index"> {{ item }}
                            </b-badge>
                        </p>
                        <p>
                            Ville(s) Sélectionné(s) :
                            <b-badge class="mr-1" variant="primary"
                                     v-for="(item,index) in formData.locations.locality" :key="index">
                                {{ item }}
                            </b-badge>
                        </p>
                    </b-col>
                </b-row>
                <b-row class="align-items-center justify-content-center" v-else>
                    <b-col cols="12">
                        <b-form @submit.prevent="setNewLocation()" class="mb-1">
                            <b-form-group label="Nom du lieux associé aux coordonnées :"
                                          label-for="coordinates">
                                <b-input-group class="my-1">
                                    <b-form-input v-model="coordinateName"
                                                  placeholder="Ex: Gare SNCF Orléans"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Latitude :">
                                <b-input-group class="my-2">
                                    <b-form-input v-model="coordinateLat"
                                                  placeholder="Ex : 1.9"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group label="Longitude :">
                                <b-input-group class="my-2">
                                    <b-form-input v-model="coordinateLon"
                                                  placeholder="Ex : 47.3"></b-form-input>
                                </b-input-group>
                            </b-form-group>
                            <b-button type="submit" variant="primary" class="clr-white text-center">
                                Ajouter les coordonnées
                            </b-button>
                        </b-form>
                        <p class="mt-5">
                            Point(s) d'intérêt(s) ajoutée(s) :
                        </p>
                        <p v-for="(latLong,name, index) in formData.coordinates" :key="index">
                            <b-badge class="mr-1" variant="primary">{{ name | capitalize }}</b-badge>
                            - Coordonnées: [{{ latLong[0] }} , {{ latLong[1] }}]
                            <b-icon icon="x-circle-fill" class="clr-red" @click="removeGps(name)"/>
                        </p>
                    </b-col>
                </b-row>
            </b-col>
        </b-col>
        <b-col cols="12" md="8" v-if="formStep === 2" class="align-self-center shadow rounded p-2">
            <p class="text-center dataAccessFormTitle clr-dark-grey">RESUME DE LA MODIFICATION D'ACCÈS</p>
            <b-row class="justify-content-center mt-5 mb-4">
                <b-col cols="10" md="5" class="bg-lighter-grey shadow-complete-data-access py-2 mb-3 mb-md-0">
                    <p class="clr-dark-grey">RESSOURCES QUI VONT RECEVOIR LES ACCÈS</p>
                    <div>
                        <p>Compte :</p>
                        <p class="ml-2 clr-dark-blue">{{ getDataAccess.accountGroup.account.name }}</p>
                    </div>
                    <div>
                        <p>Groupe :</p>
                        <p class="ml-2 clr-dark-blue">{{ getDataAccess.accountGroup.name }}</p>
                    </div>
                </b-col>
                <b-col cols="1" class="text-center align-self-center d-none d-md-block">
                    <b-icon
                        icon="arrow-bar-right"
                        animation="cylon"
                        variant="primary"
                        scale="3"
                    >
                    </b-icon>
                </b-col>
                <b-col cols="10" md="5" class="bg-lighter-grey shadow-complete-data-access py-2">
                    <p class="clr-dark-grey">RESSOURCES AUTORISÉES</p>
                    <div v-if="ressourceCategory === 'sensor'" class="mt-2">
                        <p>Capteurs :</p>
                        <p class="ml-2 clr-dark-blue">
                            <span
                                v-for="(sensor,key) in finalSensors"
                                :key="key">
                               <b-badge class="mr-2" variant="primary">{{ sensor.serialNumber }}</b-badge>
                            </span>
                        </p>
                    </div>
                    <b-row v-else-if="ressourceCategory === 'accountGroup'" class="mt-2">
                        <p class="col-12">Les groupes:</p>
                        <b-row class="col-12" v-for="(account, index) in chosenAccountsByChosenAccountGroups"
                               :key="index">
                            <b-col cols="5" class="clr-dark-blue">
                                {{ account.name + ' :' }}
                            </b-col>
                            <b-col cols="7" class="clr-dark-blue">
                                <b-badge v-for="(accountGroup,index) in chosenGroupsByAccount(account.id)"
                                         :key="index" variant="primary" class="mr-2">
                                    {{ accountGroup.name }}
                                </b-badge>
                            </b-col>
                        </b-row>
                    </b-row>
                    <b-row v-else-if="ressourceCategory === 'location'" class="mt-2">
                        <b-col cols="12" class="mb-1">Les Point d'intérêt ou lieux :</b-col>
                        <b-row v-for="(location,key6) in formData.locations" :key="key6" class="col-12">
                            <b-col v-if="location.length > 0" cols="5">
                                <p class="clr-dark-blue">
                                    {{ locationTypesOptions.find(item => item.value === key6).text + ' :' }}
                                </p>
                            </b-col>
                            <b-col v-if="location.length > 0" cols="7">
                                <b-badge class="mr-2" variant="primary"
                                         v-for="(item,key7) in location" :key="key7">
                                    {{ item }}
                                </b-badge>
                            </b-col>
                        </b-row>
                        <b-col cols="12" v-if="Object.entries(formData.coordinates).length > 0" class="mt-2">
                            <p class="m-0">
                                Coordonnées Gps
                            </p>
                            <p class="ml-2 clr-dark-blue" v-for="(location, name , key) in formData.coordinates"
                               :key="key">
                                <b-badge class="mr-1" variant="primary">
                                    {{ name | capitalize }}
                                </b-badge>
                                - [{{ location[0] }} , {{ location[1] }}]
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="justify-content-around bg-lighter-grey mb-5 p-3 shadow-edit-data-access">
                <b-col cols="6" class="text-center">
                    <p class="m-0">Type d'accès :
                        <b-badge :class="`bg-${colorsByAccessType[formData.accessType]}`" class="ml-2 p-2">
                            {{ formData.accessType | toUpperCase }}
                        </b-badge>
                    </p>
                </b-col>
                <b-col cols="6" class="text-center">
                    <p class="m-0">Date de fin :
                        <span class="ml-2 clr-dark-blue" v-if="formData.endAt">{{ formData.endAt | dayDate }} </span>
                        <span class="ml-2 clr-dark-blue" v-else>Aucune</span>
                    </p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center text-center mb-3">
                <b-col cols="6">
                    <b-button class="bg-green clr-white" @click="sendAccess()" size="lg">Enregistrer</b-button>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="8" class="mt-2 text-center" v-if="formStep > 0">
            <b-progress :value="formStep" :max="2" class="mb-3"
                        :variant="formStep === 2 ? 'success':'primary'"></b-progress>
            <b-button class="mr-1 clr-white" @click="stepDown"
                      :class="formStep > 1 ? 'bg-light-grey':'bg-red'">
                {{ formStep > 1 ? 'Précédent' : 'Annuler' }}
            </b-button>
            <b-button class="text-center" @click="stepUp" :class="isStepOk ? 'bg-blue clr-white' : ''"
                      v-if="formStep !== 2">Valider
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "AccountAccessEdit",
    data() {
        return {
            formStep: 1,
            addAllAccountGroupsReceiver: false,
            addAllAccountGroupsGiver: false,
            chosenLocationType: 'countryLongName',
            chosenAccounts: [],
            locationTypesOptions: [
                {value: 'countryLongName', text: 'Pays(s)'},
                {value: 'administrativeAreaLvl2', text: 'Région(s)'},
                {value: 'administrativeAreaLvl1', text: 'Département(s)'},
                {value: 'locality', text: 'Ville(s)'}
            ],
            accessTypes: [
                {value: 'standard', text: 'Standard'},
                {value: 'pro', text: 'Entreprise'},
                {value: 'scientific', text: 'Scientifique'}
            ],
            locationTypeFilter: '',
            switchLocationToCoordinate: false,
            coordinateName: '',
            coordinateLat: '',
            coordinateLon: '',
            formData: {
                chosenDataRefs: [],
                chosenAccountGroups: [],
                accessType: 'standard',
                endAt: null,
                locations: {
                    countryLongName: [],
                    administrativeAreaLvl1: [],
                    administrativeAreaLvl2: [],
                    locality: [],
                },
                coordinates: {}
            },
            search: '',
            ressourceCategory: '',
            chosenSensors: [],
            colorsByAccessType: {
                'standard': 'green3',
                'pro': 'light-green',
                'scientific': 'yellow',
            }
        }
    },
    methods: {
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('dataAccess', {
            editDataAccess: 'editDataAccess',
            setDataAccess: 'setDataAccess',
            setDataAccessByAccountGroup: 'setDataAccessByAccountGroup'
        }),
        ...Vuex.mapActions('sensor', {
            setSensorList: 'setSensorList',
            setAvailableLocations: 'setAvailableLocations'
        }),
        stepUp() {
            if (this.isStepOk) {
                this.formStep += 1
            } else {
                alert("Veuillez choisir au moins un compte avant de passer à l'étape suivante")
            }
        },
        stepDown() {
            if (this.formStep > 1) {
                this.formStep -= 1
            } else {
                this.$router.go(-1);
            }
        },
        refreshSensors() {
            const params = {
                sort: this.sort,
                direction: this.direction,
            }
            this.setSensorList(params)
        },
        setNewLocation() {
            const key = this.coordinateName;
            const lat = this.coordinateLat;
            const lon = this.coordinateLon;

            if (key && lat && lon) {
                this.formData.coordinates[key] = [lat, lon]
                this.formData.coordinates = {...this.formData.coordinates};
                this.coordinateName = '';
                this.coordinateLat = '';
                this.coordinateLon = '';
            } else if (!key || !lat || !lon) {
                alert("Le formulaire n'est pas complet")
            } else {
                alert('Cette entrée existe déjà!')
            }
        },
        getAccountObjectById(id) {
            return this.getAccountsById(id)
        },
        generateQueryString() {
            let data = new FormData();
            //
            // if (this.formData.chosenDataRefs.length > 0 && this.ressourceCategory === 'sensor') {
            //     this.formData.chosenDataRefs.forEach(dataRef => {
            //         data.append('accesses[]', dataRef);
            //     })
            // }
            if (this.finalSensors.length > 0 && this.ressourceCategory === 'sensor') {
                this.finalSensors.forEach(item => {
                    data.append('accesses[]', item.dataReference.id);
                })
            }
            if (this.formData.chosenAccountGroups.length > 0 && this.ressourceCategory === 'accountGroup') {
                this.formData.chosenAccountGroups.forEach(accountGroup => {
                    data.append('accesses[]', accountGroup);
                })
            }
            if (this.formData.endAt) data.append('endAt', this.formData.endAt);
            data.append('accessType', this.formData.accessType);

            if (Object.entries(this.formData.coordinates).length > 0) {
                Object.entries(this.formData.coordinates).forEach((coordinate) => {
                    const lat = coordinate[1][0].toString();
                    const long = coordinate[1][1].toString();
                    data.append('locations[coordinates][' + coordinate[0] + ']', lat + ',' + long);

                })
            }
            //
            if (this.isLocationsSet) {
                for (const property in this.formData.locations) {
                    let ressources = ''
                    for (let u = 0; u < this.formData.locations[property].length; u++) {
                        if (u === (this.formData.locations[property].length - 1)) {
                            ressources += this.formData.locations[property][u]
                        } else {
                            ressources += this.formData.locations[property][u] + ','
                        }
                    }
                    data.append('locations[' + property + ']', ressources);
                }
            }
            return data
        },
        sendAccess() {
            let data = this.generateQueryString()
            this.editDataAccess({id: this.$route.params.id, params: data}).then(() => {
                if (this.getRequest.success) {
                    setTimeout(() => {
                        this.resetForm()
                    }, 2500);
                }
            })
        },
        resetForm() {
            this.formData = {
                chosenDataRefs: [],
                chosenAccountGroups: [],
                accessType: 'standard',
                endAt: null,
                locations: {
                    countryLongName: [],
                    administrativeAreaLvl1: [],
                    administrativeAreaLvl2: [],
                    locality: [],
                },
                coordinates: {}
            }
        },
        setFormDataBasedOnExistingAccess() {
            this.formData.accessType = this.getDataAccess.accessType;
            this.formData.endAt = this.getDataAccess.endAt;
            if (this.ressourceCategory === 'sensor') {
                delete this.formData.locations;
                this.getDataAccess.accesses.forEach(access => {
                    this.chosenSensors.push(access.sensor.serialNumber)
                })
            }
            if (this.ressourceCategory === 'accountGroup') {
                delete this.formData.locations;
                this.getDataAccess.accesses.forEach(access => {
                    this.chosenAccounts.push(access.account.id);
                    this.formData.chosenAccountGroups.push(access.id)
                })
            }
            if (this.ressourceCategory === 'location') {
                const locations = this.getDataAccess.accesses;
                Object.keys(locations).forEach((locationType) => {
                    if (locationType === 'coordinates') {
                        Object.entries(locations[locationType]).forEach((coordinate) => {
                            this.formData.coordinates[coordinate[0]] = coordinate[1];
                        })
                    } else if (locations[locationType][0] !== '') {
                        this.formData.locations[locationType] = Object.values(locations[locationType]);
                    }
                })
            }
        },
        isEditMode() {
            return this.accountGroupToEdit !== null
        },
        isLocationsSet() {
            let locations = this.formData.locations
            return locations.administrativeAreaLvl2.length ||
                locations.administrativeAreaLvl1.length ||
                locations.countryLongName.length ||
                locations.locality.length

        },
        chosenGroupsByAccount(accountId) {
            const account = this.getAccountsById(accountId);
            if (account && account.accountGroups.length > 0) {
                return account.accountGroups.filter(group => this.formData.chosenAccountGroups.includes(group.id));
            } else {
                return [];
            }
        },
        removeGps(item) {
            if (this.formData.coordinates[item].length > 0) {
                delete this.formData.coordinates[item];
                this.formData.coordinates = {...this.formData.coordinates};
            }
        },
        removeSensor(index) {
            this.chosenSensors.splice(index, 1);
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
            getAccountGroupsByAccountId: 'getAccountGroupsByAccountId',
            getAccountsByAccountIdNotChosen: 'getAccountsByAccountIdNotChosen',
            getAccountsById: 'getAccountsById',
            getAccountGroupById: 'getAccountGroupById'
        }),
        ...Vuex.mapGetters('sensor', {
            getSensorList: 'getSensorList',
            paginator: 'paginator',
            getAvailableLocations: 'getAvailableLocations',
        }),
        ...Vuex.mapGetters('dataAccess', {
            getDataAccessByGroup: 'getDataAccessByGroup',
            getDataAccess: 'getDataAccess',
            isDataAccessLoading: 'isDataAccessLoading',
            getRequest: 'getRequest'
        }),
        isStepOk() {
            if (this.formStep === 1) {
                if (this.ressourceCategory === 'sensor') {
                    return this.finalSensors.length > 0
                } else if (this.ressourceCategory === 'accountGroup') {
                    return this.formData.chosenAccountGroups.length > 0
                } else if (this.ressourceCategory === 'location') {
                    return this.isLocationsSet || this.formData.coordinates.length > 0;
                }
            }
            return false
        },
        groupsBasedOnChosenAccountsGiver() {
            return this.getAccountGroupsByAccountId([this.chosenAccounts, this.formData.chosenAccountGroups])
        },
        chosenAccountsByChosenAccountGroups() {
            const accounts = [];
            this.accounts.forEach((account) => {
                let chosenGroups = account.accountGroups.filter(
                    accountGroup => this.formData.chosenAccountGroups.includes(accountGroup.id)
                )
                if (chosenGroups.length > 0) accounts.push(account);
            })
            return accounts;
        },
        getFilteredLocations() {
            const type = this.chosenLocationType;
            if (this.chosenLocationType && this.getAvailableLocations[type] && this.getAvailableLocations[type].length > 0) {
                return this.getAvailableLocations[type].filter(
                    name => name.toLowerCase().includes(this.locationTypeFilter.toLowerCase())
                )
            } else {
                return []
            }
        },
        sensors() {
            if (this.search.length > 0) {
                let firstList = this.getSensorList.filter(sensor => sensor.serialNumber.includes(this.search));
                let secondList = this.getSensorList.filter(sensor => !sensor.serialNumber.includes(this.search));
                return firstList.concat(secondList).filter(sensor => !this.chosenSensors.includes(sensor.serialNumber));
            } else {
                return this.getSensorList.filter(sensor => !this.chosenSensors.includes(sensor.serialNumber));
            }
        },
        finalSensors() {
            let chosenSensors = this.sensors.filter(item => this.formData.chosenDataRefs.includes(item.dataReference.id))
            let existingSensors = this.getSensorList.filter(item => this.chosenSensors.includes(item.serialNumber))
            return chosenSensors.concat(existingSensors);
        },
        getAccountsInsteadMine() {
            const excludedAccount = this.getDataAccess.accountGroup.account.id;
            let accounts = [];
            this.accounts.forEach((account) => {
                if (account.id !== excludedAccount || account.accountGroups.length > 1) {
                    accounts.push(account);
                }
            })
            return accounts;
        },
        getAccountGroupsByAccountIdInsteadMine() {
            let accounts = this.accounts.filter(account => this.chosenAccounts.includes(account.id))
            let groups = []
            for (let i = 0; i < accounts.length; i++) {
                for (let y = 0; y < accounts[i].accountGroups.length; y++) {
                    if (this.getDataAccess.accountGroup.id !== accounts[i].accountGroups[y].id) {
                        groups.push(accounts[i].accountGroups[y])
                    }
                }
            }
            return groups
        },
    },
    created() {
        this.ressourceCategory = this.$route.params.type;
        this.setDataAccess(this.$route.params.id).then(async () => {
            await this.setFormDataBasedOnExistingAccess();
            if (this.ressourceCategory === 'sensor') this.setSensorList();
            if (this.ressourceCategory === 'location') this.setAvailableLocations();
            if (this.ressourceCategory === 'accountGroup') this.setAccounts();
        });
    },
    watch: {
        addAllAccountGroupsGiver: function (val) {
            let groups = [];
            if (val) {
                this.groupsBasedOnChosenAccountsGiver.forEach((accountGroup) => {
                    groups.push(accountGroup.id);
                })
            }
            this.formData.chosenAccountGroups = groups;
        },
    }
}
</script>

<style lang="scss" scoped>
#body {
    background-color: grey;
}

.categoryCard {
    background-color: $blue;
    color: white;
    min-height: 40px;

    &.active {
        background-color: white;
        color: $blue;
        border: $blue 1px solid;
    }
}

.dataAccessChoiceCard {
    background-color: $light-grey;
}

.dataAccessFormTitle {
    font-size: 1.2rem;
}

.dataAccessFormSubTitle {
    font-size: 1.1rem;
}

.shadow-edit-data-access {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.shadow-complete-data-access {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.divider {
    @include divider($light-grey, 90%, 2px);
}
</style>
