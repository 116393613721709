import ReportTypesList from "@/components/parameter/ReportTypesList";
import ReportTypeCreate from "@/components/parameter/ReportTypeCreate";

export default [
    {
        path: '/parameters',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Parameter.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                component: ReportTypesList,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Parameters'
                        },
                        {
                            name: 'Report-Type'
                        }
                    ]
                },
            },
            {
                path: 'report-types/new',
                component: ReportTypeCreate,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Parameters',
                            link: '/parameters/report-types'
                        },
                        {
                            name: 'New Report Type'
                        }
                    ]
                },
            },
        ]
    },
];