<template>
    <div class="basic-card w-100"
         :class="containerClass">
        <b-row class="justify-content-between px-1 pb-1 bg-lightcoral shadow-bottom align-items-center"
               :class="from === 'Account' ? 'pt-2' : 'pt-3'">
            <b-col class="align-content-center" cols="6">
                <p :class="contentByType[event.type].color"
                   class="container-title m-0">
                    <b-icon :icon="contentByType[event.type].icon" scale="1.4" class="mr-2"/>
                    {{ event.title }}
                </p>
            </b-col>
            <b-col v-if="from === 'Account'" cols="6">
                <p @click="goToSensor(event.sensor.serialNumber)" class="add-cursor m-0 big-title clr-white text-right">
                    {{ event.sensor.serialNumber }}
                </p>
            </b-col>
            <b-col v-if="from === 'SensorEvent'" cols="auto" class="align-content-center text-md-right">
                <b-button
                    class="mr-2"
                    @click="newNote"
                    variant="outline-light">
                    <b-icon-plus-circle class="mr-2" scale="1.2"/>
                    Ajouter une note
                </b-button>
                <b-button
                    class="mr-2"
                    @click="endEvent"
                    variant="outline-light">
                    <b-icon-check2-circle class="mr-2" scale="1.2"/>
                    Marquer comme résolu
                </b-button>
                <b-button
                    @click="editEvent"
                    variant="outline-light">
                    <b-icon-pencil scale="1.2"/>
                </b-button>
            </b-col>
            <p :class="from === 'SensorEvent' ? 'col-12' : ' col-6'" class="caption clr-white mb-0 py-1">
                Dernière update : {{ event.updatedAt | basicDate }}
            </p>
            <p v-if="from === 'Account'" class="col-6 text-right clr-white mb-0 py-1">
                {{ event.sensor.sensorName }}
            </p>
        </b-row>
        <b-row class="mt-2 px-2">
            <b-col cols="6">
                <p class="m-1"><span class="bold">Type :</span> {{ event.type }}</p>
            </b-col>
            <b-col cols="6">
                <p class="text-right m-1"><span class="bold">Par :</span> {{ event.user.email }}</p>
            </b-col>
            <b-col cols="6">
                <p class="m-1"><span class="bold">Description :</span> {{ event.context }}</p>
            </b-col>
            <b-col cols="6">
                <p class="text-right m-1"><span class="bold">Création :</span> {{ event.createdAt | dayDate }}</p>
            </b-col>
        </b-row>
        <div class="px-2" v-if="from !== 'Account'">
            <p class="text-center big-title m-0">Historique</p>

            <!--TIMELINE-->
            <div class="wrapper" v-if="histories.length > 0">
                <ul class="timeline">
                    <li class='timelineItem'
                        v-for="(item,idx) in histories"
                        :key="idx"
                        :class="{expanded: expanded === idx.toString()}">
                        <b-avatar
                            variant="light"
                            class="timelineIcon"
                            size="35px">
                            <b-icon :icon="item.icon ? item.icon : 'pen'"/>
                        </b-avatar>
                        <b-card
                            border-variant="light"
                            footer-bg-variant="light"
                            class="shadow"
                        >
                            <b-card-title>
                                {{ item.action }}
                            </b-card-title>
                            <b-card-sub-title class="mb-2 caption">
                                {{ item.user ? 'Par ' + item.user.email : 'Par lifyair_system' }}
                            </b-card-sub-title>
                            <b-avatar button
                                      variant="light"
                                      style="position:absolute; right: 10px; top: 10px; cursor:pointer"
                                      @click="expand(idx.toString())">
                                <b-icon
                                    v-if="expanded === idx.toString()"
                                    icon="arrows-angle-contract"/>
                                <b-icon
                                    v-else
                                    icon="arrows-angle-expand"/>
                            </b-avatar>
                            <b-card-text v-html="item.context"/>
                            <div class="hue" v-if="showHue(item)" :ref="idx"/>
                            <template #footer>
                                <b-row>
                                    <b-col>
                                        <span>Le {{ item.createdAt | basicDate }}</span>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-card>
                    </li>
                </ul>
            </div>
            <p class="text-center mt-3" v-else>Aucune note associée pour le moment.</p>
        </div>

        <!--NEW NOTE MODAL-->
        <b-modal id="noteModal"
                 :ok-disabled="!inputState"
                 @ok="saveNote()"
                 @cancel="cancelModal()"
                 @hidden="cancelModal()"
                 size="xl"
                 title="Ajouter une note"
        >
            <b-form id="noteForm">
                <b-form-group label="Titre" class="my-4 mx-2">
                    <b-form-input
                        v-model="form.action"
                        required
                        :state="inputState"/>
                    <b-form-invalid-feedback id="input-live-feedback">
                        <span v-if="form.action.length < 1">Entrer au moins 3 caractères</span>
                        <span v-if="form.action.length > 50">Maximum 50 caractères</span>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-row class="my-4 mx-2">
                    <b-select v-model="form.variant" :options="type"/>
                </b-row>
                <b-row class="my-4 mx-2">
                    <b-form-radio-group
                        class="d-flex align-items-center justify-content-around align-content-between flex-wrap"
                        v-model="form.icon">
                        <b-radio
                            v-for="(icon, idx) in icons"
                            :key="idx"
                            :value="icon.value">
                            <b-avatar
                                :variant="form.variant"
                                class="timelineIcon"
                                size="50px">
                                <b-icon :icon="icon.value"/>
                            </b-avatar>
                        </b-radio>
                    </b-form-radio-group>
                </b-row>
                <b-row class="my-4 mx-2 justify-content-between flex-wrap">
                    <b-col class="p-0 col-5" style="min-width: 320px">
                        <b-form-group label="Le">
                            <b-datepicker
                                id="dateNow"
                                :placeholder="new Date().toLocaleDateString()"
                                :max="form.dueDate"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                v-model="form.createdAt"/>
                        </b-form-group>
                    </b-col>
                    <b-col class="p-0 col-5" style="min-width: 320px">
                        <b-form-group label="Pour le">
                            <b-datepicker id="dueDate"
                                          placeholder=" "
                                          :min="form.createdAt"
                                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                          v-model="form.dueDate"
                                          :state="validDueDate"
                            ></b-datepicker>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group
                    label="Description"
                    label-for="sensorNoteDescription"
                    class="my-4 mx-2">
                    <vue-editor
                        v-model="form.context"
                        :editorToolbar="customToolbar"
                        aria-required="true"/>
                </b-form-group>
            </b-form>
        </b-modal>

        <!-- EDIT SENSOR EVENT MODAL-->
        <b-modal
            id="edit-sensor-event"
            ref="modal"
            title="Création d'un événement"
            @ok="handleOk"
        >
            <form ref="form">
                <b-form-group
                    label="Type"
                    label-for="type-select"
                    invalid-feedback="Veuillez sélectionner un type">
                    <b-form-select
                        id="type-select"
                        v-model="updatedSensorEvent.type"
                        :options="typeOptions"/>
                </b-form-group>
                <b-form-group
                    label="Titre"
                    label-for="input-title">
                    <b-form-input
                        id="input-title"
                        v-model="updatedSensorEvent.title"
                        placeholder="Entrez le titre..."/>
                </b-form-group>
                <b-form-group
                    label="Description"
                    label-for="textarea-context">
                    <b-form-textarea
                        id="textarea-context"
                        v-model="updatedSensorEvent.context"
                        placeholder="Description de l'événement..."/>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import Vuex from "vuex";
import moment from "moment";

export default {
    name: "SensorEventDetails",
    components: {VueEditor},
    props: {
        event: {
            type: Object,
            default: () => {
                return {}
            }
        },
        from: {
            type: String,
            default: 'SensorEvent'
        }
    },
    data() {
        return {
            updatedSensorEvent: {
                id: this.event.id,
                type: this.event.type,
                title: this.event.title,
                context: this.event.context
            },
            contentByType: {
                incident: {
                    text: 'Incident',
                    icon: 'exclamation-circle-fill',
                    backgroundColor: 'bg-pink-seashell',
                    color: 'clr-white'
                }
            },
            advancedMode: false,
            expanded: false,
            form: {
                action: '',
                context: '',
                createdAt: '',
                dueDate: '',
                icon: 'pen',
                variant: 'light',
            },
            icons: [
                {value: 'box-arrow-right'},
                {value: 'box-arrow-left'},
                {value: 'cloud-arrow-down'},
                {value: 'info-circle'},
                {value: 'pen'},
                {value: 'x-circle'},
                {value: 'wrench'},
                {value: 'tools'},
                {value: 'box-seam'},
            ],
            type: [
                {value: 'light', text: 'défaut'},
                {value: 'primary', text: 'information'},
                {value: 'warning', text: 'attention'},
                {value: 'danger', text: 'important'},
            ],
            customToolbar: [
                ["bold", "italic", "underline"],
                [{list: "ordered"}, {list: "bullet"}],
                ['link']
            ],
            formTemplate: {
                action: '',
                context: '',
                createdAt: '',
                dueDate: '',
                icon: 'pen',
                variant: 'light',
            },
            typeOptions: [
                {text: 'Incident', value: 'incident', disabled: false}
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            addSensorNote: 'addSensorNote'
        }),
        ...Vuex.mapActions('sensorEvent', {
            editSensorEvent: 'editSensorEvent',
            setSensorEvents: 'setSensorEvents'
        }),
        expand(idx) {
            if (!this.expanded) {
                this.expanded = idx;
            } else if (this.expanded && idx && this.expanded !== idx) {
                this.expanded = idx;
            } else if (this.expanded === idx) {
                this.expanded = false;
            }
        },
        showHue(item) {
            if (item && item.context) {
                return !!(item.context.length > 300 || item.context.includes('<br'));
            }
        },
        due(item) {
            if (!item.dueDate || this.isOld(item)) return false;
            let date = new Date(item.createdAt);
            date.setDate(date.getDate() + 7)
            let dueDate = new Date(item.dueDate).setHours(0, 0, 0);
            return dueDate <= date.getTime();
        },
        isOld(item) {
            let d = new Date().getTime();
            let i = new Date(item.dueDate && item.dueDate !== '' ? item.dueDate : item.createdAt).getTime();
            const sevenDays = 604800000;
            return d - i > sevenDays;
        },
        newNote() {
            this.resetForm();
            this.$bvModal.show('noteModal')
        },
        cancelModal() {
            this.resetForm();
            this.$bvModal.hide('noteModal');
        },
        resetForm() {
            Object.assign(this.form, this.formTemplate);
            delete this.form['user'];
            delete this.form['id'];
        },
        async saveNote() {
            if (!this.inputState) return;
            let query = this.generateQueryString()
            await this.addSensorNote(query).then(() => {
                this.event.sensorHistories.push(this.createdNote);
                this.$bvModal.hide('noteModal');
                this.resetForm()
            });
        },
        generateQueryString() {
            let fields = new FormData();
            fields.set('sensor', this.individualSensor.id);
            fields.set('sensorEvent', this.event.id);
            for (let [key, value] of Object.entries(this.form)) {
                if (key === 'createdAt' || key === 'dueDate') {
                    fields.set(key, this.formatDate(value));
                } else if (key !== 'user' && key !== 'id' && key !== 'payload') {
                    fields.set(key, value)
                }
            }
            return fields
        },
        formatDate(date) {
            return date ? moment(date).format('DD-MM-YYYY HH:mm:ss') : '';
        },
        endEvent() {
            this.$swal.fire({
                title: 'Terminer l\'événement',
                html: "Cela signifie que l'événement sera terminé, et que toutes les actions nécessaires pour le résoudre auront été mises en œuvre.",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, je suis sûr!'
            }).then(async (result) => {
                if (result.value) {
                    let params = new FormData();
                    params.append('endedAt', this.formatDate(moment()))
                    await this.editSensorEvent({id: this.event.id, params: params}).then(() => {
                        this.setSensorEvents(this.$route.params.id);
                    });
                }
            })
        },
        editEvent() {
            this.$bvModal.show('edit-sensor-event')
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        async handleSubmit() {
            const form = new FormData();
            for (const item in this.updatedSensorEvent) {
                if (this.updatedSensorEvent[item] !== this.event[item]) {
                    form.append(item, this.updatedSensorEvent[item])
                }
            }
            await this.editSensorEvent({id: this.event.id, params: form}).then(() => {
                this.setSensorEvents(this.$route.params.id);
            });
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sensor-event')
            })
        },
        goToSensor(serialNumber) {
            window.open("/sensors/" + serialNumber + "/detail", '_blank');
        },
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            createdNote: 'createdNote',
            individualSensor: 'individualSensor'
        }),
        inputState() {
            return this.form.action.length > 1 && this.form.action.length <= 50
        },
        validDueDate() {
            if (this.form.dueDate === '') return null;
            let today = new Date().setHours(0, 0, 0, 0)
            let due = new Date(this.form.dueDate).getTime();
            let created = new Date(this.form.createdAt).getTime();
            if (isNaN(created) && due < today) {
                return false;
            } else if (isNaN(created) && due >= today) {
                return true;
            } else if (created > due) {
                return false;
            } else if (created <= due) {
                return true;
            }
            return false;
        },
        histories() {
            const sensorHistories = this.event.sensorHistories;
            return sensorHistories.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        },
        containerClass() {
            let style = this.contentByType[this.event.type].backgroundColor;
            if (this.from === 'SensorEventHistory') {
                style += ' m-0 p-0'
            } else if (this.from === 'Account') {
                style += ' pb-3 pt-0'
            } else {
                style += ' mt-4 pb-3 pt-0'
            }
            return style;
        }
    }
}
</script>

<style scoped lang="scss">

li {
    list-style-type: none;
}

.shadow-bottom {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.wrapper {
    max-height: 500px;
    overflow: scroll;
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
    padding: 1em;

    &::-webkit-scrollbar {
        display: none;
    }
}

.timeline {
    padding-left: 75px;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: fit-content;

    .card-title {
        color: black;
        font-size: 20px;
    }

    .card-footer {
        color: black;
        font-size: 14px;
    }

    &:before {
        content: "";
        position: absolute;
        top: -20px;
        bottom: 0;
        width: 4px;
        left: 23px;
        color: #8993A6;
        background: linear-gradient(
                0deg,
                rgba(137, 147, 166, 0) 0%,
                rgba(137, 147, 166, 0.75) 10%,
                rgba(137, 147, 166, 1) 20%,
                rgb(137, 147, 166) 80%,
                rgba(137, 147, 166, 0.75) 90%,
                rgba(137, 147, 166, 0) 100%
        );
    }

    .timelineItem {
        position: relative;
        margin-bottom: 2rem;

        &.expanded {
            .hue {
                display: none;
            }

            @media screen and (min-width: 800px) {
                > .b-avatar {
                    transform: translate(120%, -17px);
                }
                .card {
                    transform: translateX(-3%) scale(1.06);
                }
                &.due .card {
                    animation: none;
                }
            }

            .card-text {
                max-height: 100%;
                overflow: visible;
                white-space: normal;
            }
        }

        &.past {
            .timelineIcon {
                background-color: grey;
                color: #FFF;
            }
        }

        &.due .card {
            animation: vibrate-1 0.3s linear infinite both;
        }


        .timelineIcon {
            position: absolute;
            left: -67px;
            border-radius: 50%;
            top: 50%;
            transform: translate(0%, -17px);
            box-shadow: -1px 2px 2px 0px rgb(0 0 0 / 38%);
            font-size: 1.2rem;
        }

        .timelineInfo {
            width: 100%;
            min-height: 50px;
            box-shadow: 2px 2px 8px 2px rgb(0 0 0 / 10%);
        }

        .card-body {
            position: relative;
            overflow: hidden;
            padding: 15px;
        }

        .card-text {
            width: 100%;
            margin-bottom: 0;
            position: relative;
            max-height: 50px;
            overflow: hidden;
            transition: all, .5s;

            p, span {
                margin-bottom: 0 !important;
                transition: all, .5s;
                text-overflow: elipsis;
            }
        }

        span.dueDate {
            float: right;
        }
    }
}

.hue {
    width: 100%;
    height: calc(0.2 * 1vmin);
    border-radius: 100% 100% 0% 0%/100% 100% 0% 0%;
    position: absolute;
    left: 0;
    bottom: -2px;
    box-shadow: 0px -2px 7px 0px #000;
}
</style>