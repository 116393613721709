import axios from "../../plugins/axios";

const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
    getUsers,
    getUser,
    createUser,
    deleteUser,
    updateUser,
    generateApiKey,
    editApiKey,
    deleteApiKey,
    deleteLinkedUserGroup,
    linkedUserToGroup,
    manageUserRoles,
    removeUserFromCampaign
}

function getUsers(data) {
    return axios.get('/users?' + querystring.stringify(data), authHeader.classicalHeader())
}

function getUser(data) {
    return axios.get('/users/' + data, authHeader.classicalHeader())
}

function createUser(data) {
    return axios.post('/users/new', data, authHeader.formDataHeader())
}

function updateUser(data) {
    return axios.post('/users/' + data[0], data[1], authHeader.formDataHeader())
}

function manageUserRoles(user, data) {
    return axios.post('/admin/users/' + user + '/roles', data, authHeader.formDataHeader())
}

function deleteUser(data) {
    return axios.delete('/users/' + data, authHeader.classicalHeader())
}

function generateApiKey(data) {
    return axios.get('/users/' + data + '/generate-api-key', authHeader.classicalHeader())
}

function editApiKey(data) {
    return axios.post('/api-key-token/' + data.id + '/edit', querystring.stringify(data.params), authHeader.classicalHeader())
}

function deleteApiKey(apiKeyId) {
    return axios.delete('/api-key-token/' + apiKeyId, authHeader.classicalHeader())
}

function deleteLinkedUserGroup(data) {
    return axios.delete('/users/'+ data[0] + '/group/' + encodeURIComponent(data[1]), authHeader.classicalHeader())
}

function linkedUserToGroup(data) {
    return axios.post('/users/'+ data[0] + '/group', data[1], authHeader.formDataHeader())
}

function removeUserFromCampaign(user, campaign) {
    return axios.delete('/users/'+ user + '/campaign/' + campaign, authHeader.formDataHeader())
}
