import api from '@/services/api/index.js';
import Toast from "@/services/swal2/mixins";

const state = {
    loadingStatus: false,
    settings: {}
}
const mutations = {
    SET_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus;
    },
    SET_MOBILE_SETTINGS: (state, request) => {
        state.settings = request;
    }
};

const actions = {
    createMobileSetting: async (store, params) => {
        store.commit('SET_LOADING_STATUS');
        await api.mobile.setNewMobileSettings(params)
            .then( response => {
            store.commit('SET_LOADING_STATUS');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message
                })
            });
    },
    editMobileSettings: async (store, params) => {
        store.commit('SET_LOADING_STATUS');
        await api.mobile.editMobileSettings(params)
            .then( response => {
                store.commit('SET_LOADING_STATUS');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message,
                })
            })
            .catch(error => {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message
                })
            });
    },
    setMobileSettingList: async (store) => {
        store.commit('SET_LOADING_STATUS');
        await api.mobile.getLastMobileSettings()
            .then( response => {
                store.commit('SET_MOBILE_SETTINGS', response.data.data);
                store.commit('SET_LOADING_STATUS');
            })
    }
}
const getters = {
    getMobileSettingsList: state => {
        return state.settings;
    }
}

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}