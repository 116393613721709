<template>
    <div class="">
        <apexchart :width="width" :height="height" type="heatmap" :options="chartOptions" :series="series"/>
    </div>
</template>

<script>

export default {
    name: "HeatMapChartExample",
    props:{
        series: {
            type: Array,
            default: () => {
                return [{
                    name: 'Global Concentration',
                    data: []
                }]
            }
        },
        chartOptions:Object,
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>

</style>