import { render, staticRenderFns } from "./NewArchitectureMigration.vue?vue&type=template&id=347d3810&scoped=true&"
import script from "./NewArchitectureMigration.vue?vue&type=script&lang=js&"
export * from "./NewArchitectureMigration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "347d3810",
  null
  
)

export default component.exports