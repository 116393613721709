export default {
    generatePassword
}

function generatePassword(character = 8) {
    let charList = 'azertyupqsdfghjkmwxcvbn23456789AZERTYUPQSDFGHJKMWXCVBN';
    let validPassword = false
    let pass = '';
    while (!validPassword){
        pass = ''

        for(let i=0;i<character;i++){
            let wpos = Math.round(Math.random()*charList.length);
            pass+=charList.substring(wpos,wpos+1);
        }

        let test = matchExpression(pass)

         validPassword = test.containsAlphabet && test.containsNumber
    }

    return pass
}

function matchExpression(str) {
    var rgularExp = {
        contains_alphaNumeric : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        containsNumber : /\d+/,
        containsAlphabet : /[a-zA-Z]/,

        onlyLetters : /^[A-Za-z]+$/,
        onlyNumbers : /^[0-9]+$/,
        onlyMixOfAlphaNumeric : /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/
    }

    var expMatch = {};
    expMatch.containsNumber = rgularExp.containsNumber.test(str);
    expMatch.containsAlphabet = rgularExp.containsAlphabet.test(str);
    expMatch.alphaNumeric = rgularExp.contains_alphaNumeric.test(str);

    expMatch.onlyNumbers = rgularExp.onlyNumbers.test(str);
    expMatch.onlyLetters = rgularExp.onlyLetters.test(str);
    expMatch.mixOfAlphaNumeric = rgularExp.onlyMixOfAlphaNumeric.test(str);

    return expMatch;
}