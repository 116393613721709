<template>
    <div class="row">
        <div class="col-xl-12">
            <p class="clr-white bg-dark-grey rounded p-1 my-1 col-xl-12">PREVIEW</p>
            <div v-html="html.outerHTML" class="col-xl-12 w-100" style="min-height: 500px"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IframePreview",
    props: {
        html: null
    }
}
</script>

<style lang="scss" scoped>
</style>