import axios from "../../plugins/axios";

// const querystring = require('querystring');
import authHeader from "./authHeader";

const prefix = '/admin/generator'

export default {
    getContent,
    getContentWithData,
    getLocations
}

function getContent(params) {
    return axios.get(prefix, {params: params, ...authHeader.classicalHeader()})
}

function getContentWithData(data) {
    return axios.post(prefix, data[0] , {params: data[1], ...authHeader.classicalHeader()})
}

function getLocations() {
    return axios.get(prefix+'/locations', authHeader.classicalHeader())
}

