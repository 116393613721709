<template>
    <b-col sm="12" class="bg-white rounded shadow">
        <b-row class="">
              <b-col cols="4"
                     class=" rounded add-cursor text-center py-2"
                     @click="subPage = 'mqtt'"
                     :class="subPage === 'mqtt' ? 'clr-orange bg-white' : 'clr-dark-grey bg-lighter-grey'"
              >
                  <p class="">
                    MQTT
                  </p>
              </b-col>
              <b-col cols="4"
                     class="rounded add-cursor text-center py-2"
                     @click="subPage = 'matooma'"
                     :class="subPage === 'matooma' ? 'clr-orange bg-white' : 'clr-dark-grey bg-lighter-grey'">
                  <p class="">
                    MATOOMA
                  </p>
              </b-col>
              <b-col cols="4"
                     class="rounded text-center py-2 bg-lighter-grey">
                  <p class="">
                  </p>
              </b-col>
        </b-row>
      <FullMQTT :mode-individual="true" :serial-number="this.$route.params.id" v-if="subPage === 'mqtt'"></FullMQTT>
      <MatoomaFollowUp :sim-id="actualSensor.sensorParameters.simId"
                       v-if="actualSensor.sensorParameters.simId && subPage === 'matooma'"
                       class="bg-white rounded"
      />
  </b-col>
</template>

<script>
import offsetCalculator from "@/services/sensor/offsetCalculator";
import operationRateCalculator from "@/services/sensor/operationRateCalculator";
import Vuex from "vuex";
import moment from "moment/moment";
import FullMQTT from "@/components/mqtt/FullMQTT.vue";
import MatoomaFollowUp from "@/components/sensor/Detail/MatoomaFollowUp.vue";

export default {
    name: "SensorTools",
    components: {MatoomaFollowUp,FullMQTT},
    props:{
      actualSensor:Object,
      actualStatus:String,
    },
    data(){
        return {
            isViabilityLoading: true,
            offsetPeriod: null,
            isOffsetRecent: true,
            isOperationRateRecent: true,
            operationRatePeriod: null,
            subPage:'mqtt'
        }
    },
    methods:{
        gotToSensorOffsetHistory() {
            this.$router.push('/sensors/' + this.$route.params.id + '/history/offset');
        },
        gotToSensorOperationRate() {
            this.$router.push('/sensors/' + this.$route.params.id + '/history/operation-rate');
        },
        offsetColor(data) {
            return offsetCalculator.calculateOffsetLevel(data, this.actualSensor.sensorVersion);
        },
        operationRateColor(rate) {
            return operationRateCalculator.calculateOperationRateLevel(rate)
        },
        isOnline(date, offset = 2) {
            let utcData = moment.utc(date);
            let twoHourAgo = moment.utc().subtract(offset, 'hours').toDate().toUTCString();
            return utcData.isAfter(twoHourAgo);
        },
    },
    computed:{
        ...Vuex.mapGetters('sensor', {
            actualOffsetHistory: "actualOffsetHistory",
            sensorsRate: "sensorsRate"
        })
    },
    mounted() {
        // Calculate offset data's viability
        let offsetViability = offsetCalculator.calculateOffsetViability(this.actualOffsetHistory);
        this.isOffsetRecent = offsetViability.isRecent;
        this.offsetPeriod = offsetViability.period;

        // Calculate operation rate data's viability
        let operationRateViability = operationRateCalculator.calculateOperationRateViability(this.sensorsRate)
        this.isOperationRateRecent = operationRateViability.isRecent;
        this.operationRatePeriod = operationRateViability.period;
    }
}
</script>

<style scoped>

</style>