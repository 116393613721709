<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card" v-if="loadingStatus || isLoading">
            <h2 class="mb-5 text-center">
                Modifier le compte pour le capteur
                <span class="clr-orange">
                    <br>N° {{ $route.params.id |toUpperCase }}
                </span>
            </h2>
            <b-skeleton
                v-for="(skeleton, index) in skeletons"
                :key="index"
                :width="randomWidth()"
                :height="skeleton.height"
                animation="wave"
                class="mb-4"/>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
                <b-col cols="auto">
                    <b-skeleton
                        width="100px"
                        height="40px"
                        animation="wave"/>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" md="8" class="basic-card" v-else>
            <h2 class="mb-5 text-center">
                Modifier le compte pour le capteur
                <span v-if="individualSensor" class="clr-orange">
                    <br>N° {{ individualSensor.serialNumber |toUpperCase }}
                </span>
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <p class="h4 mb-4">Affectation</p>
                <b-form-group
                    label="Veuillez sélectionner un compte à associer :"
                    label-for="account"
                >
                    <b-form-input
                        id="account"
                        list="account-list"
                        placeholder="Compte"
                        @change="refreshAccounts"
                        :state="chosenAccount !== '' ? true : null"
                        v-model="chosenAccount"/>
                    <datalist id="account-list">
                        <option
                            v-for="(account, index) in accounts"
                            :key="index"
                            :value="account.name">
                            {{ account.name }}
                        </option>
                    </datalist>
                </b-form-group>
                <b-form-group
                    label="Veuillez sélectionner un groupe à associer :"
                    v-slot="{ ariaDescribedby }"
                    label-for="account-groups"
                    v-if="chosenAccount">
                    <b-form-select
                        v-model="chosenAccountGroup"
                        id="account-groups"
                        required
                        :state="chosenAccountGroup !== '' ? true : null">
                        <b-form-select-option
                            :aria-describedby="ariaDescribedby"
                            :value="group.id"
                            v-for="(group, index) in groupsBasedOnChosenAccounts"
                            :key="index">
                            {{ group.name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    label="Type d'accès :"
                    label-for="access-type"
                    v-if="chosenAccount && chosenAccountGroup">
                    <b-form-select
                        id="access-type"
                        :state="accessType !== '' ? true : null"
                        v-model="accessType"
                        :options="accessTypes"/>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "SensorUpdateAccount",
    props: {
        type: {
            type: String,
            default: 'pollen'
        }
    },
    data() {
        return {
            searchTimeout: null,
            chosenAccount: '',
            chosenAccountGroup: '',
            accessType: 'standard',
            isLoading: false,
            accessTypes: [
                {value: 'standard', text: 'Standard'},
                {value: 'pro', text: 'Entreprise'},
                {value: 'scientific', text: 'Scientifique'}
            ],
            skeletons: [
                {height: "30px"},
                {height: "50px"},
                {height: "40px"},
            ]
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            changeSensorAccount: 'changeSensorAccount',
            setIndividualSensors: 'setIndividualSensors'
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts',
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.changeSensorAccount(query).then(() => {
                this.goToSensorProfile();
            });
        },
        generateQueryString() {
            const data = {sensor: this.$route.params.id};
            const fields = new FormData();
            if (this.chosenAccountGroup !== '') {
                fields.append('accountGroup', this.chosenAccountGroup);
            }
            fields.append('accessType', this.accessType)

            data.data = fields
            return data
        },
        goToSensorProfile() {
            this.$router.push('/sensors/' + this.$route.params.id + '/detail');
        },
        setOptionsAccountGroups() {
            this.optionsAccountGroups = []
            let accountList = this.accounts
            let selectedAccount = accountList.filter(item => item.name === this.chosenAccount)[0]
            if (selectedAccount) {
                for (let i = 0; i < selectedAccount.accountGroups.length; i++) {
                    this.optionsAccountGroups.push(selectedAccount.accountGroups[i])
                }
            } else {
                this.optionsAccountGroups = [];
            }
        },
        randomWidth() {
            return `${Math.random() * (100 - 60) + 60}%`;
        },
        refreshAccounts() {
            clearTimeout(this.searchTimeout)
            if (this.chosenAccount.length > 2) {
                this.searchTimeout = setTimeout(async () => {
                    await this.setAccounts({search: this.chosenAccount})
                }, 300);
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            loadingStatus: 'loadingStatus'
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
            getAccountGroupsByAccountName: 'getAccountGroupsByAccountName'
        }),
        loadedAccountGroups() {
            this.setOptionsAccountGroups()
            return this.optionsAccountGroups
        },
        groupsBasedOnChosenAccounts() {
            return this.getAccountGroupsByAccountName(this.chosenAccount)
        }
    },
    async created() {
        this.isLoading = true;
        if (Object.keys(this.individualSensor).length === 0 ||
            this.individualSensor.serialNumber !== this.$route.params.id) {
            await this.setIndividualSensors(this.$route.params.id);
        }
        await this.setAccounts()
        this.isLoading = false;
    },
}
</script>

<style lang="scss" scoped>

</style>
