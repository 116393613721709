<template>
    <div class="row justify-content-center">
        <div class="col-xl-8">
            <b-button @click="$router.push('/parameters/report-types/new')" class="my-2" variant="primary">
                <b-icon icon="plus-circle"></b-icon>
                Ajouter un Type de données
            </b-button>
            <b-table-simple
                    style="max-height: calc(100% - 40%);"
                    class="sensor-array"
                    hover
                    responsive="true"
                    sticky-header="true"
                    v-if="reportTypes"
            >
                <colgroup>
                    <col>
                    <col>
                </colgroup>
                <colgroup>
                    <col>
                    <col>
                    <col>
                </colgroup>
                <colgroup>
                    <col>
                    <col>
                </colgroup>
                <b-thead class="text-center clr-dark-blue bg-white sensor-array-header">
                    <b-tr class="equipment-header">
                        <b-th>Id</b-th>
                        <b-th>type</b-th>
                        <b-th>unité</b-th>
                        <b-th>catégorie</b-th>
                        <b-th>Action</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                        :key="i"
                        class="text-center clr-dark-blue sensor-body add-cursor overflow-auto"
                        v-for="(item,i) in reportTypes"
                    >
                        <b-td>{{ item.id }}</b-td>
                        <b-td>{{ item.name }}</b-td>
                        <b-td>{{ item.unit }}</b-td>
                        <b-td>{{ item.category }}</b-td>
                        <b-td>
                            <b-button @change="optionRisks(item.risks)" @click="$bvModal.show('modal-scoped'+item.id)"
                                      title="Niveau de risque associés" v-b-tooltip.hover
                                      v-if="item.risks.length > 0" variant="link">
                                <b-icon icon="bar-chart-fill"></b-icon>
                            </b-button>
                            <b-modal
                                    :id="'modal-scoped'+item.id"
                                    :title="'Niveau de Risque : '+item.name"
                                    centered
                                    scrollable
                            >
                                <b-card :key="i" class="mb-1" no-body v-for="(risk,i) in item.risks">
                                    <b-card-header class="p-1" header-tag="header" role="tab">
                                        <b-button block v-b-toggle="'accordion-' + i" variant="primary">
                                            {{ risk.riskZone.zoneName }}
                                        </b-button>
                                    </b-card-header>
                                    <b-collapse :id="'accordion-'+i" accordion="my-accordion" role="tabpanel" visible>
                                        <b-card-body>
                                            <b-card-text>Niveau 1 : <code class="font-weight-bolder">{{
                                                    risk.level1
                                                }}</code></b-card-text>
                                            <b-card-text>Niveau 2 : <code>{{ risk.level2 }}</code></b-card-text>
                                            <b-card-text>Niveau 3 : <code>{{ risk.level3 }}</code></b-card-text>
                                            <b-card-text>Niveau 4 : <code>{{ risk.level4 }}</code></b-card-text>
                                            <b-card-text>Niveau 5 : <code>{{ risk.level5 }}</code></b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </b-modal>
                            <b-button no-caret size="lg" title="Supprimer cet élément" toggle-class="text-decoration-none"
                                      v-b-tooltip.hover variant="link">
                                <span @click="removeType(item)" href="#"><b-icon icon="trash"></b-icon></span>
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "ReportTypesList",
    methods: {
        ...Vuex.mapActions('parameter', {
            setReportTypes: 'setReportTypes',
            deleteReportType: 'deleteReportType'
        }),
        removeType(reportType) {
            this.$swal.fire({
                title: "Etes vous sur de vouloir supprimer le type : \"" + reportType.name + "\" ?",
                text: "Cette action est irréversible",
                icon: 'warning',
                risk: '',
                risksValues: [],
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, je suis sur!'
            }).then((result) => {
                if (result.value) {
                    this.deleteReportType(reportType.id)
                }
            })
        },
        optionRisks(risks) {
            for (let i = 0; i < risks.length; i++) {
                this.risksValues.push(risks[i].riskZone.zoneName)
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('parameter', {
            reportTypes: 'reportTypes'
        }),
    },
    mounted() {
        this.setReportTypes()
    }
}
</script>

<style scoped>

</style>