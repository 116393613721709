import moment from "moment";

export default {
    calculateOffsetLevel,
    calculateOffsetViability
}

function calculateOffsetLevel(data, sensorVersion) {
    let level = 'low';
    let maxOffset = 30000;
    let middleOffset = 15000;
    if (sensorVersion === 'v1') {
        maxOffset = 40000
        middleOffset = 30000
    }


    if (data.offsetv1 == 0 || data.offsetv2 == 0 || data.offsetv3 == 0 || data.offsetv4 == 0) {
        level = 'high';
        return level;
    }
    if (data.offsetv1 >= maxOffset || data.offsetv4 >= maxOffset || data.offsetv2 >= maxOffset || data.offsetv3 >= maxOffset) {
        level = 'high';
    } else if (data.offsetv1 >= middleOffset || data.offsetv4 >= middleOffset || data.offsetv2 >= middleOffset || data.offsetv3 >= middleOffset) {
        level = 'medium';
    }
    return level;
}

function calculateOffsetViability(data) {
    let result = {isRecent: true, period: null};
    if (data !== null) {
        let offsetDate = moment(data.date);
        let offsetPeriod = moment().diff(offsetDate, 'days')
        result.period = offsetPeriod;
        if (offsetPeriod > 1) {
            result.isRecent = false;
        }
    }
    return result;
}
