<template>
    <apexchart
        :width="width"
        :height="height"
        type="donut"
        :stacked="false"
        :options="chartOptions"
        :series="series"/>
</template>

<script>
export default {
    name: "DonutChart",
    props:{
        series: {
            type: Array,
            default: () => {
                return []
            }
        },
        chartOptions:Object,
        width: {
            type: String,
            default: '90%'
        },
        height: {
            type: String,
            default: '90%'
        }
    }
}
</script>

<style scoped>

</style>