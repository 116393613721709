import dataviz from "@/views/Dataviz";

export default [
    {
        path:'/dataviz',
        name: 'Dataviz',
        component: dataviz,
        meta: {
            requiresAuth: true,
            breadCrumb: [
                {
                    name: 'Home',
                    link: '/'
                },
                {
                    name: 'Dataviz'
                }
            ]
        }
    }
]