<template>
    <div>
        <b-spinner label="loader" class="mx-auto text-center spinner" v-if="isDataAccessLoading || !accesses"/>
        <p class="text-center" v-else-if="accesses.length === 0 || !isThereCoordinates">
            Ce compte n'a accès à aucune <span class="font-weight-bold">coordonnée</span> pour le moment.
        </p>
        <b-table-simple outlined hover sticky-header v-else>
            <colgroup>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
            </colgroup>
            <b-thead class="text-center">
                <b-tr>
                    <b-th>Id</b-th>
                    <b-th>Groupe</b-th>
                    <b-th>Lieu</b-th>
                    <b-th>Lat, Long</b-th>
                    <b-th>Création</b-th>
                    <b-th>Fin</b-th>
                    <b-th>Type</b-th>
                    <b-th>Action</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(access,i) in accesses" :key="i"
                      class="text-center add-cursor justify-content-center align-items-center">
                    <b-td class="align-middle">{{ access.id }}</b-td>
                    <b-td class="align-middle">{{ access.accountGroup.name }}</b-td>
                    <b-td class="align-middle text-left"
                          v-if="access.accesses.coordinates && Object.entries(access.accesses.coordinates).length > 0">
                        <p class="mb-0" v-for="(place, index) in Object.keys(access.accesses.coordinates)" :key="index">
                                <b-badge variant="primary">{{ place }}</b-badge>
                        </p>
                    </b-td>
                    <b-td class="align-middle text-left"
                          v-if="access.accesses.coordinates && Object.entries(access.accesses.coordinates).length > 0">
                        <p class="mb-0" v-for="(coordinate, index) in access.accesses.coordinates" :key="index">
                                {{ coordinate[0] }}, {{ coordinate[1] }}
                        </p>
                    </b-td>
                    <b-td class="align-middle" v-else>Aucune</b-td>
                    <b-td class="align-middle">{{ access.createdAt | dayDate }}</b-td>
                    <b-td class="align-middle" v-if="access.endAt">{{ access.endAt | dayDate }}</b-td>
                    <b-td class="align-middle" v-else>- - -</b-td>
                    <b-td class="align-middle">
                        <b-badge :class="`bg-${colorsByAccessType[access.accessType]}`" class="p-2">
                            {{ access.accessType | toUpperCase }}
                        </b-badge>
                    </b-td>
                    <b-td class="align-middle">
                        <b-row class="justify-content-center">
                            <b-col cols="2" @click="gotToAccessEdit(access.id)">
                                <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                            </b-col>
                            <b-col cols="2" @click="deleteAccess(access.id)">
                                <b-icon icon="dash-square" aria-hidden="true"></b-icon>
                            </b-col>
                        </b-row>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "AccessCoordinatesTable",
    data() {
        return {
            colorsByAccessType: {
                'standard': 'green3',
                'pro': 'light-green',
                'scientific': 'yellow',
            },
        }
    },
    computed: {
        ...Vuex.mapGetters('dataAccess', {
            isDataAccessLoading: 'isDataAccessLoading',
            getRequest: 'getRequest',
            getDataAccess: 'getDataAccess'
        }),
        accesses() {
            return this.getDataAccess.locations;
        },
        isThereCoordinates() {
            let res = false;
            this.getDataAccess.locations.forEach((location) => {
                Object.keys(location.accesses).forEach((locationType) => {
                    if (locationType === 'coordinates') {
                        res = true;
                    }
                })
            })
            return res;
        },
    },
    methods: {
        ...Vuex.mapActions('dataAccess', {
            deleteDataAccess: 'deleteDataAccess',
            setDataAccessByAccount: 'setDataAccessByAccount'
        }),
        gotToAccessEdit(id) {
            this.$router.push({name: 'AccountAccessEdit', params: {id: id, type: 'location'}});
        },
        deleteAccess(accessId){
            this.$swal.fire({
                title: 'Êtes vous sûr?',
                text: "ATTENTION: Cela entraînera la suppression de tous les  (lieux/GPS)",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#FF5A01',
                cancelButtonColor: '#AAB4C5',
                confirmButtonText: 'Oui, supprimer les droits d\'accès!'
            }).then( (result) => {
                if (result.isConfirmed) {
                    this.deleteDataAccess(accessId).then( () => {
                        if (this.getRequest.success) {
                            this.setDataAccessByAccount(this.$route.params.id)
                            this.$swal.fire(
                                'Supprimé!',
                                'L\'accès à bien été supprimé!',
                                'success'
                            )
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.spinner {
    width: 3rem;
    height: 3rem;
}
</style>