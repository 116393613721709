<template>
    <b-row class="col-11 basic-card multi-step-form p-1 p-md-5">
        <b-col cols="12" class="mb-5">
            <slot name="header"></slot>
        </b-col>
        <b-col cols="12" class="mb-3">
            <b-row class="justify-content-between">
                <b-col cols="12" md="3" class="pl-0">
                    <b-row
                            v-for="(step, index) in steps"
                            :key="index"
                            class="justify-content-center mb-1"
                            align-v="stretch">
                        <b-row v-if="index === 0" class="col-12 mb-1">
                            <b-col cols="8">
                            </b-col>
                            <b-col cols="4" align-self="start">
                                <div class="vertical-divider"/>
                            </b-col>
                        </b-row>
                        <b-row class="col-12">
                            <b-col cols="8"
                                   align-self="center"
                                   class="text-right add-cursor"
                                   @click="goToStep(index)">
                                <p class="m-0"
                                   :class="actualStep === index ? 'clr-orange' : ''">
                                    {{ step.name }}
                                </p>
                            </b-col>
                            <b-col cols="4"
                                   @click="goToStep(index)"
                                   align-self="center"
                                   class="clr-orange add-cursor m-0 h2">
                                <b-avatar class="completed" v-if="step.completed">
                                    <b-icon-check/>
                                </b-avatar>
                                <b-avatar class="ongoing" v-else-if="actualStep === index">
                                    {{ index }}
                                </b-avatar>
                                <b-avatar class="waiting" v-else>
                                    {{ index }}
                                </b-avatar>
                            </b-col>
                        </b-row>
                        <b-row class="col-12 mt-2">
                            <b-col cols="8">
                            </b-col>
                            <b-col cols="4">
                                <div class="vertical-divider"/>
                            </b-col>
                        </b-row>
                    </b-row>
                </b-col>
                <b-col cols="12" md="9" class="mt-2 mt-md-5">
                    <slot name="step-content"></slot>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12">
            <b-row class="justify-content-end">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="goBack">Précédent</b-button>
                </b-col>
                <b-col cols="auto" v-if="steps.length === actualStep + 1">
                    <b-button class="btn-action" :disabled="!formCompleted" @click="submitForm">Terminer</b-button>
                </b-col>
                <b-col cols="auto" v-else>
                    <b-button class="btn-action" :disabled="nextDisable" @click="confirmStep">Suivant</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "MultiStepForm",
    props: {
        nextDisable: {
            type: Boolean,
            default: true
        },
        formType:{
            type:String,
            default: 'sensorSetup'
        }
    },
    data() {
        return {}
    },
    methods: {
        ...Vuex.mapActions('sensorSetup', {
            setActualStepSensorSetup: 'setActualStep'
        }),
        ...Vuex.mapActions('deliveryTracking', {
            setActualStep: 'setActualStep'
        }),
        goBack() {
            if (this.actualStep === 0) {
                this.$router.back();
            } else {
                if (this.formType === 'sensorSetup') {
                    this.setActualStepSensorSetup(this.actualStep - 1);
                } else {
                    this.setActualStep(this.actualStep - 1);
                }
            }
        },
        confirmStep() {
            this.$emit('confirmStep')
        },
        submitForm() {
            this.$emit('submitForm')
        },
        goToStep(step) {
            const previousStep = this.steps[step - 1];
            const nextStep = this.steps[step + 1];
            if (step <= this.actualStep || (previousStep && previousStep.completed) || (nextStep && nextStep.completed)) {
                this.setActualStep(step);
            }
        }
    },
    computed: {
        actualStep() {
            return this.$store.getters[this.formType+'/actualStep']
        },
        steps() {
            return this.$store.getters[this.formType+'/steps']
        },
        formCompleted() {
            return this.$store.getters[this.formType+'/formCompleted']
        }
    }
}
</script>

<style lang="scss" scoped>
.multi-step-form {
  .vertical-divider {
    opacity: 0.4;
    height: 50px;
    width: 2px;
    margin-left: 1.2em;
    border-left: 2px solid $light-grey;
  }

  .completed {
    font-size: 30px;
    color: $orange;
    background-color: $white;
    border: 2px solid $orange;
  }

  .ongoing {
    font-size: 16px;
    background-color: $orange;
    color: $white;
  }

  .waiting {
    font-size: 16px;
    background-color: $white;
    border: 2px solid $dark-grey;
    color: $dark-grey;
  }
}

</style>