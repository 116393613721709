import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getCurrentSensorMonitoringBySensor,
    getSensorMonitoringPaginated,
    getCurrentSensorMonitoring,
}

function getCurrentSensorMonitoringBySensor(sensor) {
    return axios.get('/admin/sensors/' + sensor + '/sensor-monitorings/current', authHeader.classicalHeader())
}

function getCurrentSensorMonitoring() {
    return axios.get('/admin/sensors/sensor-monitorings/current', authHeader.classicalHeader())
}

function getSensorMonitoringPaginated(params) {
    return axios.get('/admin/sensors/' + params[0] + '/sensor-monitorings', {params: params[1], ...authHeader.classicalHeader()})
}