<!-- '#188cc6' : '#F44336' -->
<template>
    <radial  :chart-options="options" :series="series" ></radial>
</template>

<script>
import RadialChart from "@/components/chart/RadialChart";
import {item} from "@/services/charts/chartsOptions"
export default {
    components:{
      'radial': RadialChart
    },
    props: {
        consumption: Number,
        random: String,
        progress: Number,
    },
    data(){
        return {
            options: item.dataConsumption,
            series: [],
        }
    },
    methods: {
        seriesCalculator() {
            if (this.progress <= 100) {
                this.series.push(Math.floor(this.progress));
            } else {
                const last = parseInt(String(Math.floor(this.progress)).slice(-2));
                let i = Math.floor(this.progress) - last;
                while (i/100 > 1){
                    this.series.push(i -100)
                    i -= 100
                }
                this.series.push(i, last);
            }
        },
        buildChartOption(){
            this.options.plotOptions.radialBar.dataLabels.total.label = [[this.consumption + 'MB'], [Math.floor(this.progress) + '%']];
        }
    },
    created() {
        this.seriesCalculator();
        this.buildChartOption()
    }
}
</script>

<style lang="scss" scoped>
</style>
