import AWSServices from "@/components/infrastructure/AWSServices";
import AWSEBS from "@/components/infrastructure/AWSEBS";
import AWSS3 from "@/components/infrastructure/AWSS3";
import GitlabGlobalView from "@/components/infrastructure/GitlabGlobalView";
import AWSDynamoDB from "@/components/infrastructure/AWSDynamoDB";

export default [
    {
        path: '/infrastructures',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Infrastructure.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                component: AWSServices,
                name: 'infrastructures',
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Infrastructures'
                        }
                    ]
                },
            },
            {
                path: 'EBS',
                component: AWSEBS,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Infrastructures',
                            link: '/infrastructures',
                        },
                        {
                            name: 'AWS EBS'
                        }
                    ]
                },
            },
            {
                path: 'S3',
                component: AWSS3,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Infrastructures',
                            link: '/infrastructures',
                        },
                        {
                            name: 'AWS S3'
                        }
                    ]
                },
            },
            {
                path: 'gitlab',
                component: GitlabGlobalView,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Infrastructures',
                            link: '/infrastructures',
                        },
                        {
                            name: 'Projets Gitlab'
                        }
                    ]
                },
            },
            {
                path: 'dynamodb',
                component: AWSDynamoDB,
                meta: {
                    requiresAuth: true,
                    breadCrumb: [
                        {
                            name: 'Home',
                            link: '/'
                        },
                        {
                            name: 'Infrastructures',
                            link: '/infrastructures',
                        },
                        {
                            name: 'DynamoDB'
                        }
                    ]
                },
            }
        ]
    },
];