import api from '@/services/api/index.js'
import Toast from "@/services/swal2/mixins";

const state = {
	accounts: [],
	paginator: {
		current:1,
	},
	loadingStatus: false,
	request:{},
	users: []
};


const mutations = {
	SET_ACCOUNTS: (state, accounts) => {
		state.loadingStatus = true
		state.accounts = accounts;
		state.loadingStatus = false
	},
	SET_PAGINATOR: (state, paginator) => {
		state.loadingStatus = true
		state.paginator = paginator;
		state.loadingStatus = false
	},
	SET_ACCOUNT_USER_LIST: (state, user) => {
		state.loadingStatus = true
		state.users = user;
		state.loadingStatus = false
	},
	SET_REQUEST: (state, request) => {
		state.request = request;
	},
	ACCOUNTS_IS_LOADING_STATUS: (state) => {
		state.loadingStatus = !state.loadingStatus
	},
	CREATE_ACCOUNT: (state, account) => {
		state.loadingStatus = true
		state.accounts.push(account);
		state.loadingStatus = false
	},
	UPDATE_INDIVIDUAL_ACCOUNT: (state, data) => {
		state.loadingStatus = true
		let selectedAccount = state.accounts.find(account => account.id === data.id)
		Object.assign(selectedAccount, data);
		state.loadingStatus = false
	},
};
const actions = {
	setAccounts: async (store,data) => {
		await api.account.getAccounts(data)
			.then( response =>  {
				// store.commit('ACCOUNTS_IS_LOADING_STATUS');
				store.commit('SET_ACCOUNTS', response.data.accounts);
				store.commit('SET_PAGINATOR', response.data.paginator);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response
				})
			});
	},
	editAccount: async (store,data) => {
		await api.account.updateAccount(data)
			.then( response =>  {
				store.commit('UPDATE_INDIVIDUAL_ACCOUNT', response.data.data);
				store.commit('SET_REQUEST', response.data);
			})
	},
	emptyAccounts: async (store) => {
		store.commit('SET_ACCOUNTS', []);
	},
	deleteAccount: async (store,data) => {
		await api.account.deleteAccount(data)
			.then( response =>  {
				store.commit('SET_REQUEST', response.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	createAccount: async (store,data) => {
		await api.account.createAccount(data)
			.then( response =>  {
				// store.commit('SENSORS_IS_LOADING_STATUS');
				store.commit('CREATE_ACCOUNT', response.data.data);
				store.commit('SET_REQUEST', response.data);
			})
			.catch(error => {
				Toast.fire({
					icon: 'error',
					title: error.response.data.message,
				})
			});
	},
	setUsersList: async(store, data) => {
		await api.account.getAccountUserList(data)
			.then( response => {
				store.commit('SET_ACCOUNT_USER_LIST', response.data.data.users);
			})
			.catch(error => {
				Toast.fire({
					icon:'error',
					title: error.response.data.data
				})
			})
	}
};

const getters = {
	accounts: state => {
		return state.accounts
	},
	paginator: state => {
		return state.paginator
	},
	getAccountsByName: (state) => (name) => {
		return state.accounts.find(account => account.name === name)
	},
	getAccountsById: (state) => (id) => {
		return state.accounts.find(account => account.id === id)
	},
	getAccountGroupsByAccountId: (state) => (data) => {
		let accounts = state.accounts.filter(account => data[0].includes(account.id))
		let groups = []
		for (let i=0;i<accounts.length;i++){
			for (let y=0;y<accounts[i].accountGroups.length;y++) {
				groups.push(accounts[i].accountGroups[y])
			}
		}
		return groups
	},
	getAccountGroupsByAccountName: (state) => (name) => {
		let accounts = state.accounts.filter(account => name === account.name)
		let groups = []
		for (let i=0;i<accounts.length;i++){
			for (let y=0;y<accounts[i].accountGroups.length;y++) {
				groups.push(accounts[i].accountGroups[y])
			}
		}
		return groups
	},
	getAccountsByAccountIdNotChosen: (state) => (data) => {
		return state.accounts.filter(account => !data.includes(account.id))
	},
	loadingStatus : state => {
		return state.loadingStatus
	},
	getRequest : state => {
		return state.request
	},
	getAccountGroupById: (state) => (data) =>{
		if (state.accounts.filter(f => f.accountGroups.some(s => data.includes(s.id))).length >0){
			return state.accounts.filter(f => f.accountGroups.some(s => data.includes(s.id)))[0].accountGroups.filter(item => data.includes(item.id))
		} else {
			return []
		}
	},
	getUsersList: state => {
		return state.users
	}
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
