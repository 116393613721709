<template>
  <b-col sm="12" class="p-0 m-0">
    <div class="rounded bg-white shadow p-3 h-100">
      <b-row >
        <b-col cols="12" class="mb-3">
          <p class="h4 mb-2 clr-dark-grey responsive-font">Historique du capteur</p>
        </b-col>
        <b-col cols="12">
          <Timeline/>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>

import Timeline from "@/components/sensor/Detail/Timeline.vue";

export default {
  name: "SensorHistory",
  components: {Timeline}
}
</script>


<style scoped lang="scss">

</style>