import axios from "../../plugins/axios";
import authHeader from "@/services/api/authHeader";


export default {
    setNewMobileSettings,
    editMobileSettings,
    getLastMobileSettings
}

function setNewMobileSettings(data) {
    return axios.post('/mobile/settings',data, authHeader.formDataHeader())
}

function editMobileSettings(data) {
    return axios.post('/mobile/settings/'+ data.id, data.form, authHeader.formDataHeader())
}
function getLastMobileSettings() {
    return axios.get('/mobile/settings', authHeader.classicalHeader());
}