<template>
    <b-table-simple
        responsive
        hover
        sticky-header
        borderless
        class="table-height"
        v-if="alerts.length > 0"
    >
        <b-thead class="text-center">
            <b-tr class="">
                <b-th @click="setSort('alert.type')" class="add-cursor">Type d'alerte
                    <b-icon
                        icon="caret-down"
                        scale="1"
                        v-if="sort === 'alert.type' && direction === 'desc'"
                    >
                    </b-icon>
                    <b-icon
                        icon="caret-up"
                        scale="1"
                        v-else-if="sort === 'alert.type'"
                    >
                    </b-icon>
                </b-th>
                <b-th @click="setSort('alert.level')" class="add-cursor">Niveau d'alerte
                    <b-icon
                        icon="caret-down"
                        scale="1"
                        v-if="sort === 'alert.level' && direction === 'desc'"
                    >
                    </b-icon>
                    <b-icon
                        icon="caret-up"
                        scale="1"
                        v-else-if="sort === 'alert.level'"
                    >
                    </b-icon>
                </b-th>
                <b-th @click="setSort('alert.context')" class="add-cursor">Context
                    <b-icon
                        icon="caret-down"
                        scale="1"
                        v-if="sort === 'alert.context' && direction === 'desc'"
                    >
                    </b-icon>
                    <b-icon
                        icon="caret-up"
                        scale="1"
                        v-else-if="sort === 'alert.context'"
                    >
                    </b-icon>
                </b-th>
                <b-th @click="setSort('alert.createdAt')" class="add-cursor">Heure de déclenchement
                    <b-icon
                        icon="caret-down"
                        scale="1"
                        v-if="sort === 'alert.createdAt' && direction === 'desc'"
                    >
                    </b-icon>
                    <b-icon
                        icon="caret-up"
                        scale="1"
                        v-else-if="sort === 'alert.createdAt'"
                    >
                    </b-icon>
                </b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr
                v-for="(item,i) in alerts"
                :key="i"
                class="text-center add-cursor"
            >
                <b-td>{{ item.type }}</b-td>
                <b-td>{{ item.level }}</b-td>
                <b-td>{{ item.context }}</b-td>
                <b-td>{{ item.createdAt }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>

export default {
    name: "AlertTable",
    props: {
        alerts: Array,
        dataLocalisation: Object,
        loadingStatus: Boolean,
        sort: String,
        direction: String,
    },
    methods: {
        setSort(value) {
            this.$emit('set-sorting', value)
        },
    }
}
</script>
