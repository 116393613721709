import axios from "../../plugins/axios";
import authHeader from "./authHeader";

const prefix = '/data-access'

export default {
    getDataAccess,
    getDataAccessByAccount,
    getDataAccessByAccountGroup,
    createDataAccess,
    editDataAccess,
    deleteDataAccess
}

function getDataAccess(id) {
    return axios.get('data-access/' + id, authHeader.classicalHeader())
}

function getDataAccessByAccount(data) {
    return axios.get('admin/accounts/' + data + '/accesses', authHeader.classicalHeader())
}

function getDataAccessByAccountGroup(data) {
    return axios.get(prefix + '/account-groups/' + data, authHeader.classicalHeader())
}

function createDataAccess(data) {
    return axios.post('admin/data-accesses/new', data, authHeader.formDataHeader())
}

function editDataAccess(id, params) {
    return axios.post('admin/data-accesses/' + id + '/edit', params, authHeader.formDataHeader())
}

function deleteDataAccess(dataAccess) {
    return axios.delete( `admin/data-accesses/${dataAccess}`, authHeader.classicalHeader())
}
