<template>
    <b-row class="justify-content-center align-content-center">
        <b-col cols="12" md="8" class="basic-card">
            <h2 class="mb-5 text-center">
                Création d'un compte
            </h2>
            <b-form @submit.prevent="submit()" class="mb-3 px-1 px-md-5">
                <b-form-group
                    id="input-group-1"
                    label="Nom du compte :"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="accountName"
                        type="text"
                        required
                        placeholder="Client TEST"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label="Plan"
                    label-for="input-2"
                >
                    <b-form-select v-model="plan" :options="optionsPlan" class="mb-3">
                    </b-form-select>
                </b-form-group>
                <b-form-group id="input-group-3" label="Logo" label-for="input-3">
                    <b-form-file
                        v-model="logo"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                </b-form-group>
            </b-form>
            <b-row class="justify-content-end mt-5 mr-2">
                <b-col cols="auto">
                    <b-button class="btn-cancel" @click="$router.go(-1)">Annuler</b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button class="btn-action" @click="submit">Valider</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import Vuex from 'vuex'
import Toast from "@/services/swal2/mixins";

export default {
    name: "AccountCreate",
    data() {
        return {
            accountName: '',
            plan: '',
            logo: null,
            optionsPlan: [
                {value: 'standard', text: 'Standard'},
                {value: 'individual', text: 'Mobile'},
                {value: 'pro', text: 'Entreprise'},
                {value: 'experimentation', text: 'Experimentation'},
            ],
        }
    },
    methods: {
        ...Vuex.mapActions('account', {
            createAccount: 'createAccount'
        }),
        async submit() {
            let query = this.generateQueryString()
            await this.createAccount(query).then();
        },
        generateQueryString() {
            let data = new FormData();
            data.append('name', this.accountName)
            data.append('planLevel', this.plan)
            data.append('logo', this.logo)
            return data
        },
        goToAccountsList() {
            this.$router.push('/accounts');
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            getRequest: "getRequest"
        }),
    },
    watch: {
        getRequest() {
            Toast.fire({
                icon: 'success',
                title: this.getRequest.message,
            }).then(
                setTimeout(this.goToAccountsList, 1500)
            )
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
